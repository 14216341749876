<template>
  <section class="chart-data-root">
    <div class="warning" v-if="!hasData">{{ parameterName }} data not available for display</div>
    <div class="chart-data-line-chart" :id="chartId" :style="chartStyle" ref="chart" v-if="hasData">
      <div v-if="!isChartOptionsValid" class="invalid-options">
        <div v-if="!hasXKey">No x-axis key defined for data</div>
        <div v-if="!hasYKey">No y-axis key defined for data</div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import * as d3 from "d3";
import momentTimezone from 'moment-timezone';

export default {
  props: {
    chartId: {
      type: String,
      default: () => `linechart-${Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))}`
    },
    colors: { type: Array, default: () => d3.schemeCategory10 },
    width: { type: String, default: '100%' },
    height: { type: String, default: '400px' },
    xAxisLabel: { type: String, default: null },
    yAxisLabel: { type: String, default: null },
    title: { type: String, default: null },
    xKey: { type: String, required: true },
    yKey: { type: String, required: true },
    parameterName: { type: String, required: false },
    interval: { type: String, default: "year" },
    thresholdValues: { type: Array, default: [] },
    dateFormat: { type: String, default: '%Y-%m-%dT%H:%M:%S.000000000' },
    timezone: { type: String, default: 'UTC' },
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    minTime() {
      const startDateTime = new moment.utc().tz(this.timezone).add(-6, 'hours');
      return startDateTime;
    },
    maxTime() {
      const endDateTime = new moment.utc().tz(this.timezone).add(5, 'days');
      return endDateTime;
    },
    ordinalColors() {
      return d3.scaleOrdinal(this.colors);
    },
    chartStyle() {
      return {
        width: this.width,
        height: this.height
      };
    },
    hasData() {
      return this.data.length !== 0;
    },
    hasXKey() {
      return !!this.xKey;
    },
    hasYKey() {
      return !!this.yKey;
    },
    isChartOptionsValid() {
      return this.hasData && this.hasXKey && this.hasYKey;
    },
    chartData() {
      const parseDate = date => {
        const r = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss.000000000Z').tz(this.timezone);
        if (!r) throw Error(`String date format expected: ${this.dateFormat}`);
        return r;
      };
      const xKey = this.xKey;
      const yKey = this.yKey;
      const returnValues = this.data.map(function(o){
        return {
          ...o,  values: o.values.map(function(d){
            return {
              x: parseDate(d[xKey]),
              y: +d[yKey]
            }
          })
        }
      });
      return returnValues
    },
    chartHeight() {
      return this.$refs.chart ? this.$refs.chart.clientHeight : 0;
    },
    chartWidth() {
      return this.$refs.chart ? this.$refs.chart.clientWidth : 0;
    },
    xAxisMax() {
      return Math.max(this.chartData.map(({ values }) => values.length));
    },
    longestSeries() {
      return this.chartData.find(function(d){return d.values.length === this.xAxisMax});
    },
    xAxisTicksByInterval() {
      const { interval, xAxisMax, timezone } = this;

      const utcOffsetFromTimezone = moment.utc().tz(timezone).utcOffset();
      const absOffset = Math.abs(utcOffsetFromTimezone);
      const minutesOffset = absOffset % 60;
      // checking if site timezone is +1/-1 GMT
      const hoursOffset = utcOffsetFromTimezone > 0 ? (24 - Math.floor(absOffset/60)) : Math.floor(absOffset/60);
      const axisBy = {
        month: d3.timeMonth,
        week: d3.timeWeek,
        day: d3.utcMinute.filter(d => {
          return d.getUTCHours() === hoursOffset && d.getUTCMinutes() === minutesOffset;
        }),
        hour: d3.utcMinute.filter(d => {
          return d.getUTCMinutes() === minutesOffset;
        })
      };
      return axisBy[interval] || xAxisMax;
    }
  },
  watch: {
    data() {
      this.renderChart();
    }
  },
  methods: {
    multiDateFormat(date, i = this.getIndexOfFocusPoint(date)) {
      
      const formatter = {
        hour: moment(date).tz(this.timezone).format("HH:mm"),
        day: moment(date).tz(this.timezone).format("MMM DD"),
        week: moment(date).tz(this.timezone).format("ww"), 
        month: moment(date).tz(this.timezone).format("MMMM"), 
        quarter: moment(date).tz(this.timezone).format("Q"), 
        year: moment(date).tz(this.timezone).format("YYYY"),
      };

      return formatter[this.interval];
    },
    getMaxAndMinTimes() {
      const endDateTimeLimit = new moment.utc().tz(this.timezone).add(5, 'days');
      const earliestTimeLimit = new moment.utc().tz(this.timezone).add(-6, 'hours');
      let minDateTime = new moment.utc().tz(this.timezone).add(5, 'days');
      let maxDateTime = new moment.utc().tz(this.timezone).subtract(5, 'days');
      for(const dataset in this.data) {
        const model = this.data[dataset];
        const values = model.values;
        values.forEach(value => {
          const time = moment.utc(value.dateTime).tz(this.timezone);
          if (time > maxDateTime && time <= endDateTimeLimit) {
            maxDateTime = time;
          }
          if (time < minDateTime && time >= earliestTimeLimit) {
            minDateTime = time;
          }
        });
      }
      return [minDateTime, maxDateTime];
    },
    getDatumInSeries({ series: { values }, xPoint, focusPoint }) {
      const bisectDate = d3.bisector(d => d.x).left;
      const i = bisectDate(values, xPoint, 1);
      const d0 = values[i - 1];
      const d1 = values[i] || {};
      const closestD = xPoint - d0.x > d1.x - xPoint ? d1 : d0;
      const xPointMinusClosestD = Math.abs(xPoint - closestD.x);
      const focusPointMinusXPoint = Math.abs(xPoint - focusPoint);
      const r = focusPoint
        ? xPointMinusClosestD > focusPointMinusXPoint
          ? null
          : closestD
        : closestD;
      return r;
    },
    getDateSansTime(date) {
      return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
    },
    getIndexOfFocusPoint(focusPoint) {
      const { getDateSansTime, longestSeries } = this;
      let returnValues = longestSeries.values.findIndex(function(d){
        return getDateSansTime(d.x) === getDateSansTime(focusPoint)
      });
      return returnValues
    },
    renderChart() {
      const c = this;
      const {
        ordinalColors: colors,
        chartData: data,
        isChartOptionsValid,
        chartId,
        chartWidth,
        chartHeight,
        multiDateFormat,
        xAxisTicksByInterval,
        xAxisLabel,
        yAxisLabel,
        timezone
      } = c;
      if (!isChartOptionsValid) return;

      const axisOffset = 16;
      const labelOffset = 21 * 1.75 + axisOffset;
      const xAxisLabelOffset = 41 * 1.75 + axisOffset;

      const margin = {
        top: 20,
        left: 20 + (yAxisLabel ? labelOffset : 0),
        right: 20,
        bottom: 20 + (xAxisLabel ? xAxisLabelOffset : 0)
      };
      const width = chartWidth - margin.left - margin.right;
      const height = chartHeight - margin.top - margin.bottom;

      /* compute max and min y-value */
      let maxY = this.thresholdValues[0];
      let minY = this.thresholdValues[0];
      for (let j = 0; j < data.length; j++) {
        let vs = data[j].values;
        for (let i = 0; i < vs.length; i++) {
          let yVal = vs[i].y;
          maxY = yVal > maxY ? yVal : maxY;
          minY = yVal < minY ? yVal : minY;
        }
      }

      const maxMinTimes = this.getMaxAndMinTimes();
      const minTime = maxMinTimes[0];
      const maxTime = maxMinTimes[1];
      /* Scales */
      const xScale = d3
        .scaleUtc()
        .domain([minTime, maxTime])
        .range([0, width]);

      const yScale = d3
        .scaleLinear()
        .domain([minY, maxY])
        .range([height - 0, 30]);

      // const tooltip = d3.select(tooltipEl);

      /* remove previously rendered, if any */
      d3.select(`#${chartId} svg`).remove();
      d3.select(`#${chartId} div`).remove();
      // tooltip.html("");

      /* SVG */
      const svg = d3
        .select(`#${chartId}`)
        .append("svg")
        .attr("width", width + margin.left + margin.right + "px")
        .attr("height", height + margin.top + margin.bottom + "px");

      /* position main g element */
      const g = svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      /* add axes */
      const xAxis = d3
        .axisBottom(xScale)
        .tickSize(5)
        .ticks(xAxisTicksByInterval)
        .tickFormat(multiDateFormat);

      g.append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0, ${height})`)
        .call(xAxis)
        .selectAll("text")
        .attr("y", axisOffset);

      const yAxis = d3
        .axisLeft(yScale)
        .tickSize(5)
        .ticks(4)
        .tickFormat(d3.format("~s"))
        .scale(yScale.nice());

      g.append("g")
        .attr("class", "y axis")
        .call(yAxis)
        .selectAll("text")
        .attr("x", -axisOffset);

      // Add X axis label
      const xAxisTicksApproxHeight = 30;
      const xLabelOffset = xAxisTicksApproxHeight + 21;
      svg
        .select(".x.axis")
        .append("text")
        .text(xAxisLabel)
        .attr("class", "line-graph-label")
        .attr("transform", `translate(${width / 2}, ${xLabelOffset})`);

      // Add Y axis label
      svg
        .select(".y.axis")
        .append("text")
        .text(yAxisLabel)
        .attr("class", "line-graph-label")
        .attr(
          "transform",
          `translate(${-labelOffset}, ${(height / 2) / 2}) rotate(-90)`
        );

      //Create title
      svg
        .append("text")
        .attr("x", width / 2 + margin.left)
        .attr("y",  15 + margin.top)
        .attr("text-anchor", "middle")
        .style("font-size", "20px")
        .text(this.title);


      /* draw line fn for each series */
      const xMin = minTime;
      const xMax = maxTime;
      const drawLine = ({ values }) =>
        d3
          .line()
          .defined(function(d) {
            return d.x < xMax && d.x > xMin;
          })
          .x(d => xScale(d.x))
          .y(d => yScale(d.y))(values);

      /* add series lines */
      const lines = g.append("g").attr("class", "lines");
      // filter out observation data
      const lineData = data
      const series = lines
        .selectAll(".line-group")
        .data(lineData)
        .enter()
        .append("g")
        .attr("class", "line-group");

      series
        .append("path")
        .attr("class", d => 'line ' + d.name.replace(" ", ""))
        .attr('name', d => d.name)
        .attr("d", d => drawLine(d))
        .style("stroke", (d, i) => colors(i))
        .style("opacity", "1");

      // add data points as circles
      // TODO: use generators instead of loops
      const tooltip = d3.select("body")
        .append("div")
        .attr('class', 'chart-tooltip');
      const tooltipText = function(name, x, y, decimals=2) {
        let t = moment(x, 'ddd MMM DD YYYY HH:mm:ss Z').tz(timezone).format('MMM DD, HH:mm');
        let str = `<p>${name}<br/>${t}<br/>${y.toFixed(decimals)}</p>`
        return str
      };
      const points = g.append("g").attr("class", "points");
      const pointGroups = points
        .selectAll(".point-group")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "point-group");
      pointGroups
        .style('fill', (d, i) => colors(i))
        .attr("class", (d) => d.name.replace(" " ,""))
        .attr('name', (d) => d.name)
        .selectAll("points")
        .data(function(d){ return d.values.filter((p) => p.x <= xMax && p.x >= xMin) })
        .enter()
        .append('circle')
        .attr('x', d => d.x)
        .attr('y', d => d.y)
        .attr('cx', d => xScale(d.x))
        .attr('cy', d => yScale(d.y))
        .attr('r', 4)
        .on("mouseover", function(d) {
          const groupName = d.target.parentNode.attributes['name'].value;
          const xVal = d.target.attributes['x'].value;
          const yVal = parseFloat(d.target.attributes['y'].value);
          return tooltip.style("visibility", "visible").html(tooltipText(groupName, xVal, yVal));
        })
        .on("mousemove", function() {
          return tooltip.style("top", (event.pageY - 30) + "px")
            .style("left", (event.pageX + 30) + "px");
        })
        .on("mouseout", function() {
          return tooltip.style("visibility", "hidden");
        });
      const legendData = data.map(d => { return { displayName: d.name, fullName: d.fullName } } );

      // add threshold lines
      for (let thresholdValue of this.thresholdValues) {
        g.append("line")
          .style("stroke", "red")
          .style("stroke-width", "2px")
          .style("stroke-opacity", "0.5")
          .attr("x1", 0)
          .attr("y1", yScale(thresholdValue))
          .attr("x2", width)
          .attr("y2", yScale(thresholdValue));
      }

      // bottom legend
      const legend = svg
        .append('g')
        .attr('transform', 'translate(' + margin.left  + ',' + (370) + ')')
        .selectAll('g')
        .data(legendData)
        .enter()
        .append('g');

      legend.append('rect')
        .attr('fill', (d, i) => colors(i))
        .attr('height', 15)
        .attr('width', 15)
        .attr('name', (d) => d.displayName)
        .attr('fullName', (d) => d.fullName)
        .attr('class', (d) => d.displayName.replace(" ","") + '-legend-rect');

      legend.append('text')
        .attr('x', 17)
        .attr('y', 10)
        .attr('dy', '.15em')
        .attr('name', (d) => d.displayName)
        .attr('fullName', (d) => d.fullName)
        .attr('class', (d) => d.displayName.replace(" ","") + '-legend-text')
        .text((d, i) => d.displayName)
        .style('text-anchor', 'start')
        .style("opacity", "0.5")
        .style('font-size', "14px");

      legend.on('click', (e) => {
        const lineName = e.target.attributes['name'].value.replace(" ", "");
        const currentOpacity = g.selectAll('.' + lineName).style('opacity')
        g.selectAll('.' + lineName).transition().style('opacity', currentOpacity == 1 ? 0:1);
        const attrValue = currentOpacity == 1 ? 'line-through' : '';
        legend.selectAll('.' + lineName + '-legend-text').attr('text-decoration', attrValue);
      })
      
      legend.on("mouseover", function(d) {
        const modelFullName = d.target.attributes['fullName'].value;
        return tooltip.style("visibility", "visible").html(`<p>${modelFullName}</p>`);
      }).on("mousemove", function() {
          return tooltip.style("top", (event.pageY - 30) + "px")
            .style("left", (event.pageX + 30) + "px");
        }).on("mouseout", function() {
        return tooltip.style("visibility", "hidden");
      })

      // Now space the groups out after they have been appended:
      const padding = 20;
      legend.attr('transform', function (d, i) {
        return 'translate(' + (d3.sum(legendData, function (e, j) {
          if (j < i) { return legend.nodes()[j].getBBox().width; } else { return 0; }
        }) + padding * i) + `, 0)`;
      });
    }
  },
  mounted() {
    this.renderChart();
  }
};
</script>