<template>
    <div class="cookieBanner" v-if="!cookiesDecisionMade">
        <div class="cookieBannerContent">
            <h3 class="cookieBannerHeader"> Cookie consent </h3>
            <div>
                <p style="color: #ffffff;"> This website uses cookies and similar technologies to function, and to offer you a secure browsing experience. Please view our <a style="text-decoration: underline; cursor: pointer;" @click="goToCookieNotice">cookie notice</a> to learn more about the use of cookies on this website. </p>  
            </div>
        </div>
        <div class="cookieBannerButtons">
            <button class="cookieBannerButton" @click="enableCookies">Ok</button>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            cookiesEnabled: null,
            cookiesDecisionMade: false
        }
    },
    mounted() {
        this.cookiesEnabled = localStorage.getItem("cookieConsent") || localStorage.getItem("cookieConsent") != null ? localStorage.getItem("cookieConsent") : true;
        this.cookiesDecisionMade = localStorage.getItem("dvCookiesDecided") || localStorage.getItem("dvCookiesDecided") != null ? localStorage.getItem("dvCookiesDecided") : false;
    },
    watch: {
        cookiesEnabled(newValue, oldValue) {
            localStorage.setItem("cookieConsent", newValue);
        },
        cookiesDecisionMade(newValue, oldValue) {
            localStorage.setItem("dvCookiesDecided", newValue);
        }

    },
    methods: {
        enableCookies() {
            this.cookiesEnabled = true;
            this.cookiesDecisionMade = true;
        },
        goToCookieNotice() {
            this.$router.push({ path: '/policies' });
        }
    }
}

</script>
