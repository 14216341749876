var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "chart-data-root" }, [
    !_vm.hasData
      ? _c("div", { staticClass: "warning" }, [
          _vm._v(_vm._s(_vm.parameterName) + " data not available for display")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasData
      ? _c(
          "div",
          {
            ref: "chart",
            staticClass: "chart-data-line-chart",
            style: _vm.chartStyle,
            attrs: { id: _vm.chartId }
          },
          [
            !_vm.isChartOptionsValid
              ? _c("div", { staticClass: "invalid-options" }, [
                  !_vm.hasXKey
                    ? _c("div", [_vm._v("No x-axis key defined for data")])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hasYKey
                    ? _c("div", [_vm._v("No y-axis key defined for data")])
                    : _vm._e()
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }