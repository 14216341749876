<template>
  <div class="map-page-container">
    <HeaderComponent>
    </HeaderComponent>
    <div class="map-container">
      <LMap
        class="map"
        :zoom="zoom"
        :center="center"
        :minZoom="minZoom"
        :maxZoom="maxZoom"
        :options="{zoomControl: false}"
        ref="map"
      >
        <LControlZoom
          position="topleft"
        />
        <LControlLayers
          position="topleft"
        />
        <LTileLayer v-for="tileProvider in baseMaps"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :options="{ errorTileUrl: tileProvider.errorTileUrl }"
          :attribution="tileProvider.attribution"
          layer-type="base"
        />
        <div v-if="showSites">
          <LCircleMarker v-for="site in sites"
            :key="site.id + '-' + site.region.id"
            :lat-lng="[site.latitude, site.longitude]"
            :radius="calculateMarkerSize(site)"
            :color="'rgba(1,1,1,0.7)'"
            :fill-color="site.color ? site.color : defaultSiteColor"
            :fill-opacity="1.0"
            :options="{ site: site }"
            @mouseover="onSiteMouseOver($event)"
            @mouseout="onSiteMouseOut($event)"
            @click="onSiteClick($event)"
          />
        </div>
        <div v-if="showRegions">
          <LRectangle v-for="region in regions"
            :key="region.id"
            :bounds="[[region.gridDefinition.minLat, region.gridDefinition.minLon], [region.gridDefinition.maxLat, region.gridDefinition.maxLon]]"
            :l-style="{ color: toRiskColor(maxRiskLevelByRegion[region.id]), fillOpacity: 0, weight: 3 }"
            :options="{ region: region }"
            @mouseover="onRegionMouseover($event)"
            @mouseout="onRegionMouseout($event)"
            @click="onRegionClick($event)"
          />
        </div>
      </LMap>
      <ControlsBar
        :key="controlsBarKey"
        :active-heat-map="activeHeatMap"
        :active-particle-layer="activeParticleMap"
        :heat-maps="riskMaps"
        :regions="regions"
        :hazards="hazards"
        :weather-maps="weatherMaps"
        :weather-map-opacity="weatherMapOpacity"
        :availableFeatures="features"
        :active-features="activeFeatures"
        :weather-parameter-info="weatherParameterInfo"
        @change="onControlChange($event)"
        @changeparticle="onParticleControlChange($event)"
        @changefeature="onFeatureChange($event)"
        @updateOpacity="onOpacityChange">
      </ControlsBar>
      <RiskLegend>
      </RiskLegend>
      <WeatherMapLegend
        v-if="activeHeatMap && activeHeatMap.metadata.key in weatherMaps"
        :weather-map="activeHeatMap.parameter"
        :display-units="activeHeatMapDisplayUnits"
      >
      </WeatherMapLegend>
      <AnimationBar
        v-show="activeHeatMap ? activeHeatMap.metadata.validDateTimes !== 0 : false"
        :date-time="currentDateTime"
        :date-times="activeHeatMap ? activeHeatMap.metadata.validDateTimes : []"
        :panelSite="panelSite"
        :panelRegion="panelRegion"
        :activePanel="activePanel"
        :panelPosition="panelPosition"
        @change="updateDateTime($event); updateHeatMapSource($event); updateParticleMapSource($event);"
      >
      </AnimationBar>
    </div>
    <div class="site-panel">
      <div class="site-panel__toggle" v-bind:style="{ 'display': panelPosition > 0 ? undefined : 'none' }">
        <i class="fas fa-chevron-up icon-rotate site-panel__icon" style="display: none"></i>
        <i class="fas fa-chevron-down site-panel__icon" @click="onPanelToggleClick(0)"></i>
        <svg width="13" height="13" viewBox="0 0 13 13" class="site-panel__icon active" @click="onPanelToggleClick(30)">
          <rect width="13" height="13"/>
          <rect x="1.5" y="1.5" width="10" height="4.25" style="fill: #fff"/>
          <rect x="1.5" y="7.25" width="10" height="4.25" style="fill: #fff"/>
        </svg>
        <i class="fas fa-chevron-up site-panel__icon" @click="onPanelToggleClick(95)"></i>
      </div>
      <div class="site-panel__content" v-bind:style="{ 'max-height': panelPosition < 90 ? '50vh' : '90vh', 'width': panelSite !== null ? '100vw' : undefined, 'display': panelPosition > 0 ? undefined : 'none' }">
        <SitePanelHelp v-if="activePanel == panelType.None">
        </SitePanelHelp>
        <RegionView
          v-if="activePanel == panelType.Region"
          :region="panelRegion"
          :key="regionViewKey"
          @updateMapSites="starredSitesUpdated($event)"
        >
        </RegionView>
        <SiteView
          v-if="activePanel == panelType.Site"
          :key="siteViewKey"
          :region="panelRegion"
          :site="panelSite"
          @updateMapHazards="starredHazardsUpdated($event)"
        >
        </SiteView>
        <FeatureView
          v-if="activePanel == panelType.Feature"
          :key="featureViewKey"
          :feature="panelFeatureConfig.feature"
          :featureConfig="panelFeatureConfig.config"
        ></FeatureView>
      </div>
    </div>
  </div>
</template>

<script>
  import swal from 'sweetalert';
  import moment from 'moment';
  import 'leaflet';
  import 'leaflet/dist/leaflet.css';
  import { LMap, LTileLayer, LControlZoom, LCircleMarker, LRectangle, LControlLayers, LIcon } from 'vue2-leaflet';
  import { imageCanvasLayer } from '../js/L.ImageCanvasLayer.js';
  import { UnitConverter } from '../js/unitConverter.js';
  import ControlsBar from './ControlsBar.vue';
  import RiskLegend from './RiskLegend.vue';
  import WeatherMapLegend from './WeatherMapLegend.vue';
  import AnimationBar from './AnimationBar.vue';
  import RegionView from './RegionView.vue';
  import SiteView from './SiteView.vue';
  import FeatureView from './FeatureView.vue';
  import SitePanelHelp from './SitePanelHelp.vue';
  import HeaderComponent from './Header.vue';
  import { parameterConstants } from '../js/parameterConstants';
  import { ColorMapCollection } from '../js/colorMapCollection';
  import { panelConstants } from '../js/panelConstants';
  import { transpileColorMapFragmentShader } from '../js/webgl.utils'
  import { ImageVectorField } from '../js/ImageVectorField.js';
  import { extendedVectorFieldAnim } from '../js/L.ExtendedVectorFieldAnim.js';
  import { parse } from 'csv-parse/lib/sync';
  import heatMapFragmentShader from '../constants/heatMapFragmentShader.js';
  import unitSets from '../constants/unitSets.json';
  import RequestBuilder from '../js/requestBuilder';
  import '../js/leaflet-shapefile/leaflet.shpfile';
  import '../js/leaflet-kml/L.KML.js';

  const colorMapCollection = new ColorMapCollection();
  const unitConverter = new UnitConverter();
  const requestBuilder = new RequestBuilder();

  export default {
    components: { LMap, LControlZoom, LTileLayer, LCircleMarker, LRectangle, ControlsBar, LIcon,
                  AnimationBar, RiskLegend, HeaderComponent, WeatherMapLegend, SiteView, RegionView, SitePanelHelp, LControlLayers, FeatureView },
    computed: {
      activeHeatMapDisplayUnits() {
        let parameterId, paramInfo, displayUnits;
        if (this.activeHeatMap) {
          if (this.activeHeatMap.metadata) {
            parameterId = this.activeHeatMap.metadata.parameter;
          }
        }
        if (parameterId) {
          if (this.weatherParameterInfo) {
            paramInfo = this.weatherParameterInfo[parameterId];
          }
        }
        if (paramInfo) {
          displayUnits = this.unitSet.hasOwnProperty(parameterId.toLowerCase()) ? this.unitSet[parameterId.toLowerCase()] : paramInfo.displayUnits;
        }
        return displayUnits;
      },
      activePanel() {
        if (this.$route.query.site) {
          return panelConstants.Site;
        }
        if (this.$route.query.region) {
          return panelConstants.Region;
        }
        if (this.panelFeatureConfig) {
          return panelConstants.Feature;
        }
        return panelConstants.None;
      },
      riskdata() { return this.$store.getters.riskdata; },
      config() { return this.$store.getters.config; },
      unitSetId() { return this.config.unitSetId; },
      unitSet() { return this.unitSets[this.unitSetId]; },
      serverRoute() { return this.$store.getters.serverRoute; },
      panelPosition() { return this.$route.query.panelPosition ? parseInt(this.$route.query.panelPosition): 0 },
      panelSite() { return this.$route.query.site ? this.$route.query.site : null },
      panelRegion() { return this.$route.query.region ? this.$route.query.region : null },
      panelType() { return panelConstants },
      regions() { return this.config.regions; },
      hazards() { return this.config.hazards; },
      zoom() { return this.config.zoom ? this.config.zoom : 3 },
      center() { return this.config.center ? this.config.center : [0, 0] },
      baseMaps() { return this.$store.getters.baseMaps; },
      currentRouteName() { return this.$router.history.current.name; },
      riskdataExpirationUtc() { return moment.utc(this.$store.getters.riskdata.lastUpdateTimeUtc).add(this.$store.getters.riskdata.maxSecondsStale, 'seconds') },
      riskMapsExpirationUtc() { return moment.utc(this.$store.getters.riskMaps.lastUpdateTimeUtc).add(this.$store.getters.riskMaps.maxSecondsStale, 'seconds') },
      weatherMapsExpirationUtc() { return moment.utc(this.$store.getters.weatherMaps.lastUpdateTimeUtc).add(this.$store.getters.weatherMaps.maxSecondsStale, 'seconds') },
      featuresExpirationUtc() { return moment.utc(this.$store.getters.features.lastUpdateTimeUtc).add(this.$store.getters.features.maxSecondsStale, 'seconds') },
    },
    created () {
      this.createTimer();
    },
    beforeDestroy() {
      this.cancelTimer();
    },
    data() {
      return {
        defaultMarkerSize: 8,
        defaultSiteColor: 'rgb(211,211,211)',
        controlsBarKey: 0,
        siteViewKey: 0,
        regionViewKey: 0,
        featureViewKey: 0,
        timer: null,
        showSites: true,
        showRegions: true,
        currentDateTime: moment.utc().format(),
        mapRef: null,
        minZoom: 3,
        maxZoom: 15,
        attribution: 'Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri',
        activeHeatMap: null,
        activeParticleMap: null,
        activePointDataPopup: null,
        riskMaps: {},
        maxRiskLevelByRegion: {},
        sites: [],
        sitePanelSite: null,
        weatherMaps: {},
        weatherMapOpacity: 0.8,
        weatherParameterInfo: parameterConstants,
        riskLevelToColor: {
          1: 'rgb(148,216,100)',
          2: 'rgb(239,244,113)',
          3: 'rgb(253,201,78)',
          4: 'rgb(238,107,63)',
          5: 'rgb(211,42,39)'
        },
        features: [],
        activeFeatures: [],
        panelFeatureConfig: null,
        wmsLayersShowing: [],
        regionHovered: null,
        unitSets: JSON.parse(JSON.stringify(unitSets))
      }
    },
    methods: {
      createTimer() {
        if (this.timer) { // clearing timer if any is present
          clearInterval(this.timer);
        }
        const timerIntervalSeconds = 610;
        this.timer = setInterval(this.updateData, timerIntervalSeconds * 1000);
      },
      cancelTimer() {
        clearInterval(this.timer);
      },
      starredSitesUpdated(event) {
        // We only want to filter out starred sites if user has that preference setup
        if (!this.$store.getters.showOnlyFavouriteSitesOnMap) { return; }
        this.parseFavouriteSitesForRegion(this.panelRegion);
      },
      starredHazardsUpdated(event) {
        // We only want to filter out starred hazards if user has that preference setup
        if (!this.$store.getters.showOnlyFavouriteHazardsOnMap) { return; }
        this.parseFavouriteSitesForRegion(this.panelRegion);
      },
      parseFavouriteSitesForRegion(regionId) {
        const config = JSON.parse(JSON.stringify(this.$store.getters.config));
        const riskdata = JSON.parse(JSON.stringify(this.riskdata.data));
        const starredSitesForRegion = this.$store.getters.starredSitesByRegion[regionId];
        const sites = config.regions[regionId].sites;
        const configSiteKeys = Object.keys(sites);
        let maxRiskLevelForRegion = 0;
        let siteIndex = 0;

        // Reseting sites for region
        const siteKeys = Object.keys(this.sites);
        for (const index in siteKeys) {
          let site = this.sites[siteKeys[index]];
          if (site.region.id == regionId) {
            delete this.sites[siteKeys[index]];
          }
        }

        for (const siteKey in configSiteKeys) {
          let site = sites[configSiteKeys[siteKey]];
          if (starredSitesForRegion) {
            if (!starredSitesForRegion.includes(site.id) && starredSitesForRegion.length != 0) { continue; }
          }
          site.region = {};
          site.region.id = regionId;
          if (this.siteInRiskData(regionId, site.id)) {
            site = Object.assign(site, riskdata[regionId][site.id]);
            site.hazardSummaries = this.calculateHazardSummary(site.dataByHazard);
            site.maxRiskLevel = this.calculateSiteMaxRiskLevel(site.dataByHazard, regionId, site.id);
          } else { // No risk data for site so won't affect overall region or site hazard color
            site.maxRiskLevel = 0;
          }
          site.color = this.toRiskColor(site.maxRiskLevel);

          if (maxRiskLevelForRegion < site.maxRiskLevel) {
              maxRiskLevelForRegion = site.maxRiskLevel;
          }

          const lastIndex = parseInt(siteKeys[siteKeys.length - 1]);
          siteIndex = siteIndex + 1;
          this.sites[lastIndex + siteIndex] = site;
        }
        this.maxRiskLevelByRegion[regionId] = maxRiskLevelForRegion;
        this.sites = Object.assign({}, this.sites);
      },
      async updateData() {
        // only update data if user is currently at this views route
        if (!(this.currentRouteName === 'map')) { 
          return;
        }
        else {
          if (moment.utc() >= this.riskdataExpirationUtc) {
            await this.updateRiskdata();
          }
          if (moment.utc() >= this.riskMapsExpirationUtc) {
            await this.updateRiskMaps();
          }
          if (moment.utc() >= this.weatherMapsExpirationUtc) {
            await this.updateWeatherMaps();
          }
          if (moment.utc() >= this.featuresExpirationUtc) {
            await this.updateFeaturesConfig();
          }

          const currentMapFeatures = this.$store.getters.getCurrentMapFeatures;
          const currentFeaturesKeys = Object.keys(currentMapFeatures);
          if (currentFeaturesKeys.length > 0) {
            await this.updateFeaturesOnMap();
          }
        }
      },
      async fetchRiskdata() {
        const [request, options] = await requestBuilder.getRiskDataRequest(this.serverRoute);
        const response = await fetch(request, options);
        if (response.status === 200) {
          const json = await response.json();
          this.$store.dispatch('updateRiskdata', json);
          return true;
        }
        else {
          swal({
            title: 'Oops!',
            text: `Seems like we couldn't fetch new risk data (error code: ${response.status})`,
            type: 'warning',
            timer: 5000
          });
        }
        return false;
      },
      async fetchWeatherMaps() {
        const [request, options] = await requestBuilder.getWeatherMapsRequest(this.serverRoute);
        const response = await fetch(request, options);
        if (response.status === 200) {
          const json = await response.json();
          this.$store.dispatch('updateWeatherMaps', json);
          return true;
        }
        else {
          swal({
            title: 'Oops!',
            text: `Seems like we couldn't fetch new weather maps (error code: ${response.status})`,
            type: 'warning',
            timer: 5000
          });
        }
        return false;
      },
      async fetchRiskMaps() {
        const [request, options] = await requestBuilder.getRiskMapsRequest(this.serverRoute);
        const response = await fetch(request, options);
        if (response.status === 200) {
          const json = await response.json();
          this.$store.dispatch('updateRiskMaps', json);
          return true;
        }
        else {
          swal({
            title: 'Oops!',
            text: `Seems like we couldn't fetch new risk maps (error code: ${response.status})`,
            type: 'warning',
            timer: 5000
          });
        }
        return false;
      },
      async fetchAvailableFeatures() {
        const [request, options] = await requestBuilder.getFeaturesRequest(this.serverRoute);
        const response = await fetch(request, options);
        if (response.status === 200) {
          const json = await response.json();
          this.$store.dispatch('updateFeatures', json);
          return true;
        }
        else {
          swal({
            title: 'Oops!',
            text: `Seems like we couldn't fetch new features (error code: ${response.status})`,
            type: 'warning',
            timer: 5000
          });
        }
        return false;
      },
      async updateRiskdata() {
          await this.fetchRiskdata();
          this.parseRiskDataToSites();
      },
      async updateRiskMaps() {
          await this.fetchRiskMaps();
          this.parseRiskMapsToRiskMaps();
          const dummyEvent = { target: { value: '' } };
          this.onControlChange(dummyEvent);
          this.onParticleControlChange(dummyEvent);
      },
      async updateWeatherMaps() {
          await this.fetchWeatherMaps();
          this.parseWeatherMapsToWeatherMaps();
          const dummyEvent = { target: { value: '' } };
          this.onControlChange(dummyEvent);
          this.onParticleControlChange(dummyEvent);
      },
      async updateFeaturesConfig() {
          await this.fetchAvailableFeatures();
          this.parseFeatures();
          const dummyEvent = { target: { value: '' } };
          this.onControlChange(dummyEvent);
      },
      async updateFeaturesOnMap() {
        const currentMapFeatures = JSON.parse(JSON.stringify(this.$store.getters.getCurrentMapFeatures));
        const currentFeaturesKeys = Object.keys(currentMapFeatures);
        const featuresConfigs = this.$store.getters.features.data;
        const defaultExpirationUtc = {
          'interval': 18000,
          'units': 'seconds'
        }

        for (const keyIndex in currentFeaturesKeys) {
          const featureId = currentFeaturesKeys[keyIndex];

          const currentFeatureConfig = featuresConfigs.find(feature => feature.id == featureId);
          const expirationTimeInfo = currentFeatureConfig.hasOwnProperty('expirationTimeInfo') ? currentFeatureConfig.expirationTimeUtc : defaultExpirationUtc;
          const expirationTimeUTC = moment.utc(currentMapFeatures[featureId].lastUpdateTimeUtc).add(expirationTimeInfo.interval, expirationTimeInfo.units);

          if (moment.utc() >= expirationTimeUTC) { // check if feature should be updated

            let mutatedList = [...this.activeFeatures];
            const currentLayer = mutatedList.find(x => x.options.id === featureId);

            if (currentLayer) {
              mutatedList = mutatedList.filter(x=> x.options.id !== featureId);
              this.mapRef.removeLayer(currentLayer);
              this.mapRef.attributionControl.setPrefix('Leaflet'); // default attribution
              this.activeFeatures = mutatedList;
            }

            // adding feature back onto map
            const featureEvent = { target: { value: featureId } };
            await this.onFeatureChange(featureEvent);

          }
        }
      },
      updateDateTime(event) {
        this.currentDateTime = event.newDateTime;
      },
      updateHeatMapSource(event) {
        if (this.activeHeatMap) {
          const dateHour = moment.utc(event.newDateTime).startOf('hour');
          const closestValidTime = this.activeHeatMap.metadata.validDateTimes.reduce((closestDateTime, nextDateTime) => {
              if (!closestDateTime) return nextDateTime;
              else if (nextDateTime.isAfter(closestDateTime) && nextDateTime.isSameOrBefore(dateHour)) return nextDateTime;
              else return closestDateTime;
          });
          const formattedNewDateTime = moment.utc(closestValidTime).format('YYYYMMDDHHmmss');
          const potentialSource = this.activeHeatMap.metadata.urlTemplate.replace('{validDateTime}', formattedNewDateTime);
          if (this.activeHeatMap.metadata.sources.includes(potentialSource)) {
            this.activeHeatMap.layer.updateOptions({
              source: potentialSource
            });
          }
        }
      },
      updateParticleMapSource(event) {
        if (this.activeParticleMap) {
          const formattedNewDateTime = moment.utc(event.newDateTime).format('YYYYMMDDHHmmss');
          const potentialSource = this.activeParticleMap.metadata.urlTemplate.replace('{validDateTime}', formattedNewDateTime);
          if (this.activeParticleMap.metadata.sources.includes(potentialSource)) {
            const imageVectorField = new ImageVectorField({
              mapRef: this.mapRef,
              source: potentialSource,
              screenDimensions: [
                screen.width,
                screen.height
              ]
            });

            imageVectorField.initialize(() => {
              const leafletLayer = extendedVectorFieldAnim(imageVectorField, {
                paths: 1000,
                fade: 0.97,
                duration: 20,
                maxAge: 100,
                velocityScale: 0.008,
                width: 1.0,
                color: 'rgba(255, 255, 255, 0.9)'
              });

              this.mapRef.removeLayer(this.activeParticleMap.leafletLayer);
              this.mapRef.addLayer(leafletLayer);
              this.activeParticleMap.leafletLayer = leafletLayer;
            });
          }
        }
      },
      getFeatureById(event){
        let selectedFeature = null;
        this.features.some((group, i)=>{
          if(group.src == null){
            group.features.some((feature,x) => {
              if(feature.id === event.target.value){
                selectedFeature = feature;
              }
              if(selectedFeature != null){
                return true;
              }
            })

          } else if(group.id == event.target.value ) {
            selectedFeature = feature;
          }
          if(selectedFeature != null){
            return true;
          }
        });
        return selectedFeature;
      },
      onParticleControlChange(event) {
        if (this.activeParticleMap) {
          this.mapRef.removeLayer(this.activeParticleMap.leafletLayer);
          this.activeParticleMap = null;
        }

        if (event.target.value !== '') {
          const currentMapCollection = this.weatherMaps[event.target.value];
          const currentDateTime = moment.utc(this.currentDateTime).startOf('hour');
            const closestValidTime = currentMapCollection.validDateTimes.reduce((closestDateTime, nextDateTime) => {
              if (!closestDateTime) {
                return nextDateTime;
              }
              if (nextDateTime.isAfter(closestDateTime) && nextDateTime.isSameOrBefore(currentDateTime)) {
                return nextDateTime;
              }
              return closestDateTime;
            });
            const formattedNewDateTime = moment.utc(closestValidTime).format('YYYYMMDDHHmmss');
            const source = currentMapCollection.urlTemplate.replace('{validDateTime}', formattedNewDateTime);
            const imageVectorField = new ImageVectorField({
              mapRef: this.mapRef,
              source: source,
              screenDimensions: [
                screen.width,
                screen.height
              ]
            })
            imageVectorField.initialize(() => {
              const leafletLayer = extendedVectorFieldAnim(imageVectorField, {
                paths: 1000,
                fade: 0.97,
                duration: 20,
                maxAge: 100,
                velocityScale: 0.008,
                width: 1.0,
                color: 'rgba(255, 255, 255, 0.9)'
              });

              this.mapRef.addLayer(leafletLayer);
              this.activeParticleMap = {
                metadata: currentMapCollection,
                leafletLayer: leafletLayer
              }
            });
        }
        let dispatchValue = null;
        if (event.target.value !== '') dispatchValue = event.target.value;
        this.$store.dispatch('updateActiveParticleMap', dispatchValue);
      },
      onControlChange(event) {
        if (this.activeHeatMap) {
          this.mapRef.removeLayer(this.activeHeatMap.layer);
          this.activeHeatMap = null;
        }
        if (event.target.value !== '') {

          if (event.target.value in this.riskMaps) { // risk map

            const currentHeatMap = this.riskMaps[event.target.value];
            const displayUnits = ''; // No units for risk map
            const currentDateTime = moment.utc(this.currentDateTime).startOf('hour');
            const closestValidTime = currentHeatMap.validDateTimes.reduce((closestDateTime, nextDateTime) => {
              if (!closestDateTime) return nextDateTime;
              else if (nextDateTime.isAfter(closestDateTime) && nextDateTime.isSameOrBefore(currentDateTime)) return nextDateTime;
              else return closestDateTime;
            });
            const formattedNewDateTime = moment.utc(closestValidTime).format('YYYYMMDDHHmmss');
            const source = currentHeatMap.urlTemplate.replace('{validDateTime}', formattedNewDateTime);
            const heatMapLayer = imageCanvasLayer({
              fragmentShader: heatMapFragmentShader,
              mapRef: this.mapRef,
              opacity: 0.8,
              source: source
            });

            heatMapLayer.on('click', (e) => {
              if (e.value !== null) {
                const units = heatMapLayer.getValueUnits();
                const displayValue = unitConverter.convert(e.value, units, displayUnits);
                this.activePointDataPopup  = L.popup()
                  .setLatLng(e.latlng)
                  .setContent(`${displayValue.toFixed(0)} ${displayUnits}`)
                  .openOn(this.mapRef);
                this.activePointDataPopup.on('close', () => {
                  this.activePointDataPopup = null;
                });
              }
            });

            heatMapLayer.on('change', () => {
              if (this.activePointDataPopup) {
                const currentPopupPosition = this.mapRef.latLngToContainerPoint(this.activePointDataPopup.getLatLng());
                const value = heatMapLayer.getValue(currentPopupPosition);
                const units = heatMapLayer.getValueUnits();
                const displayValue = unitConverter.convert(value, units, displayUnits);
                this.activePointDataPopup.setContent(`${displayValue.toFixed(0)} ${displayUnits}`);
              }
            });
            this.activeHeatMap = {
              key: event.target.value,
              layer: heatMapLayer,
              metadata: currentHeatMap
            };
            this.mapRef.addLayer(heatMapLayer);
            this.currentDateTime = closestValidTime.format();
          }

          else if (event.target.value in this.weatherMaps) { // weather map
            const currentMapCollection = this.weatherMaps[event.target.value];
            const parameterDetails = this.weatherParameterInfo[currentMapCollection.parameter];
            const parameter = currentMapCollection.parameter;
            const displayUnits = this.unitSet.hasOwnProperty(parameter.toLowerCase()) ? this.unitSet[parameter.toLowerCase()] : parameterDetails.displayUnits;
            const colorMap = colorMapCollection.map(parameterDetails.colorMap);
            const colorMapDetails = colorMapCollection.mapDetails(parameterDetails.colorMap);
            const colorArray = colorMap.toUnitRGB();
            const colorThresholds = colorMap.getThresholds();
            const fragmentShader = transpileColorMapFragmentShader(colorArray, colorThresholds, parameterDetails.fragmentTemplate);

            const currentDateTime = moment.utc(this.currentDateTime).startOf('hour');
            const closestValidTime = currentMapCollection.validDateTimes.reduce((closestDateTime, nextDateTime) => {
              if (!closestDateTime) return nextDateTime;
              else if (nextDateTime.isAfter(closestDateTime) && nextDateTime.isSameOrBefore(currentDateTime)) return nextDateTime;
              else return closestDateTime;
            });
            const formattedNewDateTime = moment.utc(closestValidTime).format('YYYYMMDDHHmmss');
            const source = currentMapCollection.urlTemplate.replace('{validDateTime}', formattedNewDateTime);
            const heatMapLayer = imageCanvasLayer({
              fragmentShader: fragmentShader,
              mapRef: this.mapRef,
              opacity: this.weatherMapOpacity,
              fragmentTemplate: parameterDetails.fragmentTemplate,
              source: source
            });

            heatMapLayer.on('click', (e) => {
              if (e.value !== null) {
                const units = heatMapLayer.getValueUnits();
                const displayValue = unitConverter.convert(e.value, units, displayUnits, 3);
                const formattedValue = displayValue < 0.1 ? displayValue.toFixed(2) : displayValue < 1.0 ? displayValue.toFixed(1) : displayValue.toFixed(0);
                this.activePointDataPopup  = L.popup()
                  .setLatLng(e.latlng)
                  .setContent(`${formattedValue} ${displayUnits}`)
                  .openOn(this.mapRef);
                this.activePointDataPopup.on('close', () => {
                  this.activePointDataPopup = null;
                });
              }
            });

            heatMapLayer.on('change', () => {
              if (this.activePointDataPopup) {
                const currentPopupPosition = this.mapRef.latLngToContainerPoint(this.activePointDataPopup.getLatLng());
                const value = heatMapLayer.getValue(currentPopupPosition);
                if (value !== null) {
                  const units = heatMapLayer.getValueUnits();
                  const displayValue = unitConverter.convert(value, units, displayUnits, 3);
                  const formattedValue = displayValue < 0.1 ? displayValue.toFixed(2) : displayValue < 1.0 ? displayValue.toFixed(1) : displayValue.toFixed(0);
                  this.activePointDataPopup.setContent(`${formattedValue} ${displayUnits}`);
                }
              }
            });
            this.activeHeatMap = {
              key: event.target.value,
              layer: heatMapLayer,
              metadata: currentMapCollection,
              parameter: colorMapDetails
            };
            this.mapRef.addLayer(heatMapLayer);
            this.currentDateTime = closestValidTime.format();

          }
          if (this.activeParticleMap) this.activeParticleMap.leafletLayer.bringToFront();
        }

        let dispatchValue = null;
        if (event.target.value !== '') dispatchValue = event.target.value;
        this.$store.dispatch('updateActiveHeatMap', dispatchValue);
      },
      async onFeatureChange(event) {
        const context = this;
        if (event.target.value !== '' && this.getFeatureById(event) != null) {
          const featureConfig = this.getFeatureById(event);

          let mutatedList = [...this.activeFeatures];
          const currentLayer = mutatedList.find(x => x.options.id === event.target.value);

          if (currentLayer) {
            mutatedList = mutatedList.filter(x=> x.options.id !== event.target.value);
            this.mapRef.removeLayer(currentLayer);
            this.mapRef.attributionControl.setPrefix('Leaflet'); // default attribution
            this.activeFeatures = mutatedList;
            const currentFeatures = JSON.parse(JSON.stringify(this.$store.getters.getCurrentMapFeatures));
            if (currentFeatures.hasOwnProperty(featureConfig.id)) { //checking if feature is already switched on
              delete currentFeatures[featureConfig.id];
              this.$store.dispatch('updateCurrentMapFeatures', currentFeatures);
            }
            return;
          }

          let layer = null;

          if (featureConfig.fileType === 'shapefile') {
            layer = await this.constructShapeFileLayer(featureConfig);
          } else if (featureConfig.fileType === 'geojson') {
            layer = await this.constructGeoJsonLayer(featureConfig);
          } else if (featureConfig.fileType === 'wmslayer') {
            layer = await this.constructWMSLayer(featureConfig);
          } else if (featureConfig.fileType === 'kmllayer') {
            layer = await this.constructKMLLayer(featureConfig);
          } else if (featureConfig.fileType === 'csv') {
            layer = await this.constructCSVLayer(featureConfig);
          }

          if (layer != null) {
            this.mapRef.addLayer(layer);
            mutatedList.push(layer);
            this.activeFeatures = mutatedList;
            const currentFeatures = JSON.parse(JSON.stringify(this.$store.getters.getCurrentMapFeatures));

            if (!currentFeatures.hasOwnProperty(featureConfig.id)) { //checking if feature is not already suppose to be switched on so we don't add it twice
              currentFeatures[featureConfig.id] = { id: featureConfig.id, lastUpdateTimeUtc: moment.utc().format() };
            } else { //update last update time 
              currentFeatures[featureConfig.id]['lastUpdateTimeUtc'] = moment.utc().format();
            }
            this.$store.dispatch('updateCurrentMapFeatures', currentFeatures);
          }
        }

      },
      async constructCSVLayer(featureConfig) {
        const context = this;
        let response = null;
        const featureStyle = featureConfig.hasOwnProperty('style') ? featureConfig.style : null;
        const pointColor = featureStyle ? featureStyle.hasOwnProperty('pointColor') ? featureStyle.pointColor : "#000" : "#000";
        const pointFillColor = featureStyle ? featureStyle.hasOwnProperty('pointFillColor') ? featureStyle.pointFillColor : "rgba(45, 189, 182, 1)" : "rgba(45, 189, 182, 1)";
        const lineColor = featureStyle ? featureStyle.hasOwnProperty('lineColor') ? featureStyle.lineColor : "rgba(45, 189, 182, 1)" : "rgba(45, 189, 182, 1)";
        const hasCustomIcon = featureStyle ? featureStyle.hasOwnProperty("icon") ? true : false : false;
        const defaultMarkerOptions = { radius: 6, fillColor: pointFillColor, color: pointColor, weight: 2, opacity: 1, fillOpacity: 0.8 };

        if (featureConfig.hasOwnProperty('src')) {
          const src = window.location.origin + featureConfig.src;
          response = await fetch(src);
        } else {
          const [request, options] = await requestBuilder.getFeature(this.serverRoute, featureConfig.id);
          response = await fetch(request, options);
        }

        const csvText = await response.text();
        const csvData = parse(csvText, {columns: true});

        const convertedCSV = this.convertCSVToGeoJson(csvData);
        const geoLayer = L.geoJSON(convertedCSV, {
          id: featureConfig.id,
          filter: (shpFeature) => {
            return this.leafletFilterTool(shpFeature, featureConfig);
          },
          style: (feature) => {
            if (featureStyle) {
              if (featureStyle.hasOwnProperty('lineColorConditions')) {
                return this.getCustomFeatureLineColor(feature, featureStyle.lineColorConditions);
              } else {
                return { color: featureStyle.hasOwnProperty('lineColor') ? lineColor : pointColor }
              }
            } else {
              return;
            }
          },
          pointToLayer: (geoJsonPoint, latlng) => {
            if (hasCustomIcon) {
              this.mapRef.attributionControl.setPrefix(featureStyle.icon.attribution);
              return this.getCustomFeatureIcon(featureStyle, geoJsonPoint, latlng, defaultMarkerOptions);
            } else {
              return L.circleMarker(latlng, defaultMarkerOptions);
            }
          },
          onEachFeature: (feature, layer) =>{
            if (feature.properties) {
              layer.on({
                click: (e)=> { context.onFeatureClick(e,featureConfig)}
              })
            }
          }
        });
        return geoLayer;
      },
      async constructGeoJsonLayer(featureConfig) {
        const context = this;
        let src = null;
        const featureStyle = featureConfig.hasOwnProperty('style') ? featureConfig.style : null;
        const pointColor = featureStyle ? featureStyle.hasOwnProperty('pointColor') ? featureStyle.pointColor : "#000" : "#000";
        const pointFillColor = featureStyle ? featureStyle.hasOwnProperty('pointFillColor') ? featureStyle.pointFillColor : "rgba(45, 189, 182, 1)" : "rgba(45, 189, 182, 1)";
        const lineColor = featureStyle ? featureStyle.hasOwnProperty('lineColor') ? featureStyle.lineColor : "rgba(45, 189, 182, 1)" : "rgba(45, 189, 182, 1)";
        const hasCustomIcon = featureStyle ? featureStyle.hasOwnProperty("icon") ? true : false : false;
        const defaultMarkerOptions = { radius: 6, fillColor: pointFillColor, color: pointColor, weight: 2, opacity: 1, fillOpacity: 0.8 };

        if (featureConfig.hasOwnProperty('src')) {
          src = window.location.origin + featureConfig.src;
        } else {
          const [request, options] = await requestBuilder.getFeature(this.serverRoute, featureConfig.id);
          const response = await fetch(request, options);
          src = await response.json();
        }

        const geoLayer = L.geoJSON(src, {
          id: featureConfig.id,
          filter: (shpFeature) => {
            return this.leafletFilterTool(shpFeature, featureConfig);
          },
          style: (feature) => {
            if (featureStyle) {
              if (featureStyle.hasOwnProperty('lineColorConditions')) {
                return this.getCustomFeatureLineColor(feature, featureStyle.lineColorConditions);
              } else {
                return { color: featureStyle.hasOwnProperty('lineColor') ? lineColor : pointColor }
              }
            } else {
              return;
            }
          },
          pointToLayer: (geoJsonPoint, latlng) => {
            if (hasCustomIcon) {
              this.mapRef.attributionControl.setPrefix(featureStyle.icon.attribution);
              return this.getCustomFeatureIcon(featureStyle, geoJsonPoint, latlng, defaultMarkerOptions);
            } else {
              return L.circleMarker(latlng, defaultMarkerOptions);
            }
          },
          onEachFeature: (feature, layer) =>{
            if (feature.properties) {
              layer.on({
                click: (e)=> { context.onFeatureClick(e,featureConfig)}
              })
            }
          }
        });
        return geoLayer;
      },
      async constructShapeFileLayer(featureConfig) {
        const context = this;
        const sources = [];
        const featureStyle = featureConfig.hasOwnProperty('style') ? featureConfig.style : null;
        const pointColor = featureStyle ? featureStyle.hasOwnProperty('pointColor') ? featureStyle.pointColor : "#000" : "#000";
        const pointFillColor = featureStyle ? featureStyle.hasOwnProperty('pointFillColor') ? featureStyle.pointFillColor : "rgba(45, 189, 182, 1)" : "rgba(45, 189, 182, 1)";
        const lineColor = featureStyle ? featureStyle.hasOwnProperty('lineColor') ? featureStyle.lineColor : "rgba(45, 189, 182, 1)" : "rgba(45, 189, 182, 1)";
        const hasCustomIcon = featureStyle ? featureStyle.hasOwnProperty("icon") ? true : false : false;
        const defaultMarkerOptions = { radius: 6, fillColor: pointFillColor, color: pointColor, weight: 2, opacity: 1, fillOpacity: 0.8 };

        if (featureConfig.hasOwnProperty('src')) {
          const src = window.location.origin + featureConfig.src;
          sources.push(src);
        } else if (featureConfig.hasOwnProperty('s3')) {
          const [request, options] = await requestBuilder.getMultipleFeatures(this.serverRoute, featureConfig.id);
          const response = await fetch(request, options);
          const files = await response.json();

          for (let i = 0; i < files.length; i++) {
            const [request, options] = await requestBuilder.getFeature(this.serverRoute, featureConfig.id, files[i]);
            const response = await fetch(request, options);
            const src = await response.arrayBuffer();
            sources.push(src);
          }
        } else {
          const [request, options] = await requestBuilder.getFeature(this.serverRoute, featureConfig.id);
          const response = await fetch(request, options);
          const src = await response.arrayBuffer();
          sources.push(src);
        }

        const shapeLayer = L.shapefile(sources, {
          id: featureConfig.id,
          loadMultipleFiles: true,
          filter: (shpFeature) => {
            return this.leafletFilterTool(shpFeature, featureConfig);
          },
          style: (feature) => {
            if (featureStyle) {
              if (featureStyle.hasOwnProperty('lineColorConditions')) {
                return this.getCustomFeatureLineColor(feature, featureStyle.lineColorConditions);
              } else {
                return { color: featureStyle.hasOwnProperty('lineColor') ? lineColor : pointColor }
              }
            } else {
              return;
            }
          },
          pointToLayer: (feature, latlng) => {
            if (hasCustomIcon) {
              this.mapRef.attributionControl.setPrefix(featureStyle.icon.attribution);
              return this.getCustomFeatureIcon(featureStyle, feature, latlng, defaultMarkerOptions);
            } else {
              return L.circleMarker(latlng, defaultMarkerOptions)
            }
          },
          onEachFeature: (feature, layer) =>{
            if (feature.properties) {
              layer.on({
                click: (e)=> { context.onFeatureClick(e,featureConfig)}
              })
            }
          }
        });

        return shapeLayer;
      },
      async constructWMSLayer(featureConfig) {
        const context = this;
        const baseUrl = featureConfig.url;
        const layers = featureConfig.layers;
        const style = featureConfig.styles;
        const format = featureConfig.format;
        const transparent = featureConfig.transparent;

        const wmsPane = this.mapRef.createPane('wmsPane'); // we need this so wms layers will appear on top when toggling base maps
        wmsPane.style.zIndex = 250;

        L.TileLayer.WMSExtented = L.TileLayer.WMS.extend({ 
            onAdd: async function (map) {
              // Triggered when the layer is added to a map.
              L.TileLayer.WMS.prototype.onAdd.call(this, map);
              context.wmsLayersShowing.push({id: featureConfig.id, hasData: true}); // we need this because region boxes sometimes overlay wms layers and we may have multiple wms layers showing at the same time
              context.mapRef.on('click', this.getFeatureInfo, this);
            },
            onRemove: function (map) {
              // triggered when the layer is removed from a map.
              L.TileLayer.WMS.prototype.onRemove.call(this, map);
              const wmsIndex = context.wmsLayersShowing.findIndex((layer) => { return layer.id == featureConfig.id; });
              context.wmsLayersShowing.splice(wmsIndex, 1);
              context.mapRef.off('click', this.getFeatureInfo, this);
            },
            getFeatureInfo: async function (evt) {
              if (context.sitePanelSite != null) { return }; // we will show site information over feature info when site is being hovered

              const wmsLayerIndex = context.wmsLayersShowing.findIndex((layer) => { return layer.id == featureConfig.id; });
              context.wmsLayersShowing[wmsLayerIndex].hasData = true;
              let featuresCollection = { features: [], type: "FeatureCollection" };

              const infoParams = this.getFeatureInfoParams(evt.latlng);
              const [request, options] = await requestBuilder.getFeatureLayerInfo(context.serverRoute, featureConfig.id, infoParams);
              const response = await fetch(request, options);

              if (response.status == 200) {
                const resp_content = await response.json();
                featuresCollection = baseUrl.includes('https://geo.weather.gc.ca/geomet') ? context.parseECWarnings(resp_content) : resp_content;
              }

              const featuresCount = featuresCollection ? featuresCollection.hasOwnProperty('features') ? featuresCollection.features.length : 0 : 0;

              if (featuresCount > 0) {
                  context.onFeatureClick(featuresCollection, featureConfig);
              } else if (context.regionHovered != null) { // doing region click if we are actually hover over a region and click outside wms layer
                  context.wmsLayersShowing[wmsLayerIndex].hasData = false;
                  const layersWithNoInfo = context.wmsLayersShowing.filter((layer) => {return !layer.hasData; });
                  if (layersWithNoInfo.length == context.wmsLayersShowing.length) {
                    context.onRegionClick(context.regionHovered, true); // we need this because region boxes sometimes overlay wms layers and we may click both at the same time
                  }
              }

            },
            getFeatureInfoParams: function (latlng) {
              // construct a GetFeatureInfo request URL given a point
              const point = this._map.latLngToContainerPoint(latlng, this._map.getZoom());
              const size = this._map.getSize();
              const bounds = this._map.getBounds(),
                nw = this._map.options.crs.project(bounds.getNorthWest()),
                se = this._map.options.crs.project(bounds.getSouthEast()),
                bbox = [nw.x.toFixed(6), se.y.toFixed(6), se.x.toFixed(6), nw.y.toFixed(6)].join(',');

              const params = {
                version: this.wmsParams.version,
                bbox: bbox,
                height: size.y,
                width: size.x
              };

              params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
              params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;
              return L.Util.getParamString(params);
            }

        });

        const wmsLayer = new L.TileLayer.WMSExtented(baseUrl, {
            id: featureConfig.id,
            layers: layers,
            format: format,
            styles: style,
            transparent: transparent,
            pane: wmsPane,
            opacity: 0.8
        });

        return wmsLayer;
      },
      async constructKMLLayer(featureConfig) {
        let kmlText = null;

        if (featureConfig.hasOwnProperty('s3')) {
          const filename = featureConfig.s3.filename;
          const [request, options] = await requestBuilder.getFeature(this.serverRoute, featureConfig.id, filename);
          const response = await fetch(request, options);
          kmlText = await response.text();

        } else {
          const [request, options] = await requestBuilder.getFeature(this.serverRoute, featureConfig.id);
          const response = await fetch(request, options);
          kmlText = await response.text();
        }

        const parser = new DOMParser();
        const parsedKml = parser.parseFromString(kmlText, 'text/xml');
        const kmlLayer = new L.KML(parsedKml, {
          id: featureConfig.id
        });

        return kmlLayer;
      },
      convertCSVToGeoJson(parsedCsv) {
        const features = [];
        const possibleLatitudeNames = ["latitude", "lat"];
        const possibleLongitudeNames = ["longitude", "lon"];

        for (const rowIndex in parsedCsv) {
          const row = parsedCsv[rowIndex];
          const feature = {"type": "Feature", "properties": {}, "geometry": { "type": "Point", "coordinates": [0,0] }};
          for (const column in row) {
            feature['properties'][column] = row[column];
            if (possibleLatitudeNames.includes(column.toLowerCase())) {
              feature["geometry"]["coordinates"][1] = parseFloat(row[column]);
            } else if (possibleLongitudeNames.includes(column.toLowerCase())) {
              feature["geometry"]["coordinates"][0] = parseFloat(row[column]);
            }
          }
          features.push(feature);
        }
        return features;
      },
      parseECWarnings(text) {
        const featuresCollection = { features: [], type: "FeatureCollection" };
        const featureIds = text.match(/Feature \d+:/g);
        const textFeatures = text.split(/Feature \d+:/g).splice(1);

        for (const index in textFeatures) {
          const textFeature = textFeatures[index];
          const feature = { properties: {}, geometry: {}, id: featureIds[index] };
          const propertyGroups = textFeature.split("\n");
          const properties = propertyGroups.map((property) => { return property.trim().split("="); });
          for (const pIndex in properties) {
            const keyValuePair = properties[pIndex];
            if (keyValuePair.length > 1) {
              const parsedValue = keyValuePair[1].trim().replaceAll("'","");
              feature['properties'][keyValuePair[0].trim()] = typeof parsedValue == "string" ? this.camelCaseToSentenceCase(parsedValue) : parsedValue;
            }
          }
          featuresCollection['features'].push(feature);
        }
        return featuresCollection;
      },
      camelCaseToSentenceCase(s) {
        if (s == null) {
          return;
        }
        const result = s.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
      },
      leafletFilterTool(shpFeature, featureConfig) {
        if (featureConfig.filter) {

          let geometryType = true;
          let propertyCriteria = true;
          let satisfyRegionCondition = featureConfig.filter.hasOwnProperty("withinRegions") ? false : true; // defined regions on map which sites fall within
          if (featureConfig.filter.hasOwnProperty("geometryType")) {
            const criteria = featureConfig.filter.geometryType.criteria;
            geometryType = criteria.some(x => x.toLowerCase() === shpFeature.geometry.type.toLowerCase());
          }

          if (featureConfig.filter.hasOwnProperty("withinRegions") && shpFeature.geometry.type.toLowerCase() == "point") {
            const definedRegions = JSON.parse(JSON.stringify(this.$store.getters.config.regions));
            for (const region in definedRegions) {
              const regionInfo = definedRegions[region];
              const gridInfo = regionInfo.gridDefinition;
              const shpFeatureCoordinates = shpFeature.geometry.coordinates;
              const withinLongitudeBounds = shpFeatureCoordinates[0] >= gridInfo.minLon && shpFeatureCoordinates[0] <= gridInfo.maxLon ? true : false;
              const withinLatitudeBounds = shpFeatureCoordinates[1] >= gridInfo.minLat && shpFeatureCoordinates[1] <= gridInfo.maxLat ? true : false;

              if (featureConfig.filter.withinRegions && (withinLatitudeBounds && withinLongitudeBounds)) { // we only want points within region
                satisfyRegionCondition = true;
              }
            }
          }

          if (featureConfig.filter.hasOwnProperty("featureProperty")) {

            for (const property in featureConfig.filter.featureProperty) {
              const featureCriteria = featureConfig.filter.featureProperty[property];
              const threshold = featureCriteria.threshold;
              const operator = featureCriteria.operator;
              const parameterValue = shpFeature.properties.hasOwnProperty(property.toUpperCase()) ? shpFeature.properties[property.toUpperCase()] : shpFeature.properties[property.toLowerCase()];
              propertyCriteria = this.comparisonFunction(operator, threshold, parameterValue) ? true : false;

              if (!propertyCriteria) {
                return false;
              }
            }
          }

          if (geometryType && propertyCriteria && satisfyRegionCondition) {
            return true;
          } else {
            return false;
          }

        } else {
          return true;
        }
      },
      getCustomFeatureIcon(featureStyle, currentFeature, latlng, defaultOptions) {
        const hasIconConditions = featureStyle.icon.hasOwnProperty("conditions") ? true : false;

        if (hasIconConditions) {
          const relevantIcon = featureStyle.icon.conditions.find(condition => {
            const parameterName = condition.parameter;
            let parameterValue = currentFeature.properties.hasOwnProperty(parameterName.toUpperCase()) ? currentFeature.properties[parameterName.toUpperCase()] : currentFeature.properties[parameterName.toLowerCase()];

            if (isNaN(parameterValue)) {
              // feature properties may sometimes include units so we may need to extract the number
              const numbers = parameterValue.match(/(\d+)/);
              if (numbers) { parameterValue = numbers[0]; }
            }
            if (this.comparisonFunction(condition.operator, condition.threshold, parameterValue)) {
              return condition;
            }
          });

          if(relevantIcon) {
            const customIcon = L.icon({
              iconUrl: relevantIcon.src,
              iconSize: relevantIcon.iconSize
            });

            return L.marker(latlng, {icon: customIcon });
          } else {
            return L.circleMarker(latlng, defaultOptions);
          }

        } else {
          const customIcon = L.icon({
            iconUrl: featureStyle.icon.src,
            iconSize: featureStyle.icon.iconSize
          });
          return L.marker(latlng, {icon: customIcon });
        }
      },
      getCustomFeatureLineColor(currentFeature, conditions) {
        const relevantCondition = conditions.find(condition => {
          const parameterName = condition.parameter;
          let parameterValue = currentFeature.properties.hasOwnProperty(parameterName.toUpperCase()) ? currentFeature.properties[parameterName.toUpperCase()] : currentFeature.properties[parameterName.toLowerCase()];

          if (isNaN(parameterValue)) {
            // feature properties may sometimes include units so we may need to extract the number
            const numbers = parameterValue.match(/(\d+)/);
            if (numbers) { parameterValue = numbers[0]; }
          }
          if (this.comparisonFunction(condition.operator, condition.threshold, parameterValue)) {
            return condition;
          }
        });
        return { color: relevantCondition ? relevantCondition.color : "rgba(45, 189, 182, 1)" }
      },
      comparisonFunction(operator, threshold, parameterValue) {
        let condition = false;
        switch(operator) {
          case '>=':
            condition = parameterValue >= threshold ? true : false;
            break;
          case '<=':
            condition = parameterValue <= threshold ? true : false;
            break;
          case '>':
            condition = parameterValue > threshold ? true : false;
            break;
          case '!<':
            condition = parameterValue < threshold ? false : true;
            break;
          case '!<=':
            condition = parameterValue <= threshold ? false : true;
            break;
          case '!>=':
            condition = parameterValue >= threshold ? false : true;
            break;
          case '<':
            condition = parameterValue < threshold ? true : false;
            break;
          case '!>=':
            condition = parameterValue >= threshold ? false : true;
            break;
          case '==':
            condition = parameterValue == threshold ? true : false;
            break;
          case '!==':
            condition = parameterValue !== threshold ? true : false;
            break;
          default:
            condition = false;
        }
        return condition;
      },
      onOpacityChange(opacity) {
        this.weatherMapOpacity = opacity;
        const hasLayer = this.mapRef._layers.hasOwnProperty(this.activeHeatMap.layer._leaflet_id);
        if (hasLayer) {
          this.activeHeatMap.layer.options.opacity = opacity;
          const updatedOptions = this.activeHeatMap.layer.options;
          this.mapRef._layers[this.activeHeatMap.layer._leaflet_id].updateOptions(updatedOptions);
          if (this.activeParticleMap) {
            this.activeParticleMap.leafletLayer.bringToFront();
          }
        }
      },
      onSiteMouseOver(event) {
        this.sitePanelSite = Object.assign({}, event.target.options.site);
      },
      onSiteMouseOut() {
        this.sitePanelSite = null;
      },
      onSiteClick(event) {
        const siteId = event.target.options.site.id;
        const regionId = event.target.options.site.region.id;
        const existingQuery = this.$route.query;
        const newQuery = Object.assign({}, existingQuery, { site: siteId, region: regionId, panelPosition: this.panelPosition ? this.panelPosition : 30});
        this.$router.push({ query: newQuery });
      },
      onRegionClick(event, overlyingLayer = false) {
        if (this.wmsLayersShowing.length < 1 || overlyingLayer ) {
          const regionId = event.target.options.region.id;
          const existingQuery = this.$route.query;
          const newQuery = Object.assign({}, existingQuery, { site: undefined, region: regionId, panelPosition: this.panelPosition ? this.panelPosition : 30});
          this.$router.push({ query: newQuery });
        }
      },
      onRegionMouseover(event) {
        this.regionHovered = event;
        event.target.setStyle({ fillColor: '#ffffff', fillOpacity: 0.4 });
      },
      onRegionMouseout(event) {
        event.target.setStyle({ fillOpacity: 0 });
        this.regionHovered = null;
      },
      onPanelToggleClick(event) {
        const existingQuery = this.$route.query;
        const newQuery = Object.assign({}, existingQuery, { panelPosition: event  });
        this.$router.push({ query: newQuery });
        setTimeout(() => {
          if (this.mapRef) {
            this.mapRef.invalidateSize();
          }
        }, 100);
      },
      async onFeatureClick(event,config){
        const v = event.type.toLowerCase() == "featurecollection" ? event : event.target.feature;
        if (this.activePanel != panelConstants.Feature || this.panelPosition == 0) { // checking if active panel for feature is already open
          const existingQuery = this.$route.query;
          const newQuery = Object.assign({}, existingQuery, { site: undefined, region: undefined, panelPosition: this.panelPosition ? this.panelPosition : 40});
          this.$router.push({ query: newQuery });
          this.activePanel = panelConstants.Feature;
        }

        if (config.groupId == "observations") { // we are appending timezone for observations
          const latitude = v.geometry.coordinates[1];
          const longitude = v.geometry.coordinates[0];
          const [request, options] = await requestBuilder.getTzRequest(this.serverRoute, latitude, longitude);
          const response = await fetch(request, options);
          const json = await response.json();
          v.geometry.timezone = json.timezoneString;
        }

        this.panelFeatureConfig = {feature:v,config:config};
      },
      toRiskColor(riskLevel) {
        return riskLevel in this.riskLevelToColor? this.riskLevelToColor[riskLevel] : this.defaultSiteColor;
      },
      calculateMarkerSize(site) {
        if (!site.hazardSummaries) {
          return this.defaultMarkerSize;
        }
        const currentDate = moment.utc().startOf('day');
        const estimateSeverityFunc = (day, riskLevel) => {
          return Math.max(0, 2 - day) * riskLevel;
        };
        const riskProximity = site.hazardSummaries.reduce((currentRiskProximity, nextHazard) => {
          const currentHazardDate = moment.utc(nextHazard.dateTime, 'YYYY-MM-DD');
          const differenceInDays = currentHazardDate.diff(currentDate, 'day')
          const estimatedSeverity = estimateSeverityFunc(differenceInDays, nextHazard.maxRiskLevel);
          if (estimatedSeverity > currentRiskProximity) {
            currentRiskProximity = estimatedSeverity;
          }
          return currentRiskProximity;
        }, 1);
        return this.defaultMarkerSize + riskProximity;
      },
      parseWeatherNC2JPGUrl(url) {
        const splitBySlash = url.split('/');
        const fileName = splitBySlash[splitBySlash.length - 1];
        const splitByDash = fileName.split('_');
        const rootUrl = splitBySlash.slice(0, splitBySlash.length - 1).join('/');
        return {
          model: splitByDash[0],
          config: splitByDash[1],
          level: splitByDash[2],
          fileType: splitByDash[3],
          domain: splitByDash[4],
          initialDateTime: moment.utc(splitByDash[5], 'YYYYMMDDHH'),
          parameter: splitByDash[6],
          validDateTime: moment.utc(splitByDash[7], 'YYYYMMDDHHmmss'),
          suffix: splitByDash[8],
          rootUrl: rootUrl
        }
      },
      getDisabledHazardKeys(){
        const config = JSON.parse(JSON.stringify(this.$store.getters.config));
        const allHazards = config['hazards'];
        return Object.keys(allHazards).filter(hazard => (allHazards[hazard].hasOwnProperty('enabled') && !allHazards[hazard].enabled));
      },
      calculateSiteMaxRiskLevel(dataByHazard, regionId, siteId) {
        let siteMaxRiskLevel = 0;
        const disabledHazardKeys = this.getDisabledHazardKeys();
        const showOnlyFavHazards = this.$store.getters.showOnlyFavouriteHazardsOnMap;
        const regionWithStarredHazards = this.$store.getters.starredHazardsByRegion[regionId] ? this.$store.getters.starredHazardsByRegion[regionId] : [];
        const starredHazardIds = regionWithStarredHazards[siteId] ? regionWithStarredHazards[siteId] : [];

        for (const [key, value] of Object.entries(dataByHazard)) {
          if (!disabledHazardKeys.includes(key)) {
            if (showOnlyFavHazards && starredHazardIds.length > 0) { // We only want to filter out starred hazards if feature is enabled and there is actually starred/fav hazards
              if (starredHazardIds.includes(key)) {
                const hazardMaxRiskLevel = Math.max(...dataByHazard[key].values);
                if (hazardMaxRiskLevel > siteMaxRiskLevel) {
                  siteMaxRiskLevel = hazardMaxRiskLevel;
                }
              }
            } else {
              const hazardMaxRiskLevel = Math.max(...dataByHazard[key].values);
              if (hazardMaxRiskLevel > siteMaxRiskLevel) {
                siteMaxRiskLevel = hazardMaxRiskLevel;
              }
            }
          }
        }
        return siteMaxRiskLevel;
      },
      calculateHazardSummary(dataByHazard) {
        const hazardSummary = [];
        for (const [hazardKey, hazardValue] of Object.entries(dataByHazard)) {
          const disabledHazardKeys = this.getDisabledHazardKeys();
          if (disabledHazardKeys.includes(hazardKey)) {
            continue;
          }
          for (let i = 0; i < 6; i++) { // going through days of week starting from current day
            const dayOfWeek = moment.utc().startOf('day').add(i, 'days');
            const currentHazard = dataByHazard[hazardKey];
            const dayRiskValues = currentHazard.dateTimes.map((day, dateIndex) => {
              const riskDay = moment.utc(day);
              const dateRiskLevel = dayOfWeek.isSame(riskDay,'day') ? currentHazard.values[dateIndex] : 0;
              return dateRiskLevel;
            });

            const maxRiskForDay = Math.max(...dayRiskValues);
            const summaryDayIndex = hazardSummary.findIndex(riskData => riskData.dateTime == dayOfWeek.format('YYYY-MM-DD'));
            if (summaryDayIndex != -1) {
              if (maxRiskForDay > hazardSummary[summaryDayIndex].maxRiskLevel) {
                hazardSummary[summaryDayIndex].maxRiskLevel = maxRiskForDay;
              } 
            } else {
              const dateObject = {};
              dateObject.dateTime = dayOfWeek.format('YYYY-MM-DD');
              dateObject.maxRiskLevel = maxRiskForDay;
              hazardSummary.push(dateObject);
            }
          }
        }
        return hazardSummary;
      },
      parseRiskNC2JPGUrl(url) {
        const splitBySlash = url.split('/');
        const fileName = splitBySlash[splitBySlash.length - 1];
        const splitByDash = fileName.split('_');
        const rootUrl = splitBySlash.slice(0, splitBySlash.length - 1).join('/');
        return {
          region: splitByDash[0],
          hazardName: splitByDash[1],
          validDateTime: moment.utc(splitByDash[2], 'YYYYMMDDHHmmss'),
          rootUrl: rootUrl
        };
      },
      regionInConfig(regionKey) {
        return Object.keys(this.$store.getters.config.regions).includes(regionKey);
      },
      siteInConfig(regionKey, siteKey) {
        if (!this.regionInConfig(regionKey)) {
          return false;
        }
        else {
          return Object.keys(this.$store.getters.config.regions[regionKey].sites).includes(siteKey);
        }
      },
      regionInRiskData(regionKey) {
        return Object.keys(this.riskdata.data).includes(regionKey);
      },
      siteInRiskData(regionKey, siteKey) {
        if (!this.regionInRiskData(regionKey)) {
          return false;
        }
        else {
          return Object.keys(this.riskdata.data[regionKey]).includes(siteKey);
        }
      },
      parseRiskDataToSites() {
        const config = JSON.parse(JSON.stringify(this.$store.getters.config));
        const riskdata = JSON.parse(JSON.stringify(this.riskdata.data));
        const sites = [];
        const maxRiskLevelByRegion = {};
        const regionKeys1 = Object.keys(riskdata);
        for (let i = 0; i < regionKeys1.length; i++) {
          let regionKey1 = regionKeys1[i];
          const starredSitesForRegion = this.$store.getters.starredSitesByRegion[regionKey1];
          maxRiskLevelByRegion[regionKey1] = 0;
          let siteKeys1 = Object.keys(riskdata[regionKey1]);
          for (let j = 0; j < siteKeys1.length; j++) {
            let siteKey1 = siteKeys1[j];

            if (starredSitesForRegion && this.$store.getters.showOnlyFavouriteSitesOnMap) {
              if (!starredSitesForRegion.includes(siteKey1) && starredSitesForRegion.length > 0) {
                continue;
              }
            }

            if (this.siteInConfig(regionKey1, siteKey1)) { // catches recently deleted sites
              let site = config.regions[regionKey1].sites[siteKey1];
              site = Object.assign(site, riskdata[regionKey1][siteKey1]);
              site.hazardSummaries = this.calculateHazardSummary(site.dataByHazard);
              site.maxRiskLevel = this.calculateSiteMaxRiskLevel(site.dataByHazard, regionKey1, site.id);
              site.region = {};
              site.region.id = regionKey1;
              site.color = this.toRiskColor(site.maxRiskLevel);
              sites.push(site);
              if (maxRiskLevelByRegion[regionKey1] < site.maxRiskLevel) {
                maxRiskLevelByRegion[regionKey1] = site.maxRiskLevel;
              }
            }
          }
        };

        // iterate again but this time through config so that we can catch newly added sites
        const regionKeys2 = Object.keys(config.regions);
        const maxRiskLevelByRegionKeys = Object.keys(maxRiskLevelByRegion);
        for (let ii = 0; ii < regionKeys2.length; ii++) {
          let regionKey2 = regionKeys2[ii];
          if (!maxRiskLevelByRegionKeys.includes(regionKey2)) {
            maxRiskLevelByRegion[regionKey2] = 0;
          }
          const starredSitesForRegion = this.$store.getters.starredSitesByRegion[regionKey2];
          const siteKeys2 = Object.keys(config.regions[regionKey2].sites);
          for (let jj = 0; jj < siteKeys2.length; jj++) {
            const siteKey2 = siteKeys2[jj];
            if (starredSitesForRegion && this.$store.getters.showOnlyFavouriteSitesOnMap) {
              if (!starredSitesForRegion.includes(siteKey2)) {
                continue;
              }
            }
            if (!this.siteInRiskData(regionKey2, siteKey2)) { // catches recently added sites
              let site = config.regions[regionKey2].sites[siteKey2];
              site.region = {};
              site.region.id = regionKey2;
              site.maxRiskLevel = 0;
              site.color = this.toRiskColor(site.maxRiskLevel);
              sites.push(site);
              if (maxRiskLevelByRegion[regionKey2] < site.maxRiskLevel) {
                maxRiskLevelByRegion[regionKey2] = site.maxRiskLevel;
              }
            }
          }
        }

        this.maxRiskLevelByRegion = Object.assign({}, this.maxRiskLevelByRegion, maxRiskLevelByRegion); // update and force reaction
        this.sites = {};
        this.sites = Object.assign({}, this.sites, sites); // update and force reaction
        this.updateSiteComponents();
      },
      updateSiteComponents() {
        this.siteViewKey = !this.siteViewKey;
        this.regionViewKey = !this.regionViewKey;
        this.featureViewKey = !this.featureViewKey;
      },
      parseWeatherMapsToWeatherMaps() {
        const config = this.config;
        const json = this.$store.getters.weatherMaps.data;
        let includeRegexes = [];
        let excludeRegexes = []
        if (config.layerConfig) {
          if (config.layerConfig.layerIncludeRegexes) {
            includeRegexes = config.layerConfig.layerIncludeRegexes.map((r) => {
              return new RegExp(r);
            });
          }
          if (config.layerConfig.layerExcludeRegexes) {
            excludeRegexes = config.layerConfig.layerExcludeRegexes.map((r) => {
              return new RegExp(r);
            });
          }
        }
        const evaluateLayerRegexes = (key) => {
          let shouldInclude = true;
          if (includeRegexes.length !== 0) {
            shouldInclude = shouldInclude && includeRegexes.find((r) => key.match(r));
          }

          if (excludeRegexes.length !== 0) {
            const shouldExclude = excludeRegexes.find((r) => key.match(r));
            shouldInclude = shouldInclude && !shouldExclude;
          }

          return shouldInclude;
        }

        const weatherMaps = json.reduce((heatMapCollection, nextMapFile) => {
          const imgProps = this.parseWeatherNC2JPGUrl(nextMapFile);
          const key = `${imgProps.model}-${imgProps.level}-${imgProps.parameter}`;
          const initialDateTimeStr = imgProps.initialDateTime.format('YYYYMMDDHH');
          const validDateTimeStr = imgProps.validDateTime.format('YYYYMMDDHHmmss');
          if (!(evaluateLayerRegexes(key))) {
            return heatMapCollection;
          }

          if (!(key in heatMapCollection)) {
            heatMapCollection[key] = {
              key: key,
              model: imgProps.model,
              parameter: imgProps.parameter,
              level: imgProps.level,
              sources: [],
              validDateTimes: [],
              urlTemplate: `https://nc2jpg.s3.amazonaws.com/${imgProps.rootUrl}/${imgProps.model}_${imgProps.config}` +
                           `_${imgProps.level}_${imgProps.fileType}_${imgProps.domain}_${initialDateTimeStr}_${imgProps.parameter}` +
                           `_{validDateTime}_${imgProps.suffix}`
            }
          }
          heatMapCollection[key].sources.push(`https://nc2jpg.s3.amazonaws.com/${nextMapFile}`);
          heatMapCollection[key].validDateTimes.push(imgProps.validDateTime);
          return heatMapCollection;
        }, {});

        this.weatherMaps = Object.assign({}, this.weatherMaps, weatherMaps); // update and force reaction

        if (this.$store.getters.activeHeatMap && Object.keys(this.weatherMaps).length !== 0) {
          const event = { target: { value: this.$store.getters.activeHeatMap } };
          this.onControlChange(event);
        }
        if (this.$store.getters.activeParticleMap && Object.keys(this.weatherMaps).length !== 0) {
          const event = { target: { value: this.$store.getters.activeParticleMap } };
          this.onParticleControlChange(event);
        }

        this.controlsBarKey = !this.controlsBarKey; // update and force reaction
      },
      parseFeatures(){
        // Here we are transforming the json coming out of this to structure them into groups
        const json = this.$store.getters.features.data;
        const structuredJson = json.length == 0 ? [] : json.reduce((groupList, nextFeature) => {
          const { groupName,groupId } = nextFeature;
          if(!Array.isArray(groupList)){
            groupList = [{
              features:[groupList],
              id: groupId,
              name: groupName
            }];
          }
          if(groupList.some(x=> x.id === groupId)){
            let g = groupList.find(x => x.id === groupId)
            g.features.push(nextFeature);
          } else {
            groupList.push({
              features:[nextFeature],
              id: groupId,
              name: groupName
            });
          }
          return groupList;
        })
        this.features = structuredJson;
      },
      async verifyActiveFeatures() {
        const currentMapFeatures = JSON.parse(JSON.stringify(this.$store.getters.getCurrentMapFeatures));
        const activeMapLayers = [...this.activeFeatures];
        const featureKeys = Object.keys(currentMapFeatures);

        for (const keyIndex in featureKeys) {
          const featureId = featureKeys[keyIndex];
          const currentLayer = activeMapLayers.find(x => x.options.id === featureId);

          if (!currentLayer) { // feature is suppose to be switched on but map layer is not present
            const featureEvent = { target: { value: featureId } };
            await this.onFeatureChange(featureEvent);
          }
        }
      },
      parseRiskMapsToRiskMaps() {
        const json = this.$store.getters.riskMaps.data;
        const riskMaps = json.reduce((heatMapCollection, nextMapFile) => {
          const imgProps = this.parseRiskNC2JPGUrl(nextMapFile);
          const key = `${imgProps.region}-${imgProps.hazardName}`
          const validDateTimeStr = imgProps.validDateTime.format('YYYYMMDDHHmmss');
          if (!(key in heatMapCollection)) {
            heatMapCollection[key] = {
              key: key,
              region: imgProps.region,
              variable: imgProps.hazardName,
              sources: [],
              validDateTimes: [],
              urlTemplate: `https://nc2jpg.s3.amazonaws.com/${imgProps.rootUrl}/${imgProps.region}` +
                           `_${imgProps.hazardName}_{validDateTime}.jpg`
            }
          }
          heatMapCollection[key].sources.push(`https://nc2jpg.s3.amazonaws.com/${nextMapFile}`);
          heatMapCollection[key].validDateTimes.push(imgProps.validDateTime);
          return heatMapCollection;
        }, {});
        this.riskMaps = Object.assign({}, this.riskMaps, riskMaps); // update and force reaction
        this.controlsBarKey = !this.controlsBarKey; // update and force reaction
      },
      mountMap() {
        this.mapRef = this.$refs.map.mapObject;
        this.mapRef.setMaxBounds([[-90, -180], [90, 180]]); // prevents panning outside these bounds
        if (this.config.zoom) {
          setTimeout(() => {
          }, 500);
        }
        if (this.config.baseMapConfig) {
          this.url = this.config.baseMapConfig.url;
          this.attribution = this.config.baseMapConfig.attribution;
        }
        L.Icon.Default.mergeOptions({
          iconUrl: "../assets/img/circle.png" ,
          iconRetinaUrl: "../assets/img/circle.png" ,
          shadowUrl: "../assets/img/circle.png",
          iconSize: [8,8],
          shadowSize: [0,0],
          iconAnchor: [4,4]
        });
      }
    },

    async mounted() {

      // fetch new data if data in store is stale
      this.updateData();

      this.mountMap();
      this.parseRiskDataToSites();
      this.parseRiskMapsToRiskMaps();
      this.parseWeatherMapsToWeatherMaps();
      this.parseFeatures();
      this.verifyActiveFeatures();
    },
  }
</script>
