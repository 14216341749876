var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HeaderComponent"),
      _vm._v(" "),
      _c("cookie-notice"),
      _vm._v(" "),
      _vm.isLoading ? _c("CubeSpinner") : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", [
            _c("div", { staticClass: "rd-hazard-visualizer" }, [
              _c(
                "div",
                { staticClass: "rd-hazard-visualizer__region-navigation" },
                [
                  _c(
                    "div",
                    { staticClass: "rd-hazard-visualizer__column" },
                    [
                      _c(
                        "div",
                        { staticClass: "rd-hazard-visualizer__header" },
                        [_vm._v("Regions")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.regions, function(region) {
                        return _c("div", { key: region.id }, [
                          _c(
                            "div",
                            {
                              staticClass: "rd-hazard-visualizer__data-header",
                              class: { open: _vm.activeRegion === region.id },
                              on: {
                                click: function($event) {
                                  return _vm.updateRegion(region.id)
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(region.name))]),
                              _vm.activeRegion !== region.id
                                ? _c("i", { staticClass: "fas fa-caret-down" })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          region.id == _vm.activeRegion
                            ? _c(
                                "div",
                                _vm._l(_vm.activeSites, function(site) {
                                  return _c(
                                    "div",
                                    {
                                      key: site.id,
                                      staticClass:
                                        "rd-hazard-visualizer__data-row",
                                      class: {
                                        open: _vm.activeSite === site.id
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateSite(site.id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(site.name) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ])
                      })
                    ],
                    2
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rd-hazard-visualizer__chart-container" },
                [
                  _c(
                    "div",
                    { staticClass: "rd-hazard-visualizer__chart-component" },
                    [
                      _vm.loadingChartData ? _c("CubeSpinner") : _vm._e(),
                      _vm._v(" "),
                      !_vm.loadingChartData && _vm.regionHasHazards
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "rd-hazard-visualizer__line-chart-container"
                              },
                              _vm._l(_vm.activeHazardParameters, function(
                                parameter
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: parameter.id,
                                    staticClass: "line-chart-d3-cls"
                                  },
                                  [
                                    _c(
                                      "line-chart-d3",
                                      _vm._b(
                                        { key: _vm.lineChartKey },
                                        "line-chart-d3",
                                        _vm.lineChartProps(parameter),
                                        false
                                      )
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.regionHasHazards && !_vm.loadingChartData
                        ? _c("div", [_vm._m(0)])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "rd-hazard-visualizer__parameter-tab"
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rd-hazard-visualizer__hazard-navigation" },
                [
                  _c(
                    "div",
                    { staticClass: "rd-hazard-visualizer__column" },
                    [
                      _c(
                        "div",
                        { staticClass: "rd-hazard-visualizer__header" },
                        [_vm._v("Hazards")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.hazards, function(hazard) {
                        return _c("div", { key: hazard.id }, [
                          hazard.regions
                            ? _c("div", [
                                hazard.regions.includes(_vm.activeRegion) &&
                                _vm.satisfyFavouriteHazardPreference(hazard.id)
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rd-hazard-visualizer__data-header",
                                          class: {
                                            open: _vm.activeHazard === hazard.id
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateHazard(hazard.id)
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(hazard.name))
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      })
                    ],
                    2
                  )
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "rd-hazard-visualizer__line-chart-container" },
      [
        _c("div", { staticClass: "line-chart-d3-cls" }, [
          _c("div", { staticClass: "warning" }, [
            _vm._v("There are no hazards for this region")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }