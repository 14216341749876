var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "hc-heading hc-heading--page" }, [
    _c("h2", { staticClass: "hc-heading__text" }, [_vm._v("Configuration")]),
    _vm._v(" "),
    _c("div", { staticClass: "hc-actions" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "hc-btn js-toggle-errors",
          attrs: { title: "Save" },
          on: { click: _vm.postConfig }
        },
        [_c("i", { staticClass: "fas fa-save" })]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "hc-btn hc-btn--grey", attrs: { title: "Reset" } },
      [_c("i", { staticClass: "fas fa-undo" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }