<template>
  <div class="ani1 row-clean" v-if="ready">
    <svg id="riskcurvesvg" v-bind="constSvgAttrs">
      <rect fill="#d32a27" y="5" v-bind="constRectAttrs"/>
      <rect fill="#ee6b3f" y="65" v-bind="constRectAttrs"/>
      <rect fill="#fdc94e" y="125" v-bind="constRectAttrs"/>
      <rect fill="#eff471" y="185" v-bind="constRectAttrs"/>
      <rect fill="#94d864" y="245" v-bind="constRectAttrs"/>
      <g class='lineChart' :transform="translate">
        <axis class='yA' :scales="yAxis" :chartDefaults='chartDefaults' :data='dataObj' :translate='translateY'/>
        <axis class='xA' :scales="xAxis" :chartDefaults='chartDefaults' :data='dataObj' :translate='translateX'/>
        <text fill="black" transform="translate(-20, 180) rotate(-90)">Risk level</text>
        <text fill="black" transform="translate(300, 350)">{{ name }} ({{ formatDisplayUnit(displayUnits) }})</text>
        <path id="riskcurvepath" class='line' :d="line"/>
      </g>
    </svg>
    <div>
      <div v-if="isBetween" class="slider-container-row">
          <div class="slider-input-container">
            Threshold 1: <vue-slider
                v-model="mu1"
                :min="parseFloat(minMu)"
                :max="parseFloat(mu2)"
                :interval="sliderInterval"
                @change="updatePath()"/>
            </div>
          <input class="wg-form-control slider-input" max="mu2" min="minMu" v-model="mu1" @change="updatePath()"/>
          <span> {{ formatDisplayUnit(displayUnits) }} </span>
      </div>
      <div v-if="isBetween" class="slider-container-row">
          <div class="slider-input-container">
            Threshold 2: <vue-slider
                v-model="mu2"
                :min="parseFloat(mu1)"
                :max="parseFloat(maxMu)"
                :interval="sliderInterval"
                @change="updatePath()"/>
            </div>
          <input class="wg-form-control slider-input" max="maxMu" min="mu1" v-model="mu2" @change="updatePath()"/>
          <span> {{ formatDisplayUnit(displayUnits) }} </span>
      </div>
      <div v-else class="slider-container-row">
          <div class="slider-input-container">
            Threshold: <vue-slider
                v-model="mu"
                :min="minMu"
                :max="maxMu"
                :interval="sliderInterval"
                @change="updatePath()"/>
            </div>
          <input class="wg-form-control slider-input" max="maxMu" min="minMu" v-model="mu" @change="updatePath()"/>
          <span> {{ formatDisplayUnit(displayUnits) }} </span>
      </div>
      <div class="slider-container-row">
        <div class="slider-input-container">
          Sensitivity: <vue-slider
              v-model="sigma"
              :min="minSigma"
              :max="maxSigma"
              :interval="sliderInterval"
              @change="updatePath()"/>
        </div>
        <input class="wg-form-control slider-input" max="maxMu" min="minMu" v-model="sigma" @change="updatePath()"/>
        <span> {{ formatDisplayUnit(displayUnits) }} </span>
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";
import Axis from './Axis.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { UnitConverter } from '../js/unitConverter.js';
import { SensitivityConverter } from '../js/sensitivityConverter.js';
import { formatDisplayUnit } from '../js/helperFunctions.js';
const unitConverter = new UnitConverter();
const sensitivityConverter = new SensitivityConverter();

export default {
  components: { axis: Axis, VueSlider },
  props: [
    'parameter',
    'parameterInfo',
    'units',
    'displayUnits'
  ],
  mounted() {
    this.ready = false;
    this.init();
    this.ready = true;
  },
  data() {
    return {

      ready: false,

      name: this.parameterInfo.name,
      mu: this.parameter.threshold.mu,
      mu1: this.parameter.threshold.mu1,
      mu2: this.parameter.threshold.mu2,
      sigma: this.parameter.threshold.sigma,
      comparisonType: this.parameter.threshold.comparisonType,
      precision: this.parameterInfo.threshold.precision,
      sliderInterval: 0.1,

      minMu: this.parameterInfo.threshold.minMu,
      maxMu: this.parameterInfo.threshold.maxMu,
      minSigma: this.parameterInfo.threshold.minSigma,
      maxSigma: this.parameterInfo.threshold.maxSigma,

      xs: undefined,
      ys: undefined,
      dataObj: undefined,
      xScale: undefined,
      yScale: undefined,
      xAxis: undefined,
      yAxis: undefined,
      yGrid: undefined,
      path: undefined,
      line: undefined,

      numberOfXPoints: 1000,
      yTickValues: [0.1, 0.3, 0.5, 0.7, 0.9],
      yTickLabels: [1, 2, 3, 4, 5],
      constTextAttrs: {
        'font-size': '10px'
      },
      constSvgAttrs: {
        width: "40%",
        height: "50%",
        viewBox: "0 0 800 360",
        preserveAspectRatio: "xMidYMid meet",
      },
      constRectAttrs: {
        x: 50,
        width: 700,
        height: 60,
        opacity: 1
      },
      chartDefaults: {
        width: 800,
        height: 300,
        title: 'Risk Curve',
        grid: {
          style: {
            opacity: 1
          }
        },
      },
      translate: "translate(" + 50 + "," + 5 + ")",
      translateY: "translate(0,0)",
    }
  },
  computed: {
    isBetween() { return this.comparisonType === 'between'; },
    translateX() { return "translate(0," + this.chartDefaults.height + ")"; },
  },
  methods: {
    convertValue(valueIn, unitsIn, unitsOut, precision = 1) {
      const valueOut = unitConverter.convert(valueIn, unitsIn, unitsOut, precision);
      return +valueOut;
    },
    convertValueWithoutRounding(valueIn, unitsIn, unitsOut, precision = 1) {
      const valueOut = unitConverter.convert(valueIn, unitsIn, unitsOut, 4);
      const factor = Math.pow(10, precision);
      const formatedValue = Math.floor(valueOut * factor) / factor;
      return formatedValue;
    },
    convertSensitivity(valueIn, unitsIn, unitsOut, precision = 1) {
      const valueOut = sensitivityConverter.convert(valueIn, unitsIn, unitsOut, precision);
      return +valueOut;
    },
    convertSensitivityWithoutRounding(valueIn, unitsIn, unitsOut, precision = 1) {
      const valueOut = sensitivityConverter.convert(valueIn, unitsIn, unitsOut, 4);
      const factor = Math.pow(10, precision);
      const formatedValue = Math.floor(valueOut * factor) / factor;
      return formatedValue;
    },
    getSliderInterval(precision) {
      return 1 / Math.pow(10,precision);
    },
    init() {
      this.minMu = this.convertValueWithoutRounding(this.minMu, this.units, this.displayUnits, this.precision);
      this.maxMu = this.convertValueWithoutRounding(this.maxMu, this.units, this.displayUnits, this.precision);
      this.mu = this.convertValue(this.mu, this.units, this.displayUnits, this.precision);
      this.mu1 = this.convertValue(this.mu1, this.units, this.displayUnits, this.precision);
      this.mu2 = this.convertValue(this.mu2, this.units, this.displayUnits, this.precision);
      this.sliderInterval = this.getSliderInterval(this.precision);

      this.minSigma = this.convertSensitivityWithoutRounding(this.minSigma, this.units, this.displayUnits, this.precision);
      this.maxSigma = this.convertSensitivityWithoutRounding(this.maxSigma, this.units, this.displayUnits, this.precision);

      this.sigma = this.convertSensitivity(this.sigma, this.units, this.displayUnits, this.precision);

      this.xs = this.computeXs(this.minMu, this.maxMu);
      this.ys = this.computeYs(this.xs, this.mu, this.mu1, this.mu2, this.sigma, this.comparisonType);
      this.dataObj = this.xs.map((x, i) => { return { x, y: this.ys[i] } });
      this.xScale = this.getScaleX(this.dataObj);
      this.yScale = this.getScaleY();
      this.xAxis = this.getAxisScaleX(this.xScale);
      this.yAxis = this.getAxisScaleY(this.yScale);
      this.yGrid = this.getGridScaleY(this.yScale);
      this.path = d3.line()
        .x(d => this.xScale(d.x))
        .y(d => this.yScale(d.y))
        .curve(d3.curveCardinal);
      this.line = this.path(this.dataObj);
    },
    getScaleX(data) {
      const xScale = d3.scaleLinear()
        .domain(d3.extent(data, d => d.x ))
        .rangeRound([0, this.chartDefaults.width - 100]);
      return xScale;
    },
    getScaleY() {
      const yScale = d3.scaleLinear()
        .domain([0, 1])
        .range([this.chartDefaults.height, 0]);
      return yScale;
    },
    getAxisScaleX(xScale) {
      const xAxis = d3.axisBottom()
        .scale(xScale)
        .ticks(4);
      return xAxis;
    },
    getAxisScaleY(yScale) {
      const yAxis = d3.axisLeft()
        .scale(yScale)
        .tickValues(this.yTickValues)
        .tickFormat((d,i) => this.yTickLabels[i])
        .tickSizeOuter(0)
        .tickSizeInner(0);
      return yAxis;
    },
    getGridScaleY(yScale) {
      const yGrid = d3.axisLeft()
        .scale(yScale)
        .ticks(0);
      return yGrid;
    },
    computeXs(x0, xn) {
      const dx = Math.abs(xn - x0) / this.numberOfXPoints;
      const xs = [];
      let x = x0;
      while (x < xn) {
        xs.push(x);
        x += dx;
      }
      return xs;
    },
    computeYs(xs, mu, mu1, mu2, sigma, comparisonType) {
      let ys = [];
      switch (comparisonType) {
        case 'between':
          ys = xs.map( x => this.computeBetweenFxValue(x, mu1, mu2, sigma) );
          break;
        case 'greaterThan':
          ys = xs.map( x => this.computeGreaterThanFxValue(x, mu, sigma) );
          break;
        case 'lessThan':
          ys = xs.map( x => this.computeLessThanFxValue(x, mu, sigma) );
          break;
      }
      return ys;
    },
    computeBetweenFxValue(x, mu1, mu2, sigma) {
      if (x >= mu1 && x <= mu2) {
        return 1;
      }
      else if (x <= mu1) {
        return this.gaussian(x, mu1, sigma);
      }
      else if (x >= mu2) {
        return this.gaussian(x, mu2, sigma);
      }
      else {
        console.log(`An invalid value ${x} was encountered`)
      }
    },
    gaussian(x, mu, sigma) {
      return Math.exp(-1 * (x - mu)**2 / (2 * sigma**2));
    },
    computeGreaterThanFxValue(x, mu, sigma) {
      return (x <= mu) ? this.gaussian(x, mu, sigma) : 1;
    },
    computeLessThanFxValue(x, mu, sigma) {
      return (x >= mu) ? this.gaussian(x, mu, sigma) : 1;
    },
    updatePath() {
      if (isNaN(this.sigma) || isNaN(this.mu)) {
        this.$emit('update-from-chart-event',this.parameter, NaN, NaN, NaN, NaN);
        return;
      }
      this.ys = this.computeYs(this.xs, this.mu, this.mu1, this.mu2, this.sigma, this.comparisonType);
      this.data = this.xs.map((x, i) => { return { x: x, y: this.ys[i] } });
      this.path = d3.line()
        .x(d => this.xScale(d.x))
        .y(d => this.yScale(d.y))
        .curve(d3.curveCardinal);
      this.line = this.path(this.data);
      this.$emit('update-from-chart-event',
        this.parameter,
        this.convertValue(parseFloat(this.mu), this.displayUnits, this.units, 3),
        this.convertValue(parseFloat(this.mu1), this.displayUnits, this.units, 3),
        this.convertValue(parseFloat(this.mu2), this.displayUnits, this.units, 3),
        this.convertSensitivity(parseFloat(this.sigma), this.displayUnits, this.units, 3)
      );
    },
    formatDisplayUnit
  }
}

</script>
