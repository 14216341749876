export function formatComparisonType(value) {
  switch (value) {
    case 'greaterThan':
      return '>';
    case 'lessThan':
        return '<';
    case 'equal':
        return '=';
    default:
      return value;
  }
}

export function formatDisplayUnit(displayunit) {
  switch (displayunit) {
    case 'C':
      return '°C';
    case 'm^3/s':
      return 'm³/s';
    default:
      return displayunit;
  }
}