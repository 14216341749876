import L from 'leaflet';
import chroma from 'chroma-js';
import 'leaflet-canvaslayer-field';
import { initializeProgram, createShader, getRecolorVertexShader } from './webgl.utils.js';

L.ImageCanvasLayer = L.CanvasLayer.extend({
  initialize: function(options) {
    L.CanvasLayer.prototype.initialize.call(
        this,
        options
    );
    L.Util.setOptions(this, options);
    this.image = new Image();
    this.blob = null;
    this.metadata = null;
    this.loaded = false;
  },

  onLayerDidMount() {
    this._alignCanvas();
    this._enableControls();
  },

  onLayerWillUnmount() {
    this._disableControls();
  },

  /* eslint-disable no-unused-vars */
  onDrawLayer(viewInfo) {
    this._loadImage((metadata, image) => {
      this._drawAndColorImage(metadata, image);
      this.fire('change');
    });
  },

  nextSource(nextSource) {
    this.loaded = false;
    this.options.source = nextSource;
    this.onDrawLayer();
  },

  updateOptions(newOptions) {
    this.loaded = false;
    this.options = Object.assign(this.options, newOptions);
    this.onDrawLayer();
  },

  updateFragmentShader(newFragmentShader) {
    this.loaded = false;
    this.options.fragmentShader = newFragmentShader;
    this.onDrawLayer();
  },

  isVisible() {
    return this._visible;
  },

  getValue(containerPoint) {
    return this._getValue(containerPoint);
  },

  getMetadata(callback) {
    if (this.metadata) {
      return callback(this.metadata);
    }

    // Do not let this merge without being fixed
    const context = this;
    setTimeout(() => {
      return callback(context.metadata);
    }, 1000);
  },

  getValueUnits() {
    return this.metadata.valueUnits;
  },

  _enableControls() {
    this._map.on('click', this._onClick, this);
  },

  _disableControls() {
    this._map.off('click', this._onClick, this);
  },

  _loadImage(callback) {
    if (this.loaded) {
      return callback(this.metadata, this.image);
    }
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default'
    };

    const request = new Request(this.options.source);
    fetch(request, options).then((response) => {
      this.metadata = this._parseImageHeadersToMetadata(response.headers);
      response.blob().then((blob) => {
        var objectURL = URL.createObjectURL(blob);
        this.image.src = objectURL;
        this.blob = blob;
        this.image.onload = () => {
          this.loaded = true;
          return callback(this.metadata, this.image);
        };
      });
    });
  },

  _parseImageHeadersToMetadata(headers) {
    const minValue = headers.get('x-amz-meta-vmin');
    const maxValue = headers.get('x-amz-meta-vmax');
    const lowerLeftLat = headers.get('x-amz-meta-llcrnrlat');
    const lowerLeftLon = headers.get('x-amz-meta-llcrnrlon');
    const upperRightLat = headers.get('x-amz-meta-urcrnrlat');
    const upperRightLon = headers.get('x-amz-meta-urcrnrlon');
    const units = headers.get('x-amz-meta-units');
    const param = headers.get('x-amz-meta-param');
    return {
      bounds: [[lowerLeftLat, lowerLeftLon], [upperRightLat, upperRightLon]],
      minValue: parseFloat(minValue),
      maxValue: parseFloat(maxValue),
      parameter: param,
      valueUnits: units
    };
  },

  _showCanvas() {
    L.CanvasLayer.prototype._showCanvas.call(this);
    this.needRedraw(); // TODO check spurious redraw (e.g. hide/show without moving map)
  },

  _alignCanvas() {
    const topLeft = this.options.mapRef.containerPointToLayerPoint([0, 0]);
    L.DomUtil.setPosition(this._canvas, topLeft);
  },

  _onClick(e) {
    this.fire('click', {
      latlng: e.latlng,
      value: this._getValue(e.containerPoint)
    });
  },

  _getValue(containerPoint) {
    if (!this.metadata) {
      return null;
    }
    const leafletLL = this.options.mapRef.latLngToContainerPoint(this.metadata.bounds[0]);
    const leafletUR = this.options.mapRef.latLngToContainerPoint(this.metadata.bounds[1]);

    const imageCanvas = document.createElement("CANVAS");
    const mapSize = this.options.mapRef.getSize();
    imageCanvas.width = mapSize.x;
    imageCanvas.height = mapSize.y;
    const imageContext = imageCanvas.getContext('2d');
    imageContext.clearRect(0, 0, imageCanvas.width, imageCanvas.height);

    imageContext.drawImage(this.image, leafletLL.x, leafletUR.y, (leafletUR.x - leafletLL.x), (leafletLL.y - leafletUR.y));
    const imageData = imageContext.getImageData(0, 0, mapSize.x, mapSize.y);
    const currentImagePosition = Math.round(containerPoint.y) * imageData.width + Math.round(containerPoint.x);
    
    const minValue = this.metadata.minValue;
    const maxValue = this.metadata.maxValue;
    const uValue = minValue + (maxValue - minValue) * imageData.data[currentImagePosition*4]/255;
    let vValue = 0;
    if (this.metadata.parameter === 'UGRD-VGRD') {
      vValue = minValue + (maxValue - minValue) * imageData.data[currentImagePosition*4 + 1]/255
    }
    let value = Math.sqrt(uValue*uValue + vValue*vValue);
    const currentMaskValue = imageData.data[currentImagePosition*4 + 2];
    const minimumMaskValue = this.options.fragmentTemplate == "mask-land" ? 150 : 25;
    return currentMaskValue < minimumMaskValue ? value : null; // Matplotlib colors are weird - blue is not really blue
  },

  _drawAndColorImage(metadata, image) {
    let renderer = this._getRenderer();
    const leafletLL = this.options.mapRef.latLngToContainerPoint(metadata.bounds[0]);
    const leafletUR = this.options.mapRef.latLngToContainerPoint(metadata.bounds[1]);
    renderer(image, leafletLL, leafletUR);
  },

  _getRenderer: function() {
    let gl = null;
    if (!this.options.disableWebgl) {
      gl = this._canvas.getContext('webgl2');
      if (gl) {
        return this._renderImageOnGPU.bind(this, gl);
      }
    }
    gl = this._canvas.getContext('2d');
    gl.clearRect(0, 0, this._canvas.width, this._canvas.height);
    return this._renderImageOnCPU.bind(this, gl);
  },

  _getProgram(gl) {
    const vertexShader = createShader(gl, gl.VERTEX_SHADER, getRecolorVertexShader());
    const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, this.options.fragmentShader);
    this.program = initializeProgram(gl, [vertexShader, fragmentShader]);
    return this.program;
  },

  _renderImageOnGPU: function(gl, image, leafletLL, leafletUR) {
    const program = this._getProgram(gl);
    var positionLocation = gl.getAttribLocation(program, "a_position");
    var texcoordLocation = gl.getAttribLocation(program, "a_texCoord");
    var textureSizeLocation = gl.getUniformLocation(program, "u_textureSize");

    // Create a buffer to put three 2d clip space points in
    var positionBuffer = gl.createBuffer();

    // Fix opacity
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    // Set a rectangle the same size as the canvas.
    this._setRectangle(gl, 0, 0, this._canvas.width, this._canvas.height);

    // provide texture coordinates for the rectangle.
    var texcoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);

    // Work out where in the frame the view currently is
    const xStartPosition = (0 - leafletLL.x)/(leafletUR.x - leafletLL.x);
    const yStartPosition = (0 - leafletUR.y)/(leafletLL.y - leafletUR.y);
    const xEndPosition = (this._canvas.width - leafletLL.x)/(leafletUR.x - leafletLL.x);
    const yEndPosition = (this._canvas.height - leafletUR.y)/(leafletLL.y - leafletUR.y);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
        xStartPosition,  yStartPosition,
        xEndPosition,  yStartPosition,
        xStartPosition,  yEndPosition,
        xStartPosition,  yEndPosition,
        xEndPosition,  yStartPosition,
        xEndPosition,  yEndPosition,
    ]), gl.STATIC_DRAW);

    // Create a texture.
    var texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

    // lookup uniforms
    var resolutionLocation = gl.getUniformLocation(program, "u_resolution");
    var lowerLeftBounds = gl.getUniformLocation(program, "u_lowerLeftBoundXY");
    var upperRightBounds = gl.getUniformLocation(program, "u_upperRightBoundXY");
    var opacity = gl.getUniformLocation(program, "u_opacity");

    // set the resolution, lower left bound of the image in XY, upper right bound of the image in XY
    gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);
    gl.uniform2f(lowerLeftBounds, leafletLL.x, leafletLL.y);
    gl.uniform2f(upperRightBounds, leafletUR.x, leafletUR.y);
    gl.uniform2f(textureSizeLocation, image.width, image.height);
    gl.uniform1f(opacity, this.options.opacity);

    // Tell WebGL how to convert from clip space to pixels
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    // Clear the canvas
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    // Turn on the position attribute
    gl.enableVertexAttribArray(positionLocation);

    // Bind the position buffer.
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

    // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    var size = 2;          // 2 components per iteration
    var type = gl.FLOAT;   // the data is 32bit floats
    var normalize = false; // don't normalize the data
    var stride = 0;        // 0 = move forward size * sizeof(type) each iteration to get the next position
    var offset = 0;        // start at the beginning of the buffer
    gl.vertexAttribPointer(
        positionLocation, size, type, normalize, stride, offset);

    // Turn on the texcoord attribute
    gl.enableVertexAttribArray(texcoordLocation);

    // Bind the texcoordBuffer
    gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);

    // Tell the texcoord attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    size = 2;          // 2 components per iteration
    type = gl.FLOAT;   // the data is 32bit floats
    normalize = false; // don't normalize the data
    stride = 0;        // 0 = move forward size * sizeof(type) each iteration to get the next position
    offset = 0;        // start at the beginning of the buffer
    gl.vertexAttribPointer(
        texcoordLocation, size, type, normalize, stride, offset);

    // Draw the rectangle.
    var primitiveType = gl.TRIANGLES;
    offset = 0;
    var count = 6;
    gl.drawArrays(primitiveType, offset, count);
  },

  _renderImageOnCPU: function(ctx, image, leafletLL, leafletUR) {
    ctx.globalAlpha = this.options.opacity;
    ctx.drawImage(image, leafletLL.x, leafletUR.y, (leafletUR.x - leafletLL.x), (leafletLL.y - leafletUR.y));
    var imageData = ctx.getImageData(0, 0, this._canvas.width, this._canvas.height);
    function changeToColor(data) {
      const colorScale = chroma.scale(['white', '#f3f5e1', '#4169e0', 'cyan', '#00ff80', '#80ff00', 'orange', '#ff8000', 'red']);
      for (var i = 0; i < data.length; i = i + 4) {
        const newColor = colorScale(Math.sqrt(data[i]*data[i] + data[i+1]*data[i+1])/255.0)._rgb;
        data[i] = newColor[0];
        data[i+1] = newColor[1];
        data[i+2] = newColor[2];
      }
    }
    changeToColor(imageData.data);
    ctx.putImageData(imageData, 0, 0);
  },

  _setRectangle(gl, x, y, width, height) {
    var x1 = x;
    var x2 = x + width;
    var y1 = y;
    var y2 = y + height;
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
      x1, y1,
      x2, y1,
      x1, y2,
      x1, y2,
      x2, y1,
      x2, y2,
    ]), gl.STATIC_DRAW);
  }
});

export function imageCanvasLayer(options) {
  return new L.ImageCanvasLayer(options);
}
