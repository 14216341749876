import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import LoginComponent from '../views/Login.vue';
import MapComponent from '../views/Map.vue';
import VisualizationComponent from '../views/Visualization.vue';
import SettingsComponent from '../views/Settings.vue';
import ProfileView from '../views/Profile.vue';
import Policies from '../views/Policies.vue';
const { default: authService } = require("../js/authenticationService");

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginComponent,
    meta: { public: true }
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: { public: false }
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsComponent,
    meta: { public: false }
  },
  {
    path: "/policies",
    name: "policies",
    component: Policies,
    meta: { public: false }
  },
  {
    path: "/",
    name: "map",
    component: MapComponent,
    meta: { public: false }
  },
  {
    path: '/vis',
    name: 'vis',
    component: VisualizationComponent,
    meta: { public: false }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes: routes,
});

router.latestMapView = '';
router.latestRegionView = '';
router.latestSiteView = '';
router.lastRouteFullPath = '';
const deadEnds = ['profile', 'settings'];

router.beforeEach(async (to, from, next) => {

  const isLoggedIn = await authService.getUser().then(function(user) {return user; });

  // clear localStorage on logout
  if (to.name === 'login' && !isLoggedIn) {
    window.localStorage.clear(); // eslint-disable-line no-undef
  }

  // if try to access a page other than login while not authenticated, reroute to login
  if (to.name !== 'login' && !isLoggedIn) {
    const allRouteNames = routes.map(route => route.name);
    // if we were logged in before and we are no longer authorized then we need to clear local storage as well
    if (allRouteNames.includes(from.name) && from.name !== 'login') {
      window.localStorage.clear(); // eslint-disable-line no-undef
    }
    next({ name: 'login' });
  }

  if(to.name !== 'login' && isLoggedIn && !store.getters.initialDataLoaded) { // even though user may be authenticated it doesn't make sense to load decisionvue if there is no data
    next({ name: 'login'})
  }

  // if try to go back to login without logging out, go to map
  if (to.name === 'login' && isLoggedIn && store.getters.initialDataLoaded) {
    next({ name: 'map' });
  }

  // nav dot magic
  router.latestMapView = '/';
  router.latestRegionView = `/?region=${from.query.region}&panelPosition=100`;
  router.latestSiteView = `/?region=${from.query.region}&site=${from.query.site}&panelPosition=100`;
  if (to.name == 'lastRouteFullPath') router.push({ 'path': router.lastRouteFullPath });
  else if (to.name == 'latestMapView') router.push({ 'path': router.latestMapView });
  else if (to.name == 'latestRegionView') router.push({ 'path': router.latestRegionView });
  else if (to.name == 'latestSiteView') router.push({ 'path': router.latestSiteView });
  else next();
  if (!deadEnds.includes(from.name)) {
    router.lastRouteFullPath = from.fullPath;
  }
})

export default router;