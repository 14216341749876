var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HeaderComponent"),
      _vm._v(" "),
      _c("cookie-notice"),
      _vm._v(" "),
      _c("header", { staticClass: "hc-heading hc-heading--page" }, [
        _c("h2", { staticClass: "hc-heading__text" }, [
          _vm._v("Configuration")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "hc-actions" }, [
          _c(
            "button",
            {
              class: _vm.buttonClass,
              attrs: { title: _vm.resetTitle },
              on: { click: _vm.resetConfig }
            },
            [_c("i", { staticClass: "fas fa-undo" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.buttonClass,
              attrs: { title: _vm.saveTitle },
              on: { click: _vm.postConfig }
            },
            [_c("i", { staticClass: "fas fa-save" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "hc-btn js-toggle-errors",
              attrs: { title: "Exit" },
              on: { click: _vm.exit }
            },
            [_c("i", { staticClass: "fas fa-window-close" })]
          )
        ])
      ]),
      _vm._v(" "),
      _c("Editor", { ref: "editor" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }