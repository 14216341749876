<template>
  <div :class="'timeline__block timeline__block--' + numberOfBlocks + 'block'">
    <div class="timeline__row timeline__row--labels">
      <SiteHazardRowLabel
        v-for="item in dataGroup.riskData" :key="item.riskHour"
        :data="item"
        :sunrise-hour="sunriseHour"
        :sunset-hour="sunsetHour"
      >
      </SiteHazardRowLabel>
    </div>
    <div class="timeline__row timeline__row--readings">
      <div v-for="item in dataGroup.riskData" :class="'timeline__unit timeline__unit--' + item.riskTimeSpanHours + 'hr timeline__unit--lev0' + item.value" :key="item.riskHour"></div>
    </div>
  </div>
</template>

<script>
  import '../css/style.css';
  import SiteHazardRowLabel from './SiteHazardRowLabel.vue';
  export default {
    name: 'SiteHazardRow',
    components: { SiteHazardRowLabel },
    props: [
    'dataGroup',
    'sunriseHour',
    'sunsetHour'
    ],
    computed: {
      numberOfBlocks() {
        return this.dataGroup.numberOfBlocks;
      }
    },
  }
</script>