<template>
  <header class="header">
    <div class="flex-row flex-row--end">
      <nav-dots/>
      <img class="header__logo header__logo-clickable" src="../assets/img/decisionvue-logo.svg" alt="DecisionVue" @click="nav2map"/>
    </div>
    <div class="flex-row">
      <img class="header__logo center" src="../assets/img/wsp-logo.png" alt="WSP"/>
    </div>
    <UserButton/>
  </header>
</template>

<script>
  import NavDots from './NavDots.vue'
  import UserButton from './UserButton.vue';
  export default {
    name: 'HeaderComponent',
    components: { UserButton, NavDots },
    data() { return {} },
    computed: {},
    methods: {
      nav2map: function() {
        this.$router.push({ name: 'map' });
      },
    },
  }
</script>