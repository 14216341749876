class Vector {
  constructor(u, v) {
    this.u = u;
    this.v = v;
  }

  magnitude() {
    return Math.sqrt(this.u*this.u + this.v*this.v);
  }
}

export class ImageVectorField {
  constructor(params) {
    this.params = params;
    this.image = new Image();
    this.blob = null;
    this.metadata = null;
    this.imageData = null;
    this.width = this.params.screenDimensions[0];
    this.height = this.params.screenDimensions[1];
    this.nCols = this.width;
    this.nRows = this.height;
  }

  initialize(callback) {
    this.params.mapRef.on('moveend', () => {
      this.imageData = null;
    });
    return this._loadImage(callback);
  }

  extent() {
    return [this.metadata.bounds[0][0], this.metadata.bounds[0][1], this.metadata.bounds[1][0], this.metadata.bounds[1][1]];
  }

  hasValueAt(lon, lat) {
    return this.valueAt(lon, lat) !== null;
  }

  _lonLatAtIndexes(x, y) {
    const latLng = this.params.mapRef.layerPointToLatLng([x, y]);
    return [latLng.lng, latLng.lat];
  }

  valueAt(lon, lat) {
    const containerPoint = this.params.mapRef.latLngToContainerPoint([lat, lon]);

    if (!this.imageData) {
      const leafletLL = this.params.mapRef.latLngToContainerPoint(this.metadata.bounds[0]);
      const leafletUR = this.params.mapRef.latLngToContainerPoint(this.metadata.bounds[1]);
      const imageCanvas = document.createElement("CANVAS");
      const mapSize = this.params.mapRef.getSize();
      imageCanvas.width = mapSize.x;
      imageCanvas.height = mapSize.y;
      const imageContext = imageCanvas.getContext('2d');
      imageContext.clearRect(0, 0, imageCanvas.width, imageCanvas.height);

      imageContext.drawImage(this.image, leafletLL.x, leafletUR.y, (leafletUR.x - leafletLL.x), (leafletLL.y - leafletUR.y));
      this.imageData = imageContext.getImageData(0, 0, mapSize.x, mapSize.y);
    }
    
    const currentImagePosition =  Math.round((containerPoint.y) * this.imageData.width + containerPoint.x);
    const minValue = this.metadata.minValue;
    const maxValue = this.metadata.maxValue;
    const uValue = minValue + (maxValue - minValue) * this.imageData.data[currentImagePosition*4]/255;
    const vValue = minValue + (maxValue - minValue) * this.imageData.data[currentImagePosition*4 + 1]/255
    if (isNaN(uValue) || isNaN(vValue)) {
      return null;
    }
    return new Vector(
      uValue, vValue
    );
  }

  lonLatAtIndexes(x, y) {
    return this.params.mapRef.containerPointToLatLng([x, y]);
  }

  randomPosition(o = {}) {
    const mapSize = this.params.mapRef.getSize();
    const i = Math.round(Math.random() * (mapSize.x - 1)) | 0;
    const j = Math.round(Math.random() * (mapSize.y - 1)) | 0;

    const latLng = this.params.mapRef.containerPointToLatLng([i, j]);
    o.x = latLng.lng;
    o.y = latLng.lat;
    return o;
  }

  setFilter() {
    
  }

  _loadImage(callback) {
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default'
    };

    const request = new Request(this.params.source);
    fetch(request, options).then((response) => {
      this.metadata = this._parseImageHeadersToMetadata(response.headers);
      response.blob().then((blob) => {
        var objectURL = URL.createObjectURL(blob);
        this.image.src = objectURL;
        this.blob = blob;
        this.image.onload = () => {
          this.loaded = true;

          return callback(this.metadata, this.image);
        };
      });
    });
  }

  _parseImageHeadersToMetadata(headers) {
    const minValue = headers.get('x-amz-meta-vmin');
    const maxValue = headers.get('x-amz-meta-vmax');
    const lowerLeftLat = headers.get('x-amz-meta-llcrnrlat');
    const lowerLeftLon = headers.get('x-amz-meta-llcrnrlon');
    const upperRightLat = headers.get('x-amz-meta-urcrnrlat');
    const upperRightLon = headers.get('x-amz-meta-urcrnrlon');
    const units = headers.get('x-amz-meta-units');
    return {
      bounds: [[lowerLeftLat, lowerLeftLon], [upperRightLat, upperRightLon]],
      minValue: parseFloat(minValue),
      maxValue: parseFloat(maxValue),
      valueUnits: units
    };
  }
}