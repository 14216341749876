<template>
  <div class="weather-map-legend-container">
    <div class="weather-map-legend-items">
      <div class="weather-map-legend-label">
        {{ unitsBeingDisplayed }}
      </div>
      <div class="weather-map-legend-item-container">
        <div class="weather-map-legend-item" v-for="item in legendItems" :key="item[0]" :style="{ 'backgroundColor': item[0] }">
          {{ convertValue(item[1]) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { UnitConverter } from '../js/unitConverter.js';
  const unitConverter = new UnitConverter();
  export default {
    name: 'WeatherMapLegend',
    props: [
      'weatherMap',
      'displayUnits'
    ],
    computed: {
      unitsBeingDisplayed() {
        return this.displayUnits ? this.displayUnits : this.weatherMap.units;
      },
      legendItems() {
        if (this.weatherMap.hasOwnProperty("customLegend")) {
          return this.weatherMap.customLegend.colorArray.map((color, i) => {
            const value = this.weatherMap.customLegend.thresholds[i];
            return [color, value];
          });
        } 
        else {
          return this.weatherMap.colorArray.map((color, i) => {
            const value = this.weatherMap.thresholdConfig.values[i];
            return [color, value];
          });
        }
      }
    },
    methods: {
      convertValue(value) {
        const unitsIn = this.weatherMap.units;
        const unitsOut = this.displayUnits;
        if (unitsIn && unitsOut && unitsIn != unitsOut) {
          const val = unitConverter.convert(value, unitsIn, unitsOut, 3);
          if (val < 0.1) {
            return unitConverter.convert(value, unitsIn, unitsOut, 2);
          } else {
            return unitConverter.convert(value, unitsIn, unitsOut);
          }
        }
        else {
          return value;
        }
      }
    }
  }
</script>