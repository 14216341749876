var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "controls-container" },
    [
      _c("LayerControls", {
        attrs: {
          "heat-maps": _vm.heatMaps,
          "weather-maps": _vm.weatherMaps,
          "weather-map-opacity": _vm.weatherMapOpacity,
          "available-features": _vm.availableFeatures,
          regions: _vm.regions,
          hazards: _vm.enabledHazards,
          "weather-parameter-info": _vm.weatherParameterInfo,
          open: _vm.layerControlState.open,
          "active-heat-map": _vm.activeHeatMap,
          "active-particle-layer": _vm.activeParticleLayer,
          "active-features": _vm.activeFeatures
        },
        on: {
          click: function($event) {
            return _vm.toggleLayerControlState($event)
          },
          change: function($event) {
            return _vm.onLayerControlChange($event)
          },
          changeparticle: function($event) {
            return _vm.onParticleLayerControlChange($event)
          },
          changefeature: function($event) {
            return _vm.onFeatureChange($event)
          },
          changeopacity: _vm.onOpacityChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }