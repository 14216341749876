<template>
  <g :transform='translate'><g ref="axis"></g></g>
</template>

<script>
import * as d3 from 'd3';
export default {
  name: 'axis',
  props: {
    scales: Function,
    chartDefaults: Object,
    data: Array,
    translate: String
  },
  data() {
    return {
      width: 0,
      height: 0
    };
  },
  mounted() {
    var node = this.$refs.axis;
    d3.select(node).call(this.scales);
  }
};
</script>