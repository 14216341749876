<template>
  <div class="control-container">
    <div class="control-container__button" v-on:click="onControlClick($event)" :class="{ open: open }">
      <i class="fas fa-layer-group"></i>
    </div>
    <div v-if="open" class="control-container__panel">
      <div class="control-container__panel-inner">
        <div class="control-container__section" v-for="(heatMapGroup, key) in heatMapsGroupedByRegion">
          <div class="control-container__section-heading">{{ regions[key].name }} Risk Maps</div>
          <div class="control-container__item">
            <div class="control-container__item-row">
              <select class="control-container__item-select" v-on:change="onSelectChange($event)" :value="activeHeatMap && activeHeatMap.metadata.key in heatMapGroup ? activeHeatMap.metadata.key : ''">
                <option value="">No risk map</option>
                <option v-for="map in heatMapGroup" :value="map.key" v-if="map.variable in hazards && satisfyFavouritePreference(map.variable, key)">
                  {{ hazards[map.variable].name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="control-container__section">
          <div class="control-container__section-heading">Weather Color Maps</div>
          <div class="control-container__item">
            <div class="control-container__item-row">
              <select class="control-container__item-select" v-on:change="onSelectChange($event)" :value="activeHeatMap && activeHeatMap.metadata.key in weatherMaps ? activeHeatMap.metadata.key : ''">
                <option value="">No weather color map</option>
                <option v-if="map.parameter in weatherParameterInfo" v-for="map in weatherMaps" :value="map.key" :key="map.key">
                  {{ weatherParameterInfo[map.parameter].displayName }}
                </option>
              </select>
            </div>
            <div class="control-container__item-row" v-if="activeHeatMap && activeHeatMap.metadata.key in weatherMaps && !activeHeatMap.parameter.customLegend">
              Opacity: <input class="control-container__feature-item-number-input" v-model="opacity" @change="onOpacityChange()" type="number" min="0" max="1" step="0.1">
            </div>
          </div>
        </div>
        <div class="control-container__section">
          <div class="control-container__section-heading">Weather Particles</div>
          <div class="control-container__item">
            <div class="control-container__item-row">
              <select class="control-container__item-select" v-on:change="onSelectParticleChange($event)" :value="activeParticleLayer && activeParticleLayer.metadata.key in weatherMaps ? activeParticleLayer.metadata.key : ''">
                <option value="">No weather particles</option>
                <option v-if="map.parameter in weatherParameterInfo" v-for="map in availableParticleLayers" :value="map.key" :key="map.key">
                  {{ weatherParameterInfo[map.parameter].displayName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="control-container__section" v-if="availableFeatures.length > 0">
          <div class="control-container__section-heading">Features</div>
          <div class="control-container__item">
            <div class="control-container__item-row">
              <ul class="control-container__feature-group-list">
                <li v-for="group in availableFeatures" :key="group.id">
                  <span v-if="group.features">
                    <button type="button" @click="toggleFeatureList(group.id)" :id="'control-container__feature-group-head-' + group.id" class="control-container-feature-group-collapsible"> {{group.name}} <i :id="'feature-group-arrow-'+ group.id" class="control-container-feature-group-arrow"></i></button>
                    <ul class="control-container__feature-list" :id="'control-container__feature-group-list-' + group.id">
                      <li v-for="feature in group.features" :key="feature.id">
                          <label class="control-container__feature-item-label" :for="'cb-control__feature-' + feature.id"><input :checked="activeFeatures.some(x => x.options.id === feature.id)" :id="'cb-control__feature-' + feature.id" type="checkbox" :value="feature.id" v-on:change="onFeatureChange($event)" /> <span>{{feature.title}}</span></label>
                      </li>
                    </ul>
                  </span>
                  <span v-if="group.src">
                    <input :checked="features.some(x=> x.options.id === group.id)" :id="'cb-control__feature-' + group.id" type="checkbox" :value="group.id" v-on:change="onFeatureChange($event)" />
                    <label  class="control-container__feature-item-label" :for="'cb-control__feature-' + group.id">{{group.title}}</label>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'regions',
      'hazards',
      'heatMaps',
      'activeHeatMap',
      'activeParticleLayer',
      'activeFeatures',
      'open',
      'weatherParameterInfo',
      'weatherMaps',
      'weatherMapOpacity',
      'availableFeatures'
    ],
    data() {
      return {
        opacity: this.weatherMapOpacity
      }
    },
    computed: {
      features(){
        return [];
      },
      availableParticleLayers() {
        
        return Object.keys(this.weatherMaps).reduce((availableParticleLayers, nextWeatherMapKey) => {
          const currentWeatherMap = this.weatherMaps[nextWeatherMapKey];
          if (currentWeatherMap.parameter in this.weatherParameterInfo) {
            const currentWeatherParameterInfo = this.weatherParameterInfo[currentWeatherMap.parameter];
            if (currentWeatherParameterInfo.particleLayer) {
              availableParticleLayers.push(currentWeatherMap);
            }
          }
          return availableParticleLayers;
        }, []);
      },
      heatMapsGroupedByRegion() {
        return Object.keys(this.heatMaps).reduce((groupedHeatMaps, nextHeatMapKey) => {
          const currentHeatMap = this.heatMaps[nextHeatMapKey];
          const heatMapRegion = currentHeatMap.region;
          if (!(currentHeatMap.region in this.regions)) {
            return groupedHeatMaps;
          }
          if (!(heatMapRegion in groupedHeatMaps)) {
            groupedHeatMaps[heatMapRegion] = {};
          }
          groupedHeatMaps[heatMapRegion][nextHeatMapKey] = currentHeatMap;
          return groupedHeatMaps;
        }, {});
      },
    },
    methods: {
      onControlClick(event) {
        this.$emit('click', event);
      },
      onSelectChange(event) {
        this.$emit('change', event);
      },
      onSelectParticleChange(event) {
        this.$emit('changeparticle', event);
      },
      onFeatureChange(event) {
        this.$emit('changefeature',event);
      },
      onOpacityChange() {
        this.$emit('changeopacity', this.opacity);
      },
      satisfyFavouritePreference(hazard, regionId) {
        if (!this.$store.getters.showOnlyFavouriteHazardsRiskMaps) { return true; }
        const regionWithStarredHazards = this.$store.getters.starredHazardsByRegion[regionId] ? this.$store.getters.starredHazardsByRegion[regionId] : [];
        let favouriteHazards = [];

        for (const site in regionWithStarredHazards) {
          const siteHazards = regionWithStarredHazards[site];
          favouriteHazards = favouriteHazards.concat(siteHazards);
        }

        if (favouriteHazards.length == 0) {
          return true;
        } else if (favouriteHazards.includes(hazard)) {
          return true;
        }

        return false;
      },
      toggleFeatureList(id) {
        const feature_heading = document.getElementById('control-container__feature-group-head-'+id);
        const feature_list = document.getElementById('control-container__feature-group-list-'+id);
        const arrow = document.getElementById('feature-group-arrow-'+id);

        if (feature_list.style.maxHeight) {
          feature_list.style.maxHeight = null;
          feature_heading.classList.remove("control-container-feature-group-collapsible-active");
          arrow.style.transform = "rotate(45deg)";
          arrow.style['-webkit-transform'] = "rotate(45deg)";
        } else {
          feature_heading.classList.add("control-container-feature-group-collapsible-active");
          feature_list.style.maxHeight = feature_list.scrollHeight + "px";
          arrow.style.transform = "rotate(-135deg)";
          arrow.style['-webkit-transform'] = "rotate(-135deg)";
        }
      }
    }
  }
</script>