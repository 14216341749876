var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HeaderComponent"),
      _vm._v(" "),
      _c("cookie-notice"),
      _vm._v(" "),
      _c("header", { staticClass: "hc-heading hc-heading--page" }, [
        _c("h2", { staticClass: "hc-heading__text" }, [_vm._v("Profile")]),
        _vm._v(" "),
        _c("div", { staticClass: "hc-actions" }, [
          _c(
            "button",
            {
              staticClass: "hc-btn js-toggle-errors",
              attrs: { title: "Exit" },
              on: { click: _vm.exit }
            },
            [_c("i", { staticClass: "fas fa-window-close" })]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "profileTabs" }, [
        _c("div", { staticClass: "profileTab" }, [
          _c("input", {
            attrs: { type: "radio", id: "tab-1", name: "tab-group-1" },
            domProps: { checked: _vm.showPersonalTab },
            on: {
              click: function($event) {
                _vm.showNotificationsTab = false
                _vm.showPersonalTab = true
                _vm.showPreferencesTab = false
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "profileTabLabel", attrs: { for: "tab-1" } },
            [_vm._v("Personal")]
          ),
          _vm._v(" "),
          _vm.showPersonalTab
            ? _c("div", { staticClass: "profileTabContent" }, [
                _c(
                  "div",
                  { staticClass: "profileSettings" },
                  [
                    _vm._l(_vm.personalSettings, function(
                      settingValue,
                      settingKey
                    ) {
                      return _c("div", { key: settingKey }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex-row flex-row--align-end flex-row--margin"
                          },
                          [
                            _c("div", [
                              _c("label", [_vm._v(_vm._s(settingKey))]),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "wg-form-control u-width-300",
                                attrs: { disabled: "" },
                                domProps: { value: settingValue }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("br")
                      ])
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "profileButton",
                        attrs: { type: "button" },
                        on: { click: _vm.manageAccount }
                      },
                      [_vm._v(" Manage Account ")]
                    ),
                    _vm._v(" "),
                    !_vm.readOnly
                      ? _c("div", [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "profileButton",
                              attrs: { type: "button" },
                              on: { click: _vm.manageClients }
                            },
                            [_vm._v(" Manage Client Account ")]
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profileTab" }, [
          _c("input", {
            attrs: { type: "radio", id: "tab-2", name: "tab-group-2" },
            domProps: { checked: _vm.showNotificationsTab },
            on: {
              click: function($event) {
                _vm.showPersonalTab = false
                _vm.showNotificationsTab = true
                _vm.showPreferencesTab = false
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "profileTabLabel", attrs: { for: "tab-2" } },
            [_vm._v("Notifications")]
          ),
          _vm._v(" "),
          _vm.showNotificationsTab
            ? _c("div", { staticClass: "profileTabContent" }, [
                _c("div", { staticClass: "hc-content" }, [
                  _c(
                    "div",
                    { staticClass: "hc-content__config" },
                    [
                      _c("div", { staticClass: "flex-row flex-row--margin" }, [
                        _c("span", [
                          _vm._v("Notify me when hazards reach risk level")
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            staticClass:
                              "wg-form-control wg-form-control--auto",
                            on: {
                              change: function($event) {
                                return _vm.processAlertLevelChange($event)
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              { domProps: { selected: _vm.hazardLevel === 3 } },
                              [_vm._v("3")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { domProps: { selected: _vm.hazardLevel === 4 } },
                              [_vm._v("4")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { domProps: { selected: _vm.hazardLevel === 5 } },
                              [_vm._v("5")]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row flex-row--align-end flex-row--margin"
                        },
                        [
                          _c("div", [
                            _c("label", [_vm._v("Email")]),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "wg-form-control u-width-300",
                              attrs: { disabled: "" },
                              domProps: { value: _vm.email }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "wg-form__switch",
                              attrs: { for: "emailNotifications" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sendEmail,
                                    expression: "sendEmail"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "emailNotifications",
                                  name: "emailNotifications"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.sendEmail)
                                    ? _vm._i(_vm.sendEmail, null) > -1
                                    : _vm.sendEmail
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.processEmailToggle()
                                  },
                                  change: function($event) {
                                    var $$a = _vm.sendEmail,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.sendEmail = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.sendEmail = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.sendEmail = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "wg-form__switch-slider"
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row flex-row--align-end flex-row--margin"
                        },
                        [
                          _c("div", [
                            _c("label", [_vm._v("SMS Number")]),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "wg-form-control u-width-300",
                              domProps: { value: _vm.phone },
                              on: {
                                change: function($event) {
                                  return _vm.processSMSNumber($event)
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "wg-form__switch",
                              attrs: { for: "smsNotifications" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sendSms,
                                    expression: "sendSms"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "smsNotifications",
                                  name: "smsNotifications"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.sendSms)
                                    ? _vm._i(_vm.sendSms, null) > -1
                                    : _vm.sendSms
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.processSmsToggle()
                                  },
                                  change: function($event) {
                                    var $$a = _vm.sendSms,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.sendSms = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.sendSms = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.sendSms = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "wg-form__switch-slider"
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.showValidationLabel
                        ? _c("ValidationWarningComponent", {
                            attrs: {
                              "warning-label": "Expected format: +10123456789"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profileTab" }, [
          _c("input", {
            attrs: { type: "radio", id: "tab-3", name: "tab-group-3" },
            domProps: { checked: _vm.showPreferencesTab },
            on: {
              click: function($event) {
                _vm.showPersonalTab = false
                _vm.showNotificationsTab = false
                _vm.showPreferencesTab = true
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "profileTabLabel", attrs: { for: "tab-3" } },
            [_vm._v("Preferences")]
          ),
          _vm._v(" "),
          _vm.showPreferencesTab
            ? _c("div", { staticClass: "profileTabContent" }, [
                _c("div", { staticClass: "hc-content" }, [
                  _c("div", { staticClass: "hc-content__config" }, [
                    _c("label", [_vm._v(" Favourite Sites: ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-row flex-row--margin preferences-indent"
                      },
                      [
                        _c("span", [
                          _vm._v("Only show favourite sites on map")
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "wg-form__switch",
                            attrs: { for: "siteMapPreferences" }
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: "siteMapPreferences",
                                name: "siteMapPreferences"
                              },
                              domProps: {
                                checked: _vm.favouriteSiteMapPreference === true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addFavouriteSiteMapPreference()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "wg-form__switch-slider" })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-row flex-row--margin preferences-indent"
                      },
                      [
                        _c("span", [
                          _vm._v("Only show favourite sites in visualizer")
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "wg-form__switch",
                            attrs: { for: "siteVizualizerPreferences" }
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: "siteVizualizerPreferences",
                                name: "siteVizualizerPreferences"
                              },
                              domProps: {
                                checked:
                                  _vm.favouriteSiteVizualizerPreference === true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addFavouriteSiteVizualizerPreference()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "wg-form__switch-slider" })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("label", [_vm._v(" Favourite Hazards: ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-row flex-row--margin preferences-indent"
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "Only allow favourite hazards for sites on the map"
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "wg-form__switch",
                            attrs: { for: "hazardMapPreferences" }
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: "hazardMapPreferences",
                                name: "hazardMapPreferences"
                              },
                              domProps: {
                                checked:
                                  _vm.favouriteHazardMapPreference === true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleFavouriteHazardMapPreference()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "wg-form__switch-slider" })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-row flex-row--margin preferences-indent"
                      },
                      [
                        _c("span", [
                          _vm._v("Only allow favourite hazards in visualizer")
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "wg-form__switch",
                            attrs: { for: "hazardVizualizerPreferences" }
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: "hazardVizualizerPreferences",
                                name: "hazardVizualizerPreferences"
                              },
                              domProps: {
                                checked:
                                  _vm.favouriteHazardVizualizerPreference ===
                                  true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleFavouriteHazardVizualizerPreference()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "wg-form__switch-slider" })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-row flex-row--margin preferences-indent"
                      },
                      [
                        _c("span", [
                          _vm._v("Only show risk maps for favourite hazards")
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "wg-form__switch",
                            attrs: { for: "hazardRiskMapPreferences" }
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: "hazardRiskMapPreferences",
                                name: "hazardRiskMapPreferences"
                              },
                              domProps: {
                                checked:
                                  _vm.favouriteHazardRiskMapPreference === true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleFavouriteHazardRiskMapPreference()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "wg-form__switch-slider" })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-row flex-row--margin preferences-indent"
                      },
                      [
                        _c("span", [
                          _vm._v("Only allow alerts from favourite hazards")
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "wg-form__switch",
                            attrs: { for: "hazardAlertPreferences" }
                          },
                          [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: "hazardAlertPreferences",
                                name: "hazardAlertPreferences"
                              },
                              domProps: {
                                checked:
                                  _vm.favouriteHazardAlertPreference === true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleFavouriteHazardAlertPreference()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "wg-form__switch-slider" })
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "hc-heading__text",
        staticStyle: { "margin-bottom": "10px" }
      },
      [_c("br"), _vm._v("Admin:"), _c("br")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }