<template>
    <div>
        <HeaderComponent>
        </HeaderComponent>
        <cookie-notice></cookie-notice>

        <div class="policiesBody">
            <h1 class="policiesHeader"> Privacy Policy </h1>
            <h3 class="policiesHeaderH3"> View our <a href="https://www.wsp.com/en-us/legal/privacy-policy" target="_blank">Privacy Policy</a> </h3>


            <h1 class="policiesHeader"> Cookie notice </h1>
            <p class="policiesParagraph"> WSP Global Inc. and its subsidiaries (“WSP”) is committed to maintaining the privacy and security of your personal information (“Personal Information”) and complying with relevant data protection legislation. 
                This Privacy Notice explains how we use cookies and similar technologies to recognise you when you visit our website at risk.decisionvue.net (“website”) and how you can exercise your privacy rights. 
                As a global professional services firm, WSP acts as a data controller for the Personal Information provided by clients, business partners, and other individuals, including website users. 
            </p>  
            
            <div class="policiesGroup">
                <h2 class="policiesHeaderH2">What are cookies?</h2>
                <p class="policiesParagraph"> 
                    Cookies are usually small text files that are placed on your computer or mobile device (“device”) when you visit our website. 
                    Cookies are stored on your device for the duration of your visit or for when you re-visit our website at a later time. 
                    Cookies set by WSP are called "first party cookies". Cookies set by parties other than us are called "third party cookies". 
                    Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). 
                    Please note that third party cookies allow third parties to recognise your device both when you visit this website and also when you visit certain other websites. 
                </p>  
            </div>

            <div class="policiesGroup">
                <h2 class="policiesHeaderH2">Why do we use cookies?</h2>
                <p class="policiesParagraph"> 
                    We use cookies to authorize users and for technical reasons to allow our website to operate properly.
                </p>  
            </div>

            <div class="policiesGroup">
                <h2 class="policiesHeaderH2">What type of cookies do we use?</h2>
                <h3 class="policiesHeaderH3">Strictly Necessary Cookies</h3>

                <p class="policiesParagraph"> 
                    These cookies are necessary for the website to function and therefore cannot be switched off. 
                    They allow us to offer you key functions of the website (such as managing your account settings) and also secures our website against unauthorized users. 
                    Without these cookies, services you have requested could not be provided (e.g. being able to change your profile name or manage clients).
                </p>  

                <h3 class="policiesHeaderH3">Do we use targeting cookies on our website?</h3>
                <p class="policiesParagraph"> 
                    We donot use targeting cookies. Therefore, the information collected through our cookies does not enable us to identify your name, contact details or other personally identifying details.
                </p>  
            </div>

            <div class="policiesGroup">
                <h2 class="policiesHeaderH2">How can I switch off or remove cookies?</h2>

                <p class="policiesParagraph"> 
                    You can choose to opt out of all but the necessary cookies. In the settings of the browser, you can change the settings to ensure that cookies will be blocked. 
                    Most browsers provide you with an explanation on how to do this in the so-called ‘help-function’. 
                    However, if you block the cookies, it is possible that you will not be able to enjoy all the technical features our website has to offer and it may negatively affect your user experience.
                </p>  
            </div>
        </div>

       
        
    </div>
</template>

<script>

import HeaderComponent from '../components/Header.vue';
import CookieNotice from '../components/CookieNotice.vue';

export default {
    components: { HeaderComponent, CookieNotice },
}

</script>