var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "timeline__label timeline__label--1hr",
    class: { "timeline__unit--daynight": _vm.displaySunsetHour },
    attrs: { "data-content": _vm.hourLabel }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }