var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "map-legend-container" }, [
      _c("div", { staticClass: "map-legend-items" }, [
        _c("div", { staticClass: "map-legend-label" }, [
          _vm._v("\n      Risk\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "map-legend-item key__item--lev01" }, [
          _vm._v("\n      1\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "map-legend-item key__item--lev02" }, [
          _vm._v("\n      2\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "map-legend-item key__item--lev03" }, [
          _vm._v("\n      3\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "map-legend-item key__item--lev04" }, [
          _vm._v("\n      4\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "map-legend-item key__item--lev05" }, [
          _vm._v("\n      5\n    ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }