<template>
  <div class="cell" style="z-index: 1000">
    <span class="dot" :class="{ active: dotActivityStatusList[0], visited: dotVisitedStatusList[0] }" @click="nav2map"></span>
    <span class="dot" :class="{ active: dotActivityStatusList[1], visited: dotVisitedStatusList[1] }" @click="nav2region"></span>
    <span class="dot" :class="{ active: dotActivityStatusList[2], visited: dotVisitedStatusList[2] }" @click="nav2site"></span>
    <span class="dot" :class="{ active: dotActivityStatusList[3], visited: dotVisitedStatusList[3] }"></span>
  </div>
</template>

<script>
  export default {
    name: 'NavDots',
    data() { 
      return {
        activeView: '',
        dotActivityStatusList: [ false, false, false, false ],
        dotVisitedStatusList: [ false, false, false, false ],
      } 
    },
    computed: { 
      route() { return this.$route; },
    },
    watch: {
      route() {
        this.determineActiveView();
      },
      activeView() {
        this.determineDots();
      }
    },
    methods: {
      determineDots() {
        let av = this.activeView;
        if (av == 'map') {
          this.dotActivityStatusList = [ true, false, false, false ];
          this.dotVisitedStatusList = [ false, false, false, false ];
        }
        else if (av == 'region') {
          this.dotActivityStatusList = [ false, true, false, false ];
          this.dotVisitedStatusList = [ true, false, false, false ];
        }
        else if (av == 'site') {
          this.dotActivityStatusList = [ false, false, true, false ];
          this.dotVisitedStatusList = [ true, true, false, false ];
        }
        else if (av == 'vis') {
          this.dotActivityStatusList = [ false, false, false, true ];
          this.dotVisitedStatusList = [ true, true, true, false ];
        }
      },
      determineActiveView() {
        let q = this.route.query;
        let n = this.route.name;
        if (n == 'map' && !q.region && !q.site) this.activeView = 'map';
        else if (n == 'map' && q.panelPosition == 0) this.activeView = 'map';
        else if (n == 'map' && q.region && !q.site && q.panelPosition > 0) this.activeView = 'region';
        else if (n == 'map' && q.region && q.site && q.panelPosition > 0) this.activeView = 'site';
        else if (n == 'vis') this.activeView = 'vis';
        else this.activeView = '';
      },
      // todo: fix error going from region or site view to map view via dots
      nav2map() { 
        let q = this.route.query;
        let av = this.activeView;
        if (av === 'region' || av === 'site' || av === 'vis') {
          if (this.activeView === 'region') this.$router.replace(`/?region=${q.region}&panelPosition=0`);
          else this.$router.push({ name: 'latestMapView' });
        }
      },
      nav2region() { 
        let av = this.activeView;
        if (av === 'site' || av === 'vis') {
          this.$router.push({ name: 'latestRegionView' }); 
        }
      },  
      nav2site() { 
        let av = this.activeView;
        if (av === 'vis') {
          this.$router.push({ name: 'latestSiteView' }); 
        }
      }
    },
    mounted() {
      this.determineActiveView();
      this.determineDots();
    }
  }
</script>
