<template>

  <div>
    <div v-if="isLoading && !noSiteForecast" class="loading-spinner">
      <div class="loading-spinner__text">
        Loading site risk data
      </div>
      <CubeSpinner></CubeSpinner>
    </div>
    <div v-if="noSiteForecast">
      <div class="header__text">No site forecast currently issued for this site - please check back again later.</div>
    </div>
    <div v-if="!isLoading && !noSiteForecast">
      <div class="header__text">{{ siteObj.name }}</div>
      <div class="content">
        <div class="wg-page-wrapper">
          <div>
            <div class="wg-row-header">
              <div class="wg-heading-cell--25" ></div>
              <div class="wg-heading-cell--175" >Hazard</div>
              <th class="rd-table__filter-header">
                <i class="fas fa-filter"
                  :class="getFilterClass()"
                  title="Toggle showing of starred hazards"
                  @click="toggleFilter()">
                </i>
              </th>
            </div>
            <div v-for="hazard in regionHazards" :key="hazard.id" class="wg-row">
              <div class="wg-table-cell--200">
                <div :class="'rd-table__risk-cell rd-table__risk-cell--lev0' + dataByHazard[hazard.id].maxRiskLevel"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-html="true">
                  <a @click="nav2vis(hazard.id)" role="button" class="rd-table__risk-info">
                    <span class="rd-table__risk-text short-text" :title="hazard.name" aria-multiline="">{{ hazard.name }}</span>
                    <span class="rd-table__risk-number">{{ dataByHazard[hazard.id].maxRiskLevel }}</span>
                  </a>
                </div>
              </div>
              <td class="rd-table__filter-cell">
                <div class="rd-table__filter-text">
                  <div>
                    <i class="fas fa-star" title="Click to toggle star."
                      :id="hazard.id"
                      :class="getStarClass(hazard.id)"
                      @click="processStarEvent($event)">
                    </i>
                  </div>
                </div>
              </td>
            </div>
          </div>
          <div :class="'wg-timelines-wrapper forecast--' + forecastDays + 'day'">
            <div class="wg-scrollpane">
              <div class="wg-row--timeline-timezone">Time ({{ timezoneAbbr }})</div>
              <div class="wg-row wg-row--dates">
                <div v-for="(numberOfBlocks, dateTime) in timelineHeader"
                  :class="'wg-heading-cell timeline__block timeline__block--' + numberOfBlocks + 'block'"
                  :key="dateTime">{{dateTime}}
                </div>
              </div>
              <div v-for="hazard in regionHazards" class="wg-row wg-row--timeline" :key="hazard.id">
                <div class="timeline">
                  <SiteHazardRow
                    v-for="(dataGroup, currentDay) in dataByHazard[hazard.id].riskDataGroupedByDay" :key="currentDay"
                    :data-group="dataGroup"
                    :sunrise-hour="sunriseHour"
                    :sunset-hour="sunsetHour"
                  >
                  </SiteHazardRow>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-info">
          <a @click="nav2region" class="text-and-icon text-and-icon--align-center cursor-pointer">
            <img class="text-and-icon__icon" src="../assets/img/icon_back.svg" />
            <span class="text-and-icon__text text-and-icon__text--right">Up to parent region</span>
          </a>
        </div>
      </div>
    </div>
</template>

<script>

  import SunCalc from 'suncalc';
  import moment from 'moment';
  import momentTimezone from 'moment-timezone';
  import { CubeSpinner } from 'vue-spinners';
  import SiteHazardRow from '../components/SiteHazardRow.vue';

  export default {
    components: { CubeSpinner, SiteHazardRow },
    props: [
      'region',
      'site'
    ],
    data() {
      return {
        dataByHazard: {},
        isLoading: true,
        noSiteForecast: false,
        showOnlyStarredHazardIds: false,
        allHazards: [],
        timelineHeader: null,
        forecastDays: 5,
        sunriseHour: null,
        sunsetHour: null,
        timezoneString: 'Etc/UTC',
        timezoneAbbr: 'UTC'
      }
    },
    computed: {
      riskdata() { return this.$store.getters.riskdata.data; },
      config() { return this.$store.getters.config; },
      serverRoute() { return this.$store.getters.serverRoute; },
      siteObj() { return this.config.regions[this.region].sites[this.site];  },
      configHazards() { return this.config.hazards; },
      starredHazardRegion() { return this.$store.getters.starredHazardsByRegion[this.region] ? this.$store.getters.starredHazardsByRegion[this.region] : []; },
      starredHazards() {
        const regionWithStarredHazards = this.$store.getters.starredHazardsByRegion[this.region] ? this.$store.getters.starredHazardsByRegion[this.region] : [];
        const starredHazardIds = regionWithStarredHazards[this.site] ? regionWithStarredHazards[this.site] : [];
        return starredHazardIds;
      },
      regionHazards() {
        const regionHazards = {};
          const configHazardKeys = Object.keys(this.configHazards);
          for (let i = 0; i < configHazardKeys.length; i++) {
            const configHazardKey = configHazardKeys[i];
            let configHazard = this.configHazards[configHazardKey];
            if (configHazard.regions.includes(this.region) && (configHazard.enabled || !configHazard.hasOwnProperty("enabled"))) {
              regionHazards[configHazard.id] = JSON.parse(JSON.stringify(configHazard));
            }
          }
          this.allHazards = JSON.parse(JSON.stringify(regionHazards));
          return regionHazards;
      }
    },
    async mounted() {
      await this.loadSiteData();
    },
    methods: {
        nav2region() {
        const existingQuery = this.$route.query;
        const newQuery = Object.assign({}, existingQuery, { site: undefined });
        this.$router.push({ query: newQuery });
      },
      nav2vis(hazardId) {
        const firstParameter = this.dataByHazard[hazardId].firstParameter;
        this.$router.push({ path: `/vis?region=${this.region}&site=${this.site}&hazard=${hazardId}&parameter=${firstParameter}`})
      },
      getSunriseSunset(time, latitude, longitude) {
        // returns in site timezone!
        const date = new Date(moment(time).format('YYYY-MM-DD')); // assumes negligible change in sun schedule over forecast
        const sunTimes = SunCalc.getTimes(date, latitude, longitude);
        const sunriseHour = parseInt(moment.utc(sunTimes.sunrise.toUTCString()).tz(this.timezoneString).format('H'));
        const sunsetHour = parseInt(moment.utc(sunTimes.sunset.toUTCString()).tz(this.timezoneString).format('H'));
        return [sunriseHour, sunsetHour]
      },
      getTimelineHeader(times) {
        // TODO: improve so it handles more intelligent range of dates
        const timelineHeader = {};
        times.forEach(t => {
          const key = moment.tz(t, this.timezoneString).format('ddd, MMM DD');
          timelineHeader[key] = timelineHeader[key] === undefined ? 1 : timelineHeader[key] + 1;
        });
        this.timelineHeader = timelineHeader;
        return timelineHeader
      },
      getMaxRiskLevelFromValues(values) {
        let maxRiskLevel = 0;
        if (values.length > 0) {
          maxRiskLevel = Math.max(...values);
        }
        return maxRiskLevel;
      },
      getFirstParameter(hazardId) {
        return hazardId in this.config.hazards ? Object.keys(this.config.hazards[hazardId].parameters)[0] : null;
      },
      getHoursFromMidnight(t) {
        const t0 = moment.tz(t, this.timezoneString).startOf('day').format();
        const tn = moment.tz(t, this.timezoneString).format();
        const dt = moment.tz(tn, this.timezoneString).diff(moment.tz(t0, this.timezoneString), 'hours');
        return dt
      },
      getRiskDataPoint(times, values, index) {
        const t = moment.tz(times[index], this.timezoneString);
        const riskDataPoint = {};
        riskDataPoint.dateTime = t.format();
        riskDataPoint.value = values[index];
        riskDataPoint.riskHour = index;
        riskDataPoint.forecastHour = this.getHoursFromMidnight(t);
        riskDataPoint.riskTimeSpanHours = 1;
        riskDataPoint.displayLabel = riskDataPoint.forecastHour % 3 === 0;
        return riskDataPoint
      },
      getRiskDataGroupedByDay(timelineHeader, times, values) {
        const timelineHeaderKeys = Object.keys(timelineHeader);
        let counter = 0;
        const riskDataGroupedByDay = {};
        for (let i = 0; i < timelineHeaderKeys.length; i++) {
          const key = timelineHeaderKeys[i];
          let numberOfBlocks = timelineHeader[key];
          riskDataGroupedByDay[key] = {};
          riskDataGroupedByDay[key].numberOfBlocks = numberOfBlocks;
          riskDataGroupedByDay[key].riskData = [];
          for (let kk = counter; kk < counter + numberOfBlocks; kk++) {
            let riskDataPoint = this.getRiskDataPoint(times, values, kk);
            riskDataGroupedByDay[key].riskData.push(riskDataPoint);
          }
          counter += numberOfBlocks;
        }
        return riskDataGroupedByDay
      },
      getFilterClass() {
        if (this.showOnlyStarredHazardIds) return { 'rd-table__location-icon__active': true, 'rd-table__location-icon': false };
        else return { 'rd-table__location-icon__active': false, 'rd-table__location-icon': true };
      },
      getStarClass(hazardId) {
        let starred = false;
        if (this.starredHazards.includes(hazardId)) starred = true;
        return { 'rd-table__location-icon__active': starred, 'rd-table__location-icon': !starred };
      },
      processStarEvent(event) {
        let target = event.currentTarget;
        const hazardId = target.id;
        if (target.className === 'fas fa-star rd-table__location-icon') {
          target.className = 'fas fa-star rd-table__location-icon__active';
          this.$store.dispatch('starHazard', {region: this.region, site: this.site, hazard: hazardId});
        }
        else {
          target.className = 'fas fa-star rd-table__location-icon';
          this.$store.dispatch('unstarHazard', {region: this.region, site: this.site, hazard: hazardId});
          if (this.showOnlyStarredHazardIds) { // If filter is enabled we want to automatically delete unstarred hazard from list
            delete this.regionHazards[hazardId];
          }
        }
        this.$emit('updateMapHazards', event);
      },
      toggleFilter() {
        this.showOnlyStarredHazardIds = !this.showOnlyStarredHazardIds;
        this.filterHazardsByFavourites();
      },
      filterHazardsByFavourites() {
        if (this.showOnlyStarredHazardIds) {
          for (const hazard in this.allHazards) {
            if (!this.starredHazards.includes(hazard)) {
              if (this.regionHazards[hazard]) { delete this.regionHazards[hazard] }
            } else {
              this.regionHazards[hazard] = this.allHazards[hazard];
            }
          }
        } else {
          for (const hazard in this.allHazards) {
            this.regionHazards[hazard] = this.allHazards[hazard];
          }
        }
      },
      convertUtcTimesToAnotherTimezone(times, timezone) {
        const convertedTimes = [];
        for (let i = 0; i < times.length; i++) {
          let tmpTime = moment.tz(moment.utc(times[i]), timezone).format();
          convertedTimes.push(tmpTime)
        }
        return convertedTimes
      },
      convertRawDataByHazardFromUtcToTimezone(rawDataByHazard) {
        const hazardKeys = Object.keys(rawDataByHazard);
        for (let i = 0; i < hazardKeys.length; i++) {
          const hazardKey = hazardKeys[i];
          try {
            let utcTimes = rawDataByHazard[hazardKey].dateTimes;
            if (utcTimes) {
              let convertedTimes = this.convertUtcTimesToAnotherTimezone(utcTimes, this.timezoneString);
              rawDataByHazard[hazardKey].dateTimes = convertedTimes;
            }
            else { // no datetimes available to convert for particular hazard
              delete rawDataByHazard[hazardKey];
            }
          }
          catch(e) {
            console.log(`Error parsing from utc to timezone for ${hazardKey}`, e);
            delete rawDataByHazard[hazardKey];
          }
        }
        return rawDataByHazard;
      },
      createDataByHazard() {
        const rawSiteData = this.riskdata[this.region][this.site];
        const rawDataByHazard = JSON.parse(JSON.stringify(rawSiteData['dataByHazard']));
        const timezoneCorrectedDataByHazard = this.convertRawDataByHazardFromUtcToTimezone(rawDataByHazard);
        const interpolatedDataByHazard = this.interpolateDataByHazardOntoHourlyTimeGrid(timezoneCorrectedDataByHazard);
        const filterByDaysWithData = this.filterByActiveData(interpolatedDataByHazard);
        this.forecastDays = this.getDaysWithRiskData(filterByDaysWithData).length > 5 ? 5 : this.getDaysWithRiskData(filterByDaysWithData).length;
        this.dataByHazard = filterByDaysWithData;
      },
      getDaysWithRiskData(data) {
        const daysWithRiskData = [];
        for(const hazard in data) { // getting which days have risk data greater than 0.
          const hazardRiskData = data[hazard];
          const times = hazardRiskData.dateTimes;
          const values = hazardRiskData.values;
          times.forEach((time, index) => {
            const day = moment.tz(time, this.timezoneString).format('ddd, MMM DD');
            if ( !daysWithRiskData.includes(day) && values[index] > 0) {
              daysWithRiskData.push(day);
            }
          });
        }

        return daysWithRiskData;
      },
      filterByActiveData(data) {
        const daysWithRiskData = this.getDaysWithRiskData(data);
        const filteredHazardData = JSON.parse(JSON.stringify(data));

        for(const hazard in data) { // removing times with no risk data greater than 0
          const hazardRiskData = data[hazard];
          const times = hazardRiskData.dateTimes;
          const filteredDateTimes = [];
          const filteredValues = [];
          times.forEach((time, index) => {
            const day = moment.tz(time, this.timezoneString).format('ddd, MMM DD');
            if (daysWithRiskData.includes(day)) {
              filteredDateTimes.push(time);
              filteredValues.push(hazardRiskData.values[index]);
            }
          });
          filteredHazardData[hazard].dateTimes = filteredDateTimes;
          filteredHazardData[hazard].values = filteredValues;
        }
        return filteredHazardData;
      },
      getEarliestAndLatestDateTimes(data) {
        // returns datetimes in site timezone!
        let earliestDateTime = moment.tz('01-01-2050 00:00', 'MM-DD-YYYY HH:mm', this.timezoneString);
        let latestDateTime = moment.tz('01-01-2000 00:00', 'MM-DD-YYYY HH:mm', this.timezoneString);
        const keys = Object.keys(data);
        for (let i=0; i<keys.length; i++) {
          const key = keys[i]
          try {
            let t0 = moment.tz(data[key]['dateTimes'][0], this.timezoneString);
            let tn = moment.tz(data[key]['dateTimes'].slice(-1)[0], this.timezoneString);
            if (t0 < earliestDateTime) {
              earliestDateTime = t0;
            }
            if (tn > latestDateTime) {
              latestDateTime = tn;
            }
          } catch(e) {
            // error parsing key
            console.log("Error parsing earliest datetime key ", e);
          }
        }
        return [earliestDateTime, latestDateTime]
      },
      interpolateDataByHazardOntoHourlyTimeGrid(data) {
        const dateTimeRange = this.getEarliestAndLatestDateTimes(data);
        const earliestDateTime = dateTimeRange[0];
        const latestDateTime = dateTimeRange[1];
        const dateTimes = this.getHourlyTimestampsBetweenDates(earliestDateTime, latestDateTime);
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          try {
            let values = this.getValues(data[key], dateTimes);
            data[key]['dateTimes'] = dateTimes;
            data[key]['values'] = values;
          }
          catch(e) {
            console.log('Error interpolating data onto hourly grid for', key);
            delete data.key;
          }
        }
        return data
      },
      getValues(data, dateTimes) {
        // data = { 'dateTimes': [...], 'values': [...] }
        // dateTimes = target dateTime grid
        const format = 'YYYY-MM-DD HH';
        const values = new Array(dateTimes.length).fill(0);
        const dateTimesStar = data['dateTimes'];

        let latestOuterIndex = 0; // assumes datetimes are increasing
        for (let i=0; i<dateTimes.length; i++) {
          let latestInnerIndex = latestOuterIndex;
          let matchFound = false;
          for (let j=latestInnerIndex; j<dateTimesStar.length; j++) {
            if (moment(dateTimes[i]).format(format) === moment(dateTimesStar[j]).format(format)) {
              values[i] = data['values'][j];
              matchFound = true;
              break;
            }
            latestInnerIndex++;
          }
          if (matchFound) {
            latestOuterIndex = latestInnerIndex;
          }
        }
        return values
      },
      getHourlyTimestampsBetweenDates(startDate, endDate) {
        const dates = [];
        const duration = moment.duration(moment(endDate).startOf('hour').diff(moment(startDate).startOf('hour')));
        const hours = duration.asHours();
        let tmpDate =  moment.tz(startDate, this.timezoneString).clone().startOf('hour');
        const format = 'MM-DD-YYYY HH:mm:ss';
        let lastDate = moment.tz('01-01-2000 00:00', format, this.timezoneString);
        dates.push(tmpDate.clone().format());
        for (let i = 0; i < hours; i++) {
          lastDate = tmpDate.clone();
          tmpDate = tmpDate.add(1, 'hour');
          if (lastDate.format(format) !== tmpDate.clone().format(format)) {
            dates.push(tmpDate.clone().format());
          }
          else {
            console.log('Discarded a cell for end of daylight savings time.');
          }
        }
        return dates;
      },
      computeRiskDataGroupedByDay() {
        // depends on this.dataByHazard;
        const hazardKeysFromData = Object.keys(this.dataByHazard);
        let timelineHeader;
        for (let i = 0; i < hazardKeysFromData.length; i++) {
          const hazardId = hazardKeysFromData[i];
          this.dataByHazard[hazardId].rootHazard = true;
          this.dataByHazard[hazardId].firstParameter = this.getFirstParameter(hazardId);
          let times = this.dataByHazard[hazardId].dateTimes;
          let values = this.dataByHazard[hazardId].values;
          this.dataByHazard[hazardId].maxRiskLevel = this.getMaxRiskLevelFromValues(values);
          timelineHeader = this.getTimelineHeader(times);
          this.dataByHazard[hazardId].riskDataGroupedByDay = this.getRiskDataGroupedByDay(timelineHeader, times, values);
        }
      },
      createHazardDataSkeleton() {
        const skeleton = {
          dateTimes: [],
          values: [],
          maxRiskLevel: 0,
          firstParameter: undefined,
          rootHazard: true,
          riskDataGroupedByDay: {}
        }
        return skeleton;
      },
      addMissingHazards() {
        const regionHazardKeys = Object.keys(this.regionHazards);
        const dataByHazardKeys = Object.keys(this.dataByHazard);
        for (let i = 0; i < regionHazardKeys.length; i++) {
          const regionHazardKey = regionHazardKeys[i];
          if (!dataByHazardKeys.includes(regionHazardKey)) {
            this.dataByHazard[regionHazardKey] = this.createHazardDataSkeleton();
            this.dataByHazard[regionHazardKey].firstParameter = this.getFirstParameter(regionHazardKey);
          }
        }
      },
      async loadSiteData() {
        this.noSiteForecast = false;
        this.isLoading = true;
        try {
          this.timezoneString = this.siteObj.timezone;
          this.timezoneAbbr = moment().tz(this.siteObj.timezone).zoneAbbr();
          const sunriseSunset = this.getSunriseSunset(moment.utc(), this.siteObj.latitude, this.siteObj.longitude);
          this.sunriseHour = sunriseSunset[0];
          this.sunsetHour = sunriseSunset[1];
          this.createDataByHazard();
          this.computeRiskDataGroupedByDay();
          this.addMissingHazards();
          // If there are starred hazards we only want to display them on initial upload
          this.showOnlyStarredHazardIds = this.starredHazards.length > 0 ? true : false;
          this.filterHazardsByFavourites();
        }
        catch(e) {
          console.log('Error loading site data', e);
          this.noSiteForecast = true;
        }
        finally {
          this.isLoading = false;
        }
      }
    },
    watch: {
      async site() {
        await this.loadSiteData();
      }
    }
  }
</script>