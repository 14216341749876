var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "animation-controls-container" }, [
    _c(
      "div",
      {
        staticClass: "animation-controls-play",
        on: {
          click: function($event) {
            return _vm.onControlsClick($event)
          }
        }
      },
      [
        !_vm.isPlaying ? _c("i", { staticClass: "fas fa-play" }) : _vm._e(),
        _vm._v(" "),
        _vm.isPlaying ? _c("i", { staticClass: "fas fa-stop" }) : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "animationBar",
        staticClass: "animation-controls-bar",
        on: {
          click: function($event) {
            return _vm.onBarClick($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "animation-controls-bar-played",
            style: { width: _vm.currentPositionPercent + "%" }
          },
          [
            _c(
              "div",
              {
                staticClass: "animation-controls-current-position",
                on: { "": function($event) {} }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "animation-controls-current-position-panel",
                    on: {
                      mousedown: function($event) {
                        return _vm.onPanelMousedown($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.currentPositionDateTime) +
                        "\n        "
                    )
                  ]
                )
              ]
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "animation-controls-labels" },
      _vm._l(this.dayLabels, function(dayLabel) {
        return _c("div", { staticClass: "animation-controls-label" }, [
          _vm._v("\n      " + _vm._s(dayLabel) + "\n    ")
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }