<template>
<div>
  <HeaderComponent>
  </HeaderComponent>
  <cookie-notice></cookie-notice>
  <header class="hc-heading hc-heading--page">
    <h2 class="hc-heading__text">Configuration</h2>
    <div class="hc-actions">
      <button :class="buttonClass" :title="resetTitle" @click="resetConfig"><i class="fas fa-undo"></i></button>
      <button :class="buttonClass" :title="saveTitle" @click="postConfig"><i class="fas fa-save"></i></button>
      <button class="hc-btn js-toggle-errors" title="Exit" @click="exit"><i class="fas fa-window-close"></i></button>
    </div>
  </header>
  <Editor ref="editor"/>
</div>
</template>

<script>
import HeaderComponent from '../components/Header.vue';
import SettingsHeader from '../components/SettingsHeader.vue';
import Editor from '../components/Editor.vue';
import RequestBuilder from '../js/requestBuilder';
import CookieNotice from '../components/CookieNotice.vue';
import _ from 'lodash';
const requestBuilder = new RequestBuilder();
export default {
  components: { HeaderComponent, SettingsHeader, Editor, CookieNotice },
  computed: {
    buttonClass() { return this.readOnly ? 'hc-btn-offlimits' : 'hc-btn'; },
    resetTitle() { return this.readOnly ? 'Cannot reset' : 'Reset'; },
    saveTitle() { return this.readOnly ? 'Cannot save' : 'Save'; },
    readOnly() { return this.$store.getters.readOnly; },
    serverRoute() { return this.$store.getters.serverRoute; },
    clientId() { return this.$route.query.clientId ? this.$route.query.clientId: this.$store.getters.clientId; },
    serverRoute() { return this.$store.getters.serverRoute; }
  },
  data() {
    return {
      config0: {},
      config: {},
      preferences0: {},
      preferences: {}
    }
  },
  methods: {
    exit() {
      this.$router.push({ name: 'lastRouteFullPath' });
    },
    resetConfig() {
      if (this.readOnly) return;
       swal({
        title: "Reset?",
        text: "Are you sure you want to reset?",
        buttons: true,
        dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
          this.config = JSON.parse(JSON.stringify(this.config0));
          this.preferences = JSON.parse(JSON.stringify(this.preferences0));
          this.$refs.editor.resetHandler();
        } 
      });
    },
    async getRemoteConfig() {
      const [request, options] = await requestBuilder.getConfigureRequest(this.serverRoute, this.clientId);
      const response = await fetch(request, options);
      if (!response.ok) {
        swal(`Seems there is an issue verifying the latest remote configuration. Error: ${response.status}`);
        return undefined;
      } else {
        const json = await response.json();
        return json;
      }
    },
    async postConfig() {
      if (this.readOnly) return;

      // checking to see if user is trying to save outdated config
      const remoteConfig = await this.getRemoteConfig();
      if (!_.isEqual(this.config0, remoteConfig) && remoteConfig) {
        swal({
          title: 'Oops!',
          text: `Seems like your settings configuration is outdated! Please refresh page.`,
          icon: 'error',
        });
      } else if (remoteConfig) {
        const [request, options] = await requestBuilder.saveConfigRequest(this.serverRoute, this.config, this.clientId);
        swal({
          title: "Save?",
          text: "Are you sure you want to save the changes?",
          buttons: true,
          dangerMode: true,
        })
        .then((confirm) => {
          if (confirm) {
              fetch(request, options).then(async response => {
                if (!response.ok) {
                  return Promise.reject(response.status);
                }
                this.$store.dispatch('updateConfig', this.config);
                this.$store.dispatch('updatePreferences', this.preferences);
                this.config0 = JSON.parse(JSON.stringify(this.config));
                this.preferences0 = JSON.parse(JSON.stringify(this.preferences));
                swal('Successfully Saved!');
              })
              .catch(error => {
                  swal({
                    title: 'Oops!',
                    text: `Seems there was an error while saving. Error: ${error}`,
                    icon: 'error',
                  });
              });
          }
        });
      }
    }
  },
  async mounted() {
    this.config0 = this.$store.getters.config;
    this.preferences0 = this.$store.getters.preferences;
    this.config = JSON.parse(JSON.stringify(this.config0));
    this.preferences = JSON.parse(JSON.stringify(this.preferences0));

    // checking remote config against local config
    const remoteConfig = await this.getRemoteConfig();
    if (!_.isEqual(this.config, remoteConfig) && remoteConfig) {
      swal({
        title: 'Oops!',
        text: `Seems like your settings configuration was outdated so an automatic update was done!`,
        icon: 'info',
      }).then( () => {
        this.$store.dispatch('updateConfig', remoteConfig);
        this.$store.dispatch('getUserDefaultsFromConfig', remoteConfig);
        this.config = JSON.parse(JSON.stringify(remoteConfig)); 
        this.config0 = remoteConfig;
      } );
    }
  }
}

</script>
