const { default: authService } = require("./authenticationService");

class RequestBuilder {
  constructor() {
    // pass
  }
  async getHeaders() {
    const token = await authService.getAccessToken().then(function (token) { return token; });
    return new Headers({'Content-Type': 'application/json', 'Authorization': 'Bearer' + ' ' + token }); // eslint-disable-line no-undef
  }
  async getRiskMapsRequest(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/maps`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getWeatherMapsRequest(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/weathermaps`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getRiskDataRequest(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/riskdata`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getPersonalizeRequest(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default',
    };
    const request = new Request(`${serverRoute}/personalize`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getConfigureRequest(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/config`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getBaseMapConfigureRequest(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/baseMaps`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getConfigureParametersRequest(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/parameterconfig`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getFeaturesRequest(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/features`); // eslint-disable-line no-undef
    return [request, options];
  }
  async weatherModelRequest(serverRoute, region, site, hazard, parameter) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: headers
    };
    const request = new Request(`${serverRoute}/wxdata?region=${region}&site=${site}&hazard=${hazard}&parameter=${parameter}`); // eslint-disable-line no-undef
    return [request, options];
  }
  async weatherObservationRequest(serverRoute, latitude, longitude, parameter, radius) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: headers
    };
    const request = new Request(`${serverRoute}/weatherobs?latitude=${latitude}&longitude=${longitude}&parameter=${parameter}&radius=${radius}`); // eslint-disable-line no-undef
    return [request, options];
  }
  async saveConfigRequest(serverRoute, config) {
    const headers = await this.getHeaders();
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      cache: 'default',
      body: JSON.stringify(config)
    };
    const request = new Request(`${serverRoute}/config`); // eslint-disable-line no-undef
    return [request, options];
  }
  async publishPreferencesRequest(serverRoute, preferences) {
    const headers = await this.getHeaders();
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      cache: 'default',
      body: JSON.stringify(preferences)
    };
    const request = new Request(`${serverRoute}/preferences`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getReadOnlyRoles(serverRoute) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/readOnlyRoles`); // eslint-disable-line no-undef
    return [request, options];
  }
  async getFeature(serverRoute, featureId, featureName = null) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    let requestUrl = `${serverRoute}/getFeatureResource?id=${featureId}`
    if (featureName != null) {
      requestUrl += `&featureName=${featureName}`
    }
    const request = new Request(requestUrl); // eslint-disable-line no-undef
    return [request, options];
  }
  async getTzRequest(serverRoute, latitude, longitude) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default',
    };
    const request = new Request(`${serverRoute}/tz?latitude=${latitude}&longitude=${longitude}`); // eslint-disable-line no-undef
    return [request, options];
  }

  async getMultipleFeatures(serverRoute, featureId) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/getMultipleFeatureResources?id=${featureId}`); // eslint-disable-line no-undef
    return [request, options];
  }

  async getFeatureLayerInfo(serverRoute, featureId, layerParams) {
    const headers = await this.getHeaders();
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: headers,
      cache: 'default'
    };
    const request = new Request(`${serverRoute}/getFeatureLayerInfo${layerParams}&id=${featureId}`); // eslint-disable-line no-undef
    return [request, options];
  }
}

module.exports = RequestBuilder;
