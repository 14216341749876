import Vue from "vue";

const state = {
  config: {},
  parameterConfig: {}
};

const getters = {
  config(state) { return state.config; },
  parameterConfig(state) { return state.parameterConfig; },
};

const mutations = {
  changeConfig(state, newConfig) { Vue.set(state, 'config', newConfig); },
  changeParameterConfig(state, newConfig) { Vue.set(state, 'parameterConfig', newConfig); },
};

const actions = {
  updateConfig(context, payload) { context.commit('changeConfig', payload); },
  updateParameterConfig(context, payload) { context.commit('changeParameterConfig', payload); },
  logout(context) {
    context.commit('changeConfig', {});
    context.commit('changeParameterConfig', {});
  },
};

export default { state, getters, mutations, actions }