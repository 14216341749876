var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", { staticClass: "ani1 row-clean" }, [
        _c(
          "svg",
          _vm._b(
            { attrs: { id: "riskcurvesvg" } },
            "svg",
            _vm.constSvgAttrs,
            false
          ),
          [
            _c(
              "rect",
              _vm._b(
                { attrs: { fill: "#d32a27", y: "5" } },
                "rect",
                _vm.constRectAttrs,
                false
              )
            ),
            _vm._v(" "),
            _c(
              "rect",
              _vm._b(
                { attrs: { fill: "#ee6b3f", y: "65" } },
                "rect",
                _vm.constRectAttrs,
                false
              )
            ),
            _vm._v(" "),
            _c(
              "rect",
              _vm._b(
                { attrs: { fill: "#fdc94e", y: "125" } },
                "rect",
                _vm.constRectAttrs,
                false
              )
            ),
            _vm._v(" "),
            _c(
              "rect",
              _vm._b(
                { attrs: { fill: "#eff471", y: "185" } },
                "rect",
                _vm.constRectAttrs,
                false
              )
            ),
            _vm._v(" "),
            _c(
              "rect",
              _vm._b(
                { attrs: { fill: "#94d864", y: "245" } },
                "rect",
                _vm.constRectAttrs,
                false
              )
            ),
            _vm._v(" "),
            _c(
              "g",
              { staticClass: "lineChart", attrs: { transform: _vm.translate } },
              [
                _c("axis", {
                  staticClass: "yA",
                  attrs: {
                    scales: _vm.yAxis,
                    chartDefaults: _vm.chartDefaults,
                    data: _vm.dataObj,
                    translate: _vm.translateY
                  }
                }),
                _vm._v(" "),
                _c("axis", {
                  staticClass: "xA",
                  attrs: {
                    scales: _vm.xAxis,
                    chartDefaults: _vm.chartDefaults,
                    data: _vm.dataObj,
                    translate: _vm.translateX
                  }
                }),
                _vm._v(" "),
                _c(
                  "text",
                  {
                    attrs: {
                      fill: "black",
                      transform: "translate(-20, 180) rotate(-90)"
                    }
                  },
                  [_vm._v("Risk level")]
                ),
                _vm._v(" "),
                _c(
                  "text",
                  {
                    attrs: { fill: "black", transform: "translate(300, 350)" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.name) +
                        " (" +
                        _vm._s(_vm.formatDisplayUnit(_vm.displayUnits)) +
                        ")"
                    )
                  ]
                ),
                _vm._v(" "),
                _c("path", {
                  staticClass: "line",
                  attrs: { id: "riskcurvepath", d: _vm.line }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _vm.isBetween
            ? _c("div", { staticClass: "slider-container-row" }, [
                _c(
                  "div",
                  { staticClass: "slider-input-container" },
                  [
                    _vm._v("\n          Threshold 1: "),
                    _c("vue-slider", {
                      attrs: {
                        min: parseFloat(_vm.minMu),
                        max: parseFloat(_vm.mu2),
                        interval: _vm.sliderInterval
                      },
                      on: {
                        change: function($event) {
                          return _vm.updatePath()
                        }
                      },
                      model: {
                        value: _vm.mu1,
                        callback: function($$v) {
                          _vm.mu1 = $$v
                        },
                        expression: "mu1"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mu1,
                      expression: "mu1"
                    }
                  ],
                  staticClass: "wg-form-control slider-input",
                  attrs: { max: "mu2", min: "minMu" },
                  domProps: { value: _vm.mu1 },
                  on: {
                    change: function($event) {
                      return _vm.updatePath()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mu1 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.formatDisplayUnit(_vm.displayUnits)) + " "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isBetween
            ? _c("div", { staticClass: "slider-container-row" }, [
                _c(
                  "div",
                  { staticClass: "slider-input-container" },
                  [
                    _vm._v("\n          Threshold 2: "),
                    _c("vue-slider", {
                      attrs: {
                        min: parseFloat(_vm.mu1),
                        max: parseFloat(_vm.maxMu),
                        interval: _vm.sliderInterval
                      },
                      on: {
                        change: function($event) {
                          return _vm.updatePath()
                        }
                      },
                      model: {
                        value: _vm.mu2,
                        callback: function($$v) {
                          _vm.mu2 = $$v
                        },
                        expression: "mu2"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mu2,
                      expression: "mu2"
                    }
                  ],
                  staticClass: "wg-form-control slider-input",
                  attrs: { max: "maxMu", min: "mu1" },
                  domProps: { value: _vm.mu2 },
                  on: {
                    change: function($event) {
                      return _vm.updatePath()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mu2 = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.formatDisplayUnit(_vm.displayUnits)) + " "
                  )
                ])
              ])
            : _c("div", { staticClass: "slider-container-row" }, [
                _c(
                  "div",
                  { staticClass: "slider-input-container" },
                  [
                    _vm._v("\n          Threshold: "),
                    _c("vue-slider", {
                      attrs: {
                        min: _vm.minMu,
                        max: _vm.maxMu,
                        interval: _vm.sliderInterval
                      },
                      on: {
                        change: function($event) {
                          return _vm.updatePath()
                        }
                      },
                      model: {
                        value: _vm.mu,
                        callback: function($$v) {
                          _vm.mu = $$v
                        },
                        expression: "mu"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mu,
                      expression: "mu"
                    }
                  ],
                  staticClass: "wg-form-control slider-input",
                  attrs: { max: "maxMu", min: "minMu" },
                  domProps: { value: _vm.mu },
                  on: {
                    change: function($event) {
                      return _vm.updatePath()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mu = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    " " + _vm._s(_vm.formatDisplayUnit(_vm.displayUnits)) + " "
                  )
                ])
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "slider-container-row" }, [
            _c(
              "div",
              { staticClass: "slider-input-container" },
              [
                _vm._v("\n        Sensitivity: "),
                _c("vue-slider", {
                  attrs: {
                    min: _vm.minSigma,
                    max: _vm.maxSigma,
                    interval: _vm.sliderInterval
                  },
                  on: {
                    change: function($event) {
                      return _vm.updatePath()
                    }
                  },
                  model: {
                    value: _vm.sigma,
                    callback: function($$v) {
                      _vm.sigma = $$v
                    },
                    expression: "sigma"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sigma,
                  expression: "sigma"
                }
              ],
              staticClass: "wg-form-control slider-input",
              attrs: { max: "maxMu", min: "minMu" },
              domProps: { value: _vm.sigma },
              on: {
                change: function($event) {
                  return _vm.updatePath()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.sigma = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                " " + _vm._s(_vm.formatDisplayUnit(_vm.displayUnits)) + " "
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }