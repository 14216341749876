var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "editorTabs" }, [
      _c("div", { staticClass: "editorTab" }, [
        _c("input", {
          attrs: {
            type: "radio",
            id: "tab-1",
            name: "tab-group-1",
            checked: ""
          },
          on: {
            click: function($event) {
              _vm.showRegionTab = false
              _vm.showHazardTab = true
              _vm.showUnitsTab = false
              _vm.showAlertsTab = false
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "editorTabLabel", attrs: { for: "tab-1" } },
          [_vm._v("Hazards")]
        ),
        _vm._v(" "),
        _vm.showHazardTab
          ? _c("div", { staticClass: "editorTabContent" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "hc-block" },
                  [
                    _c("div", { staticClass: "hc-heading" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "hc-actions" }, [
                        _c(
                          "button",
                          {
                            class: _vm.buttonClass,
                            on: { click: _vm.showAddHazardModalTrue }
                          },
                          [_c("i", { staticClass: "fa fa-plus" })]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.showAddHazardModal
                      ? _c(
                          "div",
                          [
                            _c("settings-wizard", {
                              attrs: {
                                title: _vm.addHazardTitle,
                                hazardNames: _vm.hazardNames,
                                regions: _vm.regions,
                                availableParametersArray:
                                  _vm.availableParametersArray,
                                initHazard: _vm.dummyHazard,
                                initParameters: [
                                  _vm.availableParametersArray[0]
                                ],
                                newHazard: true
                              },
                              on: {
                                "hazard-complete-event":
                                  _vm.hazardCompleteEventHandler,
                                "child-hide-event": _vm.hideAddHazardModal,
                                "hazard-cancel-event":
                                  _vm.hazarEditAddCancelEventHandler
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.hazards, function(hazard) {
                      return _c(
                        "div",
                        { key: hazard.id, staticClass: "hc-hazard" },
                        [
                          _c("div", [
                            _c("span", [
                              _c(
                                "b",
                                { class: _vm.getHazardStatusClass(hazard) },
                                [_vm._v(_vm._s(hazard.name))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { float: "right" } }, [
                              _c(
                                "button",
                                {
                                  class: _vm.buttonClass,
                                  attrs: { title: _vm.toggleHazardTitle },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleHazard(hazard)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    class: _vm.hazardIsEnabled(hazard)
                                      ? "fa fa-toggle-on"
                                      : "fa fa-toggle-off"
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showEditHazardModal !== true ||
                              hazard.id !== _vm.activeHazard.id
                                ? _c(
                                    "button",
                                    {
                                      class: _vm.buttonClass,
                                      attrs: { title: _vm.editHazardTitle },
                                      on: {
                                        click: function($event) {
                                          return _vm.beginEditHazard(hazard)
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-pen" })]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  class: _vm.buttonClass,
                                  attrs: { title: _vm.deleteHazardTitle },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteHazard(hazard.id)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fas fa-trash" })]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "hc-component" },
                            [
                              _c(
                                "ul",
                                _vm._l(hazard.parameters, function(parameter) {
                                  return _c(
                                    "li",
                                    {
                                      key: parameter.id,
                                      class: _vm.getHazardStatusClass(hazard)
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.hazardSummaryText(parameter)
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.config.regions, function(region) {
                                return _c(
                                  "span",
                                  {
                                    key: region.id,
                                    class: _vm.getHazardStatusClass(hazard),
                                    staticStyle: { "margin-left": "10px" }
                                  },
                                  [
                                    _c("input", {
                                      attrs: {
                                        onclick: "return false",
                                        type: "checkbox",
                                        disabled: !_vm.hazardIsEnabled(hazard)
                                      },
                                      domProps: {
                                        checked: _vm.hazardIsInRegion(
                                          hazard.id,
                                          region.id
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(region.name) + "\n                "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.showEditHazardModal &&
                          hazard.id === _vm.activeHazard.id
                            ? _c(
                                "div",
                                [
                                  _c("settings-wizard", {
                                    attrs: {
                                      title: _vm.editHazardTitle,
                                      hazardNames: _vm.hazardNames,
                                      regions: _vm.regions,
                                      availableParametersArray:
                                        _vm.availableParametersArray,
                                      initHazard: _vm.activeHazard,
                                      initParameters: Object.values(
                                        _vm.activeHazard.parameters
                                      )
                                    },
                                    on: {
                                      "hazard-complete-event":
                                        _vm.hazardCompleteEventHandler,
                                      "hazard-cancel-event":
                                        _vm.hazarEditAddCancelEventHandler,
                                      "child-hide-event":
                                        _vm.hideEditHazardModal
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "editorTab" }, [
        _c("input", {
          attrs: { type: "radio", id: "tab-4", name: "tab-group-1" },
          on: {
            click: function($event) {
              _vm.showHazardTab = false
              _vm.showRegionTab = false
              _vm.showUnitsTab = false
              _vm.showAlertsTab = true
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "editorTabLabel", attrs: { for: "tab-4" } },
          [_vm._v("Obs Alerts")]
        ),
        _vm._v(" "),
        _vm.showAlertsTab
          ? _c("div", { staticClass: "editorTabContent" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "hc-block" },
                  [
                    _c("div", { staticClass: "hc-heading" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "hc-actions" }, [
                        _c(
                          "button",
                          {
                            class: _vm.buttonClass,
                            on: {
                              click: function($event) {
                                _vm.showAddAlertModal = true
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-plus",
                              attrs: { title: _vm.addAlertTitle }
                            })
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.preferences.alertObservations, function(alert) {
                      return _vm.preferences.alertObservations
                        ? _c(
                            "div",
                            {
                              key: alert.id,
                              staticClass: "hc-location-details"
                            },
                            [
                              _c(
                                "div",
                                {
                                  key: alert.id,
                                  staticClass: "flex-row",
                                  staticStyle: { "margin-left": "1.5em" }
                                },
                                [
                                  _c("div", [
                                    _c("label", [_vm._v("Alert ID")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: alert.id,
                                          expression: "alert.id"
                                        }
                                      ],
                                      staticClass: "wg-form-control--alerts",
                                      attrs: { disabled: "", type: "text" },
                                      domProps: { value: alert.id },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            alert,
                                            "id",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("label", [_vm._v("Alert name")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: alert.name,
                                          expression: "alert.name"
                                        }
                                      ],
                                      staticClass: "wg-form-control--alerts",
                                      attrs: { disabled: "", type: "text" },
                                      domProps: { value: alert.name },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            alert,
                                            "name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("label", [_vm._v("Region")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: alert.region,
                                          expression: "alert.region"
                                        }
                                      ],
                                      staticClass: "wg-form-control--alerts",
                                      attrs: { disabled: "", type: "text" },
                                      domProps: { value: alert.region },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            alert,
                                            "region",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("label", [_vm._v("Site")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: alert.site,
                                          expression: "alert.site"
                                        }
                                      ],
                                      staticClass: "wg-form-control--alerts",
                                      attrs: { disabled: "", type: "text" },
                                      domProps: { value: alert.site },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            alert,
                                            "site",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("label", [_vm._v("Parameter")]),
                                    _c("input", {
                                      staticClass: "wg-form-control--alerts",
                                      attrs: { disabled: "", type: "text" },
                                      domProps: {
                                        value: _vm.camelCaseToSentenceCase(
                                          alert.parameter
                                        )
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("label", [
                                      _vm._v(
                                        "Radius (" +
                                          _vm._s(_vm.unitSet["radius"]) +
                                          ")"
                                      )
                                    ]),
                                    _c("input", {
                                      staticClass: "wg-form-control--alerts",
                                      attrs: { disabled: "", type: "text" },
                                      domProps: {
                                        value: _vm.formatRadius(alert.radius)
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(alert.childConditions, function(
                                    condition
                                  ) {
                                    return alert.childConditions &&
                                      _vm.displayChildConditions(
                                        alert.parameter
                                      )
                                      ? _c("div", { key: condition.id }, [
                                          _c("label", [
                                            _vm._v(_vm._s(condition.name))
                                          ]),
                                          _c("input", {
                                            staticClass:
                                              "wg-form-control--alerts",
                                            attrs: {
                                              disabled: "",
                                              type: "text"
                                            },
                                            domProps: {
                                              value:
                                                _vm.formatComparisonType(
                                                  condition.comparisonType
                                                ) +
                                                _vm.formatChildThreshold(
                                                  condition
                                                )
                                            }
                                          })
                                        ])
                                      : _vm._e()
                                  }),
                                  _vm._v(" "),
                                  _c("button", { class: _vm.buttonClass }, [
                                    _c("i", {
                                      staticClass: "fas fa-edit",
                                      attrs: { title: _vm.editAlertTitle },
                                      on: {
                                        click: function($event) {
                                          return _vm.editAlert(alert.id)
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("button", { class: _vm.buttonClass }, [
                                    _c("i", {
                                      staticClass: "fas fa-trash",
                                      attrs: { title: _vm.deleteAlertTitle },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteAlert(alert.id)
                                        }
                                      }
                                    })
                                  ])
                                ],
                                2
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm.showAddAlertModal || _vm.showEditAlertModal
                      ? _c(
                          "div",
                          [
                            _c(
                              "transition",
                              { attrs: { name: "editor-modal" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "editor-modal-mask" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "editor-modal-wrapper" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "editor-modal-container flex-row"
                                          },
                                          [
                                            _c("div", [
                                              _c("label", [_vm._v("Alert ID")]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.inputAlertId,
                                                    expression: "inputAlertId"
                                                  }
                                                ],
                                                staticClass:
                                                  "wg-form-control--alerts",
                                                attrs: {
                                                  disabled:
                                                    _vm.showEditAlertModal,
                                                  type: "text"
                                                },
                                                domProps: {
                                                  value: _vm.inputAlertId
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.inputAlertId =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("label", [
                                                _vm._v("Alert name")
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.inputAlertName,
                                                    expression: "inputAlertName"
                                                  }
                                                ],
                                                staticClass:
                                                  "wg-form-control--alerts",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.inputAlertName
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.inputAlertName =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("label", [_vm._v("Site")]),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.alertSite,
                                                      expression: "alertSite"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "wg-form-control--alerts",
                                                  staticStyle: {
                                                    "margin-right": "40px"
                                                  },
                                                  domProps: {
                                                    value: _vm.alertSite
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.alertSite = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    }
                                                  }
                                                },
                                                _vm._l(_vm.allSites, function(
                                                  site
                                                ) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: site.id,
                                                      domProps: {
                                                        value: site.id
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              " +
                                                          _vm._s(site.name) +
                                                          "\n                            "
                                                      )
                                                    ]
                                                  )
                                                }),
                                                0
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("label", [
                                                _vm._v("Parameter")
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.alertParameter,
                                                      expression:
                                                        "alertParameter"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "wg-form-control--alerts",
                                                  staticStyle: {
                                                    "margin-right": "40px"
                                                  },
                                                  domProps: {
                                                    value: _vm.alertParameter
                                                  },
                                                  on: {
                                                    change: [
                                                      function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.alertParameter = $event
                                                          .target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      },
                                                      function($event) {
                                                        return _vm.setParameterChildConditions(
                                                          $event
                                                        )
                                                      }
                                                    ]
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.allAlertParameters,
                                                  function(parameter) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: parameter.id,
                                                        domProps: {
                                                          value: parameter.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                              " +
                                                            _vm._s(
                                                              parameter.name
                                                            ) +
                                                            "\n                            "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.alertChildConditions,
                                              function(condition) {
                                                return _vm.displayChildConditions(
                                                  _vm.alertParameter
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: condition.id,
                                                        staticClass: "flex-row"
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                condition.name
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  condition.threshold,
                                                                expression:
                                                                  "condition.threshold"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "wg-form-control--alerts",
                                                            attrs: {
                                                              type: "number"
                                                            },
                                                            domProps: {
                                                              value:
                                                                condition.threshold
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  condition,
                                                                  "threshold",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _c("label", [
                                                            _vm._v("Comparison")
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    condition.comparisonType,
                                                                  expression:
                                                                    "condition.comparisonType"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "wg-form-control--alerts",
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "40px"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  condition.comparisonType
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  var $$selectedVal = Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                  _vm.$set(
                                                                    condition,
                                                                    "comparisonType",
                                                                    $event
                                                                      .target
                                                                      .multiple
                                                                      ? $$selectedVal
                                                                      : $$selectedVal[0]
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.alertComparisonTypes,
                                                              function(
                                                                comparison
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: comparison,
                                                                    domProps: {
                                                                      value: comparison
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                " +
                                                                        _vm._s(
                                                                          _vm.camelCaseToSentenceCase(
                                                                            comparison
                                                                          )
                                                                        ) +
                                                                        "\n                              "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("label", [
                                                _vm._v(
                                                  "Radius (" +
                                                    _vm._s(
                                                      _vm.unitSet["radius"]
                                                    ) +
                                                    ")"
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.inputAlertRadius,
                                                    expression:
                                                      "inputAlertRadius"
                                                  }
                                                ],
                                                staticClass:
                                                  "wg-form-control--alerts",
                                                attrs: { type: "number" },
                                                domProps: {
                                                  value: _vm.inputAlertRadius
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.inputAlertRadius =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "editor-modal-default-button editor-modal-default-button--teal",
                                                on: { click: _vm.addEditAlert }
                                              },
                                              [_vm._v("OK")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "editor-modal-default-button editor-modal-default-button--teal",
                                                on: {
                                                  click: _vm.cancelAddEditAlert
                                                }
                                              },
                                              [_vm._v("Cancel")]
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "editorTab" }, [
        _c("input", {
          attrs: { type: "radio", id: "tab-2", name: "tab-group-1" },
          on: {
            click: function($event) {
              _vm.showHazardTab = false
              _vm.showRegionTab = true
              _vm.showUnitsTab = false
              _vm.showAlertsTab = false
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "editorTabLabel", attrs: { for: "tab-2" } },
          [_vm._v("Regions")]
        ),
        _vm._v(" "),
        _vm.showRegionTab
          ? _c("div", { staticClass: "editorTabContent" }, [
              _c("div", [
                _c("div", { staticClass: "hc-block" }, [
                  _c("div", { staticClass: "hc-heading" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    false
                      ? _c("div", [
                          _c("div", { staticClass: "hc-actions" }, [
                            _c(
                              "button",
                              {
                                class: _vm.buttonClass,
                                on: { click: _vm.showAddRegionModalTrue }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-plus",
                                  attrs: { title: _vm.addRegion }
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm.showAddRegionModal
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "transition",
                                    { attrs: { name: "editor-modal" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "editor-modal-mask" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "editor-modal-wrapper"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "editor-modal-container"
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.inputRegionId,
                                                        expression:
                                                          "inputRegionId"
                                                      }
                                                    ],
                                                    attrs: {
                                                      placeholder: "Region ID"
                                                    },
                                                    domProps: {
                                                      value: _vm.inputRegionId
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.inputRegionId =
                                                          $event.target.value
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.inputRegionName,
                                                        expression:
                                                          "inputRegionName"
                                                      }
                                                    ],
                                                    attrs: {
                                                      placeholder: "Region Name"
                                                    },
                                                    domProps: {
                                                      value: _vm.inputRegionName
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.inputRegionName =
                                                          $event.target.value
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "editor-modal-default-button editor-modal-default-button--teal",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showAddRegionModal = false
                                                          _vm.addRegion()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("OK")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "editor-modal-default-button editor-modal-default-button--teal",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showAddRegionModal = false
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Cancel")]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "hc-content__config" }, [
                    _c("div", { staticClass: "tab-content" }, [
                      _c(
                        "div",
                        { staticClass: "tab-pane" },
                        _vm._l(_vm.regions, function(region) {
                          return _c(
                            "div",
                            { key: region.id, staticClass: "hc-block" },
                            [
                              _c("div", { staticClass: "hc-heading" }, [
                                _c("div", { staticClass: "hc-heading__text" }, [
                                  _vm._v(_vm._s(region.name))
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "hc-actions" }, [
                                  _c("button", { class: _vm.buttonClass }, [
                                    _c("i", {
                                      staticClass: "fa fa-plus",
                                      attrs: { title: _vm.addSiteTitle },
                                      on: {
                                        click: function($event) {
                                          return _vm.showAddSiteModalClick(
                                            region.id
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "hc-location-details" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-row",
                                      staticStyle: { "margin-left": "1.5em" }
                                    },
                                    [
                                      _c("div", [
                                        _c("label", [_vm._v("Min Latitude")]),
                                        _c("input", {
                                          staticClass: "wg-form-control",
                                          attrs: { disabled: "", type: "text" },
                                          domProps: {
                                            value: region.gridDefinition.minLat
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("label", [_vm._v("Min Longitude")]),
                                        _c("input", {
                                          staticClass: "wg-form-control",
                                          attrs: { disabled: "", type: "text" },
                                          domProps: {
                                            value: region.gridDefinition.minLon
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("label", [_vm._v("Max Latitude")]),
                                        _c("input", {
                                          staticClass: "wg-form-control",
                                          attrs: { disabled: "", type: "text" },
                                          domProps: {
                                            value: region.gridDefinition.maxLat
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("label", [_vm._v("Max Longitude")]),
                                        _c("input", {
                                          staticClass: "wg-form-control",
                                          attrs: { disabled: "", type: "text" },
                                          domProps: {
                                            value: region.gridDefinition.maxLon
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("label", [_vm._v("Timezone")]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: region.timezone,
                                              expression: "region.timezone"
                                            }
                                          ],
                                          staticClass: "wg-form-control",
                                          attrs: { disabled: "", type: "text" },
                                          domProps: { value: region.timezone },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                region,
                                                "timezone",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(region.sites, function(site) {
                                return _c(
                                  "div",
                                  {
                                    key: site.id,
                                    staticClass: "hc-location-details"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        key: region.id + site.id,
                                        staticClass: "flex-row",
                                        staticStyle: { "margin-left": "1.5em" }
                                      },
                                      [
                                        _c("div", [
                                          _c("label", [_vm._v("Site ID")]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: site.id,
                                                expression: "site.id"
                                              }
                                            ],
                                            staticClass: "wg-form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text"
                                            },
                                            domProps: { value: site.id },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  site,
                                                  "id",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("label", [_vm._v("Site name")]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: site.name,
                                                expression: "site.name"
                                              }
                                            ],
                                            staticClass: "wg-form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text"
                                            },
                                            domProps: { value: site.name },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  site,
                                                  "name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("label", [_vm._v("Latitude")]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: site.latitude,
                                                expression: "site.latitude"
                                              }
                                            ],
                                            staticClass: "wg-form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text"
                                            },
                                            domProps: { value: site.latitude },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  site,
                                                  "latitude",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("label", [_vm._v("Longitude")]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: site.longitude,
                                                expression: "site.longitude"
                                              }
                                            ],
                                            staticClass: "wg-form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text"
                                            },
                                            domProps: { value: site.longitude },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  site,
                                                  "longitude",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("label", [
                                            _vm._v(
                                              "Radius (" +
                                                _vm._s(_vm.unitSet["radius"]) +
                                                ")"
                                            )
                                          ]),
                                          _c("input", {
                                            staticClass: "wg-form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text"
                                            },
                                            domProps: {
                                              value: _vm.formatRadius(
                                                site.radius
                                              )
                                            }
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("label", [_vm._v("Timezone")]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: site.timezone,
                                                expression: "site.timezone"
                                              }
                                            ],
                                            staticClass: "wg-form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text"
                                            },
                                            domProps: { value: site.timezone },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  site,
                                                  "timezone",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          { class: _vm.buttonClass },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-edit",
                                              attrs: {
                                                title: _vm.editSiteTitle
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editSite(
                                                    region.id,
                                                    site.id
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          { class: _vm.buttonClass },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-trash",
                                              attrs: {
                                                title: _vm.deleteSiteTitle
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteSite(
                                                    region.id,
                                                    site.id
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _vm.showAddSiteModal || _vm.showEditSiteModal
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "transition",
                                        { attrs: { name: "editor-modal" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "editor-modal-mask"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "editor-modal-wrapper"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "editor-modal-container flex-row"
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("label", [
                                                          _vm._v("Site ID")
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.inputSiteId,
                                                              expression:
                                                                "inputSiteId"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "wg-form-control",
                                                          attrs: {
                                                            type: "text"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.inputSiteId
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.inputSiteId =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _c("label", [
                                                          _vm._v("Site name")
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.inputSiteName,
                                                              expression:
                                                                "inputSiteName"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "wg-form-control",
                                                          attrs: {
                                                            type: "text"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.inputSiteName
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.inputSiteName =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _c("label", [
                                                          _vm._v("Latitude")
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.inputSiteLatitude,
                                                              expression:
                                                                "inputSiteLatitude"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "wg-form-control",
                                                          attrs: {
                                                            type: "number"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.inputSiteLatitude
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.inputSiteLatitude =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _c("label", [
                                                          _vm._v("Longitude")
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.inputSiteLongitude,
                                                              expression:
                                                                "inputSiteLongitude"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "wg-form-control",
                                                          attrs: {
                                                            type: "number"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.inputSiteLongitude
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.inputSiteLongitude =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _c("label", [
                                                          _vm._v(
                                                            "Radius (" +
                                                              _vm._s(
                                                                _vm.unitSet[
                                                                  "radius"
                                                                ]
                                                              ) +
                                                              ")"
                                                          )
                                                        ]),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.inputSiteRadius,
                                                              expression:
                                                                "inputSiteRadius"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "wg-form-control",
                                                          attrs: {
                                                            type: "number"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.inputSiteRadius
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.inputSiteRadius =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _c("label", [
                                                          _vm._v("Timezone")
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.selectedTimezone,
                                                                expression:
                                                                  "selectedTimezone"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "wg-form-control",
                                                            staticStyle: {
                                                              "margin-right":
                                                                "40px"
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.selectedTimezone
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.selectedTimezone = $event
                                                                  .target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              }
                                                            }
                                                          },
                                                          _vm._l(
                                                            _vm.availableTimezones,
                                                            function(timezone) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: timezone,
                                                                  domProps: {
                                                                    value: timezone
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                      " +
                                                                      _vm._s(
                                                                        timezone
                                                                      ) +
                                                                      "\n                                    "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "editor-modal-default-button editor-modal-default-button--teal",
                                                          on: {
                                                            click:
                                                              _vm.addEditSite
                                                          }
                                                        },
                                                        [_vm._v("OK")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "editor-modal-default-button editor-modal-default-button--teal",
                                                          on: {
                                                            click:
                                                              _vm.cancelAddEditSite
                                                          }
                                                        },
                                                        [_vm._v("Cancel")]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "editorTab" }, [
        _c("input", {
          attrs: { type: "radio", id: "tab-3", name: "tab-group-1" },
          on: {
            click: function($event) {
              _vm.showHazardTab = false
              _vm.showRegionTab = false
              _vm.showUnitsTab = true
              _vm.showAlertsTab = false
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "editorTabLabel", attrs: { for: "tab-3" } },
          [_vm._v("Units")]
        ),
        _vm._v(" "),
        _vm.showUnitsTab
          ? _c("div", { staticClass: "editorTabContent" }, [
              _c("div", [
                _c("div", { staticClass: "hc-block" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("div", { staticClass: "hc-content__config" }, [
                    _c("div", { staticClass: "editorTab-content" }, [
                      _c(
                        "div",
                        { staticClass: "editorTab-pane" },
                        _vm._l(_vm.unitSets, function(us) {
                          return _c("div", { key: us.id }, [
                            _c("input", {
                              staticStyle: { display: "inline" },
                              attrs: { type: "radio", name: "units-radio" },
                              domProps: {
                                value: us.id,
                                checked: us.id === _vm.unitSetId
                              },
                              on: {
                                change: function($event) {
                                  return _vm.updateUnitSet(us.id)
                                }
                              }
                            }),
                            _vm._v(_vm._s(us.name)),
                            _c("br")
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hc-heading__text" }, [
      _c("br"),
      _vm._v("Hazards"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hc-heading__text" }, [
      _c("br"),
      _vm._v("Obs Alerts"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hc-heading__text" }, [
      _c("br"),
      _vm._v("Regions"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hc-heading" }, [
      _c("div", { staticClass: "hc-heading__text" }, [
        _c("br"),
        _vm._v("Units"),
        _c("br")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }