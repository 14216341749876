var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wg-flex-container" }, [
    _c(
      "div",
      { staticClass: "wg-feature-table-container" },
      _vm._l(_vm.featureConfig.featureDetails, function(
        detailsGroup,
        detailsGroupId
      ) {
        return _c(
          "div",
          {
            key: detailsGroupId,
            staticClass: "wg-feature-table-container-item"
          },
          [
            _c("div", { staticClass: "wg-feature-table-header" }, [
              _vm.featureCollection
                ? _c("div", { staticClass: "feature-navigators" }, [
                    _c(
                      "a",
                      {
                        staticClass: "feature-navigate-button",
                        on: { click: _vm.navigateBack }
                      },
                      [_vm._v("‹")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "feature-navigate-button",
                        on: { click: _vm.navigateForward }
                      },
                      [_vm._v("›")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(detailsGroup.name))]),
              _vm._v(" "),
              _vm.featureCollection
                ? _c("h4", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.currentFeatureIndex + 1) +
                        " / " +
                        _vm._s(_vm.feature.features.length) +
                        " "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "wg-feature-table-inner-container" }, [
              _c("table", { staticClass: "wg-feature-table" }, [
                _c(
                  "tbody",
                  _vm._l(detailsGroup.properties, function(property, name) {
                    return _vm.featureAvailable(property)
                      ? _c("tr", { key: property.id }, [
                          _c("td", [_vm._v(_vm._s(property.name))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.formatFeatureDescription(property)) +
                                " " +
                                _vm._s(_vm.getUnits(property))
                            )
                          ])
                        ])
                      : _vm._e()
                  }),
                  0
                )
              ])
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }