var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.cookiesDecisionMade
    ? _c("div", { staticClass: "cookieBanner" }, [
        _c("div", { staticClass: "cookieBannerContent" }, [
          _c("h3", { staticClass: "cookieBannerHeader" }, [
            _vm._v(" Cookie consent ")
          ]),
          _vm._v(" "),
          _c("div", [
            _c("p", { staticStyle: { color: "#ffffff" } }, [
              _vm._v(
                " This website uses cookies and similar technologies to function, and to offer you a secure browsing experience. Please view our "
              ),
              _c(
                "a",
                {
                  staticStyle: {
                    "text-decoration": "underline",
                    cursor: "pointer"
                  },
                  on: { click: _vm.goToCookieNotice }
                },
                [_vm._v("cookie notice")]
              ),
              _vm._v(
                " to learn more about the use of cookies on this website. "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cookieBannerButtons" }, [
          _c(
            "button",
            {
              staticClass: "cookieBannerButton",
              on: { click: _vm.enableCookies }
            },
            [_vm._v("Ok")]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }