import Vue from 'vue';
import moment from 'moment';
import RequestBuilder from '../../js/requestBuilder';

const requestBuilder = new RequestBuilder();

const state = {
  user: {
    initialDataLoaded: false,
    readOnly: true,
    username: '',
    email: '',
    givenName: '',
    familyName: '',
    clientId: '',
    organizationId: '',
    preferences: {
      wxAlertConfig: {}
    },
    mapState: {
      activeHeatMap: null,
      activeParticleMap: null,
      activePointDataPopup: {}
    }
  },
  baseMapConfig: [],
  riskdata: {
    data: {},
    lastUpdateTimeUtc: '',
    maxSecondsStale: 610
  },
  riskMaps: {
    data: {},
    lastUpdateTimeUtc: '',
    maxSecondsStale: 610
  },
  weatherMaps: {
    data: {},
    lastUpdateTimeUtc: '',
    maxSecondsStale: 3610
  },
  features: {
    data: [],
    lastUpdateTimeUtc: '',
    maxSecondsStale: 3610
  },
  currentMapFeatures: {},
  serverRoute: process.env.RISK_API_URL // eslint-disable-line no-undef
};

const getters = {
  riskdata(state) { return state.riskdata; },
  riskMaps(state) { return state.riskMaps; },
  baseMaps(state) { return state.baseMapConfig; },
  weatherMaps(state) { return state.weatherMaps; },
  features(state) { return state.features},
  serverRoute(state) { return state.serverRoute; },
  clientId(state) { return state.user.clientId; },
  initialDataLoaded(state) { return state.user.initialDataLoaded; },
  username(state) { return state.user.username; },
  email(state) { return state.user.email; },
  givenName(state) { return state.user.givenName; },
  familyName(state) { return state.user.familyName; },
  organizationId(state) { return state.user.organizationId; },
  preferences(state) { return state.user.preferences; },
  starredSitesByRegion(state) { return state.user.preferences.starredSitesByRegion ? state.user.preferences.starredSitesByRegion : {}; },
  starredHazardsByRegion(state) { return state.user.preferences.starredHazardsByRegion ? state.user.preferences.starredHazardsByRegion : {}; },
  alertSitesByRegion(state) { return state.user.preferences.alertSitesByRegion ? state.user.preferences.alertSitesByRegion : {}; },
  wxAlertConfig(state) { return state.user.preferences.wxAlertConfig ? state.user.preferences.wxAlertConfig : {}; },
  wxAlertHazardLevel(state) {
    if (state.user.preferences.wxAlertConfig) {
      return state.user.preferences.wxAlertConfig.hazardLevel ? state.user.preferences.wxAlertConfig.hazardLevel : 5;
    }
    return 5;
  },
  wxAlertSendEmail(state) {
    if (state.user.preferences.wxAlertConfig) {
      return state.user.preferences.wxAlertConfig.sendEmail ? state.user.preferences.wxAlertConfig.sendEmail : false;
    }
    return false;
  },
  wxAlertSendSms(state) {
    if (state.user.preferences.wxAlertConfig) {
      return state.user.preferences.wxAlertConfig.sendSms ? state.user.preferences.wxAlertConfig.sendSms : false;
    }
    return false;
  },
  showOnlyFavouriteSitesOnMap(state) {
    if (state.user.preferences.showOnlyFavouriteSitesOnMap) {
      return state.user.preferences.showOnlyFavouriteSitesOnMap;
    } else {
      return false;
    }
  },
  showOnlyFavouriteHazardsOnMap(state) {
    if (state.user.preferences.showOnlyFavouriteHazardsOnMap) {
      return state.user.preferences.showOnlyFavouriteHazardsOnMap;
    } else {
      return false;
    }
  },
  showOnlyFavouriteSitesInVizualizer(state) {
    if (state.user.preferences.showOnlyFavouriteSitesInVizualizer) {
      return state.user.preferences.showOnlyFavouriteSitesInVizualizer;
    } else {
      return false;
    }
  },
  showOnlyFavouriteHazardsInVizualizer(state) {
    if (state.user.preferences.showOnlyFavouriteHazardsInVizualizer) {
      return state.user.preferences.showOnlyFavouriteHazardsInVizualizer;
    } else {
      return false;
    }
  },
  showOnlyFavouriteHazardAlerts(state) {
    if (state.user.preferences.showOnlyFavouriteHazardAlerts) {
      return state.user.preferences.showOnlyFavouriteHazardAlerts;
    } else {
      return false;
    }
  },
  showOnlyFavouriteHazardsRiskMaps(state) {
    if (state.user.preferences.showOnlyFavouriteHazardsRiskMaps) {
      return state.user.preferences.showOnlyFavouriteHazardsRiskMaps;
    } else {
      return false;
    }
  },
  smsNumber(state) {
    if (state.user.preferences.wxAlertConfig) {
      return state.user.preferences.wxAlertConfig.smsNumber
    }
    return undefined;
  },
  readOnly(state) { return state.user.readOnly; },
  activeHeatMap(state) { return state.user.mapState.activeHeatMap; },
  activeParticleMap(state) { return state.user.mapState.activeParticleMap; },
  activePointDataPopup(state) { return state.user.mapState.activePointDataPopup; },
  getCurrentMapFeatures(state) { return state.currentMapFeatures; }
};

const mutations = {
  changeServerRoute(state, newServerRoute) {
    Vue.set(state, 'serverRoute', newServerRoute);
  },
  clearRiskdata(state) {
    Vue.set(state.riskdata, 'data', {});
    Vue.set(state.riskdata, 'lastUpdateTimeUtc', '');
  },
  clearRiskMaps(state) {
    Vue.set(state.riskMaps, 'data', {});
    Vue.set(state.riskMaps, 'lastUpdateTimeUtc', '');
  },
  clearWeatherMaps(state) {
    Vue.set(state.weatherMaps, 'data', {});
    Vue.set(state.weatherMaps, 'lastUpdateTimeUtc', '');
  },
  clearFeatures(state) {
    Vue.set(state.features, 'data', {});
    Vue.set(state.features, 'lastUpdateTimeUtc', '');
  },
  changeRiskdata(state, newRiskdata) {
    Vue.set(state.riskdata, 'lastUpdateTimeUtc', moment.utc().format());
    Vue.set(state.riskdata, 'data', newRiskdata);
  },
  changeRiskMaps(state, newRiskMaps) {
    Vue.set(state.riskMaps, 'lastUpdateTimeUtc', moment.utc().format());
    Vue.set(state.riskMaps, 'data', newRiskMaps);
  },
  changeWeatherMaps(state, newWeatherMaps) {
    Vue.set(state.weatherMaps, 'lastUpdateTimeUtc', moment.utc().format());
    Vue.set(state.weatherMaps, 'data', newWeatherMaps);
  },
  changeFeatures(state, newFeatures) {
    Vue.set(state.features, 'lastUpdateTimeUtc', moment.utc().format());
    Vue.set(state.features, 'data', newFeatures);
  },
  initialDataLoaded(state) { Vue.set(state.user, 'initialDataLoaded', true); },
  unloadInitialData(state) { Vue.set(state.user, 'initialDataLoaded', false); },
  changeUsername(state, username) { Vue.set(state.user, 'username', username); },
  changeClientId(state, clientId) { Vue.set(state.user, 'clientId', clientId); },
  changeEmail(state, email) { Vue.set(state.user, 'email', email); },
  changeGivenName(state, givenName) { Vue.set(state.user, 'givenName', givenName); },
  changeFamilyName(state, familyName) { Vue.set(state.user, 'familyName', familyName); },
  changeOrganizationId(state, organizationId) { Vue.set(state.user, 'organizationId', organizationId); },
  changePreferences(state, preferences) { Vue.set(state.user, 'preferences', preferences); },
  changeReadOnly(state, readOnly) { Vue.set(state.user, 'readOnly', readOnly); },
  addStarredSite(state, d) {
    // d = {region: 'myRegion', site: 'mySite'}
    if (!state.user.preferences.starredSitesByRegion) Vue.set(state.user.preferences, 'starredSitesByRegion', {});
    if (state.user.preferences.starredSitesByRegion[d.region]) state.user.preferences.starredSitesByRegion[d.region].push(d.site);
    else Vue.set(state.user.preferences.starredSitesByRegion, d.region, [d.site]);
    publishPreferences(state.user.preferences);
  },
  removeStarredSite(state, d) {
    // d = {region: 'myRegion', site: 'mySite'}
    state.user.preferences.starredSitesByRegion[d.region] = state.user.preferences.starredSitesByRegion[d.region].filter(x => x !== d.site);
    if (state.user.preferences.starredSitesByRegion[d.region].length == 0) delete state.user.preferences.starredSitesByRegion[d.region];
    publishPreferences(state.user.preferences);
  },
  addStarredHazard(state, d) {
    // d = {region: 'myRegion', site: 'mySite', hazard: 'hazard id'}
    if (!state.user.preferences.starredHazardsByRegion) Vue.set(state.user.preferences, 'starredHazardsByRegion', {});

    if (state.user.preferences.starredHazardsByRegion.hasOwnProperty(d.region)) {
      if (state.user.preferences.starredHazardsByRegion[d.region].hasOwnProperty(d.site)) {
        state.user.preferences.starredHazardsByRegion[d.region][d.site].push(d.hazard);
      } else {
        Vue.set(state.user.preferences.starredHazardsByRegion[d.region], d.site, [d.hazard]);
      }
    } else {
      Vue.set(state.user.preferences.starredHazardsByRegion, d.region, {});
      Vue.set(state.user.preferences.starredHazardsByRegion[d.region], d.site, [d.hazard]);
    }
    publishPreferences(state.user.preferences);
  },
  removeStarredHazard(state, d) {
    // d = {region: 'myRegion', site: 'mySite', hazard: 'hazard id'}
    state.user.preferences.starredHazardsByRegion[d.region][d.site] = state.user.preferences.starredHazardsByRegion[d.region][d.site].filter(x => x !== d.hazard);
    if (state.user.preferences.starredHazardsByRegion[d.region][d.site].length == 0) delete state.user.preferences.starredHazardsByRegion[d.region][d.site];
    if (Object.keys(state.user.preferences.starredHazardsByRegion[d.region]).length == 0) delete state.user.preferences.starredHazardsByRegion[d.region];
    publishPreferences(state.user.preferences);
  },
  addAlertSite(state, d) {
    // d = {region: 'myRegion', site: 'mySite'}
    if (!state.user.preferences.alertSitesByRegion) Vue.set(state.user.preferences, 'alertSitesByRegion', {});
    if (state.user.preferences.alertSitesByRegion[d.region]) state.user.preferences.alertSitesByRegion[d.region].push(d.site);
    else Vue.set(state.user.preferences.alertSitesByRegion, d.region, [d.site]);
    publishPreferences(state.user.preferences);
  },
  removeAlertSite(state, d) {
    // d = {region: 'myRegion', site: 'mySite'}
    state.user.preferences.alertSitesByRegion[d.region] = state.user.preferences.alertSitesByRegion[d.region].filter(x => x !== d.site);
    if (state.user.preferences.alertSitesByRegion[d.region].length == 0) delete state.user.preferences.alertSitesByRegion[d.region];
    publishPreferences(state.user.preferences);
  },
  changeAlertHazardLevel(state, d) {
    // d = {hazardLevel: intBetween1and5}
    if (!state.user.preferences.wxAlertConfig) Vue.set(state.user.preferences, 'wxAlertConfig', {});
    state.user.preferences.wxAlertConfig.hazardLevel = d.hazardLevel;
    publishPreferences(state.user.preferences);
  },
  toggleEmailNotifications(state, b) {
    // b = bool
    if (!state.user.preferences.wxAlertConfig) Vue.set(state.user.preferences, 'wxAlertConfig', {});
    state.user.preferences.wxAlertConfig.sendEmail = b;
    publishPreferences(state.user.preferences);
  },
  toggleSmsNotifications(state, b) {
    // b = bool
    if (!state.user.preferences.wxAlertConfig) Vue.set(state.user.preferences, 'wxAlertConfig', {});
    state.user.preferences.wxAlertConfig.sendSms = b;
    publishPreferences(state.user.preferences);
  },
  toggleSiteMapPreference(state, b) {
    if (!state.user.preferences.showOnlyFavouriteSitesOnMap) {
      Vue.set(state.user.preferences, 'showOnlyFavouriteSitesOnMap', b);
    } else {
      state.user.preferences.showOnlyFavouriteSitesOnMap = b;
    }
    publishPreferences(state.user.preferences);
  },
  toggleSiteVizualizerPreference(state, b) {
    if (!state.user.preferences.showOnlyFavouriteSitesInVizualizer) {
      Vue.set(state.user.preferences, 'showOnlyFavouriteSitesInVizualizer', b);
    } else {
      state.user.preferences.showOnlyFavouriteSitesInVizualizer = b;
    }
    publishPreferences(state.user.preferences);
  },
  toggleHazardMapPreference(state, b) {
    if (!state.user.preferences.showOnlyFavouriteHazardsOnMap) {
      Vue.set(state.user.preferences, 'showOnlyFavouriteHazardsOnMap', b);
    } else {
      state.user.preferences.showOnlyFavouriteHazardsOnMap = b;
    }
    publishPreferences(state.user.preferences);
  },
  toggleHazardVizualizerPreference(state, b) {
    if (!state.user.preferences.showOnlyFavouriteHazardsInVizualizer) {
      Vue.set(state.user.preferences, 'showOnlyFavouriteHazardsInVizualizer', b);
    } else {
      state.user.preferences.showOnlyFavouriteHazardsInVizualizer = b;
    }
    publishPreferences(state.user.preferences);
  },
  toggleHazardRiskMapPreference(state, b) {
    if (!state.user.preferences.showOnlyFavouriteHazardsRiskMaps) {
      Vue.set(state.user.preferences, 'showOnlyFavouriteHazardsRiskMaps', b);
    } else {
      state.user.preferences.showOnlyFavouriteHazardsRiskMaps = b;
    }
    publishPreferences(state.user.preferences);
  },
  toggleHazardAlertPreference(state, b) {
    if (!state.user.preferences.showOnlyFavouriteHazardAlerts) {
      Vue.set(state.user.preferences, 'showOnlyFavouriteHazardAlerts', b);
    } else {
      state.user.preferences.showOnlyFavouriteHazardAlerts = b;
    }
    publishPreferences(state.user.preferences);
  },
  updatePreferences(state, preferences) {
    Vue.set(state.user, 'preferences', preferences);
    publishPreferences(state.user.preferences);
  },
  updateBaseMaps(state, config) {
    state.baseMapConfig = config;
  },
  changeSmsNumber(state, s) {
    // s = string (Or undefined - if sms number is invalid)
    if (!state.user.preferences.wxAlertConfig) Vue.set(state.user.preferences, 'wxAlertConfig', {});
    state.user.preferences.wxAlertConfig.smsNumber = s;
    publishPreferences(state.user.preferences);
  },
  changeActiveHeatMap(state, s) { Vue.set(state.user.mapState, 'activeHeatMap', s); },
  changeActiveParticleMap(state, s) { Vue.set(state.user.mapState, 'activeParticleMap', s); },
  changeActivePointDataPopup(state, d) { state.user.mapState.activePointDataPopup = d; },
  setCurrentMapFeatures(state, d) { state.currentMapFeatures = d }
};

const actions = {
  updateServerRoute(context, payload) { context.commit('changeServerRoute', payload); },
  updateRiskdata(context, payload) { context.commit('changeRiskdata', payload); },
  updateRiskMaps(context, payload) { context.commit('changeRiskMaps', payload); },
  updateWeatherMaps(context, payload) { context.commit('changeWeatherMaps', payload); },
  updateFeatures(context, payload) { context.commit('changeFeatures', payload); },
  updateInitialData(context) { context.commit('initialDataLoaded'); },
  login(context, payload) {
    commitIfKeyInDict(context, 'changeClientId', 'organization_id', payload);
    commitIfKeyInDict(context, 'changeUsername', 'username', payload);
    commitIfKeyInDict(context, 'changeEmail', 'email', payload);
    commitIfKeyInDict(context, 'changeClientId', 'organization_id', payload);
    commitIfKeyInDict(context, 'changeGivenName', 'given_name', payload);
    commitIfKeyInDict(context, 'changeFamilyName', 'family_name', payload);
    commitIfKeyInDict(context, 'changeOrganizationId', 'organization_id', payload);
    commitIfKeyInDict(context, 'changeReadOnly', 'readOnly', payload);
  },
  logout(context) {
    context.commit('unloadInitialData');
    context.commit('changeUsername', '');
    context.commit('changeClientId', '');
    context.commit('changeEmail', '');
    context.commit('changeGivenName', '');
    context.commit('changeFamilyName', '');
    context.commit('changeOrganizationId', '');
    context.commit('changePreferences', {});
    context.commit('changeActiveHeatMap', null);
    context.commit('changeActiveParticleMap', null);
    context.commit('clearRiskdata', {});
    context.commit('clearRiskMaps', {});
    context.commit('clearWeatherMaps', {});
    context.commit('clearFeatures', {});
  },
  personalize(context, payload) { context.commit('changePreferences', payload); },
  updatePreferences(context, payload) { context.commit('updatePreferences', payload); },
  starSite(context, payload) { context.commit('addStarredSite', payload); },
  unstarSite(context, payload) { context.commit('removeStarredSite', payload); },
  starHazard(context, payload) { context.commit('addStarredHazard', payload); },
  unstarHazard(context, payload) { context.commit('removeStarredHazard', payload); },
  changeAlertHazardLevel(context, payload) { context.commit('changeAlertHazardLevel', payload); },
  toggleEmailNotifications(context, payload) { context.commit('toggleEmailNotifications', payload); },
  toggleSmsNotifications(context, payload) { context.commit('toggleSmsNotifications', payload); },
  toggleSiteMapPreference(context, payload) { context.commit('toggleSiteMapPreference', payload); },
  toggleSiteVizualizerPreference(context, payload) { context.commit('toggleSiteVizualizerPreference', payload); },
  toggleHazardMapPreference(context, payload) { context.commit('toggleHazardMapPreference', payload); },
  toggleHazardVizualizerPreference(context, payload) { context.commit('toggleHazardVizualizerPreference', payload); },
  toggleHazardRiskMapPreference(context, payload) { context.commit('toggleHazardRiskMapPreference', payload); },
  toggleHazardAlertPreference(context, payload) { context.commit('toggleHazardAlertPreference', payload); },
  changeSmsNumber(context, payload) { context.commit('changeSmsNumber', payload); },
  alertSite(context, payload) { context.commit('addAlertSite', payload); },
  unalertSite(context, payload) { context.commit('removeAlertSite', payload); },
  updateActiveHeatMap(context, payload) { context.commit('changeActiveHeatMap', payload); },
  updateActiveParticleMap(context, payload) { context.commit('changeActiveParticleMap', payload); },
  updateActivePointDataPopup(context, payload) { context.commit('changeActivePointDataPopup', payload); },
  updateBaseMapConfig(context, payload) { context.commit('updateBaseMaps', payload); },
  getUserDefaultsFromConfig(context, payload) {
    if (payload.layerConfig && payload.layerConfig.heatMapKey) context.commit('changeActiveHeatMap', payload.layerConfig.heatMapKey);
    if (payload.layerConfig && payload.layerConfig.particleMapKey) context.commit('changeActiveParticleMap', payload.layerConfig.particleMapKey);
  },
  updateCurrentMapFeatures(context, payload) { context.commit('setCurrentMapFeatures', payload); }
};

async function publishPreferences(preferences) {
  const [request, options] = await requestBuilder.publishPreferencesRequest(state.serverRoute, preferences) // eslint-disable-line no-undef
  const response = await fetch(request, options); // eslint-disable-line no-undef
  return response;
}

function commitIfKeyInDict(context, functionName, key, dict) {
  if (key in dict) context.commit(functionName, dict[key])
}


export default { state, getters, mutations, actions }