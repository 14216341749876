var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HeaderComponent"),
      _vm._v(" "),
      _c("cookie-notice"),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "policiesBody" }, [
      _c("h1", { staticClass: "policiesHeader" }, [_vm._v(" Privacy Policy ")]),
      _vm._v(" "),
      _c("h3", { staticClass: "policiesHeaderH3" }, [
        _vm._v(" View our "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.wsp.com/en-us/legal/privacy-policy",
              target: "_blank"
            }
          },
          [_vm._v("Privacy Policy")]
        )
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "policiesHeader" }, [_vm._v(" Cookie notice ")]),
      _vm._v(" "),
      _c("p", { staticClass: "policiesParagraph" }, [
        _vm._v(
          " WSP Global Inc. and its subsidiaries (“WSP”) is committed to maintaining the privacy and security of your personal information (“Personal Information”) and complying with relevant data protection legislation. \n            This Privacy Notice explains how we use cookies and similar technologies to recognise you when you visit our website at risk.decisionvue.net (“website”) and how you can exercise your privacy rights. \n            As a global professional services firm, WSP acts as a data controller for the Personal Information provided by clients, business partners, and other individuals, including website users. \n        "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "policiesGroup" }, [
        _c("h2", { staticClass: "policiesHeaderH2" }, [
          _vm._v("What are cookies?")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "policiesParagraph" }, [
          _vm._v(
            ' \n                Cookies are usually small text files that are placed on your computer or mobile device (“device”) when you visit our website. \n                Cookies are stored on your device for the duration of your visit or for when you re-visit our website at a later time. \n                Cookies set by WSP are called "first party cookies". Cookies set by parties other than us are called "third party cookies". \n                Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). \n                Please note that third party cookies allow third parties to recognise your device both when you visit this website and also when you visit certain other websites. \n            '
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "policiesGroup" }, [
        _c("h2", { staticClass: "policiesHeaderH2" }, [
          _vm._v("Why do we use cookies?")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "policiesParagraph" }, [
          _vm._v(
            " \n                We use cookies to authorize users and for technical reasons to allow our website to operate properly.\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "policiesGroup" }, [
        _c("h2", { staticClass: "policiesHeaderH2" }, [
          _vm._v("What type of cookies do we use?")
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "policiesHeaderH3" }, [
          _vm._v("Strictly Necessary Cookies")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "policiesParagraph" }, [
          _vm._v(
            " \n                These cookies are necessary for the website to function and therefore cannot be switched off. \n                They allow us to offer you key functions of the website (such as managing your account settings) and also secures our website against unauthorized users. \n                Without these cookies, services you have requested could not be provided (e.g. being able to change your profile name or manage clients).\n            "
          )
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "policiesHeaderH3" }, [
          _vm._v("Do we use targeting cookies on our website?")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "policiesParagraph" }, [
          _vm._v(
            " \n                We donot use targeting cookies. Therefore, the information collected through our cookies does not enable us to identify your name, contact details or other personally identifying details.\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "policiesGroup" }, [
        _c("h2", { staticClass: "policiesHeaderH2" }, [
          _vm._v("How can I switch off or remove cookies?")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "policiesParagraph" }, [
          _vm._v(
            " \n                You can choose to opt out of all but the necessary cookies. In the settings of the browser, you can change the settings to ensure that cookies will be blocked. \n                Most browsers provide you with an explanation on how to do this in the so-called ‘help-function’. \n                However, if you block the cookies, it is possible that you will not be able to enjoy all the technical features our website has to offer and it may negatively affect your user experience.\n            "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }