/*
This module converts values from one unit to another but ignores offsets (additions and subtractions).
*/

class SensitivityConverter {
  constructor() {
    this.kelvinToCelsius = (x) => x;
    this.celsiusToKelvin = (x) => x;
    this.kelvinToFahrenheit = (x) => 1.8 * x;
    this.fahrenheitToKelvin = (x) => 5/9 * x;
    this.fahrenheitToCelsius = (x) => x / 1.8;
    this.celsiusToFahrenheit = (x) => 1.8 * x;
    this.metersPerSecondToKnots = (x) => x * 1.94384;  
    this.knotToMetersPerSecond = (x) => x / 1.94384;  
    this.metersPerSecondToKilometersPerHour = (x) => x * 3.6;
    this.metersPerSecondToMilesPerHour = (x) => x * 2.237;
    this.kilometersPerHourToMetersPerSecond = (x) => x / 3.6; 
    this.kilometersPerHourToKnots = (x) => x * 0.539957;
    this.kilometersPerHourToMilesPerHour = (x) => x / 1.609;
    this.knotsToKilometersPerHour = (x) => x * 1.852; 
    this.metersToNauticalMiles = (x) => x * 0.000539957;  
    this.metersToStatuteMiles = (x) => x * 0.000621371; 
    this.metersToFeet = (x) => x * 3.28084; 
    this.metersToInches = (x) => x * 39.37;
    this.metersToMiles = (x) => x / 1609;
    this.milesPerHourToMetersPerSecond = (x) => x / 2.237;
    this.nauticalMilesToMeters = (x) => x * 1852; 
    this.nauticalMilesToStatuteMiles = (x) => x * 1.15078; 
    this.nauticalMilesToFeet = (x) => x * 6076;
    this.statuteMilesToMeters = (x) => x * 1609;
    this.statuteMilesToNauticalMiles = (x) => x * 0.868976; 
    this.statuteMilesToFeet = (x) => x * 5280;
    this.feetToMeters = (x) => x * 0.3048;
    this.feetToNauticalMiles = (x) => x * 0.000164579;
    this.feetToStatuteMiles = (x) => x * 0.000189394;
    this.pascalsToKiloPascals = (x) => x / 1000;
    this.kiloPascalsToPascals = (x) => x * 1000;
    this.kilogramsPerMetersSquaredPerSecond = (x) => x * 3600 * 3;
    this.pascalsToHectoPascals = (x) => x * 1000;
    this.kiloPascalsToHectoPascals = (x) => x * 10;
    this.hectoPascalsToKiloPascals = (x) => x / 10;
    this.hectoPascalsToPascals = (x) => x * 100;
    this.millimeterPerHourToCentimeterPerHour = (x) => x / 10;
    this.centimeterPerHourToMillimeterPerHour = (x) => x * 10;
    this.proportionToPercentage = (x) => x * 100;
    this.percentageToProportion = (x) => x / 100;
    this.metersToKilometers = (x) => x / 1000;
    this.millimeterToCentimeter = (x) => x / 10;
    this.millimeterToInches = (x) => x / 25.4;
    this.centimeterToInches = (x) => x / 2.54;
    this.centimeterToMillimeter = (x) => x * 10;
    this.cubicMetersPerSecondToCubicFeetPerSecond = (x) => x * 35.315;
    this.inchesToMillimeters = (x) => x * 25.4;
    this.inchesToMeters = (x) => x / 39.37;
    this.inchesToCentimeters = (x) => x * 2.54;

    this.converters = {
      '°C': {
        'F': this.celsiusToFahrenheit,
        'K': this.celsiusToKelvin
      },
      'C': {
        'F': this.celsiusToFahrenheit,
        'K': this.celsiusToKelvin
      },
      'F': {
        'C': this.fahrenheitToCelsius,
        'K': this.fahrenheitToKelvin
      },
      'K': {
        'C': this.kelvinToCelsius,
        '°C': this.kelvinToCelsius,
        'F': this.kelvinToFahrenheit
      },
      'M/S': {
        'kn': this.metersPerSecondToKnots,
        'km/h': this.metersPerSecondToKilometersPerHour,
        'mph': this.metersPerSecondToMilesPerHour
      },
      'm/s': {
        'kn': this.metersPerSecondToKnots,
        'km/h': this.metersPerSecondToKilometersPerHour,
        'mph': this.metersPerSecondToMilesPerHour
      },
      'mph': {
        'm/s': this.milesPerHourToMetersPerSecond
      },
      'kn': {
        'm/s': this.knotToMetersPerSecond,
        'km/h': this.knotsToKilometersPerHour
      },
      'km/h': {
        'kn': this.kilometersPerHourToKnots,
        'm/s': this.kilometersPerHourToMetersPerSecond,
        'mph': this.kilometersPerHourToMilesPerHour
      },
      'm': {
        'NM': this.metersToNauticalMiles,
        'SM': this.metersToStatuteMiles,
        'ft': this.metersToFeet,
        'in': this.metersToInches,
        'miles': this.metersToMiles
      },
      'miles': {
        'm': this.statuteMilesToMeters
      },
      'NM': {
        'm': this.nauticalMilesToMeters,
        'SM': this.nauticalMilesToStatuteMiles,
        'ft': this.nauticalMilesToFeet
      },
      'SM': {
        'm': this.statuteMilesToMeters,
        'NM': this.statuteMilesToNauticalMiles,
        'ft': this.statuteMilesToFeet
      },
      'ft': {
        'm': this.feetToMeters,
        'NM': this.feetToNauticalMiles,
        'SM': this.feetToStatuteMiles
      },
      'Pa': {
        'kPa': this.pascalsToKiloPascals,
        'hPa': this.pascalsToHectoPascals
      },
      'kPa': {
        'Pa': this.kiloPascalsToPascals,
        'hPa': this.kiloPascalsToHectoPascals
      },
      'hPa': {
        'kPa': this.hectoPascalsToKiloPascals,
        'Pa': this.hectoPascalsToPascals
      },
      'kg/m^2/s': {
        'mm/hr': this.kilogramsPerMetersSquaredPerSecond,
        'mm/h': this.kilogramsPerMetersSquaredPerSecond
      },
      'mm/h': {
        'cm/h': this.millimeterPerHourToCentimeterPerHour
      },
      'cm/h': {
        'mm/h': this.centimeterPerHourToMillimeterPerHour
      },
      'Proportion': {
        '%': this.proportionToPercentage
      },
      '1': {
        '%': this.proportionToPercentage
      },
      '%': {
        'Proportion': this.percentageToProportion
      },
      'mm': {
        'cm': this.millimeterToCentimeter,
        'in': this.millimeterToInches
      },
      'in': {
        'mm': this.inchesToMillimeters,
        'm': this.inchesToMeters,
        'cm': this.inchesToCentimeters
      },
      'cm': {
        'mm': this.centimeterToMillimeter,
        'in': this.centimeterToInches
      },
      'm^3/s': {
        'ft^3/s' : this.cubicMetersPerSecondToCubicFeetPerSecond
      }
    }
  }

  convert(value, sourceUnit, targetUnit, precision = 1) {
    const sourceSensitivityConverters = this.converters[sourceUnit];
    if (sourceSensitivityConverters) {
      const converterFunction = sourceSensitivityConverters[targetUnit];
      if (converterFunction) {
        return parseFloat(converterFunction(value).toFixed(precision));
      }
    }
    if (!isNaN(value)) {
      return parseFloat(value.toFixed(precision));
    }
    return value;
  }
}

module.exports = {
  SensitivityConverter
};