<template>
  <div class="map-legend-container">
    <div class="map-legend-items">
      <div class="map-legend-label">
        Risk
      </div>
      <div class="map-legend-item key__item--lev01">
        1
      </div>
      <div class="map-legend-item key__item--lev02">
        2
      </div>
      <div class="map-legend-item key__item--lev03">
        3
      </div>
      <div class="map-legend-item key__item--lev04">
        4
      </div>
      <div class="map-legend-item key__item--lev05">
        5
      </div>
    </div>
  </div>
</template>
<script>
  export default {

  }
</script>