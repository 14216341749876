<template>
  <div>
    <map-component :key="mapKey"></map-component>
    <cookie-notice></cookie-notice>
  </div>
</template>

<script>
import CookieNotice from '../components/CookieNotice.vue';
import MapComponent from '../components/MapComponent.vue';
export default {
  components: { MapComponent, CookieNotice },
  data() {
    return {
      mapKey: null
    }
  },
  computed: {
    panelPosition() { return this.$route.query.panelPosition ? parseInt(this.$route.query.panelPosition): 0 },
  },
  watch: {
    panelPosition(p1, p0) {
      // force reload of map
      if (p0 === 100 || p0 === '100') this.mapKey = Math.round(Math.random() * 1000);
    }
  },
}
</script>