<template>
  <div>
  <div class="dv-background js-background-image" :style="{backgroundImage: randBackgroundImagePath}">
    <div class="dv-login">
      <div class="dv-login__body dv-login__body--dark">
        <div class="dv-login__logo">
          <img src="../assets/img/branding/decisionvue-logo.svg" />
        </div>
        <div v-if="isLoading" class="dv-login__loading--spinner">
          <CubeSpinner></CubeSpinner>
          <span>{{ this.loadingDescription }}</span>
        </div>
        <div v-else-if="!loginError && !isLoading">
          <div class="lg-form-group flex-row flex-row--space-between">
            <button class="lg-button" @click="authRedirect">Log in</button>
          </div>
        </div>
        <div v-else-if="loginError && !isLoading" class="lg-form-group flex-row flex-row--space-between">
          <span class="dv-login-error-msg">Error: {{this.errorMessage}}</span>
          <a class="lg-btn lg-btn--teal" @click="loginError=false;">Ok</a>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import * as bg1 from '../assets/img/backgrounds/background1.jpg';
  import * as bg2 from '../assets/img/backgrounds/background2.jpg';
  import * as bg3 from '../assets/img/backgrounds/background3.jpg';
  import RequestBuilder from '../js/requestBuilder';
  import authService from '../js/authenticationService';
  import { CubeSpinner } from 'vue-spinners';
  const requestBuilder = new RequestBuilder();
  authService.init();
  export default {
    name: 'Login',
    components: { CubeSpinner },
    computed: {
      randInt() { return Math.floor(Math.random() * 3); },
      bgs() { return [bg1, bg2, bg3]; },
      randBackgroundImagePath() { return `url(${this.bgs[this.randInt]})` },
      serverRoute() { return this.$store.getters.serverRoute; },
    },
    data() {
      return {
        loginError: null,
        errorMessage: "",
        isLoading: false,
        loadingDescription: '',
      }
    },
    mounted() {
      this.authorizeUserCheck();
    },
    methods: {
      handleKeyup(e) {
        if (e.keyCode === 13) {
          this.login();
        }
      },
      register: function() {
        alert('This feature is not yet available');
      },
      forgotPassword: function() {
        alert('This feature is not yet available');
      },
      authRedirect() {
        authService.login();
      },
      async authorizeUserCheck() {
        let user = await authService.getUser().then(function (user) { return user; });
        if (user) {
           this.login();
        }
      },
      async configure() {
        const [request, options] = await requestBuilder.getConfigureRequest(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async baseMapConfigure() {
        const [request, options] = await requestBuilder.getBaseMapConfigureRequest(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async configureParameters() {
        const [request, options] = await requestBuilder.getConfigureParametersRequest(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async personalize() {
        const [request, options] = await requestBuilder.getPersonalizeRequest(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async riskdata() {
        const [request, options] = await requestBuilder.getRiskDataRequest(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async weatherMaps() {
        const [request, options] = await requestBuilder.getWeatherMapsRequest(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async riskMaps() {
        const [request, options] = await requestBuilder.getRiskMapsRequest(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async features() {
        const [request, options] = await requestBuilder.getFeaturesRequest(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async readOnlyRoles() {
        const [request, options] = await requestBuilder.getReadOnlyRoles(this.serverRoute);
        const response = await fetch(request, options);
        const json = await response.json();
        return json
      },
      async login() {
        try {
          this.isLoading = true;
          this.loadingDescription = "Getting Profile";
          const userProfile = await authService.getProfile().then(function (profile) { return profile; });
          const viewOnly = await this.readOnlyRoles();
          if (userProfile.role) {
            // A list is returned if there is more than one role 
            if (Array.isArray(userProfile.role)) {
              const roles = userProfile.role;
              const lowercaseRoles = roles.map(role => { return role.toLowerCase(); });
              userProfile.readOnly = lowercaseRoles.includes("clientadministrator") || lowercaseRoles.includes("administrator") ? false : true;
            } else { // otherwise a string is returned
              userProfile.readOnly = viewOnly['readOnlyRoles'].includes(userProfile.role.toLowerCase()) ? true : false;
            }
          } else {
            userProfile.readOnly = true;
          }
          this.$store.dispatch('login', userProfile);
          try {
            this.loadingDescription = "Loading configuration";
            const configuredJson = await this.configure();
            this.$store.dispatch('updateConfig', configuredJson);
            this.$store.dispatch('getUserDefaultsFromConfig', configuredJson);
            try {
              this.loadingDescription = "Loading base maps config";
              const baseMapConfig = await this.baseMapConfigure();
              this.$store.dispatch('updateBaseMapConfig', baseMapConfig);
              try {
                this.loadingDescription = "Loading parameter configuration";
                const parameterConfig = await this.configureParameters();
                this.$store.dispatch('updateParameterConfig', parameterConfig);
                try {
                  this.loadingDescription = "Loading risk data";
                  const riskdataJson = await this.riskdata();
                  this.$store.dispatch('updateRiskdata', riskdataJson);
                  try {
                    this.loadingDescription = "Loading weather maps";
                    const weatherMapsJson = await this.weatherMaps();
                    this.$store.dispatch('updateWeatherMaps', weatherMapsJson);
                    try {
                      this.loadingDescription = "Loading features";
                      const featuresJson = this.$store.getters.config.features ? await this.features() : [];
                      this.$store.dispatch('updateFeatures', featuresJson);
                      try {
                        const riskMapsJson = await this.riskMaps();
                        this.loadingDescription = "Loading risk maps";
                        this.$store.dispatch('updateRiskMaps', riskMapsJson);
                        try {
                          const unparsedPersonalizedJson = await this.personalize();
                          const personalizedJson = JSON.parse(unparsedPersonalizedJson);
                          this.$store.dispatch('personalize', personalizedJson);
                        }
                        catch(e) {
                          // pass
                          this.isLoading = false;
                          this.errorMessage = "Something went wrong loading personalized file";
                          console.warn('Error getting personalized json.', e);
                        }
                        finally {
                          this.$store.dispatch('updateInitialData');
                          this.isLoading = false;
                          this.$router.replace({ name: "map" });
                        }
                      }
                      catch(e) {
                        console.log(e);
                        this.errorMessage = "Something went wrong loading risk maps";
                        this.loginError = true;
                        this.isLoading = false;
                        this.$store.dispatch('logout');
                      }
                    }
                    catch(e) {
                      console.log(e);
                      this.errorMessage = "Something went wrong loading features";
                      this.loginError = true;
                      this.isLoading = false;
                      this.$store.dispatch('logout');
                    }
                  }
                  catch(e) {
                    console.log(e);
                    this.errorMessage = "Something went wrong loading weather maps";
                    this.loginError = true;
                    this.isLoading = false;
                    this.$store.dispatch('logout');
                  }
                }
                catch(e) {
                  console.log(e);
                  this.errorMessage = "Something went wrong loading risk data";
                  this.loginError = true;
                  this.isLoading = false;
                  this.$store.dispatch('logout');
                }
              }
              catch(e) {
                console.log(e);
                this.errorMessage = "Something went wrong loading parameter configuration";
                this.loginError = true;
                this.isLoading = false;
                this.$store.dispatch('logout');
              }
            }
            catch(e) {
              console.log(e);
              this.errorMessage = "Something went wrong loading the base map configuration";
              this.loginError = true;
              this.isLoading = false;
              this.$store.dispatch('logout');
            }
          }
          catch(e) {
            console.log(e);
            this.errorMessage = "Something went wrong loading configuration file";
            this.loginError = true;
            this.isLoading = false;
            this.$store.dispatch('logout');
          }
        }
        catch(e) {
          console.log(e);
          this.errorMessage = "Something went wrong with authentication profile";
          this.loginError = true;
          this.isLoading = false;
          this.$store.dispatch('logout');
        }
      }
    },
  }
</script>

