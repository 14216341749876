const colorMapFragmentShaderTemplate = `
  precision highp float;
  uniform sampler2D u_image;
  varying vec2 v_texture;
  uniform float u_opacity;
  uniform vec2 u_textureSize;

  #define rescale(u, v, x) (x - u)/(v - u)

  @map_colors@
  @map_cutoffs@

  vec3 map_to_color(float t) {
    @map_color_function_body@
  }

  void main() {
    if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
      discard;
    }
    vec4 imageData = texture2D(u_image, v_texture, 1.0);
    float value = imageData[0];
    gl_FragColor = vec4(map_to_color(value), u_opacity);
  }
`;

const colorMapBinaryFragmentShaderTemplate = `
  precision highp float;
  uniform sampler2D u_image;
  varying vec2 v_texture;
  uniform float u_opacity;
  uniform vec2 u_textureSize;

  void main() {
    if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
      discard;
    }
    vec4 imageData = texture2D(u_image, v_texture, 1.0);
    float value = imageData[0];
    if (value < @normalized_lower_bound || value > @normalized_upper_bound) {
      discard;
    }
    gl_FragColor = vec4(vec3(1.0, 0.0, 0.0), u_opacity);
  }
`;

const colorMapVectorFragmentShaderTemplate = `
  precision highp float;
  uniform sampler2D u_image;
  varying vec2 v_texture;
  uniform float u_opacity;
  uniform vec2 u_textureSize;

  #define rescale(u, v, x) (x - u)/(v - u)

  @map_colors@
  @map_cutoffs@

  vec3 map_to_color(float t) {
    @map_color_function_body@
  }

  void main() {
    if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
      discard;
    }
    
    vec4 imageData = texture2D(u_image, v_texture, 1.0);
    float u_norm = (imageData[0] - 0.5)/0.5;
    float v_norm = (imageData[1] - 0.5)/0.5;
    float value = sqrt(u_norm*u_norm + v_norm*v_norm)/1.41;
    gl_FragColor = vec4(map_to_color(value), u_opacity);
  }
`;

const colorMapVectorMaskFragmentShaderTemplate = `
  precision highp float;
  uniform sampler2D u_image;
  varying vec2 v_texture;
  uniform float u_opacity;
  uniform vec2 u_textureSize;

  #define rescale(u, v, x) (x - u)/(v - u)

  @map_colors@
  @map_cutoffs@

  vec3 map_to_color(float t) {
    @map_color_function_body@
  }

  void main() {
    if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
      discard;
    }

    vec4 imageData = texture2D(u_image, v_texture, 1.0);
    if (imageData[2] > 0.2) {
      discard;
    }
    float u_norm = (imageData[0] - 0.5)/0.5;
    float v_norm = (imageData[1] - 0.5)/0.5;
    float value = sqrt(u_norm*u_norm + v_norm*v_norm)/1.41;
    gl_FragColor = vec4(map_to_color(value), u_opacity);
  }
`;

const colorMapLandMaskFragmentShaderTemplate = `
  precision highp float;
  uniform sampler2D u_image;
  varying vec2 v_texture;
  uniform float u_opacity;
  uniform vec2 u_textureSize;

  #define rescale(u, v, x) (x - u)/(v - u)

  @map_colors@
  @map_cutoffs@

  vec3 map_to_color(float t) {
    @map_color_function_body@
  }

  void main() {
    if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
      discard;
    }
    vec4 imageData = texture2D(u_image, v_texture, 1.0);
    if (imageData[2] > 0.45) {
      discard;
    }
    float value = imageData[0];
    gl_FragColor = vec4(map_to_color(value), u_opacity);
  }
`;

const colorMapMaskFragmentShaderTemplate = `
precision highp float;
uniform sampler2D u_image;
varying vec2 v_texture;
uniform float u_opacity;
uniform vec2 u_textureSize;

#define rescale(u, v, x) (x - u)/(v - u)

@map_colors@
@map_cutoffs@

vec3 map_to_color(float t) {
  @map_color_function_body@
}

void main() {
  if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
    discard;
  }
  vec4 imageData = texture2D(u_image, v_texture, 1.0);
  if (imageData[2] > 0.2) {
    discard;
  }
  float value = imageData[0];
  gl_FragColor = vec4(map_to_color(value), u_opacity);
}
`;

const colorMapDiscreteTemplate = `
precision highp float;
uniform sampler2D u_image;
varying vec2 v_texture;
uniform float u_opacity;
uniform vec2 u_textureSize;

#define rescale(u, v, x) (x - u)/(v - u)

@map_colors@
@map_cutoffs@

vec3 map_to_color(float t) {
  if (t < 0.2) {
    return vec3(0.0, 1.0, 0.0);
  } else if (t < 0.4) {
    return vec3(1.0, 1.0, 0.0);
  } else {
    return vec3(1.0, 0.0, 0.0);
  }
}

void main() {
  if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
    discard;
  }
  vec4 imageData = texture2D(u_image, v_texture, 1.0);
  if (imageData[2] > 0.2) {
    discard;
  }
  float value = imageData[0];
  gl_FragColor = vec4(map_to_color(value), u_opacity);
}
`;

const colorMapThresholdFragmentShaderTemplate = `
  precision highp float;
  uniform sampler2D u_image;
  varying vec2 v_texture;
  uniform float u_opacity;
  uniform vec2 u_textureSize;

  #define rescale(u, v, x) (x - u)/(v - u)

  @map_colors@
  @map_cutoffs@

  vec3 map_to_color(float t) {
    @map_color_function_body@
  }

  void main() {
    if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
      discard;
    }
    vec4 imageData = texture2D(u_image, v_texture, 1.0);
    if (imageData[0] < 0.01) {
      discard;
    }
    float value = imageData[0];
    gl_FragColor = vec4(map_to_color(value), u_opacity);
  }
`;

const cloudFragmentShaderTemplate = `
precision highp float;
uniform sampler2D u_image;
varying vec2 v_texture;
uniform float u_opacity;
uniform vec2 u_textureSize;

#define rescale(u, v, x) (x - u)/(v - u)

void main() {
  vec4 imageData = texture2D(u_image, v_texture, 1.0);
  gl_FragColor = vec4(1.0, 1.0, 1.0, 0.5*imageData[0]);
}
`
const thresholdCutoffFragmentShaderTemplate = `
  precision highp float;
  uniform sampler2D u_image;
  varying vec2 v_texture;
  uniform float u_opacity;
  uniform vec2 u_textureSize;

  #define rescale(u, v, x) (x - u)/(v - u)

  @map_colors@
  @map_cutoffs@

  vec3 map_to_color(float t) {
    @map_color_function_body@
  }

  void main() {
    if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
      discard;
    }
    vec4 imageData = texture2D(u_image, v_texture, 1.0);
    float value = imageData[0];

    if (value > @last_threshold) {
      discard;
    }

    gl_FragColor = vec4(map_to_color(value), u_opacity);
  }
`

const recolorVertexShader = `
attribute vec2 a_position;
attribute vec2 a_texCoord;

uniform vec2 u_resolution;
uniform vec2 u_lowerLeftBoundXY;
uniform vec2 u_upperRightBoundXY;

varying vec2 v_texture;

void main() {
  // convert the rectangle from pixels to 0.0 to 1.0
  vec2 zeroToOne = a_position / u_resolution;

  // convert from 0->1 to 0->2
  vec2 zeroToTwo = zeroToOne * 2.0;

  // convert from 0->2 to -1->+1 (clipspace)
  vec2 clipSpace = zeroToTwo - 1.0;

  gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);

  // pass the texCoord to the fragment shader
  // The GPU will interpolate this value between points.
  v_texture = a_texCoord;
}
`;


const particleVertexShader = `
attribute float a_noise;
attribute vec2 a_origin;
attribute vec2 a_position;

varying vec2 v_position;
varying vec2 v_origin;
varying float v_noise;
 
void main() {
  v_origin = a_origin;
  v_position = a_position;
  v_noise = a_noise;
  gl_Position = vec4(a_position * vec2(1, -1), 0, 1);
}
`;

const currentParticleFragmentShader = `
`;

const waveParticleFragmentShader = `
`;

const windParticleFragmentShader = `
// fragment shaders don't have a default precision so we need
// to pick one. mediump is a good default. It means "medium precision"
precision mediump float;

varying float v_noise;
varying vec2 v_origin;
varying vec2 v_position;

uniform float u_step_number;
 
void main() {
  float delta_x = v_position[0] - v_origin[0];
  float delta_y = v_position[1] - v_origin[1];
  float distance = sqrt(delta_x * delta_x + delta_y * delta_y);
  float current_step = mod(u_step_number + v_noise, 0.1);
  if (delta_y > 0.01) {
    discard;
  }
  if (delta_y < -0.01) {
    discard;
  }
  if (distance > (0.022 + current_step)) {
    discard;
  } else if (distance < (current_step + 0.02)) {
    discard;
  }
  gl_FragColor = vec4(1, 1, 1, 0.5);
}
`;

export function getRecolorVertexShader() {
  return recolorVertexShader;
}

const fragmentShaderTemplateMap = {
  'cloud': cloudFragmentShaderTemplate,
  'thresholdCutoff': thresholdCutoffFragmentShaderTemplate,
  'default': colorMapFragmentShaderTemplate,
  'discrete': colorMapDiscreteTemplate,
  'mask': colorMapMaskFragmentShaderTemplate,
  'threshold': colorMapThresholdFragmentShaderTemplate,
  'vector': colorMapVectorFragmentShaderTemplate,
  'vector-mask': colorMapVectorMaskFragmentShaderTemplate,
  'mask-land': colorMapLandMaskFragmentShaderTemplate
};

const particleFragmentShaderTemplateMap = {
  'current': currentParticleFragmentShader,
  'wave': waveParticleFragmentShader,
  'wind': windParticleFragmentShader
};

const particleVertexShaderTemplateMap = {
  'particle': particleVertexShader
};

export function getParticleFragmentShader(templateName) {
  if (templateName && templateName in particleFragmentShaderTemplateMap) {
    return particleFragmentShaderTemplateMap[templateName];
  }
  return null;
}

export function getParticleVertexShader(templateName) {
  if (templateName && templateName in particleVertexShaderTemplateMap) {
    return particleVertexShaderTemplateMap[templateName];
  }
  return null;
}

export function transpileColorMapFragmentShader(unitColorArray, thresholdArray, templateName) {
  let template = colorMapFragmentShaderTemplate;
  if (templateName && templateName in fragmentShaderTemplateMap) {
    template = fragmentShaderTemplateMap[templateName];
  }
  const keyRange = [...Array(unitColorArray.length).keys()]
  const mapColors = keyRange.map((i) => {
    const currentColor = unitColorArray[i];
    return `
  const vec3 color${i} = vec3(${currentColor[0].toFixed(1)},${currentColor[1].toFixed(1)},${currentColor[2].toFixed(1)});`;
  }).join('');

  const mapCutoffs = keyRange.map((i) => {
    return `
  const float color_u${i} = ${thresholdArray[i].toFixed(3)};`
  }).join('');

  const mapColorFunctionBody = keyRange.slice(0, keyRange.length - 1).reduce((currentBody, i) => {
    const newRange = `+ (mix(color${i}, color${i+1}, rescale(color_u${i}, color_u${i+1}, t))`;
    const previousRange = (i !== 0 ? ` - mix(color${i-1}, color${i}, rescale(color_u${i-1}, color_u${i}, t))) * step(color_u${i}, t)` : `)`);
    return currentBody + `
    ${newRange}${previousRange}`}, `return `) + ';';

  const lastThreshold = thresholdArray[thresholdArray.length - 1];
 
  return template
    .replace('@map_colors@', mapColors)
    .replace('@map_cutoffs@', mapCutoffs)
    .replace('@map_color_function_body@', mapColorFunctionBody)
    .replace('@last_threshold', lastThreshold);
}

export function normalizeAndTranspileColorMapBinaryFragmentShader(minValue, lowerBound, upperBound, maxValue) {
  const range = maxValue - minValue;
  const normalizedLowerBound = (lowerBound - minValue)/range;
  const normalizedUpperBound = (upperBound - minValue)/range;

  return transpileColorMapBinaryFragmentShader(normalizedLowerBound, normalizedUpperBound);
}

export function transpileColorMapBinaryFragmentShader(normalizedLowerBound, normalizedUpperBound) {
  let template = colorMapBinaryFragmentShaderTemplate;
  
  return template
    .replace('@normalized_lower_bound', normalizedLowerBound.toFixed(3))
    .replace('@normalized_upper_bound', normalizedUpperBound.toFixed(3));
}

export function createShader(gl, type, source) {
  let shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  let success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if (success) {
    return shader;
  }

  gl.deleteShader(shader);
  return null;
}

export function initializeProgram(gl, shaders) {
  let program = gl.createProgram();
  for (var i = 0; i < shaders.length; i++) {
    gl.attachShader(program, shaders[i]);
  }
  gl.linkProgram(program);
  let success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (success) {
    gl.useProgram(program);
    return program;
  }
  gl.deleteProgram(program);
  return null;
}
