var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-dropdown" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "user-dropdown-content" }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.nav2profile()
            }
          }
        },
        [_vm._v("Profile")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.nav2settings()
            }
          }
        },
        [_vm._v("Settings")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.nav2Policy()
            }
          }
        },
        [_vm._v("Policies")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.logout()
            }
          }
        },
        [_vm._v("Logout")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-dropbtn" }, [
      _c("i", { staticClass: "fas fa-user" }),
      _c("span", { staticClass: "user-name" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }