const parameterConstants = {
  'TMP': {
    colorMap: 'ColdToWarm',
    displayName: 'Temperature',
    displayUnits: '°C',
    particleShaderFragmentTemplate: 'wind',
    particleVertexFragmentTemplate: 'particle',
  },
  'KRIG': {
    colorMap: 'ColdToWarm',
    displayName: 'Current Conditions',
    displayUnits: '°C'
  },
  'DPT': {
    colorMap: 'ColdToWarm',
    displayName: 'Dewpoint',
    displayUnits: 'C'
  },
  'RH': {
    colorMap: 'RelativeHumidity',
    displayName: 'Relative Humidity',
    displayUnits: '%'
  },
  'PRATE': {
    colorMap: 'Precipitation',
    fragmentTemplate: 'threshold',
    displayName: 'Precipitation',
    displayUnits: 'kg/m^2/hr'
  },
  'R1H': {
    colorMap: 'RainfallRate',
    fragmentTemplate: 'threshold',
    displayName: 'Precipitation Rate',
    displayUnits: 'kg/m^2/hr'
  },
  'RRAIN1H': {
    colorMap: 'RainfallRate',
    fragmentTemplate: 'threshold',
    displayName: 'Rainfall Rate',
    displayUnits: 'mm/hr'
  },
  'RFRZR1H': {
    colorMap: 'FreezingRainfallRate',
    fragmentTemplate: 'threshold',
    displayName: 'Freezing Rainfall Rate',
    displayUnits: 'mm/hr'
  },
  'RICEP1H': {
    colorMap: 'IcePelletsRate',
    fragmentTemplate: 'threshold',
    displayName: 'Ice Pellets Rate',
    displayUnits: 'mm/hr'
  },
  'RSNOW1H': {
    colorMap: 'SnowfallRate',
    fragmentTemplate: 'threshold',
    displayName: 'Snowfall Rate',
    displayUnits: 'cm/hr'
  },
  'TCDC': {
    colorMap: 'Sky',
    displayName: 'Total Cloud Cover',
    fragmentTemplate: 'cloud',
    displayUnits: '%'
  },
  'ICEC': {
    colorMap: 'IceConcentration',
    fragmentTemplate: 'threshold',
    displayName: 'Ice Concentration',
    displayUnits: '%'
  },
  'SWHGT': {
    colorMap: 'Waves',
    fragmentTemplate: 'mask',
    displayName: 'Swell Height',
    displayUnits: 'm'
  },
  'WVHGT': {
    colorMap: 'Waves',
    fragmentTemplate: 'mask',
    displayName: 'Wave Height',
    displayUnits: 'm'
  },
  'HTSGW': {
    colorMap: 'Waves',
    fragmentTemplate: 'mask',
    displayName: 'Significant Wave Height',
    displayUnits: 'm'
  },
  'UGRD-VGRD': {
    arrowLayer: true,
    colorMap: 'Winds',
    displayName: 'Wind Speed',
    fragmentTemplate: 'vector',
    particleShaderFragmentTemplate: 'wind',
    particleVertexFragmentTemplate: 'particle',
    particleLayer: true,
    displayUnits: 'km/h'
  },
  'FWIND': {
    colorMap: 'Winds',
    displayName: 'Flight Level Winds',
    fragmentTemplate: 'vector-mask',
    displayUnits: 'km/h'
  },
  'GUST': {
    colorMap: 'Gusts',
    displayName: 'Gust',
    displayUnits: 'km/h'
  },
  'RADAR': {
    colorMap: 'Radar',
    displayName: 'Reflectivity',
    fragmentTemplate: 'mask',
    displayUnits: 'dB'
  },
  'VIS': {
    colorMap: 'Visibility',
    displayName: 'Visibility',
    fragmentTemplate: 'thresholdCutoff',
    displayUnits: 'km'
  },
  'PRES': {
    colorMap: 'Pressure',
    displayName: 'Pressure',
    displayUnits: 'kPa'
  },
  'FWI': {
    colorMap: 'FWI',
    displayName: 'Fire Weather Index (FWI)',
    fragmentTemplate: 'mask-land',
    displayUnits: ''
  },
  'ISI': {
    colorMap: 'ISI',
    displayName: 'Initial Spread Index (ISI)',
    fragmentTemplate: 'mask-land',
    displayUnits: ''
  }
};


module.exports = {
  parameterConstants
};
