class DummyColorMap {
  toRGB() {
    return [];
  }

  toUnitRGB() {
    return [];
  }

  toHex() {
    return [];
  }

  getThresholds() {
    return [];
  }
}

class ColorMap {
  constructor(config) {
    if (!('colorArray' in config)) {
      throw Error('Must provide color array when instantiating color map');
    }
    if (config.colorArray.length < 2) {
      throw Error('Must provide color array of at least length 2 when instantiating a color map');
    }
    this.colorArray = config.colorArray;
    this.colorThresholds = config.colorThresholds || this._getDefaultThresholds(config.colorArray.length);
  }

  toRGB() {
    return this.colorArray.map((item) => {
      return this._hexToRgb(item);
    });
  }

  toUnitRGB() {
    return this.colorArray.map((item) => {
      return this._hexToUnitRgb(item);
    });
  }

  toHex() {
    return this.colorArray;
  }

  getThresholds() {
    return this.colorThresholds;
  }

  _hexToRgb(hexString) {
    if (!hexString.startsWith('#') || hexString.length !== 7) {
      throw 'Cannot convert hex to rgb - invalid string';
    }
    return [...Array(3).keys()].map((index) => {
      return parseInt(hexString.slice(1 +index*2, 1 + index*2 + 2), 16);
    });
  }

  _hexToUnitRgb(hexString) {
    if (!hexString.startsWith('#') || hexString.length !== 7) {
      throw 'Cannot convert hex to unit rgb - invalid string';
    }
    const rgb = this._hexToRgb(hexString);
    return rgb.map((nextColor) => {
      return nextColor/255.0;
    });
  }

  _rgbToHex(rgbArray) {
    if (rgbArray.length !== 3) {
      throw 'Cannot convert rgb to hex - invalid length.';
    }
    return rgbArray.reduce((hexCode, nextColor) => {
      return hexCode + (nextColor & 255).toString(16).padStart(2, '0');
    }, '#');
  }

  _unitRgbToHex(unitRgbArray) {
    if (unitRgbArray.length !== 3) {
      throw 'Cannot convert unit rgb to hex - invalid length';
    }
    const rgbArray = unitRgbArray.map((unitColor) => {
      return Math.round(unitColor * 255) & 255;
    });
    return this._rgbToHex(rgbArray);
  }

  _getDefaultThresholds(arrayLength) {
    const range = [...Array(arrayLength).keys()];
    return range.map((i) => {
      return i/(arrayLength-1);
    });
  }
}

class ColorMapCollection {
  constructor() {
    this.maps = {
      'Winds': {
        'colorArray': [
          '#0000ff',
          '#00ffff',
          '#00ffaa',
          '#00ff00',
          '#ffff00',
          '#ff8800',
          '#ff0000'
        ],
        'thresholdConfig': {
          'max': 254,
          'min': 0,
          'values': [
            5,
            10,
            20,
            30,
            40,
            60,
            80
          ]
        },
        'units': 'km/h'
      },
      'Gusts': {
        'colorArray': [
          '#0000ff',
          '#00ffff',
          '#00ffaa',
          '#00ff00',
          '#ffff00',
          '#ff8800',
          '#ff0000'
        ],
        'thresholdConfig': {
          'max': 108,
          'min': 0,
          'values': [
            5,
            10,
            20,
            30,
            40,
            60,
            80
          ]
        },
        'units': 'km/h'
      },
      'ColdToWarm': {
        'colorArray': [
          '#4069e2',
          '#00ffff',
          '#8cff00',
          '#ffff00',
          '#ff8c00',
          '#ff0000',
          '#800000'
        ],
        'thresholdConfig': {
          'max': 41.85,
          'min': -23.15,
          'values': [
            -20,
            -10,
            0,
            10,
            20,
            30,
            40
          ]
        },
        'units': '°C'
      },
      'RelativeHumidity': {
        'colorArray': [
          '#4069e2',
          '#00ffff',
          '#00ff7d',
          '#8cff00',
          '#ffff00',
          '#ff8c00',
          '#ff0000',
          '#800000'
        ],
        'thresholdConfig': {
          'max': 100,
          'min': 0,
          'values': [
            0,
            15,
            30,
            45,
            60,
            75,
            90,
            100
          ]
        },
        'units': '%'
      },
      'Sky': {
        'customLegend': {
          thresholds: [
            '0',
            '20',
            '40',
            '60',
            '80',
            '100'
          ],
          colorArray: [
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0.1)',
            'rgba(255, 255, 255, 0.2)',
            'rgba(255, 255, 255, 0.3)',
            'rgba(255, 255, 255, 0.4)',
            'rgba(255, 255, 255, 0.5)'
          ]
        },
        'units': '%'
      },
      'Precipitation': {
        'colorArray': [
          '#00ffff',
          '#0000ff',
          '#90ee90',
          '#228b22',
          '#ffff00',
          '#ffa500',
          '#ff0000',
          '#800080'
        ],
        'thresholdConfig': {
          'max': 0.0001,
          'min': 0,
          'values': [
            0,
            0.000010,
            0.000020,
            0.000030,
            0.000040,
            0.000060,
            0.000080,
            0.0001
          ]
        },
        'units': 'mm/hr'
      },
      'RainfallRate': {
        'colorArray': [
          '#00ffff',
          '#0000ff',
          '#90ee90',
          '#228b22',
          '#ffff00',
          '#ffa500',
          '#ff0000',
          '#800080'
        ],
        'thresholdConfig': {
          'max': 25,
          'min': 0,
          'values': [
            0,
            1,
            2,
            3,
            5,
            10,
            15,
            25
          ]
        },
        'units': 'mm/hr'
      },
      'FreezingRainfallRate': {
        'colorArray': [
          '#00ffff',
          '#0000ff',
          '#90ee90',
          '#228b22',
          '#ffff00',
          '#ffa500',
          '#ff0000',
          '#800080'
        ],
        'thresholdConfig': {
          'max': 25,
          'min': 0,
          'values': [
            0,
            1,
            2,
            3,
            5,
            10,
            15,
            25
          ]
        },
        'units': 'mm/hr'
      },
      'IcePelletsRate': {
        'colorArray': [
          '#00ffff',
          '#0000ff',
          '#90ee90',
          '#228b22',
          '#ffff00',
          '#ffa500',
          '#ff0000',
          '#800080'
        ],
        'thresholdConfig': {
          'max': 25,
          'min': 0,
          'values': [
            0,
            1,
            2,
            3,
            5,
            10,
            15,
            25
          ]
        },
        'units': 'mm/hr'
      },
      'SnowfallRate': {
        'colorArray': [
          '#00ffff',
          '#0000ff',
          '#90ee90',
          '#228b22',
          '#ffff00',
          '#ffa500',
          '#ff0000',
          '#800080'
        ],
        'thresholdConfig': {
          'max': 25,
          'min': 0,
          'values': [
            0,
            1,
            2,
            3,
            5,
            10,
            15,
            25
          ]
        },
        'units': 'mm/hr'
      },
      'IceConcentration': {
        'colorArray': [
          '#00ffff',
          '#0000ff',
          '#90ee90',
          '#228b22',
          '#800080',
          '#ff00ff'
        ],
        'thresholdConfig': {
          'max': 100,
          'min': 0,
          'values': [
            1,
            20,
            40,
            60,
            80,
            100
          ]
        },
        'units': '%'
      },
      'Waves': {
        'colorArray': [
          '#0000ff',
          '#00ffff',
          '#00ff00',
          '#9400d3'
        ],
        'thresholdConfig': {
          'max': 10,
          'min': 0,
          'values': [
            1,
            2,
            4,
            8
          ]
        },
        'units': 'm'
      },
      'Radar': {
        'colorArray': [
          '#00ffff',
          '#0000ff',
          '#90ee90',
          '#228b22',
          '#ffff00',
          '#ffa500',
          '#ff0000',
          '#800080'
        ],
        'thresholdConfig': {
          'max': 70,
          'min': 0,
          'values': [
            0,
            10,
            20,
            30,
            40,
            50,
            60,
            70
          ]
        },
        'units': 'dBZ'
      },
      'RiskMap': {
        'colorArray': [
          '#94d864',
          '#eff471',
          '#fdc94e',
          '#ee6b3f',
          '#d32a27'
        ],
        'thresholdConfig': {
          'max': 5,
          'min': 1,
          'values': [
            1,
            2,
            3,
            4,
            5
          ]
        },
        'units': ''
      },
      'Visibility': {
        'colorArray': [
          '#ff00ff',
          '#800080',
          '#ff0000',
          '#ff5349',
          '#ffa500'
        ],
        'thresholdConfig': {
          'max': 25,
          'min': 0,
          'values': [
            0.5,
            1,
            2,
            4,
            8
          ]
        },
        'units': 'NM'
      },
      'Pressure': {
        'colorArray': [
          '#4069e2',
          '#00ffff',
          '#00ff7d',
          '#8cff00',
          '#ffff00',
          '#ff8c00',
          '#ff0000',
          '#800000'
        ],
        'thresholdConfig': {
          'max': 1050,
          'min': 950,
          'values': [
            960,
            970,
            980,
            990,
            1000,
            1010,
            1020,
            1030
          ]
        },
        'units': 'hPa'
      },
      'FWI': {
        'colorArray': [
          '#0c2eed',
          '#50b4f2',
          '#a8d9f7',
          '#fffc96',
          '#fcd66d',
          '#f7ae39',
          '#ff8c21',
          '#e34a2b',
          '#c9280c',
          '#8c1d0a',
          '#591408'
        ],
        'thresholdConfig': {
          'max': 100,
          'min': 0,
          'values': [
            0,
            10,
            20,
            30,
            40,
            50,
            60,
            70,
            80,
            90,
            100
          ]
        },
        'units': ''
      },
      'ISI': {
        'colorArray': [
          '#0c2eed',
          '#50b4f2',
          '#a8d9f7',
          '#fffc96',
          '#fcd66d',
          '#f7ae39',
          '#ff8c21',
          '#e34a2b',
          '#c9280c',
          '#8c1d0a',
          '#591408'
        ],
        'thresholdConfig': {
          'max': 100,
          'min': 0,
          'values': [
            0,
            10,
            20,
            30,
            40,
            50,
            60,
            70,
            80,
            90,
            100
          ]
        },
        'units': ''
      }
    }
  }

  map(mapName) {
    if (mapName in this.maps) {
      if ('customLegend' in this.maps[mapName]) {
        return new DummyColorMap({});
      }
      return new ColorMap({
        colorArray: this.maps[mapName].colorArray ? this.maps[mapName].colorArray: [],
        colorThresholds: this.maps[mapName].thresholdConfig ? this.thresholdConfigToArray(this.maps[mapName].thresholdConfig) : []
      });
    }
    return null;
  }

  mapDetails(mapName) {
    if (mapName in this.maps) {
      return this.maps[mapName];
    }
    return null;
  }

  getLeafletLegend(mapName) {
    if (mapName in this.maps) {
      if (this.maps[mapName].thresholdConfig) {
        return {
          thresholds: this.maps[mapName].thresholdConfig.values,
          gradient: `linear-gradient(to right, ${this.maps[mapName].colorArray.join(',')})`,
          min: this.maps[mapName].thresholdConfig.min,
          max: this.maps[mapName].thresholdConfig.max
        }
      }
      if (this.maps[mapName].customLegend) {
        return {
          thresholds: this.maps[mapName].customLegend.thresholds,
          gradient: `linear-gradient(to right, ${this.maps[mapName].customLegend.colorArray.join(',')})`,
          min: this.maps[mapName].thresholdConfig.min,
          max: this.maps[mapName].thresholdConfig.max
        }
      }
      return null;
    }
    return null;
  }

  thresholdConfigToArray(thresholdConfig) {
    const min = thresholdConfig.min;
    const max = thresholdConfig.max;
    return thresholdConfig.values.map((value) => {
      return (value - min)/(max - min);
    });
  }
}

module.exports = {
  ColorMap,
  ColorMapCollection
};