var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "loading-spinner" },
          [
            _c("div", { staticClass: "loading-spinner__text" }, [
              _vm._v("\n      Loading risk summary data\n    ")
            ]),
            _vm._v(" "),
            _c("CubeSpinner")
          ],
          1
        )
      : _vm.failedLoading
      ? _c("div", [_c("span", [_vm._v(_vm._s(_vm.errorMsg))])])
      : _c("div", [
          _c("div", { staticClass: "header__text" }, [
            _vm._v(_vm._s(_vm.regionName))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wg-row--timeline-timezone" }, [
            _vm._v("Time (" + _vm._s(_vm.regionTimezoneAbbr) + ")")
          ]),
          _vm._v(" "),
          _c("main", { staticClass: "content" }, [
            _c("table", { staticClass: "rd-table" }, [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("th", { staticClass: "rd-table__filter-header" }, [
                      _c("i", {
                        staticClass: "fas fa-filter",
                        class: _vm.getFilterClass(),
                        attrs: { title: "Toggle showing of starred sites" },
                        on: {
                          click: function($event) {
                            _vm.showOnlyStarredSiteIds = !_vm.showOnlyStarredSiteIds
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.summaryDates, function(dateTime) {
                      return _c(
                        "th",
                        { key: dateTime, staticClass: "rd-table__date-header" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(dateTime) +
                              "\n            "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.siteHazardSummariesToShow, function(site) {
                  return _c(
                    "tr",
                    { key: site.id },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "rd-table__location-cell",
                          attrs: { "data-label": "Location" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "rd-table__location-text" },
                            [_c("span", [_c("b", [_vm._v(_vm._s(site.name))])])]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "rd-table__filter-cell" }, [
                        _c("div", { staticClass: "rd-table__filter-text" }, [
                          _c("div", [
                            _c("i", {
                              staticClass: "fas fa-bell",
                              class: _vm.getAlertClass(site.id),
                              attrs: {
                                title: "Click to toggle alert.",
                                id: site.id
                              },
                              on: {
                                click: function($event) {
                                  return _vm.processAlertEvent($event)
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("i", {
                              staticClass: "fas fa-star",
                              class: _vm.getStarClass(site.id),
                              attrs: {
                                title: "Click to toggle star.",
                                id: site.id
                              },
                              on: {
                                click: function($event) {
                                  return _vm.processStarEvent($event)
                                }
                              }
                            })
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._l(site.dailyHazardSummaries, function(dailySummary) {
                        return _c(
                          "td",
                          {
                            key: dailySummary.dateTime,
                            attrs: { "data-label": dailySummary.dateTime }
                          },
                          [
                            _c(
                              "div",
                              {
                                class:
                                  "rd-table__risk-cell rd-table__risk-cell--lev0" +
                                  dailySummary.maxRiskLevel,
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  "data-html": "true"
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "rd-table__risk-info",
                                    attrs: { role: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.nav2site(site.id, _vm.region)
                                      }
                                    }
                                  },
                                  [
                                    Object.keys(dailySummary.hazards).length > 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "rd-table__risk-text"
                                          },
                                          [_vm._v("Multiple events")]
                                        )
                                      : Object.keys(dailySummary.hazards)
                                          .length == 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "rd-table__risk-text"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                dailySummary.hazards[
                                                  Object.keys(
                                                    dailySummary.hazards
                                                  )[0]
                                                ].name
                                              )
                                            )
                                          ]
                                        )
                                      : _c("span", {
                                          staticClass: "rd-table__risk-text"
                                        }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "rd-table__risk-number" },
                                      [
                                        _vm._v(
                                          _vm._s(dailySummary.maxRiskLevel)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "rd-table__location-header" }, [
      _c("b", [_vm._v("Location")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }