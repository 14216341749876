var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "dv-background js-background-image",
        style: { backgroundImage: _vm.randBackgroundImagePath }
      },
      [
        _c("div", { staticClass: "dv-login" }, [
          _c("div", { staticClass: "dv-login__body dv-login__body--dark" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "dv-login__loading--spinner" },
                  [
                    _c("CubeSpinner"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(this.loadingDescription))])
                  ],
                  1
                )
              : !_vm.loginError && !_vm.isLoading
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "lg-form-group flex-row flex-row--space-between"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "lg-button",
                          on: { click: _vm.authRedirect }
                        },
                        [_vm._v("Log in")]
                      )
                    ]
                  )
                ])
              : _vm.loginError && !_vm.isLoading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "lg-form-group flex-row flex-row--space-between"
                  },
                  [
                    _c("span", { staticClass: "dv-login-error-msg" }, [
                      _vm._v("Error: " + _vm._s(this.errorMessage))
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "lg-btn lg-btn--teal",
                        on: {
                          click: function($event) {
                            _vm.loginError = false
                          }
                        }
                      },
                      [_vm._v("Ok")]
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dv-login__logo" }, [
      _c("img", {
        attrs: { src: require("../assets/img/branding/decisionvue-logo.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }