import L from 'leaflet';
import 'leaflet-canvaslayer-field';

L.ExtendedVectorFieldAnim = L.CanvasLayer.VectorFieldAnim.extend({
  bringToFront: function () {
    if (this._map) {
			L.DomUtil.toFront(this._canvas);
		}
		return this;
  }
});

export function extendedVectorFieldAnim(field, options) {
  return new L.ExtendedVectorFieldAnim(field, options);
}