var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "control-container" }, [
    _c(
      "div",
      {
        staticClass: "control-container__button",
        class: { open: _vm.open },
        on: {
          click: function($event) {
            return _vm.onControlClick($event)
          }
        }
      },
      [_c("i", { staticClass: "fas fa-layer-group" })]
    ),
    _vm._v(" "),
    _vm.open
      ? _c("div", { staticClass: "control-container__panel" }, [
          _c(
            "div",
            { staticClass: "control-container__panel-inner" },
            [
              _vm._l(_vm.heatMapsGroupedByRegion, function(heatMapGroup, key) {
                return _c(
                  "div",
                  { staticClass: "control-container__section" },
                  [
                    _c(
                      "div",
                      { staticClass: "control-container__section-heading" },
                      [_vm._v(_vm._s(_vm.regions[key].name) + " Risk Maps")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-container__item" }, [
                      _c(
                        "div",
                        { staticClass: "control-container__item-row" },
                        [
                          _c(
                            "select",
                            {
                              staticClass: "control-container__item-select",
                              domProps: {
                                value:
                                  _vm.activeHeatMap &&
                                  _vm.activeHeatMap.metadata.key in heatMapGroup
                                    ? _vm.activeHeatMap.metadata.key
                                    : ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onSelectChange($event)
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("No risk map")
                              ]),
                              _vm._v(" "),
                              _vm._l(heatMapGroup, function(map) {
                                return map.variable in _vm.hazards &&
                                  _vm.satisfyFavouritePreference(
                                    map.variable,
                                    key
                                  )
                                  ? _c(
                                      "option",
                                      { domProps: { value: map.key } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.hazards[map.variable].name
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "control-container__section" }, [
                _c(
                  "div",
                  { staticClass: "control-container__section-heading" },
                  [_vm._v("Weather Color Maps")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "control-container__item" }, [
                  _c("div", { staticClass: "control-container__item-row" }, [
                    _c(
                      "select",
                      {
                        staticClass: "control-container__item-select",
                        domProps: {
                          value:
                            _vm.activeHeatMap &&
                            _vm.activeHeatMap.metadata.key in _vm.weatherMaps
                              ? _vm.activeHeatMap.metadata.key
                              : ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.onSelectChange($event)
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("No weather color map")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.weatherMaps, function(map) {
                          return map.parameter in _vm.weatherParameterInfo
                            ? _c(
                                "option",
                                { key: map.key, domProps: { value: map.key } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.weatherParameterInfo[map.parameter]
                                          .displayName
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e()
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _vm.activeHeatMap &&
                  _vm.activeHeatMap.metadata.key in _vm.weatherMaps &&
                  !_vm.activeHeatMap.parameter.customLegend
                    ? _c(
                        "div",
                        { staticClass: "control-container__item-row" },
                        [
                          _vm._v("\n            Opacity: "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.opacity,
                                expression: "opacity"
                              }
                            ],
                            staticClass:
                              "control-container__feature-item-number-input",
                            attrs: {
                              type: "number",
                              min: "0",
                              max: "1",
                              step: "0.1"
                            },
                            domProps: { value: _vm.opacity },
                            on: {
                              change: function($event) {
                                return _vm.onOpacityChange()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.opacity = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control-container__section" }, [
                _c(
                  "div",
                  { staticClass: "control-container__section-heading" },
                  [_vm._v("Weather Particles")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "control-container__item" }, [
                  _c("div", { staticClass: "control-container__item-row" }, [
                    _c(
                      "select",
                      {
                        staticClass: "control-container__item-select",
                        domProps: {
                          value:
                            _vm.activeParticleLayer &&
                            _vm.activeParticleLayer.metadata.key in
                              _vm.weatherMaps
                              ? _vm.activeParticleLayer.metadata.key
                              : ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.onSelectParticleChange($event)
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("No weather particles")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.availableParticleLayers, function(map) {
                          return map.parameter in _vm.weatherParameterInfo
                            ? _c(
                                "option",
                                { key: map.key, domProps: { value: map.key } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.weatherParameterInfo[map.parameter]
                                          .displayName
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e()
                        })
                      ],
                      2
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.availableFeatures.length > 0
                ? _c("div", { staticClass: "control-container__section" }, [
                    _c(
                      "div",
                      { staticClass: "control-container__section-heading" },
                      [_vm._v("Features")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control-container__item" }, [
                      _c(
                        "div",
                        { staticClass: "control-container__item-row" },
                        [
                          _c(
                            "ul",
                            {
                              staticClass:
                                "control-container__feature-group-list"
                            },
                            _vm._l(_vm.availableFeatures, function(group) {
                              return _c("li", { key: group.id }, [
                                group.features
                                  ? _c("span", [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "control-container-feature-group-collapsible",
                                          attrs: {
                                            type: "button",
                                            id:
                                              "control-container__feature-group-head-" +
                                              group.id
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleFeatureList(
                                                group.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(group.name) + " "
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "control-container-feature-group-arrow",
                                            attrs: {
                                              id:
                                                "feature-group-arrow-" +
                                                group.id
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "control-container__feature-list",
                                          attrs: {
                                            id:
                                              "control-container__feature-group-list-" +
                                              group.id
                                          }
                                        },
                                        _vm._l(group.features, function(
                                          feature
                                        ) {
                                          return _c("li", { key: feature.id }, [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "control-container__feature-item-label",
                                                attrs: {
                                                  for:
                                                    "cb-control__feature-" +
                                                    feature.id
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    id:
                                                      "cb-control__feature-" +
                                                      feature.id,
                                                    type: "checkbox"
                                                  },
                                                  domProps: {
                                                    checked: _vm.activeFeatures.some(
                                                      function(x) {
                                                        return (
                                                          x.options.id ===
                                                          feature.id
                                                        )
                                                      }
                                                    ),
                                                    value: feature.id
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.onFeatureChange(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(feature.title))
                                                ])
                                              ]
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                group.src
                                  ? _c("span", [
                                      _c("input", {
                                        attrs: {
                                          id: "cb-control__feature-" + group.id,
                                          type: "checkbox"
                                        },
                                        domProps: {
                                          checked: _vm.features.some(function(
                                            x
                                          ) {
                                            return x.options.id === group.id
                                          }),
                                          value: group.id
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onFeatureChange($event)
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "control-container__feature-item-label",
                                          attrs: {
                                            for:
                                              "cb-control__feature-" + group.id
                                          }
                                        },
                                        [_vm._v(_vm._s(group.title))]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }