var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-page-container" },
    [
      _c("HeaderComponent"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "map-container" },
        [
          _c(
            "LMap",
            {
              ref: "map",
              staticClass: "map",
              attrs: {
                zoom: _vm.zoom,
                center: _vm.center,
                minZoom: _vm.minZoom,
                maxZoom: _vm.maxZoom,
                options: { zoomControl: false }
              }
            },
            [
              _c("LControlZoom", { attrs: { position: "topleft" } }),
              _vm._v(" "),
              _c("LControlLayers", { attrs: { position: "topleft" } }),
              _vm._v(" "),
              _vm._l(_vm.baseMaps, function(tileProvider) {
                return _c("LTileLayer", {
                  key: tileProvider.name,
                  attrs: {
                    name: tileProvider.name,
                    visible: tileProvider.visible,
                    url: tileProvider.url,
                    options: { errorTileUrl: tileProvider.errorTileUrl },
                    attribution: tileProvider.attribution,
                    "layer-type": "base"
                  }
                })
              }),
              _vm._v(" "),
              _vm.showSites
                ? _c(
                    "div",
                    _vm._l(_vm.sites, function(site) {
                      return _c("LCircleMarker", {
                        key: site.id + "-" + site.region.id,
                        attrs: {
                          "lat-lng": [site.latitude, site.longitude],
                          radius: _vm.calculateMarkerSize(site),
                          color: "rgba(1,1,1,0.7)",
                          "fill-color": site.color
                            ? site.color
                            : _vm.defaultSiteColor,
                          "fill-opacity": 1.0,
                          options: { site: site }
                        },
                        on: {
                          mouseover: function($event) {
                            return _vm.onSiteMouseOver($event)
                          },
                          mouseout: function($event) {
                            return _vm.onSiteMouseOut($event)
                          },
                          click: function($event) {
                            return _vm.onSiteClick($event)
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showRegions
                ? _c(
                    "div",
                    _vm._l(_vm.regions, function(region) {
                      return _c("LRectangle", {
                        key: region.id,
                        attrs: {
                          bounds: [
                            [
                              region.gridDefinition.minLat,
                              region.gridDefinition.minLon
                            ],
                            [
                              region.gridDefinition.maxLat,
                              region.gridDefinition.maxLon
                            ]
                          ],
                          "l-style": {
                            color: _vm.toRiskColor(
                              _vm.maxRiskLevelByRegion[region.id]
                            ),
                            fillOpacity: 0,
                            weight: 3
                          },
                          options: { region: region }
                        },
                        on: {
                          mouseover: function($event) {
                            return _vm.onRegionMouseover($event)
                          },
                          mouseout: function($event) {
                            return _vm.onRegionMouseout($event)
                          },
                          click: function($event) {
                            return _vm.onRegionClick($event)
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("ControlsBar", {
            key: _vm.controlsBarKey,
            attrs: {
              "active-heat-map": _vm.activeHeatMap,
              "active-particle-layer": _vm.activeParticleMap,
              "heat-maps": _vm.riskMaps,
              regions: _vm.regions,
              hazards: _vm.hazards,
              "weather-maps": _vm.weatherMaps,
              "weather-map-opacity": _vm.weatherMapOpacity,
              availableFeatures: _vm.features,
              "active-features": _vm.activeFeatures,
              "weather-parameter-info": _vm.weatherParameterInfo
            },
            on: {
              change: function($event) {
                return _vm.onControlChange($event)
              },
              changeparticle: function($event) {
                return _vm.onParticleControlChange($event)
              },
              changefeature: function($event) {
                return _vm.onFeatureChange($event)
              },
              updateOpacity: _vm.onOpacityChange
            }
          }),
          _vm._v(" "),
          _c("RiskLegend"),
          _vm._v(" "),
          _vm.activeHeatMap && _vm.activeHeatMap.metadata.key in _vm.weatherMaps
            ? _c("WeatherMapLegend", {
                attrs: {
                  "weather-map": _vm.activeHeatMap.parameter,
                  "display-units": _vm.activeHeatMapDisplayUnits
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("AnimationBar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeHeatMap
                  ? _vm.activeHeatMap.metadata.validDateTimes !== 0
                  : false,
                expression:
                  "activeHeatMap ? activeHeatMap.metadata.validDateTimes !== 0 : false"
              }
            ],
            attrs: {
              "date-time": _vm.currentDateTime,
              "date-times": _vm.activeHeatMap
                ? _vm.activeHeatMap.metadata.validDateTimes
                : [],
              panelSite: _vm.panelSite,
              panelRegion: _vm.panelRegion,
              activePanel: _vm.activePanel,
              panelPosition: _vm.panelPosition
            },
            on: {
              change: function($event) {
                _vm.updateDateTime($event)
                _vm.updateHeatMapSource($event)
                _vm.updateParticleMapSource($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "site-panel" }, [
        _c(
          "div",
          {
            staticClass: "site-panel__toggle",
            style: { display: _vm.panelPosition > 0 ? undefined : "none" }
          },
          [
            _c("i", {
              staticClass: "fas fa-chevron-up icon-rotate site-panel__icon",
              staticStyle: { display: "none" }
            }),
            _vm._v(" "),
            _c("i", {
              staticClass: "fas fa-chevron-down site-panel__icon",
              on: {
                click: function($event) {
                  return _vm.onPanelToggleClick(0)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "site-panel__icon active",
                attrs: { width: "13", height: "13", viewBox: "0 0 13 13" },
                on: {
                  click: function($event) {
                    return _vm.onPanelToggleClick(30)
                  }
                }
              },
              [
                _c("rect", { attrs: { width: "13", height: "13" } }),
                _vm._v(" "),
                _c("rect", {
                  staticStyle: { fill: "#fff" },
                  attrs: { x: "1.5", y: "1.5", width: "10", height: "4.25" }
                }),
                _vm._v(" "),
                _c("rect", {
                  staticStyle: { fill: "#fff" },
                  attrs: { x: "1.5", y: "7.25", width: "10", height: "4.25" }
                })
              ]
            ),
            _vm._v(" "),
            _c("i", {
              staticClass: "fas fa-chevron-up site-panel__icon",
              on: {
                click: function($event) {
                  return _vm.onPanelToggleClick(95)
                }
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "site-panel__content",
            style: {
              "max-height": _vm.panelPosition < 90 ? "50vh" : "90vh",
              width: _vm.panelSite !== null ? "100vw" : undefined,
              display: _vm.panelPosition > 0 ? undefined : "none"
            }
          },
          [
            _vm.activePanel == _vm.panelType.None
              ? _c("SitePanelHelp")
              : _vm._e(),
            _vm._v(" "),
            _vm.activePanel == _vm.panelType.Region
              ? _c("RegionView", {
                  key: _vm.regionViewKey,
                  attrs: { region: _vm.panelRegion },
                  on: {
                    updateMapSites: function($event) {
                      return _vm.starredSitesUpdated($event)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.activePanel == _vm.panelType.Site
              ? _c("SiteView", {
                  key: _vm.siteViewKey,
                  attrs: { region: _vm.panelRegion, site: _vm.panelSite },
                  on: {
                    updateMapHazards: function($event) {
                      return _vm.starredHazardsUpdated($event)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.activePanel == _vm.panelType.Feature
              ? _c("FeatureView", {
                  key: _vm.featureViewKey,
                  attrs: {
                    feature: _vm.panelFeatureConfig.feature,
                    featureConfig: _vm.panelFeatureConfig.config
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }