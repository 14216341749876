import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import user from './modules/user.js';
import admin from './modules/admin.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { user, admin },
  plugins: [
    createPersistedState({
    })
  ],
});
