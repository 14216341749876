<template>
  <div>
    <div v-if="isLoading" class="loading-spinner">
      <div class="loading-spinner__text">
        Loading risk summary data
      </div>
      <CubeSpinner></CubeSpinner>
    </div>
    <div v-else-if="failedLoading">
      <span>{{ errorMsg }}</span>
    </div>
    <div v-else>
      <div class="header__text">{{ regionName }}</div>
      <div class="wg-row--timeline-timezone">Time ({{ regionTimezoneAbbr }})</div>
      <main class="content">
        <table class="rd-table">
          <thead>
            <tr>
              <th class="rd-table__location-header">
                <b>Location</b>
              </th>
              <th class="rd-table__filter-header">
                <i class="fas fa-filter"
                  :class="getFilterClass()"
                  title="Toggle showing of starred sites"
                  @click="showOnlyStarredSiteIds=!showOnlyStarredSiteIds">
                </i>
              </th>
              <th class="rd-table__date-header" v-for="dateTime in summaryDates" :key="dateTime">
                {{dateTime}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="site in siteHazardSummariesToShow" :key="site.id"
              >
              <td class="rd-table__location-cell" data-label="Location">
                <div class="rd-table__location-text">
                  <span><b>{{ site.name }}</b></span>
                </div>
              </td>

              <td class="rd-table__filter-cell">
                <div class="rd-table__filter-text">
                  <div>
                    <i class="fas fa-bell" title="Click to toggle alert."
                    :id="site.id"
                    :class="getAlertClass(site.id)"
                    @click="processAlertEvent($event)">
                    </i>
                  </div>
                  <div>
                    <i class="fas fa-star" title="Click to toggle star."
                      :id="site.id"
                      :class="getStarClass(site.id)"
                      @click="processStarEvent($event)">
                    </i>
                  </div>
                </div>
              </td>

              <td v-for="dailySummary in site.dailyHazardSummaries" :key="dailySummary.dateTime" :data-label="dailySummary.dateTime">
                <div :class="'rd-table__risk-cell rd-table__risk-cell--lev0' + dailySummary.maxRiskLevel"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-html="true"
                >
                  <a @click="nav2site(site.id, region)" role="button" class="rd-table__risk-info">
                    <span class="rd-table__risk-text" v-if="Object.keys(dailySummary.hazards).length > 1">Multiple events</span>
                    <span class="rd-table__risk-text" v-else-if="Object.keys(dailySummary.hazards).length == 1">{{dailySummary.hazards[Object.keys(dailySummary.hazards)[0]].name}}</span>
                    <span class="rd-table__risk-text" v-else></span>
                    <span class="rd-table__risk-number">{{ dailySummary.maxRiskLevel }}</span>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    </div>
  </div>
</template>

<script>
  import { CubeSpinner } from 'vue-spinners';
  import UserButton from '../components/UserButton.vue';
  import moment from 'moment';
  import momentTimezone from 'moment-timezone';
  export default {
    components: { CubeSpinner, UserButton },
    computed: {
      siteHazardSummariesToShow() {
        if (this.showOnlyStarredSiteIds) {
          const siteHazardSummaries = JSON.parse(JSON.stringify(this.siteHazardSummaries));
          const siteKeys = Object.keys(siteHazardSummaries);
          for (let i = 0; i < siteKeys.length; i++) {
            const siteKey = siteKeys[i];
            if (!this.starredSiteIds.includes(siteKey)) {
              delete siteHazardSummaries[siteKey];
            }
          }
          return siteHazardSummaries;
        }
        else {
          return this.siteHazardSummaries;
        }
      },
      regionName() { return this.$store.getters.config.regions[this.region].name; },
      starredSiteIds() { return this.$store.getters.starredSitesByRegion[this.region] ? this.$store.getters.starredSitesByRegion[this.region] : []; },
      sites() { return this.$store.getters.config.regions[this.region].sites; },
      starredSites() {
        const sites = this.sites;
        return this.starredSiteIds.reduce(function(o, k) { o[k] = sites[k]; return o;
        }, {});
      },
      sitesToShow() { return this.showOnlyStarredSiteIds ? this.starredSites : this.sites; },
      alertSiteIds() { return this.$store.getters.alertSitesByRegion[this.region] ? this.$store.getters.alertSitesByRegion[this.region] : []; },
    },
    props: [
      'region'
    ],
    data() {
      return {
        showOnlyStarredSiteIds: false,
        notificationsEnabled: false,
        isLoading: true,
        failedLoading: false,
        errorMsg: '',
        summaryDates: [],
        siteHazardSummaries: null,
        regionTimezoneAbbr: 'UTC'
      }
    },
    methods: {
      nav2site(siteId, regionId) {
        const existingQuery = this.$route.query;
        const newQuery = Object.assign({}, existingQuery, { site: siteId, region: regionId});
        this.$router.push({ query: newQuery });
      },
      getFilterClass() {
        if (this.showOnlyStarredSiteIds) return { 'rd-table__location-icon__active': true, 'rd-table__location-icon': false };
        else return { 'rd-table__location-icon__active': false, 'rd-table__location-icon': true };
      },
      getStarClass(siteId) {
        let starred;
        if (this.starredSiteIds.includes(siteId)) starred = true;
        else starred = false;
        return { 'rd-table__location-icon__active': starred, 'rd-table__location-icon': !starred };
      },
      getAlertClass(siteId) {
        let alert;
        if (this.alertSiteIds.includes(siteId)) alert = true;
        else alert = false;
        return { 'rd-table__location-icon__active': alert, 'rd-table__location-icon': !alert };
      },
      processAlertEvent(event) {
        let target = event.currentTarget;
        const siteId = target.id;
        if (target.className === 'fas fa-bell rd-table__location-icon') {
          target.className = 'fas fa-bell rd-table__location-icon__active';
          this.$store.dispatch('alertSite', {region: this.region, site: siteId});
        }
        else {
          target.className = 'fas fa-bell rd-table__location-icon';
          this.$store.dispatch('unalertSite', {region: this.region, site: siteId});
        }
      },
      processStarEvent(event) {
        let target = event.currentTarget;
        const siteId = target.id;
        if (target.className === 'fas fa-star rd-table__location-icon') {
          target.className = 'fas fa-star rd-table__location-icon__active';
          this.$store.dispatch('starSite', {region: this.region, site: siteId});
        }
        else {
          target.className = 'fas fa-star rd-table__location-icon';
          this.$store.dispatch('unstarSite', {region: this.region, site: siteId});
        }
        this.$emit('updateMapSites', event);
      },
      onSiteClick(site) {
        const existingQuery = this.$route.query;
        const newQuery = Object.assign({}, existingQuery, { site: site.id});
        this.$router.push({ query: newQuery });
      },
      getDaysWithRiskData(regionData, timezone) {
        const daysWithRiskData = [];
        for (const site in regionData) {
          const hazards = regionData[site].dataByHazard;
          for (const hazard in hazards) {
            const dateTimes = hazards[hazard].dateTimes;
            const values = hazards[hazard].values;
            dateTimes.forEach((time, index) => {
              const day = moment.utc(time).tz(timezone).format('YYYY-MM-DD');
              if (!daysWithRiskData.includes(day) && values[index] > 0) {
                daysWithRiskData.push(day);
              }
            });
          }
        }
        return daysWithRiskData;
      },
      loadRegionData() {
        this.isLoading = true;
        this.failedLoading = false;
        try {
          // if there are starred sites for this region, apply the filter to show only them on first load
          let l = [];
          if (Object.keys(this.$store.getters.starredSitesByRegion).includes(this.region)) {
            l = this.$store.getters.starredSitesByRegion[this.region];
          }
          if (l.length > 0) {
            this.showOnlyStarredSiteIds = true;
          } else {
            this.showOnlyStarredSiteIds = false;
          }
          const regionDetails = this.$store.getters.config.regions[this.region];
          this.regionTimezoneAbbr = moment().tz(regionDetails.timezone).zoneAbbr();
          const hazardDetails = this.$store.getters.config.hazards;
          // riskdata
          const regionData = this.$store.getters.riskdata.data[this.region];
          const daysWithDataForRegion = this.getDaysWithRiskData(regionData, regionDetails.timezone);
          const siteKeys = Object.keys(regionDetails.sites);
          if (!(siteKeys.length > 0)) {
            this.failedLoading = true;
            this.isLoading = false;
            this.errorMsg = 'No sites configured for this region.';
            return;
          }
          this.summaryDates = [];
          this.siteHazardSummaries = siteKeys.reduce((currentSummaries, nextSite) => {
            const siteDetails = regionDetails.sites[nextSite];
            const siteData = regionData[nextSite];
            if (siteData) {
              currentSummaries[nextSite] = {
                name: siteDetails.name,
                id: siteDetails.id,
                latitude: siteDetails.latitude,
                longitude: siteDetails.longitude,
                maxRiskLevel: 1
              };
              currentSummaries[nextSite].dailyHazardSummaries = Object.keys(siteData.dataByHazard).reduce((dailyHazardSummary, nextHazard) => {
                const currentHazardData = siteData.dataByHazard[nextHazard];
                const regionWithStarredHazards = this.$store.getters.starredHazardsByRegion[this.region] ? this.$store.getters.starredHazardsByRegion[this.region] : [];
                const starredHazardIds = regionWithStarredHazards[siteDetails.id] ? regionWithStarredHazards[siteDetails.id] : [];

                if (!hazardDetails[nextHazard]) { return dailyHazardSummary; } // hazard no longer available recently deleted by user
                if (currentHazardData.dateTimes && (hazardDetails[nextHazard].enabled || !hazardDetails[nextHazard].hasOwnProperty('enabled')) && !(this.$store.getters.showOnlyFavouriteHazardsOnMap && starredHazardIds.length > 0 && !starredHazardIds.includes(hazardDetails[nextHazard].id)) ) {
                  for (var i = 0; i < currentHazardData.dateTimes.length; i++) {
                      const currentDateTime = moment.utc(currentHazardData.dateTimes[i]).tz(regionDetails.timezone);
                      const formattedDateTime = currentDateTime.format('YYYY-MM-DD');
                      const currentValue = currentHazardData.values[i];
                      if (!(formattedDateTime in dailyHazardSummary) && daysWithDataForRegion.includes(formattedDateTime) ) {
                        dailyHazardSummary[formattedDateTime] = {
                          date: currentDateTime.format('ddd, MMM D'),
                          maxRiskLevel: 1,
                          hazards: {}
                        }

                        if (!this.summaryDates.includes(currentDateTime.format('ddd, MMM D'))) {
                          this.summaryDates.push(currentDateTime.format('ddd, MMM D'));
                        }
                      }
                      if (currentValue > 1) {
                        dailyHazardSummary[formattedDateTime].hazards[nextHazard] = {
                          id: nextHazard,
                          name: hazardDetails[nextHazard].name
                        };
                        if (currentValue > dailyHazardSummary[formattedDateTime].maxRiskLevel) {
                          dailyHazardSummary[formattedDateTime].maxRiskLevel = currentValue;
                        }
                      }
                  }
                }
                return dailyHazardSummary;
              }, {})
            }
            else { // new site without data
              currentSummaries[nextSite] = {
                name: siteDetails.name,
                id: siteDetails.id,
                latitude: siteDetails.latitude,
                longitude: siteDetails.longitude,
                maxRiskLevel: 1,
                dailyHazardSummaries: {}
              };
            }
            return currentSummaries;
          }, {});
        } catch(e) {
          console.warn('Failed to fetch region data.');
          this.failedLoading = true;
          this.isLoading = false;
          this.errorMsg = `Error loading region data. ${e}`;
          throw e;
          return;
        }
        this.failedLoading = false;
        return;
      }
    },
    mounted() {
      this.loadRegionData();
      this.isLoading = false;
    },
    watch: {
      region() {
        this.loadRegionData();
        this.isLoading = false;
      }
    }
  }
</script>
