var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading && !_vm.noSiteForecast
      ? _c(
          "div",
          { staticClass: "loading-spinner" },
          [
            _c("div", { staticClass: "loading-spinner__text" }, [
              _vm._v("\n      Loading site risk data\n    ")
            ]),
            _vm._v(" "),
            _c("CubeSpinner")
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.noSiteForecast
      ? _c("div", [
          _c("div", { staticClass: "header__text" }, [
            _vm._v(
              "No site forecast currently issued for this site - please check back again later."
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading && !_vm.noSiteForecast
      ? _c("div", [
          _c("div", { staticClass: "header__text" }, [
            _vm._v(_vm._s(_vm.siteObj.name))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "wg-page-wrapper" }, [
              _c(
                "div",
                [
                  _c("div", { staticClass: "wg-row-header" }, [
                    _c("div", { staticClass: "wg-heading-cell--25" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "wg-heading-cell--175" }, [
                      _vm._v("Hazard")
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "rd-table__filter-header" }, [
                      _c("i", {
                        staticClass: "fas fa-filter",
                        class: _vm.getFilterClass(),
                        attrs: { title: "Toggle showing of starred hazards" },
                        on: {
                          click: function($event) {
                            return _vm.toggleFilter()
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.regionHazards, function(hazard) {
                    return _c(
                      "div",
                      { key: hazard.id, staticClass: "wg-row" },
                      [
                        _c("div", { staticClass: "wg-table-cell--200" }, [
                          _c(
                            "div",
                            {
                              class:
                                "rd-table__risk-cell rd-table__risk-cell--lev0" +
                                _vm.dataByHazard[hazard.id].maxRiskLevel,
                              attrs: {
                                "data-toggle": "tooltip",
                                "data-placement": "top",
                                "data-html": "true"
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "rd-table__risk-info",
                                  attrs: { role: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.nav2vis(hazard.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "rd-table__risk-text short-text",
                                      attrs: {
                                        title: hazard.name,
                                        "aria-multiline": ""
                                      }
                                    },
                                    [_vm._v(_vm._s(hazard.name))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "rd-table__risk-number" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dataByHazard[hazard.id]
                                            .maxRiskLevel
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "rd-table__filter-cell" }, [
                          _c("div", { staticClass: "rd-table__filter-text" }, [
                            _c("div", [
                              _c("i", {
                                staticClass: "fas fa-star",
                                class: _vm.getStarClass(hazard.id),
                                attrs: {
                                  title: "Click to toggle star.",
                                  id: hazard.id
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.processStarEvent($event)
                                  }
                                }
                              })
                            ])
                          ])
                        ])
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class:
                    "wg-timelines-wrapper forecast--" + _vm.forecastDays + "day"
                },
                [
                  _c(
                    "div",
                    { staticClass: "wg-scrollpane" },
                    [
                      _c("div", { staticClass: "wg-row--timeline-timezone" }, [
                        _vm._v("Time (" + _vm._s(_vm.timezoneAbbr) + ")")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "wg-row wg-row--dates" },
                        _vm._l(_vm.timelineHeader, function(
                          numberOfBlocks,
                          dateTime
                        ) {
                          return _c(
                            "div",
                            {
                              key: dateTime,
                              class:
                                "wg-heading-cell timeline__block timeline__block--" +
                                numberOfBlocks +
                                "block"
                            },
                            [_vm._v(_vm._s(dateTime) + "\n              ")]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.regionHazards, function(hazard) {
                        return _c(
                          "div",
                          {
                            key: hazard.id,
                            staticClass: "wg-row wg-row--timeline"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "timeline" },
                              _vm._l(
                                _vm.dataByHazard[hazard.id]
                                  .riskDataGroupedByDay,
                                function(dataGroup, currentDay) {
                                  return _c("SiteHazardRow", {
                                    key: currentDay,
                                    attrs: {
                                      "data-group": dataGroup,
                                      "sunrise-hour": _vm.sunriseHour,
                                      "sunset-hour": _vm.sunsetHour
                                    }
                                  })
                                }
                              ),
                              1
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-info" }, [
            _c(
              "a",
              {
                staticClass:
                  "text-and-icon text-and-icon--align-center cursor-pointer",
                on: { click: _vm.nav2region }
              },
              [
                _c("img", {
                  staticClass: "text-and-icon__icon",
                  attrs: { src: require("../assets/img/icon_back.svg") }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "text-and-icon__text text-and-icon__text--right"
                  },
                  [_vm._v("Up to parent region")]
                )
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }