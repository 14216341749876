<template>
  <div class="validation-label">
    <i class="fas fa-exclamation-triangle"></i>
    <span>{{ warningLabel }}</span>
    <div class="validation-label-pointer-outer">
      <div class="validation-label-pointer-inner">
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ValidationWarning',
    props: [
      'warningLabel'
    ]
  }
</script>