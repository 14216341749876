<template>
  <div class="controls-container">
    <LayerControls
      :heat-maps="heatMaps"
      :weather-maps="weatherMaps"
      :weather-map-opacity="weatherMapOpacity"
      :available-features="availableFeatures"
      :regions="regions"
      :hazards="enabledHazards"
      :weather-parameter-info="weatherParameterInfo"
      :open="layerControlState.open"
      :active-heat-map="activeHeatMap"
      :active-particle-layer="activeParticleLayer"
      :active-features="activeFeatures"
      v-on:click="toggleLayerControlState($event)"
      v-on:change="onLayerControlChange($event)"
      v-on:changeparticle="onParticleLayerControlChange($event)"
      v-on:changefeature="onFeatureChange($event)"
      @changeopacity="onOpacityChange"
    >
    </LayerControls>
  </div>
</template>

<script>
  import LayerControls from './LayerControls.vue'
  export default {
    components: { LayerControls },
    props: [
      'activeHeatMap',
      'activeParticleLayer',
      'activeFeatures',
      'heatMaps',
      'regions',
      'hazards',
      'sites',
      'weatherMaps',
      'weatherMapOpacity',
      'availableFeatures',
      'weatherParameterInfo'
    ],
    data() {
      return {
        layerControlState: {
          open: false
        },
        regionControlState: {
          open: false
        },
        siteControlState: {
          open: false
        },
        enabledHazards: this.getEnabledHazardsOnly(this.hazards)
      }
    },
    methods: {
      getEnabledHazardsOnly() {
        const allHazards = JSON.parse(JSON.stringify(this.hazards));
        for (const [key, value] of Object.entries(allHazards)) {
          if (!allHazards[key].enabled && allHazards[key].hasOwnProperty('enabled')) {
            this.$delete(allHazards, key);
          }
        }
        return allHazards;
      },
      toggleLayerControlState() {
        this.layerControlState.open = !this.layerControlState.open;
      },
      onLayerControlChange(event) {
        this.$emit('change', event);
      },
      onParticleLayerControlChange(event) {
        this.$emit('changeparticle', event);
      },
      onFeatureChange(event) {
        this.$emit('changefeature', event);
      },
      onOpacityChange(opacity) {
        this.$emit('updateOpacity', opacity);
      }
    }
  }
</script>
