<template>
  <div>
    <HeaderComponent>
    </HeaderComponent>
    <cookie-notice></cookie-notice>
    <CubeSpinner v-if="isLoading"></CubeSpinner>
    <div v-if="!isLoading">
    <div class="rd-hazard-visualizer">
      <div class="rd-hazard-visualizer__region-navigation">
        <div class="rd-hazard-visualizer__column">
          <div class="rd-hazard-visualizer__header">Regions</div>
          <div v-for="region in regions" :key="region.id">
            <div class="rd-hazard-visualizer__data-header" @click="updateRegion(region.id)" :class="{ open: activeRegion === region.id }">
              <span>{{region.name}}</span><i v-if="activeRegion !== region.id" class="fas fa-caret-down"></i>
            </div>
            <div v-if="region.id == activeRegion">
              <div v-for="site in activeSites" :key="site.id" class="rd-hazard-visualizer__data-row" @click="updateSite(site.id)" :class="{ open: activeSite === site.id }">
                {{ site.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rd-hazard-visualizer__chart-container">
        <div class="rd-hazard-visualizer__chart-component">
          <CubeSpinner v-if="loadingChartData"></CubeSpinner>
          <div v-if="!loadingChartData && regionHasHazards">
            <div class="rd-hazard-visualizer__line-chart-container">
              <div class="line-chart-d3-cls" v-for="parameter in activeHazardParameters" :key="parameter.id">
                <line-chart-d3 :key="lineChartKey" v-bind="lineChartProps(parameter)"></line-chart-d3>
              </div>
            </div>
          </div>
          <div v-if="!regionHasHazards && !loadingChartData">
            <div class="rd-hazard-visualizer__line-chart-container">
               <div class="line-chart-d3-cls">
                  <div class="warning">There are no hazards for this region</div>
               </div>
            </div>
          </div>
        </div>
        <div class="rd-hazard-visualizer__parameter-tab">
        </div>
      </div>
      <div class="rd-hazard-visualizer__hazard-navigation">
        <div class="rd-hazard-visualizer__column">
          <div class="rd-hazard-visualizer__header">Hazards</div>
          <div v-for="hazard in hazards" :key="hazard.id">
            <div v-if="hazard.regions">
              <div v-if="hazard.regions.includes(activeRegion) && satisfyFavouriteHazardPreference(hazard.id)">
                <div class="rd-hazard-visualizer__data-header" @click="updateHazard(hazard.id)" :class="{ open: activeHazard === hazard.id }">
                  <span>{{hazard.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
  import swal from 'sweetalert';
  import { CubeSpinner } from 'vue-spinners';
  import HeaderComponent from '../components/Header.vue';
  import moment from 'moment';
  import LineChartD3 from "../components/LineChartD3.vue";
  import { UnitConverter } from '../js/unitConverter.js';
  import RequestBuilder from '../js/requestBuilder';
  import { formatDisplayUnit } from '../js/helperFunctions.js';
  import unitSets from '../constants/unitSets.json';
  import CookieNotice from '../components/CookieNotice.vue';
  const unitConverter = new UnitConverter();
  const requestBuilder = new RequestBuilder();

  export default {
    components: { CubeSpinner, HeaderComponent, LineChartD3, CookieNotice },
    created () {
      this.createTimer();
    },
    beforeDestroy() {
      this.cancelTimer();
    },
    data() {
      return {
        lineChartKey: 0,
        timer: '',
        activeRegion: this.$route.query.region,
        activeSite: this.$route.query.site,
        activeSiteTimezone: "Etc/UTC",
        activeHazard: this.$route.query.hazard,
        activeParameter: this.$route.query.parameter,
        isLoading: true,
        regions: null,
        hazards: null,
        validChartSelected: false,
        loadingChartData: true,
        regionHasHazards: true,
        chartDataVar: [],
        unitSets: JSON.parse(JSON.stringify(unitSets)),
      }
    },
    computed: {
      config() { return this.$store.getters.config; },
      availableParameters() { return JSON.parse(JSON.stringify(this.$store.getters.parameterConfig)); },
      unitSetId() { return this.config.unitSetId; },
      unitSet() { return this.unitSets[this.unitSetId]; },
      currentRouteName() { return this.$router.history.current.name; },
      serverRoute() { return this.$store.getters.serverRoute; },
      activeHazardParameters() { return this.getActiveHazardParameters(); },
      serverRoute() { return this.$store.getters.serverRoute; },
      activeSites() { return this.$store.getters.showOnlyFavouriteSitesInVizualizer ? this.filterByFavouriteSites() : this.regions[this.activeRegion].sites; },
      relevantHazards() {
        if (this.activeRegion in this.regions) {
          return Object.keys(this.hazards).reduce((relevantHazards, nextHazardKey) => {
            const currentHazard = this.hazards[nextHazardKey];
            if (currentHazard.regions.includes(this.activeRegion)) relevantHazards[nextHazardKey] = currentHazard;
            return relevantHazards;
          }, {});
        }
        return this.hazards;
      },
    },
    methods: {
      getActiveHazardParameters() {
        return JSON.parse(JSON.stringify(this.hazards[this.activeHazard].parameters));
      },
      yAxisLabel(param) {
        return `${this.availableParameters[param.id].name} (${formatDisplayUnit(this.unitSet[param.id])})`;
      },
      hazardThresholdValues(param) {
        const unitsIn = this.availableParameters[param.id].threshold.units;
        const unitsOut = this.unitSet[param.id];
        const precision = param.threshold.precision ? param.threshold.precision : 1;
        const comparisonType = param.threshold.comparisonType;
        const returnValues = [];
        if (comparisonType === 'between') {
          const mu1 = param.threshold.mu1;
          const mu2 = param.threshold.mu2;
          returnValues.push(unitConverter.convert(mu1, unitsIn, unitsOut, precision));
          returnValues.push(unitConverter.convert(mu2, unitsIn, unitsOut, precision));
        }
        else {
          const mu = param.threshold.mu;
          returnValues.push(unitConverter.convert(mu, unitsIn, unitsOut, precision));
        }
        return returnValues;
      },
      determineDateIntervals(data) {

        let earliestDateTime = new moment.utc().tz(this.activeSiteTimezone).add(5, 'days');
        let latestDateTime = new moment.utc().tz(this.activeSiteTimezone).subtract(5, 'days');
        for (const dataset in data) {
          const model = data[dataset];
          const values = model.values;
          values.forEach(value => {
            const time = moment.utc(value.dateTime).tz(this.activeSiteTimezone);
            if (time > latestDateTime) {
              latestDateTime = time;
            }
            if (time < earliestDateTime) {
              earliestDateTime = time;
            }
          });
        }
        const durationHours = Math.abs(moment.duration(moment(earliestDateTime).startOf('hour').diff(moment(latestDateTime).startOf('hour'))).asHours());

        if (durationHours <= 24) {
          return 'hour';
        } else {
          return 'day';
        }
      },
      lineChartProps(param){
        return {
          xAxisLabel: `Time (${moment().tz(this.activeSiteTimezone).zoneAbbr()})`,
          yAxisLabel: this.yAxisLabel(param),
          title: this.availableParameters[param.id].name,
          xKey: 'dateTime',
          yKey: 'value',
          interval: this.determineDateIntervals(this.activeHazardParameters[param.id].data),
          thresholdValues: this.hazardThresholdValues(param),
          dateFormat: '%Y-%m-%dT%H:%M:%S.000000000',
          timezone: this.activeSiteTimezone,
          data: this.activeHazardParameters[param.id].data,
          parameterName: this.availableParameters[param.id].name
        };
      },
      createTimer() {
        const timerIntervalSeconds = 610;
        this.timer = setInterval(this.updateData, timerIntervalSeconds * 1000);
      },
      cancelTimer() {
        clearInterval(this.timer);
      },
      getStarredHazardIds() {
        const regionWithStarredHazards = this.$store.getters.starredHazardsByRegion[this.activeRegion] ? this.$store.getters.starredHazardsByRegion[this.activeRegion] : [];
        const starredHazardIds = regionWithStarredHazards[this.activeSite] ? regionWithStarredHazards[this.activeSite] : [];
        return starredHazardIds;
      },
      satisfyFavouriteHazardPreference(hazardId) {
        const showOnlyFavHazards = this.$store.getters.showOnlyFavouriteHazardsInVizualizer;
        if(!showOnlyFavHazards) { return true; }

        const starredHazardsForRegionSite = this.getStarredHazardIds();
        if (starredHazardsForRegionSite.length > 0 && !starredHazardsForRegionSite.includes(hazardId)) {
          return false;
        } else {
          return true;
        }
      },
      filterByFavouriteSites() {
        const sites = this.regions[this.activeRegion].sites;
        const starredSitesForRegion = this.$store.getters.starredSitesByRegion[this.activeRegion];
        if (starredSitesForRegion) {
          if (starredSitesForRegion.length < 1) { return sites; }
          const siteKeys = Object.keys(sites);
          for (const index in siteKeys) {
            const siteKey = siteKeys[index];
            const siteId = sites[siteKey].id;
            if (!starredSitesForRegion.includes(siteId)) {
              delete sites[siteKey];
            }
          }
        }
        return sites;
      },
      async updateData() {
        // only update data if user is currently at this views route
        if (!(this.currentRouteName === 'vis')) { // TODO: fix (this doesnt seem to work, still updates while on map route)
          return;
        }
        else {
          const chartDataWasUpdated = await this.updateChartData();
        }
      },
      async updateChartData() {
        let activeParameters = this.getActiveHazardParameters();
        for (let key in activeParameters) {
          let _data = await this.fetchParameterData(activeParameters[key]);
          this.activeHazardParameters[key].data = _data[0].filter((param) => param.values.length != 0);
          this.lineChartKey = !this.lineChartKey; // force reload of line chart
        }
        return true;
      },
      getMinTime() {
        const localDate = new Date();
        return moment.utc(localDate).format('YYYY-MM-DDThh:mm:ss');
      },
      getMaxTime() {
        const daysAhead = 5;
        const localDate = new Date();
        const futureLocalDate = new Date(localDate);
        futureLocalDate.setDate(futureLocalDate.getDate() + daysAhead);
        return moment.utc(futureLocalDate).format('YYYY-MM-DDThh:mm:ss');
      },
      convertValueAndUnits(valueIn, p) {
        const unitsIn = this.availableParameters[p.id].threshold.units;
        const unitsOut = this.unitSet[p.id];
        const precision = p.threshold.precision ? p.threshold.precision : 1;
        let valueOut = valueIn;
        if (unitsIn !== unitsOut && unitsOut) {
          valueOut = unitConverter.convert(valueIn, unitsIn, unitsOut, precision);
        }
        return [valueOut, unitsOut]
      },
      async fetchParameterData(p) {
        const chartData = [];
        try {
          this.loadingChartData = true;
          const config = this.$store.getters.config;
          const regionObj = config['regions'][this.activeRegion];
          const siteObj = regionObj['sites'][this.activeSite];
          const hazardObj = config['hazards'][this.activeHazard];
          const latitude = siteObj.latitude;
          const longitude = siteObj.longitude;
          const radiusInMeters = siteObj.radius ? siteObj.radius : 10000;
          let requests = [await requestBuilder.weatherModelRequest(this.serverRoute, regionObj.id, siteObj.id, hazardObj.id, p.id)];
          requests.push(await requestBuilder.weatherObservationRequest(this.serverRoute, latitude, longitude, p.id, radiusInMeters));
          const res = await Promise.all(requests.map(requestBuild => {
            const [request, options] = requestBuild;
            return fetch(request, options);
          }));

          let resJson = await Promise.allSettled(res.map(r => r.json()));
          resJson = resJson.map(result => {
            if (result.status === "fulfilled" && result.value) {
              const json = result.value;
              for (let key in json) {
                  let data = json[key];
                  let arr = [];
                  let times = data.dateTimes;
                  for (let i = 0; i < times.length; i++) {
                    let tmp = {};
                    tmp['dateTime'] = times[i].replace('Z',''); // remove Z for utc
                    let valueIn = data.values[i];
                    let valueAndUnits = this.convertValueAndUnits(valueIn, p);
                    let valueOut = valueAndUnits[0];
                    let unitsOut = valueAndUnits[1];
                    tmp['value'] = valueOut;
                    tmp['unit'] = unitsOut;
                    arr.push(tmp);
                  };
                  const modelCount = Object.keys(json).filter(modelKey => json[modelKey].model.abbr == data.model.abbr).length;
                  const modelInfo = data.model;
                  const legendDisplayName = modelCount > 1 && modelInfo.name ? modelInfo.name : modelInfo.abbr ? modelInfo.abbr : data.model;
                  const modelFullName = modelInfo.name ? modelInfo.name : data.model;
                  const dataDict = { name: legendDisplayName, fullName: modelFullName, values: arr };
                  chartData.push(dataDict);
              }
            }
          });
        } catch(e) {
          console.log('Failed to fetch new data.', e);
        } finally {
          this.lineChartKey = !this.lineChartKey; // force reload of line chart
          this.loadingChartData = false;
          return [chartData];
        }
      },
      updateHistory() {
        const params = {
          region: this.activeRegion,
          site: this.activeSite,
          hazard: this.activeHazard,
          parameter: this.activeParameter
        };
        const newUrl = `/vis?region=${this.activeRegion}&site=${this.activeSite}&hazard=${this.activeHazard}&parameter=${this.activeParameter}`;
        history.replaceState(params, '', newUrl);
      },
      updateRegion(region) {
        this.regionHasHazards = true;
        this.activeRegion = region;
        this.setFirstSiteAsActive();
        if (!this.regionContainHazards()) {
          this.regionHasHazards = false;
          return;
        }
        if (!(this.activeHazard && this.hazards[this.activeHazard].regions.includes(this.activeRegion))) {
          this.setFirstHazardAsActive();
          this.setFirstParameterAsActive();
        }
        this.updateHistory();
        this.updateTimezone();
        this.updateChartData();
      },
      updateSite(site) {
        this.regionHasHazards = true;
        this.activeSite = site;
        if (!this.regionContainHazards()) {
          this.regionHasHazards = false;
          return;
        }
        this.updateTimezone();
        const showOnlyFavHazards = this.$store.getters.showOnlyFavouriteHazardsInVizualizer;
        const starredHazardsForRegionSite = this.getStarredHazardIds();
        const showOnlyFavouriteHazards = starredHazardsForRegionSite.length > 0 && showOnlyFavHazards ? true : false;

        if (!(this.activeHazard && this.hazards[this.activeHazard].regions.includes(this.activeRegion)) || (showOnlyFavouriteHazards && !starredHazardsForRegionSite.includes(this.activeHazard))) {
          this.setFirstHazardAsActive();
          this.setFirstParameterAsActive();
        }
        this.updateHistory();
        this.updateChartData();
      },
      updateHazard(hazard) {
        this.activeHazard = hazard;
        this.setFirstParameterAsActive();
        this.updateHistory();
        this.updateChartData();
      },
      updateParameter(parameter) {
        this.activeParameter = parameter;
        this.updateHistory();
      },
      async updateTimezone() {
        const config = this.$store.getters.config;
        const regionObj = config['regions'][this.activeRegion];
        const siteObj = regionObj['sites'][this.activeSite];
        this.activeSiteTimezone = siteObj.timezone;
      },
      setFirstSiteAsActive() {
        const sitesObj = this.regions[this.activeRegion].sites;
        const siteKey = Object.keys(sitesObj)[0];
        this.activeSite = sitesObj[siteKey].id;
      },
      setFirstHazardAsActive() {
        for (let hazardKey in this.hazards) {
          if (this.hazards[hazardKey].regions) {
            const showOnlyFavHazards = this.$store.getters.showOnlyFavouriteHazardsInVizualizer;
            const starredHazardsForRegionSite = this.getStarredHazardIds();
            const showOnlyFavouriteHazards = starredHazardsForRegionSite.length > 0 && showOnlyFavHazards ? true : false;

            if (this.hazards[hazardKey].regions.includes(this.activeRegion) && ( (showOnlyFavouriteHazards && starredHazardsForRegionSite.includes(hazardKey)) || !showOnlyFavouriteHazards) ) {
              this.activeHazard = hazardKey;
              break;
            }
          }
        }
      },
      setFirstParameterAsActive() {
        const parametersObj = this.hazards[this.activeHazard].parameters;
        const parameterKey = Object.keys(parametersObj)[0];
        this.activeParameter = parametersObj[parameterKey].id;
      },
      regionContainHazards() {
        for (let hazardKey in this.hazards) {
          if (this.hazards[hazardKey].regions) {
            if (this.hazards[hazardKey].regions.includes(this.activeRegion)) {
              return true;
            }
          }
        }
        return false;
      }
    },
    async mounted() {
      const json = JSON.parse(JSON.stringify(this.$store.getters.config));
      this.visualizerConfig = json;
      this.regions = json.regions;
      this.hazards = json.hazards;
      //removing disabled hazards from the list of hazards
      for (const [key, value] of Object.entries(json.hazards)) {
        if (!json.hazards[key].enabled && json.hazards[key].hasOwnProperty('enabled')) {
          this.$delete(this.hazards, key);
        }
      }
      this.isLoading = false;
      this.updateTimezone();
      this.updateChartData();
    },
  }
</script>
