var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "weather-map-legend-container" }, [
    _c("div", { staticClass: "weather-map-legend-items" }, [
      _c("div", { staticClass: "weather-map-legend-label" }, [
        _vm._v("\n      " + _vm._s(_vm.unitsBeingDisplayed) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "weather-map-legend-item-container" },
        _vm._l(_vm.legendItems, function(item) {
          return _c(
            "div",
            {
              key: item[0],
              staticClass: "weather-map-legend-item",
              style: { backgroundColor: item[0] }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.convertValue(item[1])) + "\n      "
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }