<template>
  <div class="wg-flex-container">
      <div class="wg-feature-table-container">
        <div v-for="(detailsGroup, detailsGroupId) in featureConfig.featureDetails" :key="detailsGroupId" class="wg-feature-table-container-item">
          <div class="wg-feature-table-header">
            <div v-if="featureCollection" class="feature-navigators">
              <a @click="navigateBack" class="feature-navigate-button">&#8249;</a>
              <a @click="navigateForward" class="feature-navigate-button">&#8250;</a>
            </div>
            <h3>{{detailsGroup.name}}</h3>
            <h4 v-if="featureCollection"> {{ currentFeatureIndex + 1}} / {{ feature.features.length }} </h4>
          </div>
          <div class="wg-feature-table-inner-container">
            <table class="wg-feature-table">
              <tbody>
                <tr v-for="(property, name) in detailsGroup.properties" :key="property.id" v-if="featureAvailable(property)"><td>{{ property.name }}</td><td>{{ formatFeatureDescription(property) }} {{ getUnits(property) }}</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  import { CubeSpinner } from 'vue-spinners';
  import moment from 'moment';
  import momentTimezone from 'moment-timezone';
  import { UnitConverter } from '../js/unitConverter.js';
  const unitConverter = new UnitConverter();

  export default {
    components: { CubeSpinner },
    data() {
      return {
        isLoading: true,
        noFeatureData: false,
        currentFeatureIndex: 0
      }
    },
    props: [
      'feature',
      'featureConfig'
    ],
    watch: {
      feature(newValue, oldValue) {
        this.currentFeatureIndex = 0;
      }
    },
    computed: {
      featureCollection() { return this.feature.type.toLowerCase() == "featurecollection"; }
    },
    methods: {
      navigateBack() {
        if (this.currentFeatureIndex > 0) {
          this.currentFeatureIndex = this.currentFeatureIndex - 1;
        } else {
          this.currentFeatureIndex = this.feature.features.length - 1;
        }
      },
      navigateForward(){
        if (this.currentFeatureIndex < this.feature.features.length - 1) {
          this.currentFeatureIndex = this.currentFeatureIndex + 1;
        } else {
          this.currentFeatureIndex = 0;
        }
      },
      getUnits(properties){
        const units = properties.units ? properties.units : null;
        const feature = this.featureCollection ? this.feature.features[this.currentFeatureIndex] : this.feature;
        let displayUnits = properties.displayUnits ? properties.displayUnits : units;
        if(properties.hasOwnProperty('errorCodes')) {
          displayUnits = properties.errorCodes.includes(feature.properties[properties.id]) ? '' : displayUnits;
        }

        if(displayUnits == null || displayUnits === ''){
          displayUnits = '';
        }
        return displayUnits;
      },
      formatDate(properties) {
        const outputFormat = properties.hasOwnProperty('outputTimeFormat') ? properties.outputTimeFormat : 'YYYY-MM-DD, hh:mm z';
        const feature = this.featureCollection ? this.feature.features[this.currentFeatureIndex] : this.feature;
        const timezone = feature.geometry.hasOwnProperty("timezone") ? feature.geometry.timezone : "UTC";
        if (properties.hasOwnProperty('inputTimeFormat')) {
          return moment.utc(feature.properties[properties.id], properties.inputTimeFormat).format(outputFormat);
        } else {
          return moment.utc(feature.properties[properties.id]).tz(timezone).format(outputFormat);
        }
      },
      featureAvailable(properties) {
        const feature = this.featureCollection ? this.feature.features[this.currentFeatureIndex] : this.feature;
        if (properties.name.toLowerCase() == "latitude" && !isNaN(feature.geometry.coordinates[1]) ) { return true; };
        if (properties.name.toLowerCase() == "longitude" && !isNaN(feature.geometry.coordinates[0]) ) { return true; };
        const ids = (properties.id).split(",");
        for (const index in ids) {
          const id = ids[index];
          if (feature.properties.hasOwnProperty(id)) {
            return true;
          }
        }
        return false;
      },
      formatFeatureDescription(properties) {
        const feature = this.featureCollection ? this.feature.features[this.currentFeatureIndex] : this.feature;
        if (properties.name.toLowerCase() == "latitude" && !isNaN(feature.geometry.coordinates[1]) ) { return feature.geometry.coordinates[1].toFixed(3) };
        if (properties.name.toLowerCase() == "longitude" && !isNaN(feature.geometry.coordinates[0]) ) { return feature.geometry.coordinates[0].toFixed(3) };
        if (properties.type == "date") { return this.formatDate(properties); };
        const ids = (properties.id).split(",");
        const values = [];
        for (const index in ids) {
          const id = ids[index];
          let currentValue = feature.properties.hasOwnProperty(id) ? feature.properties[id] : null;

          if (properties.hasOwnProperty('errorCodes')) {
            currentValue = properties.errorCodes.includes(currentValue) ? null : currentValue;
          };

          if (properties.hasOwnProperty('displayUnits') && currentValue !== null) {
            currentValue = unitConverter.convert(currentValue, properties.units, properties.displayUnits, 1);
          }

          currentValue = currentValue !== null ? currentValue : "";
          values.push(currentValue);
        }
        return String(values);
      }
    }
  }
</script>