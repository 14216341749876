<template>
  <header class="hc-heading hc-heading--page">
    <h2 class="hc-heading__text">Configuration</h2>
    <div class="hc-actions">
      <button class="hc-btn hc-btn--grey" title="Reset"><i class="fas fa-undo"></i></button>
      <button class="hc-btn js-toggle-errors" title="Save" @click="postConfig"><i class="fas fa-save"></i></button>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    postConfig() {
      this.$parent.postConfig;
    }
  }
}
</script>