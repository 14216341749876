var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "validation-label" }, [
    _c("i", { staticClass: "fas fa-exclamation-triangle" }),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.warningLabel))]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "validation-label-pointer-outer" }, [
      _c("div", { staticClass: "validation-label-pointer-inner" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }