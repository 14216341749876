<template>
<div>
  <div class="editorTabs">

    <!-- HAZARD TAB -->
    <div class="editorTab">
      <input type="radio" id="tab-1" name="tab-group-1" checked @click="showRegionTab=false; showHazardTab=true; showUnitsTab=false; showAlertsTab=false;">
      <label class="editorTabLabel" for="tab-1">Hazards</label>
      <div class="editorTabContent" v-if="showHazardTab">

        <!-- start hazard editor -->
        <div>
          <div class="hc-block">
            <div class="hc-heading">
              <div class="hc-heading__text"><br/>Hazards<br/></div>
              <div class="hc-actions">
                <button :class="buttonClass" @click="showAddHazardModalTrue"><i class="fa fa-plus"></i></button>
              </div>
            </div>

            <!-- start add hazard modal -->
            <div v-if="showAddHazardModal">
              <settings-wizard
                @hazard-complete-event="hazardCompleteEventHandler"
                @child-hide-event="hideAddHazardModal"
                @hazard-cancel-event="hazarEditAddCancelEventHandler"
                :title="addHazardTitle"
                :hazardNames="hazardNames"
                :regions="regions"
                :availableParametersArray="availableParametersArray"
                :initHazard="dummyHazard"
                :initParameters="[availableParametersArray[0]]"
                :newHazard="true"
              >
              </settings-wizard>
            </div>
            <!-- end add hazard modal -->

            <div v-for="hazard in hazards" :key="hazard.id" class="hc-hazard">
              <div>
                <span><b :class="getHazardStatusClass(hazard)">{{ hazard.name }}</b></span>
                <div style="float:right;">
                  <button
                    :class="buttonClass"
                    :title="toggleHazardTitle"
                    @click="toggleHazard(hazard)"
                  >
                    <i :class="hazardIsEnabled(hazard) ? 'fa fa-toggle-on' : 'fa fa-toggle-off'"></i>
                  </button>
                  <button v-if="showEditHazardModal !== true || hazard.id !== activeHazard.id"
                    :class="buttonClass"
                    :title="editHazardTitle"
                    @click="beginEditHazard(hazard)"
                  >
                    <i class="fa fa-pen"></i>
                  </button>
                  <button
                    :class="buttonClass"
                    :title="deleteHazardTitle"
                    @click="deleteHazard(hazard.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              <div class="hc-component">
                <ul>
                  <li :class="getHazardStatusClass(hazard)" v-for="parameter in hazard.parameters" :key="parameter.id">
                    {{ hazardSummaryText(parameter) }}
                  </li>
                </ul>
                <span style="margin-left: 10px" v-for="region in config.regions" :class="getHazardStatusClass(hazard)" :key="region.id">
                  <input onclick="return false" type="checkbox" :disabled="!hazardIsEnabled(hazard)" :checked="hazardIsInRegion(hazard.id, region.id)">{{ region.name }}
                </span>
              </div>

              <!-- start edit hazard modal -->
              <div v-if="showEditHazardModal && hazard.id === activeHazard.id">
                <settings-wizard
                  @hazard-complete-event="hazardCompleteEventHandler"
                  @hazard-cancel-event="hazarEditAddCancelEventHandler"
                  @child-hide-event="hideEditHazardModal"
                  :title="editHazardTitle"
                  :hazardNames="hazardNames"
                  :regions="regions"
                  :availableParametersArray="availableParametersArray"
                  :initHazard="activeHazard"
                  :initParameters="Object.values(activeHazard.parameters)"
                  >
                </settings-wizard>
              </div>
              <!-- end edit hazard modal -->

            </div>
          </div>
        </div>
        <!-- end hazard editor -->

      </div>
    </div>

    <!-- ALERTS TAB -->
    <div class="editorTab">
      <input type="radio" id="tab-4" name="tab-group-1" @click="showHazardTab=false; showRegionTab=false; showUnitsTab=false; showAlertsTab=true;">
      <label class="editorTabLabel" for="tab-4">Obs Alerts</label>
      <div class="editorTabContent" v-if="showAlertsTab">
        <div>
          <div class="hc-block">
            <div class="hc-heading">
              <div class="hc-heading__text"><br/>Obs Alerts<br/></div>
              <div class="hc-actions">
                <button :class="buttonClass" @click="showAddAlertModal=true;"><i class="fa fa-plus" :title="addAlertTitle"></i></button>
              </div>
            </div>

            <div class="hc-location-details" v-if="preferences.alertObservations" v-for="alert in preferences.alertObservations" :key="alert.id">
              <div class="flex-row" style="margin-left:1.5em" :key="alert.id">
                <div><label>Alert ID</label><input disabled type="text" class="wg-form-control--alerts" v-model="alert.id"/></div>
                <div><label>Alert name</label><input disabled type="text" class="wg-form-control--alerts" v-model="alert.name"/></div>
                <div><label>Region</label><input disabled type="text" class="wg-form-control--alerts" v-model="alert.region"/></div>
                <div><label>Site</label><input disabled type="text" class="wg-form-control--alerts" v-model="alert.site"/></div>
                <div><label>Parameter</label><input disabled type="text" class="wg-form-control--alerts" :value="camelCaseToSentenceCase(alert.parameter)"/></div>
                <div><label>Radius ({{unitSet['radius']}})</label><input disabled type="text" class="wg-form-control--alerts" :value="formatRadius(alert.radius)"/></div>
                <div v-if="alert.childConditions && displayChildConditions(alert.parameter)" v-for="condition in alert.childConditions" :key="condition.id">
                  <label>{{ condition.name }}</label><input disabled type="text" class="wg-form-control--alerts" :value="formatComparisonType(condition.comparisonType) + formatChildThreshold(condition)"/>
                </div>

                <button :class="buttonClass"><i class="fas fa-edit" :title="editAlertTitle" @click="editAlert(alert.id)"></i></button>
                <button :class="buttonClass"><i class="fas fa-trash" :title="deleteAlertTitle" @click="deleteAlert(alert.id)"></i></button>
              </div>
            </div>


            <div v-if="showAddAlertModal || showEditAlertModal">
              <transition name="editor-modal">
                <div class="editor-modal-mask">
                  <div class="editor-modal-wrapper">
                    <div class="editor-modal-container flex-row">
                      <div><label>Alert ID</label><input :disabled="showEditAlertModal"  type="text" class="wg-form-control--alerts" v-model="inputAlertId"/></div>
                      <div><label>Alert name</label><input type="text" class="wg-form-control--alerts" v-model="inputAlertName"/></div>
                      <div>
                          <label>Site</label>
                          <select class="wg-form-control--alerts" style="margin-right: 40px;" v-model="alertSite" :value="alertSite">
                            <option v-for="site in allSites" :value="site.id" :key="site.id">
                              {{ site.name }}
                            </option>
                          </select>
                      </div>
                      <div>
                          <label>Parameter</label>
                          <select class="wg-form-control--alerts" style="margin-right: 40px;" @change="setParameterChildConditions($event)" v-model="alertParameter" :value="alertParameter">
                            <option v-for="parameter in allAlertParameters" :value="parameter.id" :key="parameter.id">
                              {{ parameter.name }}
                            </option>
                          </select>
                      </div>

                      <div class="flex-row" v-if="displayChildConditions(alertParameter)" v-for="condition in alertChildConditions" :key="condition.id">
                        <div>
                          <label>{{ condition.name }}</label>
                          <input type="number" class="wg-form-control--alerts" v-model="condition.threshold"/>
                        </div>
                        <div>
                          <label>Comparison</label>
                          <select class="wg-form-control--alerts" style="margin-right: 40px;" v-model="condition.comparisonType" :value="condition.comparisonType">
                              <option v-for="comparison in alertComparisonTypes" :value="comparison" :key="comparison">
                                {{ camelCaseToSentenceCase(comparison) }}
                              </option>
                          </select>
                        </div>
                      </div>

                      <div><label>Radius ({{unitSet['radius']}})</label><input type="number" class="wg-form-control--alerts" v-model="inputAlertRadius"/></div>

                      <button class="editor-modal-default-button editor-modal-default-button--teal" @click="addEditAlert">OK</button>
                      <button class="editor-modal-default-button editor-modal-default-button--teal" @click="cancelAddEditAlert">Cancel</button>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- end alerts tab -->

    <!-- REGIONS TAB -->
    <div class="editorTab">
      <input type="radio" id="tab-2" name="tab-group-1" @click="showHazardTab=false; showRegionTab=true; showUnitsTab=false; showAlertsTab=false;">
      <label class="editorTabLabel" for="tab-2">Regions</label>
      <div class="editorTabContent" v-if="showRegionTab">

        <!-- start region editor -->
        <div>
          <div class="hc-block">
            <div class="hc-heading">
              <div class="hc-heading__text"><br/>Regions<br/></div>
              <div v-if="false"><!-- disabled because clients shouldnt add regions -->
                <div class="hc-actions">
                  <button :class="buttonClass" @click="showAddRegionModalTrue"><i class="fa fa-plus" :title="addRegion"></i></button>
                </div>
                <div v-if="showAddRegionModal">
                  <transition name="editor-modal">
                    <div class="editor-modal-mask">
                      <div class="editor-modal-wrapper">
                        <div class="editor-modal-container">
                          <input placeholder="Region ID" v-model="inputRegionId">
                          <input placeholder="Region Name" v-model="inputRegionName">
                          <button class="editor-modal-default-button editor-modal-default-button--teal" @click="showAddRegionModal=false; addRegion()">OK</button>
                          <button class="editor-modal-default-button editor-modal-default-button--teal" @click="showAddRegionModal=false">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>

            <div class="hc-content__config">
              <div class="tab-content">
                <div class="tab-pane">
                  <div class="hc-block" v-for="region in regions" :key="region.id">
                    <div class="hc-heading">
                      <div class="hc-heading__text">{{ region.name }}</div>
                      <span class="hc-actions">
                        <button :class="buttonClass">
                          <i class="fa fa-plus" :title="addSiteTitle" @click="showAddSiteModalClick(region.id)"></i>
                        </button>
                      </span>
                    </div>
                    <div class="hc-location-details">
                      <div class="flex-row" style="margin-left:1.5em">
                        <div><label>Min Latitude</label><input disabled type="text" class="wg-form-control" :value="region.gridDefinition.minLat"/></div>
                        <div><label>Min Longitude</label><input disabled type="text" class="wg-form-control" :value="region.gridDefinition.minLon"/></div>
                        <div><label>Max Latitude</label><input disabled type="text" class="wg-form-control" :value="region.gridDefinition.maxLat"/></div>
                        <div><label>Max Longitude</label><input disabled type="text" class="wg-form-control" :value="region.gridDefinition.maxLon"/></div>
                        <div><label>Timezone</label><input disabled type="text" class="wg-form-control" v-model="region.timezone"/></div>
                      </div>
                    </div>
                    <div class="hc-location-details" v-for="site in region.sites" :key="site.id">
                      <div class="flex-row" style="margin-left:1.5em" :key="region.id + site.id">
                        <div><label>Site ID</label><input disabled type="text" class="wg-form-control" v-model="site.id"/></div>
                        <div><label>Site name</label><input disabled type="text" class="wg-form-control" v-model="site.name"/></div>
                        <div><label>Latitude</label><input disabled type="text" class="wg-form-control" v-model="site.latitude"/></div>
                        <div><label>Longitude</label><input disabled type="text" class="wg-form-control" v-model="site.longitude"/></div>
                        <div><label>Radius ({{unitSet['radius']}})</label><input disabled type="text" class="wg-form-control" :value="formatRadius(site.radius)"/></div>
                        <div><label>Timezone</label><input disabled type="text" class="wg-form-control" v-model="site.timezone"/></div>
                        <button :class="buttonClass"><i class="fas fa-edit" :title="editSiteTitle" @click="editSite(region.id, site.id)"></i></button>
                        <button :class="buttonClass"><i class="fas fa-trash" :title="deleteSiteTitle" @click="deleteSite(region.id, site.id)"></i></button>
                      </div>
                    </div>
                    <!-- start add/edit site modal -->
                    <div v-if="showAddSiteModal || showEditSiteModal">
                      <transition name="editor-modal">
                        <div class="editor-modal-mask">
                          <div class="editor-modal-wrapper">
                            <div class="editor-modal-container flex-row">
                              <div><label>Site ID</label><input type="text" class="wg-form-control" v-model="inputSiteId"/></div>
                              <div><label>Site name</label><input type="text" class="wg-form-control" v-model="inputSiteName"/></div>
                              <div><label>Latitude</label><input type="number" class="wg-form-control" v-model="inputSiteLatitude"/></div>
                              <div><label>Longitude</label><input type="number" class="wg-form-control" v-model="inputSiteLongitude"/></div>
                              <div><label>Radius ({{unitSet['radius']}})</label><input type="number" class="wg-form-control" v-model="inputSiteRadius"/></div>
                              <div>
                                  <label>Timezone</label>
                                  <select class="wg-form-control" style="margin-right: 40px;" v-model="selectedTimezone" :value="selectedTimezone">
                                    <option v-for="timezone in availableTimezones" :value="timezone" :key="timezone">
                                      {{ timezone }}
                                    </option>
                                  </select>
                              </div>
                              <button class="editor-modal-default-button editor-modal-default-button--teal" @click="addEditSite">OK</button>
                              <button class="editor-modal-default-button editor-modal-default-button--teal" @click="cancelAddEditSite">Cancel</button>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                    <!-- end add/site site modal -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end region editor -->

    <!-- UNITS TAB -->
    <div class="editorTab">
      <input type="radio" id="tab-3" name="tab-group-1" @click="showHazardTab=false; showRegionTab=false; showUnitsTab=true; showAlertsTab=false;">
      <label class="editorTabLabel" for="tab-3">Units</label>
      <div class="editorTabContent" v-if="showUnitsTab">
        <div>
          <div class="hc-block">
            <div class="hc-heading">
              <div class="hc-heading__text"><br/>Units<br/></div>
            </div>
            <div class="hc-content__config">
              <div class="editorTab-content">
                <div class="editorTab-pane">
                  <div v-for="us in unitSets" :key="us.id">
                    <input style="display: inline;" type="radio" name="units-radio" :value="us.id" :checked="us.id === unitSetId" @change="updateUnitSet(us.id)">{{us.name}}<br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end units tab -->

  </div>
</div>
</template>


<script>
import SettingsWizard from './SettingsWizard.vue';
import swal from 'sweetalert';
import _ from 'lodash';
import unitSets from '../constants/unitSets.json';
import { UnitConverter } from '../js/unitConverter.js';
import { SensitivityConverter } from '../js/sensitivityConverter.js';
import { formatDisplayUnit, formatComparisonType } from '../js/helperFunctions.js';
import { alertParameters } from '../js/alertParameters.js';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
const unitConverter = new UnitConverter();
const sensitivityConverter = new SensitivityConverter();

export default {
  components: { SettingsWizard },
  data() {
    return {

      dummyHazard: {},
      activeHazard: {},
      activeParameters: {},

      // start region editor data
      siteIsLocked: true,
      showRegionTab: false,
      inputRegionId: undefined,
      inputRegionName: undefined,
      activeRegionId: undefined,
      inputSiteId: undefined,
      inputSiteName: undefined,
      inputSiteLatitude: undefined,
      inputSiteLongitude: undefined,
      inputSiteRadius: undefined,
      defaultTimezone: 'Etc/UTC',
      selectedTimezone: 'Etc/UTC',
      showAddSiteModal: false,
      showEditSiteModal: false,
      showAddRegionModal: false,
      // end region editor data

      // start hazard editor data
      showHazardTab: true,
      inputHazardKey: undefined,
      inputHazardName: undefined,
      showAddHazardModal: false,
      showEditHazardModal: false,
      activeHazardKey: undefined,
      // end hazard editor data

      // start units tab
      showUnitsTab: false,
      unitSets: JSON.parse(JSON.stringify(unitSets)),
      // end units tab

      // start alerts tab
      allAlertParameters: JSON.parse(JSON.stringify(alertParameters)),
      alertComparisonTypes: ['greaterThan', 'lessThan', 'equal'],
      alertAvailableOperators: ['or', 'and'],
      showAlertsTab: false,
      showAddAlertModal: false,
      showEditAlertModal: false,
      inputAlertId: undefined,
      inputAlertName: undefined,
      alertRegion: undefined,
      alertSite: undefined,
      alertParameter: 'Wild Fire',
      inputAlertRadius: undefined,
      alertChildConditions: undefined,
      alertOperator: undefined
      // end alerts tab

    }
  },
  computed: {
    availableParameters() { return JSON.parse(JSON.stringify(this.$store.getters.parameterConfig)); },
    availableParametersArray() {
      const unSortedParameters = Object.keys(this.availableParameters).map(k => this.availableParameters[k]);
      const sortedParameters = unSortedParameters.sort((param1, param2) => {
        if (param1.name < param2.name) {return -1;}
        if (param1.name > param2.name) {return 1;}
        return 0;
      });
      return sortedParameters;
    },
    hazardNames() { return Object.keys(this.hazards).map(k => this.hazards[k].name); },
    deleteSiteTitle() { return this.readOnly ? 'Cannot delete site' : 'Delete site'; },
    editSiteTitle() { return this.readOnly ? 'Cannot edit site' : 'Edit site'; },
    deleteHazardTitle() { return this.readOnly ? 'Cannot delete hazard' : 'Delete hazard'; },
    addHazardTitle() { return this.readOnly ? 'Cannot add hazard' : 'Add hazard'; },
    addSiteTitle() { return this.readOnly ? 'Cannot add site' : 'Add site'; },
    addAlertTitle() { return 'Add alert'},
    editAlertTitle() { return this.readOnly ? 'Cannot edit alert' : 'Edit alert'; },
    deleteAlertTitle() { return this.readOnly ? 'Cannot delete alert' : 'Delete alert'; },
    editHazardTitle() { return this.readOnly ? 'Cannot edit hazard' : 'Edit hazard'; },
    toggleHazardTitle() { return this.readOnly ? 'Cannot toggle hazard' : 'Toggle hazard'; },
    cancelEditTitle() { return 'Cancel Edit'},
    buttonClass() { return this.readOnly ? 'hc-btn-offlimits' : 'hc-btn'; },
    readOnly() { return this.$store.getters.readOnly; },
    config() { return this.$parent.config; },
    preferences() { return this.$parent.preferences; },
    regions() { return this.config.regions; },
    hazards() { return _.orderBy(this.config.hazards,'id', 'desc') ; },
    availableTimezones() { return moment.tz.names(); },
    unitSetId() { return this.config.unitSetId; },
    unitSet() { return this.unitSets[this.unitSetId]; },
    allSites() {
      const regions = this.config.regions;
      const sites = [];
      for (const region in regions) {
        const regionSites = regions[region].sites;
        for (const site in regionSites) {
          const s = regionSites[site];
          s.region = regions[region].name;
          s.regionId = regions[region].id;
          sites.push(s);
        }
      }
      return sites;
    }
  },
  methods: {
    updateUnitSet(id) {
      this.config.unitSetId = id;
    },
    hazardSummaryText(parameter) {
      const parameterName = this.availableParameters[parameter.id].name;
      const comparisonType = this.makeDisplayText(parameter.threshold.comparisonType);
      const units = this.availableParameters[parameter.id].threshold.units;
      const mu = this.convertValue(parameter.threshold.mu, units, this.unitSet[parameter.id], parameter.threshold.precision);
      const mu1 = this.convertValue(parameter.threshold.mu1, units, this.unitSet[parameter.id], parameter.threshold.precision);
      const mu2 = this.convertValue(parameter.threshold.mu2, units, this.unitSet[parameter.id], parameter.threshold.precision);
      const displayUnits = this.formatDisplayUnit(this.unitSet[parameter.id]);
      const sigma = this.convertSensitivity(parameter.threshold.sigma, units, this.unitSet[parameter.id], parameter.threshold.precision);
      if (comparisonType == 'between') {
        return `${parameterName} ${comparisonType} ${mu1} and ${mu2} ${displayUnits} with a sensitivity of ${sigma} ${displayUnits}`;
      }
      return `${parameterName} ${comparisonType} ${mu} ${displayUnits} with a sensitivity of ${sigma} ${displayUnits}`;
    },
    convertValue(valueIn, unitsIn, unitsOut, precision = 1) {
      const valueOut = unitConverter.convert(valueIn, unitsIn, unitsOut, precision);
      return +valueOut;
    },
    camelCaseToSentenceCase(s) {
      if (s == null) {
        return;
      }
      const result = s.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    convertSensitivity(valueIn, unitsIn, unitsOut, precision = 1) {
      const valueOut = sensitivityConverter.convert(valueIn, unitsIn, unitsOut, precision);
      return +valueOut;
    },
    formatRadius(radius) {
      if (!isNaN(radius)) {
        const convertedValue = this.convertValue(radius, "m", this.unitSet['radius'], 2);
        return convertedValue;
      }
      return radius;
    },
    formatDisplayUnit,
    formatComparisonType,
    makeDisplayText(s) {
      return s.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").toLowerCase()
    },
    hazardCompleteEventHandler(hazardObj, initHazardId = undefined) {
      if (initHazardId) { // edit mode
        this.$delete(this.config.hazards, initHazardId);
        this.updateStarredHazardPreferences(hazardObj, initHazardId);
      }
      this.dummyHazard = {};
      this.$set(this.config.hazards, hazardObj.id, hazardObj);
    },
    hazarEditAddCancelEventHandler(activeHazardOriginal,initHazardId = undefined){
      if(initHazardId) {
        this.config.hazards[initHazardId] = activeHazardOriginal;
      }
      else {
        this.dummyHazard = {};
      }
      this.showEditHazardModal = false;
      this.showAddHazardModal = false;
    },
    hideAddHazardModal() {
      this.showAddHazardModal = false;
    },
    hideEditHazardModal(hazard) {
      this.showEditHazardModal = false;
    },
    showAddSiteModalClick(regionId) {
      if (this.readOnly) return;
      this.setActiveRegionId(regionId);
      this.showAddSiteModalTrue();
    },
    setActiveRegionId(id) {
      if (this.readOnly) return;
      this.activeRegionId = id;
    },
    beginEditHazard(hazard) {
      this.activeHazard = hazard;
      this.activeParameters = hazard.parameters;
      this.showEditHazardModalTrue();
    },
    showEditHazardModalTrue() {
      this.showEditHazardModal = this.readOnly ? false : true;this.showAddHazardModal = false
    },
    showAddHazardModalTrue() {
      this.showAddHazardModal = this.readOnly ? false : true; this.showEditHazardModal = false;
    },
    showAddRegionModalTrue() {
      this.showAddRegionModal = this.readOnly ? false : true;
    },
    showAddSiteModalTrue() {
      this.showAddSiteModal = this.readOnly ? false : true;
    },

    updateStarredHazardPreferences(newHazardObj, previousHazardId) {

      const preferences = JSON.parse(JSON.stringify(this.preferences));
      if (!preferences.starredHazardsByRegion) { return; }
      const favouriteRegions = preferences.starredHazardsByRegion;

      for (const region in favouriteRegions) {
        const sites = favouriteRegions[region];
        for (const site in sites) {
          const siteHazards = sites[site];
          const hazardIsFavourite = siteHazards.includes(previousHazardId) ? true : false;
          if (hazardIsFavourite) {
            const regionChanged = !newHazardObj.regions.includes(region) ? true : false; // region may change if user edits the regions assigned for the hazard
            if (regionChanged) {
              const hazardIndex = siteHazards.findIndex(function (hazardId) {return hazardId == previousHazardId} );
              siteHazards.splice(hazardIndex, 1);
            }
          }
        }
        this.$set(this.preferences.starredHazardsByRegion, region, favouriteRegions[region]);
      }
    },
    updateSitePreferences(previousSiteId) {

      const preferences = JSON.parse(JSON.stringify(this.preferences));

      //updating starred sites section of preference config
      if (preferences.hasOwnProperty("starredSitesByRegion")) {
        const favouriteSiteRegions = preferences.starredSitesByRegion;
        for (const region in favouriteSiteRegions) {
          const sites = favouriteSiteRegions[region];
          const starredSite = sites.includes(previousSiteId) ? true : false;
          if (starredSite) {
            const siteIndex = sites.findIndex(function (siteId) {return siteId == previousSiteId; } );
            sites.splice(siteIndex, 1);
          }
          this.$set(this.preferences.starredSitesByRegion, region, favouriteSiteRegions[region]);
        }
      }

      // updating alerts section of preference
      if (!preferences.hasOwnProperty("alertSitesByRegion")) { return; }
      const alertRegions = preferences.alertSitesByRegion;
      for (const region in alertRegions) {
        const sites = alertRegions[region];
        const siteHasAlert = sites.includes(previousSiteId) ? true : false;
        if (siteHasAlert) {
          const siteIndex = sites.findIndex(function (siteId) {return siteId == previousSiteId} );
          sites.splice(siteIndex, 1);
        }
        this.$set(this.preferences.alertSitesByRegion, region, alertRegions[region]);
      }
    },
    // start region editor methods
    deleteSite(rKey, sKey) {
      if (this.readOnly) return;
      this.updateSitePreferences(sKey);
      this.$delete(this.config.regions[rKey].sites, sKey);
    },
    validateSite(rKey, id, lat, lon, radius, checkId) {
      const reasons = [];
      try {
        let valid = true;
        const grid = this.config.regions[rKey].gridDefinition;
        const sites = this.config.regions[rKey].sites;
        if (isNaN(lat)) {
          valid = false;
          reasons.push(['The latitude you entered is not a number.']);
        };
        if (isNaN(lon)) {
          valid = false;
          reasons.push(['The longitude you entered is not a number.']);
        };
        if (lat < -90 || lat >= 90) {
          valid = false;
          reasons.push(['The latitude you entered is not in domain [-90, 90).']);
        };
        if (lon < -180 || lon >= 180) {
          valid = false;
          reasons.push(['The longitude you entered is not in domain [-180, 180).']);
        };
        if (lat < grid.minLat || lat > grid.maxLat) {
          valid = false;
          reasons.push([`The latitude you entered is not in domain (${grid.minLat}, ${grid.maxLat}).`])
        };
        if (lon < grid.minLon || lon > grid.maxLon) {
          valid = false;
          reasons.push([`The longitude you entered is not in domain (${grid.minLon}, ${grid.maxLon}).`])
        };
        if (radius <= 0) {
          valid = false;
          reasons.push([`Radius cannot be lower than 0.`])
        };
        if (radius > 100000) {
          valid = false;
          reasons.push([`Radius cannot be greater than 100000m.`])
        };
        if (checkId) {
          if (id in sites) {
            valid = false;
            reasons.push(['The site ID you entered already exists.']);
          };
        }
        return [valid, reasons];
      }
      catch(e) {
        return [false, reasons];
      }
    },
    cancelAddEditSite() {
      this.showAddSiteModal = false;
      this.showEditSiteModal = false;
      this.inputSiteId = '';
      this.inputSiteName = '';
      this.inputSiteLatitude = '';
      this.inputSiteLongitude = '';
      this.inputSiteRadius = '';
      this.selectedTimezone = this.defaultTimezone;
    },
    editSite(rKey, sKey) {
      if (this.readOnly) return;
      this.activeRegionId = rKey;
      this.activeSiteId = sKey;
      const site = this.regions[rKey].sites[sKey];
      this.showEditSiteModal = true;
      this.inputSiteId = site.id;
      this.inputSiteName = site.name;
      this.inputSiteLatitude = site.latitude;
      this.inputSiteLongitude = site.longitude;
      this.inputSiteRadius = this.formatRadius(site.radius);
      this.selectedTimezone = site.timezone;
    },
    addEditSite() {
      if (this.readOnly) return;
      const site = {
        id: this.inputSiteId,
        name: this.inputSiteName,
        latitude: parseFloat(this.inputSiteLatitude),
        longitude: parseFloat(this.inputSiteLongitude),
        timezone: this.selectedTimezone
      };
      if (this.inputSiteRadius) {
        site.radius = this.convertValue(parseFloat(this.inputSiteRadius), this.unitSet['radius'], "m", 2);
      }
      const newSite = this.showAddHazardModal;
      const siteValidationResult = this.validateSite(this.activeRegionId, site.id, site.latitude, site.longitude, site.radius, newSite);
      const siteIsValid = siteValidationResult[0];
      const reasonsSiteIsInvalid = siteValidationResult[1];
      if (siteIsValid) {
        this.$set(this.config.regions[this.activeRegionId].sites, site.id, site);
        this.cancelAddEditSite(); // clears temp input variables
      }
      else {
        let msg = 'Invalid input.\n';
        reasonsSiteIsInvalid.forEach(reason => {
          msg += reason + '\n';
        })
        swal(msg);
      }
    },
    addRegion() {
      if (this.readOnly) return;
      const region = {
        id: this.inputRegionId,
        name: this.inputRegionName,
        sites: {}
      };
      this.$set(this.config.regions, region.id, region);
    },
    // end region editor methods


    // start hazard editor methods
    hazardIsInRegion(hKey, rKey) {
      return this.config.hazards[hKey].regions.includes(rKey);
    },
    hazardIsEnabled(hazard) {
      if (!hazard.hasOwnProperty("enabled") || hazard.enabled) {
        return true;
      } 
      else {
        return false;
      }
    },
    getHazardStatusClass(hazard) {
      if (this.hazardIsEnabled(hazard)) {
        return 'hazard-enabled';
      }
      else {
        return 'hazard-disabled';
      }
    },
    deleteHazard(hKey) {
      if (this.readOnly) return;
      swal({
        title: "Delete?",
        text: "Are you sure you want to delete this hazard?",
        buttons: true,
        dangerMode: true,
      })
      .then((confirm) => {
        if (confirm) {
           this.$delete(this.config.hazards, hKey);
           const dummyHazard = { "id":hKey, "regions":[] };
           this.updateStarredHazardPreferences(dummyHazard, hKey);
        }
      });
    },
    addHazard() {
      if (this.readOnly) return;
      const hazard = {
        id: this.inputHazardKey,
        name: this.inputHazardName,
        parameters: {},
        regions: []
      };
      this.$set(this.config.hazards, this.inputHazardKey, hazard);
    },
    toggleHazard(hazard) {
      if (this.readOnly) return;
      const hazardObj = JSON.parse(JSON.stringify(hazard));
      hazardObj.enabled = !this.hazardIsEnabled(hazard);
      this.$set(this.config.hazards, hazard.id, hazardObj);
    },
    // end hazard editor methods

    // start alert editor methods
    deleteAlert(alertId) {
      if (this.readOnly) return;
      const preferences = JSON.parse(JSON.stringify(this.preferences));
      this.$delete(preferences.alertObservations, alertId);
      //forcing vue component to update
      this.$set(this.preferences, 'alertObservations', preferences.alertObservations);
    },
    editAlert(alertId) {
      if (this.readOnly) return;
      const alert = JSON.parse(JSON.stringify(this.preferences.alertObservations[alertId]));
      this.showEditAlertModal = true;
      this.inputAlertId = alert.id;
      this.inputAlertName = alert.name;
      this.alertSite = alert.siteID;
      this.alertParameter = this.getAlertIdFromParameter(alert.parameter);
      this.inputAlertRadius = this.formatRadius(alert.radius);
      this.alertOperator = alert.operator;
      this.alertChildConditions = this.formatChildConditions(alert.childConditions);
    },
    cancelAddEditAlert() {
      this.showAddAlertModal = false;
      this.showEditAlertModal = false;
      this.inputAlertId = '';
      this.inputAlertName = '';
      this.alertSite = '';
      this.alertParameter = '';
      this.alertOperator = undefined;
      this.alertChildConditions = undefined;
      this.inputAlertRadius = '';
    },
    addEditAlert() {
      if (this.readOnly) return;
      const siteObj = this.allSites.find(site => {return site.id == this.alertSite });
      const alert = {
        id: this.inputAlertId,
        name: this.inputAlertName,
        parameter: this.alertParameter,
        parameterName: this.allAlertParameters[this.alertParameter].name,
        site: siteObj.name,
        siteID: siteObj.id,
        region: siteObj.region,
        regionID: siteObj.regionId,
        latitude: parseFloat(siteObj.latitude),
        longitude: parseFloat(siteObj.longitude),
        radius: isNaN(this.inputAlertRadius) ? 0 : this.convertValue(parseFloat(this.inputAlertRadius), this.unitSet['radius'], "m", 2)
      };


      if (this.alertChildConditions) {
        const conditions = this.alertChildConditions;
        const childConditions = [];
        for (const condition in conditions) {
          conditions[condition].threshold = isNaN(conditions[condition].threshold) ? 0 : parseFloat(conditions[condition].threshold);
          const needsToBeConverted = conditions[condition].hasOwnProperty('displayUnits') ? true : false;
          if (needsToBeConverted) {
            // we are converting from display units to original units
            conditions[condition].threshold = unitConverter.convert(conditions[condition].threshold, conditions[condition].displayUnits, conditions[condition].units, 1);
          }
          childConditions.push(conditions[condition]);
        }
        alert.childConditions = childConditions;
        alert.operator = this.alertOperator;
      }

      const newAlert = this.showAddAlertModal;
      const alertValidationResult = this.validateAlert(alert.id, alert.name, alert.radius, alert.operator, alert.childConditions, newAlert);
      const alertIsValid = alertValidationResult[0];
      const reasonsAlertIsInvalid = alertValidationResult[1];

      if (alertIsValid) {
        if (!this.preferences.hasOwnProperty('alertObservations')) {
          this.preferences.alertObservations = {};
        }
        this.$set(this.preferences.alertObservations, alert.id, alert);
        this.cancelAddEditAlert(); // clears temp input variables
      }
      else {
        let msg = 'Invalid input.\n';
        reasonsAlertIsInvalid.forEach(reason => {
          msg += reason + '\n';
        })
        swal(msg);
      }
    },
    validateAlert(id, name, radius, operator, childConditions, newAlert) {
      const reasons = [];
      try {
        let valid = true;
        const regions = this.config.regions;
        const alerts = this.preferences.alertObservations;
        if (!id) {
          valid = false;
          reasons.push(['You need to enter a valid alert id']);
        };
        if (!name) {
          valid = false;
          reasons.push(['You need to enter a valid alert name']);
        };
        if (radius <= 0) {
          valid = false;
          reasons.push([`Radius cannot be lower than 0 or equal to 0m`]);
        };
        if (radius > 100000000) {
          valid = false;
          reasons.push([`Radius cannot be greater than 100000000m`]);
        };
        if (newAlert && this.preferences.hasOwnProperty('alertObservations')) {
          if (id in alerts) {
            valid = false;
            reasons.push(['The alert ID you entered already exists.']);
          };
        };
        for (const condition in childConditions) {
          const c = childConditions[condition];

          if (c.threshold <= 0 || isNaN(c.threshold)) {
            valid = false;
            reasons.push([c.name + ' must be greater than 0.']);
          }
        }

        if (childConditions) {
          if (childConditions.length > 0 && !operator) {
            valid = false;
            reasons.push(['Parameters with child conditions must have an operator assigned.']);
          }
        }

        return [valid, reasons];
      }
      catch(e) {
        console.log(e);
        return [false, reasons];
      }
    },
    setParameterChildConditions(event) {
      const parameters = JSON.parse(JSON.stringify(this.allAlertParameters));
      const allParamKeys = Object.keys(parameters);
      const paramKey = allParamKeys.find((parameterKey) => parameters[parameterKey].id == event.target.value);

      if (paramKey) {
        this.alertChildConditions = parameters[paramKey].childConditions;
        this.alertOperator = parameters[paramKey].operator;
      } else {
        return {};
      }
    },
    getAlertIdFromParameter(parameterName) {
      return parameterName.replaceAll(' ', '').toLowerCase();
    },
    displayChildConditions(parameter) {
      const parameterId = this.getAlertIdFromParameter(parameter);
      const parameters = JSON.parse(JSON.stringify(this.allAlertParameters));
      const allParamKeys = Object.keys(parameters);
      const paramKey = allParamKeys.find((parameterKey) => parameters[parameterKey].id == parameterId);
      return parameters[paramKey].displayChildConditions;
    },
    formatChildThreshold(condition) {
      const needsToBeConverted = condition.hasOwnProperty('displayUnits') ? true : false;
      if (needsToBeConverted) {
        const value = unitConverter.convert(condition.threshold, condition.units, condition.displayUnits, 1);
        return value;
      } else {
        return condition.threshold;
      }
    },
    formatChildConditions(conditions) {
      for (const conditionIndex in conditions) {
        const condition = conditions[conditionIndex];
        condition.threshold = this.formatChildThreshold(condition);
      }
      return conditions;
    }
    // end alert editor methods

  },
 mounted() {
    //
  }
}
</script>
