<template>
  <div class="animation-controls-container">
    <div class="animation-controls-play" v-on:click="onControlsClick($event)">
      <i v-if="!isPlaying" class="fas fa-play"></i>
      <i v-if="isPlaying" class="fas fa-stop"></i>
    </div>
    <div class="animation-controls-bar" v-on:click="onBarClick($event)" ref="animationBar">
      <div class="animation-controls-bar-played" :style="{ width: currentPositionPercent + '%' }">
        <div class="animation-controls-current-position" v-on:>
          <div class="animation-controls-current-position-panel" v-on:mousedown="onPanelMousedown($event)">
            {{ currentPositionDateTime }}
          </div>
        </div>
      </div>
    </div>
    <div class="animation-controls-labels">
      <div class="animation-controls-label" v-for="dayLabel in this.dayLabels">
        {{ dayLabel }}
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import momentTimezone from 'moment-timezone';
  import { panelConstants } from '../js/panelConstants';
  export default {
    props: [
      'dateTimes',
      'dateTime',
      'panelSite',
      'panelRegion',
      'activePanel',
      'panelPosition'
    ],
    computed: {
      dates() {
        return this.dateTimes.reduce((dates, nextDateTime) => {
          const dateString = nextDateTime.format('YYYY-MM-DD');
          if (!dates.includes(dateString)) {
            dates.push(dateString);
          }
          return dates;
        }, []);
      },
      dayLabels() {
        return this.dates.map((date) => {
          return moment.utc(date).format('MMM Do');
        });
      },
      panelType() {
        return panelConstants;
      },
      timezone() {
        const config = this.$store.getters.config;
        let timezone = "Etc/UTC";
        if (this.panelRegion && this.panelPosition > 0) {
          const region = config.regions[this.panelRegion];
          if (this.panelSite && this.activePanel == this.panelType.Site) {
            const site = region.sites[this.panelSite];
            timezone = site.hasOwnProperty('timezone') ? site.timezone : "Etc/UTC";
          } else {
            timezone = region.hasOwnProperty('timezone') && this.activePanel == this.panelType.Region ? region.timezone : "Etc/UTC";
          }
        }
        return timezone;
      },
      currentPositionDateTime() {
        return moment.utc(this.dateTime).tz(this.timezone).startOf('hour').format('ddd Do, LT z');
      },
      currentPositionPercent() {
        if (this.dates.length !== 0) {
          const startDate = moment.utc(this.dates[0], 'YYYY-MM-DD');
          const endDate = moment.utc(this.dates[this.dates.length -1], 'YYYY-MM-DD').add(1, 'day');
          const currentDate = moment.utc(this.dateTime).startOf('hour');
          const fromStartHours = moment.duration(currentDate.diff(startDate)).asHours();
          const totalHours = moment.duration(endDate.diff(startDate)).asHours();
          return fromStartHours/totalHours * 100;
        }
        return 0;
      }
    },
    data() {
      return {
        isPlaying: false,
        eventEmitterInterval: null
      }
    },
    methods: {
      onControlsClick() {
        clearInterval(this.eventEmitterInterval);
        this.isPlaying = !this.isPlaying;
        if (this.isPlaying) {
          const startingCurrentDate = this.dateTime;
          const startingClosestDate = this.dateTimes.reduce(function(prevDate, currDate) {
              return ( Math.abs(moment.duration(currDate.diff(startingCurrentDate)).asHours()) < Math.abs(moment.duration(prevDate.diff(startingCurrentDate)).asHours()) ? currDate : prevDate);
          });

          let newDateIndex = this.dateTimes.findIndex((date) => date == startingClosestDate);

          this.eventEmitterInterval = setInterval(() => {
            newDateIndex = newDateIndex + 1 < this.dateTimes.length ? newDateIndex + 1 : 0;
            const nextDate = this.dateTimes[newDateIndex];
            this.$emit('change', {
              newDateTime: nextDate
            });
          }, 1000);
        }
      },
      onBarClick(event) {
        if (this.dates !== 0) {
          const elementPosition = this.$refs.animationBar.getBoundingClientRect();
          const startingPositionX = elementPosition.x;
          const percentOffset = Math.min(Math.max(event.clientX - startingPositionX, 0), elementPosition.width)/elementPosition.width;
          const startDate = moment.utc(this.dates[0], 'YYYY-MM-DD');
          const endDate = moment.utc(this.dates[this.dates.length -1], 'YYYY-MM-DD').add(1, 'day');
          const totalHours = moment.duration(endDate.diff(startDate)).asHours();
          const currentDateOfCursor = startDate.add(Math.round(percentOffset*totalHours), 'hours');

          const closestAvailableTime = this.dateTimes.reduce(function(prevDate, currDate) {
            return ( Math.abs(moment.duration(currDate.diff(currentDateOfCursor)).asHours()) < Math.abs(moment.duration(prevDate.diff(currentDateOfCursor)).asHours()) ? currDate : prevDate);
          });

          this.$emit('change', {
            newDateTime: closestAvailableTime.format()
          });
        }
      },
      onPanelMousedown() {
        this.setupEvents();
      },
      setupEvents() {
        document.addEventListener('mousemove', this.handleMoveEvent); // eslint-disable-line no-undef
        document.addEventListener('mouseup', this.cleanupEvents); // eslint-disable-line no-undef
      },
      cleanupEvents() {
        document.removeEventListener('mousemove', this.handleMoveEvent); // eslint-disable-line no-undef
        document.removeEventListener('mouseup', this.cleanupEvents); // eslint-disable-line no-undef
      },
      handleMoveEvent(event) {
        if (this.dates !== 0) {
          const elementPosition = this.$refs.animationBar.getBoundingClientRect();
          const startingPositionX = elementPosition.x;
          const percentOffset = Math.min(Math.max(event.clientX - startingPositionX, 0), elementPosition.width)/elementPosition.width;
          const startDate = moment.utc(this.dates[0], 'YYYY-MM-DD');
          const endDate = moment.utc(this.dates[this.dates.length -1], 'YYYY-MM-DD').add(1, 'day');
          const totalHours = moment.duration(endDate.diff(startDate)).asHours();
          const currentDateOfCursor = startDate.add(Math.round(percentOffset*totalHours), 'hours');

          const closestAvailableTime = this.dateTimes.reduce(function(prevDate, currDate) {
            return ( Math.abs(moment.duration(currDate.diff(currentDateOfCursor)).asHours()) < Math.abs(moment.duration(prevDate.diff(currentDateOfCursor)).asHours()) ? currDate : prevDate);
          });

          this.$emit('change', {
            newDateTime: closestAvailableTime.format()
          });
        }
      }
    },
  }
</script>
