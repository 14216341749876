<template>
    <div class="timeline__label timeline__label--1hr" :data-content=hourLabel :class="{ 'timeline__unit--daynight': displaySunsetHour  }"></div>
</template>

<script>
  import '../css/style.css';
  export default {
    props: [
    'data',
    'sunriseHour',
    'sunsetHour'
    ],
    computed: {
      hourLabel() {
        if (this.data.displayLabel) {
          return this.data.forecastHour.toFixed(0).padStart(2, '0');
        }
        return '';
      },
      displaySunsetHour() {
        if (this.data.forecastHour > this.sunsetHour || this.data.forecastHour < this.sunriseHour) {
          return true;
        }
        return false;
      }
    },
  }
</script>