<template>
<div>
  <form-wizard
    @on-complete="complete"
    :title="title"
    subtitle=""
    shape="square"
    color="#3498db"
    :style="transparentBackgroundColorStyle"
    @on-loading="setLoading"
  >
    <tab-content
      title="Choose parameter"
      icon="ti ti-cloud"
      :before-change="prepForParameterConfigChart"
      class="wizard-parameter-tab"
    >
      <div
        v-for="(activeParameter, index) in activeParameters"
        :key="activeParameter.id"
        class="wizard-parameter-item"
      >
        <h4>
          <button
            :id="'btn-remove-' + index"
            :class="buttonClass"
            @click="removeParameter($event, index)">
            <i class="fa fa-minus"></i>
          </button>
          Parameter {{ index + 1 }}
        </h4>
        <div class="wizard-parameter-container">
          <div class="wizard-parameter-content">
            <span class="wizard-parameter-select">
              <select required @change="setParameter($event, index)" class="wg-form-control wg-form-control--auto">
                <option
                  v-for="availableParameter in availableParametersArray"
                  :disabled="isUnavailableParameter(availableParameter.id)"
                  :selected="availableParameter.id === activeParameter.id"
                  :key="availableParameter.id"
                  :value="availableParameter.id"
                >
                {{ availableParameter.name }}
                </option>
              </select>
              <select
                required
                @change="setParameterComparisonType($event, index)"
                v-model="activeParameter.threshold.comparisonType" class="wg-form-control wg-form-control--auto" >
                <option v-for="comparisonType in comparisonTypes" :key="comparisonType" :value="comparisonType">
                {{ camelCaseToSentenceCase(comparisonType) }}
                </option>
              </select>
            </span>
            <span v-if="activeParameters.length != 1" class="wizard-parameter-remove-btn">
            </span>
          </div>
        </div>
      </div>
      <span v-if="checkIfMaxParameters" class="wizard-parameter-add-container">
        <button
          id="wizard-btn-add-parameter"
          :class="buttonClass"
          @click="addNewParameter($event)">
          <i class="fa fa-plus"></i>
        </button>
        <label for="wizard-btn-add-parameter">Add Parameter</label>
      </span>
    </tab-content>

    <tab-content title="Configure thresholds" :before-change="validateThresholdInput" icon="ti ti-settings" class="left-15">
      <div v-if="activeParameters.length > 1">
        <h4>Logical Operator:</h4>
        <select
          required
          v-model="activeHazard.logicalOperator"
          class="wg-form-control wg-form-control--auto">
          <option v-for="operator in logicalOperators" :key="operator" :value="operator">
            {{ camelCaseToSentenceCase(operator) }}
          </option>
        </select>
      </div>
      <div v-for="ap in activeParameters" :key="ap.id">
        <h4>{{ ap.name }}</h4>
        <parameter-config-chart
          @update-from-chart-event="updateFromChart"
          :key="componentKey"
          :parameter="ap"
          :parameterInfo="availableParameters[ap.id]"
          :units="availableParameters[ap.id].threshold.units"
          :displayUnits="unitSet[ap.id]"
        >
        </parameter-config-chart>
      </div>
    </tab-content>

    <tab-content title="Select regions" icon="ti ti-world" :before-change="validateHazardRegionInput">
      Select what regions this hazard will apply to
      <div v-for="region in regions" :key="region.id" :value="region.id" class="left-45">
        <input
          :id="'cb-wizard-region-' + region.id"
          type="checkbox"
          :checked="activeCheckedRegionIds.includes(region.id)"
          @change="updateCheckedRegionIds($event, region.id)"
        > <label :for="'cb-wizard-region-' + region.id">{{ region.name }}</label>
      </div>
    </tab-content>

    <tab-content title="Choose name" icon="ti ti-user" :before-change="validateHazardNameInput" :after-change="validateUniqueNames">
      <div class="left-65">
        <input style="width:200px" id="inputHazardName" placeholder="Enter a unique name" maxlength="30" required type="text"  v-model="activeHazard.name" class="wg-form-control"/>
      </div>
    </tab-content>
    <tab-content title="Review" icon="ti ti-check">
      <div class="wizard-review-container">
        <table class="wizard-review-detail-table">
          <tbody>
          <tr>
            <td>Name: </td>
            <td>{{ activeHazard.name }}</td>
          </tr>
          <tr>
            <td>Regions: </td>
            <td>{{ checkedRegionNamesText }}</td>
          </tr>
          <tr v-if="activeParameters.length > 1">
            <td>Logical Operator: </td>
            <td>{{ camelCaseToSentenceCase(activeHazard.logicalOperator) }}</td>
          </tr>
          </tbody>
        </table>
        <table class="wizard-parameter-table">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Threshold</th>
            <th>Sensitvity</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ap in activeParameters" :key="ap.id">
            <td>{{ ap.name }}</td>
            <td>{{ computeTableRow(ap) }}</td>
            <td>{{ convertSensitivity(ap.threshold.sigma, ap.threshold.units, unitSet[ap.id], ap.threshold.precision) }} {{ formatDisplayUnit(unitSet[ap.id]) }}</td>
          </tr>
        </tbody>
        </table>
      </div>
    </tab-content>
    <template slot="custom-buttons-right">
      <button @click="cancelWizard" class="wizard-btn" style="background-color: rgb(52, 152, 219); border-color: rgb(52, 152, 219); color: white;">Cancel</button>
    </template>
  </form-wizard>
</div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ParameterConfigChart from './ParameterConfigChart.vue';
import { UnitConverter } from '../js/unitConverter.js';
import { SensitivityConverter } from '../js/sensitivityConverter.js';
import { CubeSpinner } from 'vue-spinners';
import swal from 'sweetalert';
import { formatComparisonType,formatDisplayUnit } from '../js/helperFunctions.js';
import unitSets from '../constants/unitSets.json';

const unitConverter = new UnitConverter();
const sensitivityConverter = new SensitivityConverter();
const _MAX_PARAMETERS = 4;
export default {
  components: {
    CubeSpinner,
    FormWizard,
    TabContent,
    ParameterConfigChart
  },
  props: [
    'title',
    'hazardNames',
    'regions',
    'availableParametersArray',
    'initHazard',
    'newHazard',
    'initParameters'
  ],
  data() {
    return {

      unitSets: JSON.parse(JSON.stringify(unitSets)),

      // inits
      loadingWizard: false,
      activeHazard: this.initHazard,
      startNewHazard:  this.newHazard,
      activeHazardOriginal: JSON.parse(JSON.stringify(this.initHazard)),
      activeCheckedRegionIds: this.initHazard.regions ? this.initHazard.regions : [],
      activeParameters: this.initParameters,

      // static
      comparisonTypes: ['greaterThan', 'lessThan', 'between'],
      logicalOperators: ['or', 'and'],
      transparentBackgroundColorStyle: { '.active': {'background-color': 'transparent' }},

      // misc dynamic
      componentKey: 0
    }
  },
  computed: {
    config() { return this.$store.getters.config; },
    unitSetId() { return this.config.unitSetId; },
    unitSet() { return this.unitSets[this.unitSetId]; },
    between() { return this.activeHazard.threshold.comparisonType === 'between'; },
    checkedRegionNames() { return this.activeCheckedRegionIds.map(id => this.regions[id].name); },
    checkedRegionNamesText() { return this.arrayToSentence(this.checkedRegionNames); },
    computedActiveHazardName() { return this.activeHazard.name; },
    buttonClass() { return this.readOnly ? 'hc-btn-offlimits' : 'hc-btn'; },
    checkIfMaxParameters() { return this.activeParameters.length < _MAX_PARAMETERS ? true : false },
    availableParameters() { return JSON.parse(JSON.stringify(this.$store.getters.parameterConfig)); }
  },
  methods: {
    computeTableRow(ap) {
      const comparisonType = ap.threshold.comparisonType;
      const comparisonTypeStr = formatComparisonType(comparisonType);
      let muStr = '';
      if (comparisonType == 'between') {
        const mu1 = this.convertValue(ap.threshold.mu1, ap.threshold.units, this.unitSet[ap.id], ap.threshold.precision);
        const mu2 = this.convertValue(ap.threshold.mu2, ap.threshold.units, this.unitSet[ap.id], ap.threshold.precision);
        muStr = `${mu1} and ${mu2}`;
      }
      else {
        const mu = this.convertValue(ap.threshold.mu, ap.threshold.units, this.unitSet[ap.id], ap.threshold.precision);
        muStr = `${mu}`
      }
      const units = this.formatDisplayUnit(this.unitSet[ap.id]);
      const str = `${comparisonTypeStr} ${muStr} ${units}`;
      return str;
    },
    isUnavailableParameter(parameterId) {
      const nonSelectedParamters = this.getParametersNotSelected();
      const nonSelectedParameterIds = nonSelectedParamters.map(param => param.id);
      return !nonSelectedParameterIds.includes(parameterId);
    },
    getParametersNotSelected() { // parameters not currently selected
      const allParameters = JSON.parse(JSON.stringify(this.availableParametersArray));
      const currentlySelectedParametersIds = this.activeParameters.map(param => param.id);
      const selectableParameters = allParameters.filter(param => !currentlySelectedParametersIds.includes(param.id));
      return selectableParameters;
    },
    convertValue(valueIn, unitsIn, unitsOut, precision = 1) {
      const valueOut = unitConverter.convert(valueIn, unitsIn, unitsOut, precision);
      return +valueOut;
    },
    convertSensitivity(valueIn, unitsIn, unitsOut, precision = 1) {
      const valueOut = sensitivityConverter.convert(valueIn, unitsIn, unitsOut, precision);
      return +valueOut;
    },
    setLoading() {
      this.loadingWizard = !this.loadingWizard;
    },
    flipComponentKey() {
      this.componentKey = !this.componentKey; // forces reload of component
    },
    prepForParameterConfigChart() {
      const apLength = this.activeParameters.length;
      for (let i = 0; i < apLength; ++i) {
        const param = this.availableParametersArray.find( p => p.id === this.activeParameters[i].id );
        if (param) {
          const newComparisonType = this.activeParameters[i].threshold.comparisonType;
          this.activeParameters[i] = JSON.parse(JSON.stringify(param)); //assigning copy of object instead of reference
          this.activeParameters[i].threshold.comparisonType = newComparisonType;
          if (Object.keys(this.activeHazardOriginal).length > 0) { //checking if parameter is being edited
            this.activeParameters[i].threshold.mu = this.activeHazardOriginal.parameters.hasOwnProperty(param.id) ? 
                    this.activeHazardOriginal.parameters[param.id].threshold.mu :
                    this.activeParameters[i].threshold.mu;
            this.activeParameters[i].threshold.sigma = this.activeHazardOriginal.parameters.hasOwnProperty(param.id) ? 
                    this.activeHazardOriginal.parameters[param.id].threshold.sigma :
                    this.activeParameters[i].threshold.sigma;
          }
        }
      }
      // setting logical operator
      if (this.activeHazard.logicalOperator == null && this.activeParameters.length > 1) {
        this.activeHazard.logicalOperator = this.logicalOperators[0];
      }
      this.flipComponentKey();
      // To disable next button until next view is fully ready
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 500)
      })
    },
    camelCaseToSentenceCase(s) {
      if (s == null) {
        return;
      }
      const result = s.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    arrayToSentence(array) {
      return array
        .join(", ")
        .replace(/, ((?:.(?!, ))+)$/, ' and $1');
    },
    addNewParameter() {
      if (this.activeParameters.length < _MAX_PARAMETERS && this.availableParametersArray.length > 0) {
        const tmpParameter = JSON.parse(JSON.stringify(this.getParametersNotSelected()[0]));
        this.activeParameters.push(tmpParameter);
      }
      this.flipComponentKey(); // reload the parameter config chart component
    },
    removeParameter(event, index) {
      // checking if it's trying remove non existant parameters or if it is trying to remove the only parameter
      if (this.activeParameters.length -1 < index || (index != 0 && this.activeParameters.length === 1)) {
        return;
      }
      this.activeParameters.splice(index, 1);
      this.flipComponentKey() // reload the parameter config chart component
    },
    setParameter(event, parameterNumber) {
      if (this.activeParameters[parameterNumber] == null) {
        return;
      }
      const parameter = this.availableParametersArray.find( x => x.id === event.target.value );
      this.activeParameters[parameterNumber] = JSON.parse(JSON.stringify(parameter));
      this.flipComponentKey() // reload the parameter config chart component
    },
    setParameterComparisonType(event, parameterNumber) {
      if (this.activeParameters[parameterNumber] == null) {
        return;
      }
      this.activeParameters[parameterNumber].threshold.comparisonType = event.target.value;
      this.flipComponentKey(); // reload the parameter config chart component
    },
    updateCheckedRegionIds(event, regionId) {
      if (event.target.checked) {
        if (!this.activeCheckedRegionIds.includes(regionId)) {
          this.activeCheckedRegionIds.push(regionId);
        }
      }
      else {
        if (this.activeCheckedRegionIds.includes(regionId)) {
          this.activeCheckedRegionIds = this.activeCheckedRegionIds.filter(e => e !== regionId);
        }
      }
    },
    validateHazardNameInput() {
      const sIn = document.getElementById('inputHazardName').value;
      const alphanumericCheck = /\d|[A-z]/;
      if ([null, undefined, '', " ", ""].includes(sIn) || sIn.includes(":") || !alphanumericCheck.test(sIn) ) {
        swal('You must enter a valid name to proceed');
        return false;
      }

      const names = this.hazardNames.filter((item) => item === sIn);

      if (this.startNewHazard && names.length > 0) {
        swal('You must enter a unique name to proceed');
        return false;
      }

      if (!this.startNewHazard && names.length > 1) {
        swal('You must enter a unique name to proceed');
        return false;
      }
      this.flipComponentKey();
      return true;
    },
    validateHazardRegionInput() {
      const rIn = this.checkedRegionNames;
      if (rIn.length > 0) {
        return true;
      }
      else {
        swal('You must select  at least one region to proceed.');
        return false;
      }
    },
    validateUniqueNames() {
      if (this.startNewHazard) {
        document.getElementById('inputHazardName').value = '';
      }
    },
    validateThresholdInput() {
      const numberCheck =  /^[+-]?\d+(\.\d+)?$/;
      const isNotValidNumber = this.activeParameters.find((x) => !numberCheck.test(x.threshold.mu) || !numberCheck.test(x.threshold.sigma) );

      if (isNotValidNumber) {
        swal('You must enter only valid numbers to proceed.');
        return false;
      }

      const isGreaterThanMax = this.activeParameters.find(x => x.threshold.mu > x.threshold.maxMu || x.threshold.sigma > x.threshold.maxSigma );
      const isLessThanMin = this.activeParameters.find(x => x.threshold.mu < x.threshold.minMu || x.threshold.sigma < x.threshold.minSigma );

      if (isLessThanMin || isGreaterThanMax) {
        swal('You can only enter numbers between max and min thresholds to proceed.');
        return false;
      }

      return true;
    },
    hide() {
      this.$emit('child-hide-event');
    },
    convertHazardNameToHazardId(hazardName) {
      const hazardId = hazardName.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
      return hazardId;
    },
    constructHazardObjFromInput() {
      const hazardObj = {};
      hazardObj.id = this.startNewHazard ? this.convertHazardNameToHazardId(this.activeHazard.name) : this.activeHazard.id;
      hazardObj.name = this.activeHazard.name;
      hazardObj.logicalOperator = this.activeHazard.logicalOperator;
      hazardObj.regions = this.activeCheckedRegionIds;
      hazardObj.enabled = (this.activeHazard.enabled || !this.activeHazard.hasOwnProperty("enabled")) ? true : false;
      hazardObj.parameters = {};
      for (let i = 0; i < this.activeParameters.length; i++) {
        const param = JSON.parse(JSON.stringify(this.activeParameters[i]));
        hazardObj.parameters[this.activeParameters[i].id] = (({ id, threshold }) => ({ id, threshold }))(param);
        hazardObj.parameters[this.activeParameters[i].id].threshold = (({ mu, mu1, mu2, sigma, displayUnits, precision, comparisonType }) => ({ mu, mu1, mu2, sigma, displayUnits, precision, comparisonType }))(param.threshold);
      }
      return hazardObj;
    },
    complete() {
      const hazardObj = this.constructHazardObjFromInput();
      this.hazardObj = hazardObj;
      this.$emit('hazard-complete-event', hazardObj, this.initHazard.id);
      this.hide();
      this.loadingWizard = true;
    },
    updateFromChart(parameter, mu, mu1, mu2, sigma) {
      const ap = this.activeParameters.find(x => x.id === parameter.id);
      ap.threshold.mu = mu;
      ap.threshold.mu1 = mu1;
      ap.threshold.mu2 = mu2;
      ap.threshold.sigma = sigma;
    },
    cancelWizard() {
      this.$emit('hazard-cancel-event', this.activeHazardOriginal, this.activeHazardOriginal.id);
    },
    formatDisplayUnit,
    formatComparisonType
  }
}

</script>
