const heatMapFragmentShader =  `
  precision highp float;
  uniform sampler2D u_image;
  varying vec2 v_texture;
  uniform float u_opacity;
  uniform vec2 u_textureSize;

  vec3 map_to_color(float t) {
    if (t < 0.22) {
      return vec3(0.565, 0.847, 0.392);
    } else if (t < 0.42) {
      return vec3(0.937, 0.957, 0.443);
    } else if (t < 0.62) {
      return vec3(0.992, 0.788, 0.306);
    } else if (t < 0.82) {
      return vec3(0.933, 0.420, 0.247);
    } else {
      return vec3(0.827, 0.165, 0.153);
    }
  }

  void main() {
    if (v_texture[0] < 0.0 || v_texture[0] > 1.0 || v_texture[1] < 0.0 || v_texture[1] > 1.0) {
      discard;
    }
    vec4 imageData = texture2D(u_image, v_texture, 1.0);
    float value = imageData[0];
    gl_FragColor = vec4(map_to_color(value), u_opacity);
  }
`
module.exports = heatMapFragmentShader;
