const alertParameters = {
    'earthquake': {
      id: 'earthquake',
      name: 'Earthquake',
      operator: 'and',
      displayChildConditions: true,
      childConditions: {
          'magnitude': {
                id: 'mag',
                name: 'Magnitude',
                units: '',
                threshold: 2.5,
                comparisonType: 'greaterThan'
          }
      }
    },
    'wildfire': {
        id: 'wildfire',
        name: 'Wild Fire',
        operator: 'and',
        displayChildConditions: false,
        childConditions: {
            'confidence': {
                id: 'confidence',
                name: 'Confidence',
                units: '',
                threshold: 90,
                comparisonType: 'greaterThan'
            }
        }
    },
    'tornado': {
      id: 'tornado',
      name: 'Tornado',
      operator: 'and'
    },
    'winds': {
      id: 'winds',
      name: 'Winds',
      operator: 'and'
    },
    'hail': {
      id: 'hail',
      name: 'Hail',
      operator: 'and',
      displayChildConditions: true,
      childConditions: {
          'size': {
              id: 'size',
              name: 'Size (inches)',
              units: 'centi-inches',
              displayUnits: 'in',
              threshold: 1,
              comparisonType: 'greaterThan'
          }
      }
    }
  };


  module.exports = {
    alertParameters
  };