var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "timeline__block timeline__block--" + _vm.numberOfBlocks + "block"
    },
    [
      _c(
        "div",
        { staticClass: "timeline__row timeline__row--labels" },
        _vm._l(_vm.dataGroup.riskData, function(item) {
          return _c("SiteHazardRowLabel", {
            key: item.riskHour,
            attrs: {
              data: item,
              "sunrise-hour": _vm.sunriseHour,
              "sunset-hour": _vm.sunsetHour
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "timeline__row timeline__row--readings" },
        _vm._l(_vm.dataGroup.riskData, function(item) {
          return _c("div", {
            key: item.riskHour,
            class:
              "timeline__unit timeline__unit--" +
              item.riskTimeSpanHours +
              "hr timeline__unit--lev0" +
              item.value
          })
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }