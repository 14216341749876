var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header" },
    [
      _c(
        "div",
        { staticClass: "flex-row flex-row--end" },
        [
          _c("nav-dots"),
          _vm._v(" "),
          _c("img", {
            staticClass: "header__logo header__logo-clickable",
            attrs: {
              src: require("../assets/img/decisionvue-logo.svg"),
              alt: "DecisionVue"
            },
            on: { click: _vm.nav2map }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("UserButton")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("img", {
        staticClass: "header__logo center",
        attrs: { src: require("../assets/img/wsp-logo.png"), alt: "WSP" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }