var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cell", staticStyle: { "z-index": "1000" } },
    [
      _c("span", {
        staticClass: "dot",
        class: {
          active: _vm.dotActivityStatusList[0],
          visited: _vm.dotVisitedStatusList[0]
        },
        on: { click: _vm.nav2map }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "dot",
        class: {
          active: _vm.dotActivityStatusList[1],
          visited: _vm.dotVisitedStatusList[1]
        },
        on: { click: _vm.nav2region }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "dot",
        class: {
          active: _vm.dotActivityStatusList[2],
          visited: _vm.dotVisitedStatusList[2]
        },
        on: { click: _vm.nav2site }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "dot",
        class: {
          active: _vm.dotActivityStatusList[3],
          visited: _vm.dotVisitedStatusList[3]
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }