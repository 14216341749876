var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-wizard",
        {
          style: _vm.transparentBackgroundColorStyle,
          attrs: {
            title: _vm.title,
            subtitle: "",
            shape: "square",
            color: "#3498db"
          },
          on: { "on-complete": _vm.complete, "on-loading": _vm.setLoading }
        },
        [
          _c(
            "tab-content",
            {
              staticClass: "wizard-parameter-tab",
              attrs: {
                title: "Choose parameter",
                icon: "ti ti-cloud",
                "before-change": _vm.prepForParameterConfigChart
              }
            },
            [
              _vm._l(_vm.activeParameters, function(activeParameter, index) {
                return _c(
                  "div",
                  {
                    key: activeParameter.id,
                    staticClass: "wizard-parameter-item"
                  },
                  [
                    _c("h4", [
                      _c(
                        "button",
                        {
                          class: _vm.buttonClass,
                          attrs: { id: "btn-remove-" + index },
                          on: {
                            click: function($event) {
                              return _vm.removeParameter($event, index)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-minus" })]
                      ),
                      _vm._v(
                        "\n          Parameter " +
                          _vm._s(index + 1) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "wizard-parameter-container" }, [
                      _c("div", { staticClass: "wizard-parameter-content" }, [
                        _c("span", { staticClass: "wizard-parameter-select" }, [
                          _c(
                            "select",
                            {
                              staticClass:
                                "wg-form-control wg-form-control--auto",
                              attrs: { required: "" },
                              on: {
                                change: function($event) {
                                  return _vm.setParameter($event, index)
                                }
                              }
                            },
                            _vm._l(_vm.availableParametersArray, function(
                              availableParameter
                            ) {
                              return _c(
                                "option",
                                {
                                  key: availableParameter.id,
                                  attrs: {
                                    disabled: _vm.isUnavailableParameter(
                                      availableParameter.id
                                    )
                                  },
                                  domProps: {
                                    selected:
                                      availableParameter.id ===
                                      activeParameter.id,
                                    value: availableParameter.id
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(availableParameter.name) +
                                      "\n                "
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    activeParameter.threshold.comparisonType,
                                  expression:
                                    "activeParameter.threshold.comparisonType"
                                }
                              ],
                              staticClass:
                                "wg-form-control wg-form-control--auto",
                              attrs: { required: "" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      activeParameter.threshold,
                                      "comparisonType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function($event) {
                                    return _vm.setParameterComparisonType(
                                      $event,
                                      index
                                    )
                                  }
                                ]
                              }
                            },
                            _vm._l(_vm.comparisonTypes, function(
                              comparisonType
                            ) {
                              return _c(
                                "option",
                                {
                                  key: comparisonType,
                                  domProps: { value: comparisonType }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.camelCaseToSentenceCase(
                                          comparisonType
                                        )
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]),
                        _vm._v(" "),
                        _vm.activeParameters.length != 1
                          ? _c("span", {
                              staticClass: "wizard-parameter-remove-btn"
                            })
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _vm.checkIfMaxParameters
                ? _c(
                    "span",
                    { staticClass: "wizard-parameter-add-container" },
                    [
                      _c(
                        "button",
                        {
                          class: _vm.buttonClass,
                          attrs: { id: "wizard-btn-add-parameter" },
                          on: {
                            click: function($event) {
                              return _vm.addNewParameter($event)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-plus" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "wizard-btn-add-parameter" } },
                        [_vm._v("Add Parameter")]
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            {
              staticClass: "left-15",
              attrs: {
                title: "Configure thresholds",
                "before-change": _vm.validateThresholdInput,
                icon: "ti ti-settings"
              }
            },
            [
              _vm.activeParameters.length > 1
                ? _c("div", [
                    _c("h4", [_vm._v("Logical Operator:")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.activeHazard.logicalOperator,
                            expression: "activeHazard.logicalOperator"
                          }
                        ],
                        staticClass: "wg-form-control wg-form-control--auto",
                        attrs: { required: "" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.activeHazard,
                              "logicalOperator",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.logicalOperators, function(operator) {
                        return _c(
                          "option",
                          { key: operator, domProps: { value: operator } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.camelCaseToSentenceCase(operator)) +
                                "\n          "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.activeParameters, function(ap) {
                return _c(
                  "div",
                  { key: ap.id },
                  [
                    _c("h4", [_vm._v(_vm._s(ap.name))]),
                    _vm._v(" "),
                    _c("parameter-config-chart", {
                      key: _vm.componentKey,
                      attrs: {
                        parameter: ap,
                        parameterInfo: _vm.availableParameters[ap.id],
                        units: _vm.availableParameters[ap.id].threshold.units,
                        displayUnits: _vm.unitSet[ap.id]
                      },
                      on: { "update-from-chart-event": _vm.updateFromChart }
                    })
                  ],
                  1
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            {
              attrs: {
                title: "Select regions",
                icon: "ti ti-world",
                "before-change": _vm.validateHazardRegionInput
              }
            },
            [
              _vm._v(
                "\n      Select what regions this hazard will apply to\n      "
              ),
              _vm._l(_vm.regions, function(region) {
                return _c(
                  "div",
                  {
                    key: region.id,
                    staticClass: "left-45",
                    attrs: { value: region.id }
                  },
                  [
                    _c("input", {
                      attrs: {
                        id: "cb-wizard-region-" + region.id,
                        type: "checkbox"
                      },
                      domProps: {
                        checked: _vm.activeCheckedRegionIds.includes(region.id)
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateCheckedRegionIds($event, region.id)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { for: "cb-wizard-region-" + region.id } },
                      [_vm._v(_vm._s(region.name))]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            {
              attrs: {
                title: "Choose name",
                icon: "ti ti-user",
                "before-change": _vm.validateHazardNameInput,
                "after-change": _vm.validateUniqueNames
              }
            },
            [
              _c("div", { staticClass: "left-65" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.activeHazard.name,
                      expression: "activeHazard.name"
                    }
                  ],
                  staticClass: "wg-form-control",
                  staticStyle: { width: "200px" },
                  attrs: {
                    id: "inputHazardName",
                    placeholder: "Enter a unique name",
                    maxlength: "30",
                    required: "",
                    type: "text"
                  },
                  domProps: { value: _vm.activeHazard.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.activeHazard, "name", $event.target.value)
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "tab-content",
            { attrs: { title: "Review", icon: "ti ti-check" } },
            [
              _c("div", { staticClass: "wizard-review-container" }, [
                _c("table", { staticClass: "wizard-review-detail-table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("Name: ")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.activeHazard.name))])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Regions: ")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.checkedRegionNamesText))])
                    ]),
                    _vm._v(" "),
                    _vm.activeParameters.length > 1
                      ? _c("tr", [
                          _c("td", [_vm._v("Logical Operator: ")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.camelCaseToSentenceCase(
                                  _vm.activeHazard.logicalOperator
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "wizard-parameter-table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Parameter")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Threshold")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Sensitvity")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.activeParameters, function(ap) {
                      return _c("tr", { key: ap.id }, [
                        _c("td", [_vm._v(_vm._s(ap.name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.computeTableRow(ap)))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.convertSensitivity(
                                ap.threshold.sigma,
                                ap.threshold.units,
                                _vm.unitSet[ap.id],
                                ap.threshold.precision
                              )
                            ) +
                              " " +
                              _vm._s(_vm.formatDisplayUnit(_vm.unitSet[ap.id]))
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("template", { slot: "custom-buttons-right" }, [
            _c(
              "button",
              {
                staticClass: "wizard-btn",
                staticStyle: {
                  "background-color": "rgb(52, 152, 219)",
                  "border-color": "rgb(52, 152, 219)",
                  color: "white"
                },
                on: { click: _vm.cancelWizard }
              },
              [_vm._v("Cancel")]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }