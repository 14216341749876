import Vue from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';
import './css/style.css';
import './assets/fontawesome/css/all.css';
import './assets/themify/themify-icons.css';

new Vue({
  store,
  el: '#app',
  router: router,
  render: h => h(App)
}).$mount('#app');
