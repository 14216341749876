<template>
<div class="user-dropdown">
  <div class="user-dropbtn">
    <i class="fas fa-user"></i><span class="user-name"></span>
  </div>
  <div class="user-dropdown-content">
    <a @click="nav2profile()">Profile</a>
    <a @click="nav2settings()">Settings</a>
    <a @click="nav2Policy()">Policies</a>
    <a @click="logout()">Logout</a>
  </div>
</div>
</template>

<script>
import authService from '../js/authenticationService';


export default {
  methods: {
    nav2profile() {
      this.$router.push({ path: '/profile' });
    },
    nav2settings() {
      this.$router.push({ path: '/settings' });
    },
    nav2Policy() {
      this.$router.push({ path: '/policies' });
    },
    logout() {
      this.$store.dispatch('logout');
      window.localStorage.clear();
      authService.logout();
    }
  }
}
</script>