var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", { attrs: { transform: _vm.translate } }, [
    _c("g", { ref: "axis" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }